import React from 'react';
import { PropTypes } from 'prop-types';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

import OrderItemRow from './OrderItemRow';


const OrderItemsTable = ({ order, location }) => (
  <Table classes={{ root: 'order-items-table' }}>
    <TableHead>
      <TableRow>
        <TableCell>
          {'Item Type'}
        </TableCell>
        <TableCell>
          {'Description'}
        </TableCell>
        <TableCell align="right">
          {'Item Price'}
        </TableCell>
        <TableCell align="right">
          {'Quantity'}
        </TableCell>
        <TableCell align="right">
          {'Item Total'}
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {order.orderItem.map((orderItem) => (
        <OrderItemRow
          key={orderItem.id}
          order={order}
          orderItem={orderItem}
          location={location}
        />
      ))}
    </TableBody>
  </Table>
);

OrderItemsTable.propTypes = {
  location: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};


export default OrderItemsTable;
