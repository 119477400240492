export const ISSUE_TYPES = [
  { label: 'General Inquiry', value: 'general' },
  { label: 'Events', value: 'events' },
  { label: 'Membership', value: 'membership' },
  { label: 'Passes', value: 'passes' },
];

export const SUPPORT_REQUEST_STATUSES = [
  {
    label: 'Open',
    value: 'open',
  },
  {
    label: 'Closed',
    value: 'closed',
  },
];
