import React from 'react';
import { PropTypes } from 'prop-types';
import { MenuItem, ListItemAvatar, ListItemText } from '@material-ui/core';

import { PersonAvatar } from '../../avatars';


const ProductSearchItem = ({ children, isFocused, data, innerProps }) => (
  <MenuItem
    selected={isFocused}
    onClick={innerProps.onClick}
    className="product-search-item"
  >
    <ListItemAvatar>
      <PersonAvatar
        src={data.imageUrl}
        size="md"
      />
    </ListItemAvatar>
    <ListItemText
      primary={children.toString()}
      secondary={data.name}
      className="product-name"
    />
  </MenuItem>
);

ProductSearchItem.propTypes = {
  children: PropTypes.any,
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool,
};


export default ProductSearchItem;
