import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { AccordionDetails, TableRow, TableCell, Checkbox } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


class DataTableRow extends React.PureComponent {
  handleRowClick = () => {
    this.props.onClick(this.props.data);
  };

  handleItemToggle = () => {
    this.props.onItemToggle(this.props.data.id);
  };

  // eslint-disable-next-line class-methods-use-this
  handleCheckboxClick = (event) => event.stopPropagation();

  render() {
    const {
      data, expansionPanel, fieldsConfig, hasExpansionPanel, isExpanded,
      selectable, selected, showPointer,
    } = this.props;

    return (
      <>
        <TableRow
          hover
          onClick={this.handleRowClick}
          className={classnames('data-table-row', { 'row-pointer': showPointer })}
        >
          {selectable && (
            <TableCell className="data-table-cell min-width">
              <Checkbox
                checked={selected}
                onChange={this.handleItemToggle}
                onClick={this.handleCheckboxClick}
              />
            </TableCell>
          )}
          {fieldsConfig.map((field, idx) => (
            <TableCell
              key={idx}
              align={field.align || 'inherit'}
              className={classnames('data-table-cell', { [field.className]: Boolean(field.className) })}
              style={field.style ?? null}
            >
              {field.render ? field.render(data) : data[field.sortString]}
            </TableCell>
          ))}
          {expansionPanel && (
            <TableCell className="data-table-cell min-width">
              {hasExpansionPanel && (
                <ExpandMoreIcon className={classnames({ 'rotate-180': isExpanded })} />
              )}
            </TableCell>
          )}
        </TableRow>
        {hasExpansionPanel && isExpanded && (
          <TableRow className="data-table-row expansion-panel-row">
            <TableCell
              className="expansion-panel-cell"
              colSpan={fieldsConfig.length + 1}
              padding="none"
            >
              <AccordionDetails className="expansion-panel-details">
                {expansionPanel(data)}
              </AccordionDetails>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  }
}

DataTableRow.propTypes = {
  data: PropTypes.object.isRequired,
  expansionPanel: PropTypes.func,
  fieldsConfig: PropTypes.array.isRequired,
  hasExpansionPanel: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onItemToggle: PropTypes.func.isRequired,
  selectable: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  showPointer: PropTypes.bool.isRequired,
};


export default DataTableRow;
