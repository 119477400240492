import axios from 'axios';

import firebaseService from '../firebase';
import { getNamespace } from '../utility/urls';


let config, region;

export const injectConfig = (_config) => {
  config = _config;
};

export const injectRegion = (_region) => {
  region = _region;
};

export const initAxiosRegion = async (/*server*/) => {
  const axiosConfig = {
    // baseURL: `${config.apiRoot[server]}`,
    baseURL: `${config.apiRoot['us-east-1']}`,
  };
  const instance = axios.create(axiosConfig);

  return instance;
};

export const initAxios = async () => {
  const token = await firebaseService.getIdToken();
  const bearer = getNamespace();
  const instance = axios.create({
    baseURL: `${config.apiRoot[region]}`,
    headers: { Authorization: `${bearer} ${token}` },
  });

  return instance;
};

export const initAxiosDocs = async () => {
  const token = await firebaseService.getIdToken();
  const bearer = getNamespace();
  const instance = axios.create({
    baseURL: `${config.docsRoot[region]}`,
    headers: { Authorization: `${bearer} ${token}` },
  });

  return instance;
};
