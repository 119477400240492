import React from 'react';
import { PropTypes } from 'prop-types';
import Dropzone from 'react-dropzone';
import { Typography, Button } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';


const FileUploader = ({ acceptedFileTypes, handleNewFiles }) => (
  <div className="file-uploader">
    <Dropzone
      onDrop={(acceptedFiles) => handleNewFiles(acceptedFiles)}
      accept={acceptedFileTypes}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          className="dropzone-content"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <AttachFileIcon />
          <Typography
            variant="h6"
            align="center"
          >
            {'Drag a File'}
          </Typography>
          <Typography
            variant="caption"
            align="center"
          >
            {'--OR--'}
          </Typography>
          <Button
            variant="contained"
            size="small"
          >
            {'Select one or more files from your computer'}
          </Button>
        </div>
      )}
    </Dropzone>
  </div>
);

FileUploader.propTypes = {
  acceptedFileTypes: PropTypes.string,
  handleNewFiles: PropTypes.func.isRequired,
};

FileUploader.defaultProps = {
  acceptedFileTypes: 'image/jpeg, image/png, image/jpg',
};


export default FileUploader;
