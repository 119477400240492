import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl, FormControlLabel, Radio, RadioGroup, Typography,
} from '@material-ui/core';

import * as API from 'service/api';
import { getLocationTimezone } from 'service/i18n';
import { getErrorMessage, objectHasErrors } from 'service/utility';
import { noMoreThan } from 'service/utility/errorMessages';
import { formatItemPrice } from 'service/utility/pricing';
import * as timeUtils from 'service/utility/timeUtils';

import { NumberField } from '../../formFields';
import { Spinner, ButtonSpinner } from '../../statusIndicators';


class ResumeMembershipDialog extends React.Component {
  constructor(props) {
    super(props);

    this.timeZone = getLocationTimezone(this.props.currentLocation);

    this.state = {
      loading: true,
      saving: false,
      daysUntilNextBilling: null,
      creditInDays: 0,
      currentDate: timeUtils.iso8601Today(this.timeZone),
      customDays: 0,
      resumeType: this.props.membership.isRecurring ? 'prorate' : 'adjustDate',
      validationErrors: {},
      billingPeriodEndDisplay: null,
      billingPeriodStartDisplay: null,
      durationInDays: 0,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.calculateMembershipResume();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  calculateMembershipResume = async () => {
    const { membership, onFinish } = this.props;
    const { currentDate } = this.state;

    try {
      const { data: calculatedMembershipResume } = await API.calculateMembership(membership.id);

      if (
        calculatedMembershipResume.shouldChargeFee === false ||
        (!membership.isRecurring && (!membership.endEffectiveDate || !membership.startEffectiveDate))
      ) {
        const payload = {
          description: 'Membership reactivated.',
        };

        await API.activateMembership(membership.id, payload);

        onFinish();
        return;
      }

      const creditInDays = calculatedMembershipResume.creditInDays || 0;

      //for recurring memberships
      const daysUntilNextBilling = (
        membership.isRecurring
          ? timeUtils.numberOfDaysBetween(
            currentDate,
            timeUtils.iso8601Date(calculatedMembershipResume.billingPeriodEnd),
            this.timeZone
          )
          : 0
      );

      const billingPeriodStartDisplay = (
        membership.isRecurring
          ? timeUtils.formatShortDate(timeUtils.iso8601Date(calculatedMembershipResume.billingPeriodStart))
          : ''
      );

      const billingPeriodEndDisplay = (
        membership.isRecurring
          ? timeUtils.formatShortDate(timeUtils.iso8601Date(calculatedMembershipResume.billingPeriodEnd))
          : ''
      );

      //for non recurring memberships
      const durationInDays =
        !membership.isRecurring
          ? timeUtils.numberOfDaysBetween(
            timeUtils.iso8601Date(membership.startEffectiveDate),
            timeUtils.iso8601Date(membership.endEffectiveDate),
          )
          : 0;

      if (this._isMounted) {
        this.setState({
          daysUntilNextBilling,
          creditInDays,
          customDays: creditInDays,
          loading: false,
          billingPeriodStartDisplay,
          billingPeriodEndDisplay,
          durationInDays,
        });
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.calculateMembership error:');
      console.log(errorMessage);

      toast.error(errorMessage);

      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }
  };

  handleChange = (name) => (update) => {
    const hasNewValue = update.hasOwnProperty('value');
    const hasNewError = update.hasOwnProperty('error');

    if (!hasNewValue && !hasNewError) return;

    this.setState((prevState) => {
      const newValue = update.value;
      const newError = update.error;
      const oldValue = prevState[name];
      const oldError = prevState.validationErrors[name];
      const valueHasChanged = oldValue !== newValue;
      const errorHasChanged = oldError !== newError;

      if (
        (!hasNewValue || (hasNewValue && !valueHasChanged)) &&
        (!hasNewError || (hasNewError && !errorHasChanged))
      ) {
        return null;
      }

      const newState = {};

      if (hasNewValue && valueHasChanged) {
        newState[name] = newValue;
      }

      if (hasNewError && errorHasChanged) {
        newState.validationErrors = {
          ...prevState.validationErrors,
          [name]: newError,
        };
      }

      return newState;
    });
  };

  handleCustomDaysChange = this.handleChange('customDays');

  handleResumeTypeChange = (event) => this.setState({ resumeType: event.target.value });

  handleFormSubmit = async () => {
    const {
      membership: { id: membershipId, customer }, putItemsInCustomerCart,
      onFinish, onClose, rightDrawer, setRightDrawerView, toggleRightDrawer,
    } = this.props;
    const { currentDate, resumeType, customDays } = this.state;

    if (resumeType === 'noProrate' || resumeType === 'noAdjustDate') {
      const payload = {
        description: 'Membership reactivated.',
      };

      this.setState({ saving: true });

      try {
        await API.activateMembership(membershipId, payload);

        onFinish();
      } catch (error) {
        const errorMessage = getErrorMessage(error);

        console.log('API.activateMembership error:');
        console.log(errorMessage);

        toast.error(errorMessage);
        this.setState({ saving: false });
      }
    }

    if (resumeType === 'prorate') {
      const item = {
        type: 'MEMBERSHIP_RESUME',
        membershipId,
        resumeDate: currentDate,
        doNotProrate: 0,
        billImmediately: 1,
        creditInDays: customDays,
      };
      putItemsInCustomerCart([item], customer.id);

      if (rightDrawer.view !== 'cart') {
        setRightDrawerView('cart');
      }
      if (!rightDrawer.isOpen) {
        toggleRightDrawer();
      }
    }

    if (resumeType === 'adjustDate') {
      const payload = {
        addedDays: customDays,
      };

      this.setState({ saving: true });

      try {
        await API.resumeMembership(membershipId, payload);

        onFinish();
      } catch (error) {
        const errorMessage = getErrorMessage(error);

        console.log('API.resumeMembership error:');
        console.log(errorMessage);

        toast.error(errorMessage);
        this.setState({ saving: false });
      }
    }

    onClose();
  };

  render() {
    const { membership, onClose, currentLocation } = this.props;
    const {
      loading, daysUntilNextBilling, billingPeriodStartDisplay, billingPeriodEndDisplay,
      currentDate, creditInDays, customDays, resumeType, validationErrors, saving, durationInDays,
    } = this.state;

    const endEffectiveDate = (
      !membership.isRecurring && membership.endEffectiveDate
        ? timeUtils.formatShortDate(timeUtils.iso8601Date(membership.endEffectiveDate))
        : null
    );
    const newEndEffectiveDate = (
      !membership.isRecurring && membership.endEffectiveDate
        ? customDays > 0 & customDays < durationInDays
          // eslint-disable-next-line max-len
          ? timeUtils.formatShortDate(timeUtils.addDaysToIso8601Date(timeUtils.iso8601Date(membership.endEffectiveDate), customDays || 0))
          : timeUtils.formatShortDate(timeUtils.iso8601Date(membership.endEffectiveDate))
        : null
    );

    const hasErrors = objectHasErrors(validationErrors);

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        classes={{ root: 'resume-membership-dialog' }}
        open
        onClose={onClose}
      >
        <DialogTitle>
          {'Resume membership'}
        </DialogTitle>
        <DialogContent classes={{ root: 'resume-membership-dialog-content' }}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {membership.isRecurring ? (
                <DialogContentText gutterBottom>
                  <Typography>
                    {`Billing period: ${billingPeriodStartDisplay} - ${billingPeriodEndDisplay}`}
                  </Typography>
                  <Typography>
                    {`Days left in billing period: ${daysUntilNextBilling}`}
                  </Typography>
                  <Typography>
                    {`Credit in days (days paid for on previous billing cycle): ${customDays}`}
                  </Typography>
                  <Typography>
                    {`Membership Price: ${formatItemPrice(membership, currentLocation)}`}
                  </Typography>
                </DialogContentText>
              ) : (
                <DialogContentText gutterBottom>
                  <Typography>
                    {`Hold Date Start: ${membership.holdStartDate ? timeUtils.formatShortDate(timeUtils.iso8601Date(membership.holdStartDate)) : ' - '}`}
                  </Typography>
                  <Typography>
                    {`Resume Date: ${timeUtils.formatShortDate(timeUtils.iso8601Date(currentDate))}`}
                  </Typography>
                  <Typography>
                    {`Days Between: ${creditInDays > 0 ? creditInDays : ' - '}`}
                  </Typography>
                </DialogContentText>
              )}
              <FormControl fullWidth>
                <RadioGroup
                  name="resumeType"
                  onChange={this.handleResumeTypeChange}
                  value={resumeType}
                  classes={{ root: 'resume-membership-radio-options' }}
                >
                  {membership.isRecurring ? (
                    <>
                      <FormControlLabel
                        value="prorate"
                        control={<Radio />}
                        label={`Prorate ${daysUntilNextBilling} days til next billing date and resume`}
                      />
                      <FormControlLabel
                        value="noProrate"
                        control={<Radio />}
                        label="Resume without proration fee"
                      />
                    </>
                  ) : (
                    <>
                      <FormControlLabel
                        value="adjustDate"
                        control={<Radio />}
                        label={
                          <>
                            <div className="form-row">
                              <Typography display="inline">
                                {'Add'}
                              </Typography>
                              <NumberField
                                onChange={this.handleCustomDaysChange}
                                error={validationErrors.customDays}
                                value={customDays}
                                display="inline"
                                required={resumeType === 'adjustDate'}
                                allowNegative={false}
                                validate={(days) => (days > durationInDays ? noMoreThan('Days to add', durationInDays) : null)}
                              />
                              <Typography display="inline">
                                {'days to'}
                              </Typography>
                            </div>
                            <Typography>
                              {`Current End Date: ${endEffectiveDate}`}
                            </Typography>
                            <Typography>
                              {`New End Date: ${newEndEffectiveDate}`}
                            </Typography>
                          </>
                        }
                      />
                      <FormControlLabel
                        value="noAdjustDate"
                        control={<Radio />}
                        label="Resume without adjusting end date"
                      />
                    </>
                  )}
                </RadioGroup>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            {'Cancel'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={loading || saving || hasErrors}
            onClick={this.handleFormSubmit}
          >
            {'Submit'}
            {saving && <ButtonSpinner />}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ResumeMembershipDialog.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  membership: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  putItemsInCustomerCart: PropTypes.func.isRequired,
  rightDrawer: PropTypes.object.isRequired,
  setRightDrawerView: PropTypes.func.isRequired,
  toggleRightDrawer: PropTypes.func.isRequired,
};


export default ResumeMembershipDialog;
