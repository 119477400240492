import React from 'react';
import { PropTypes } from 'prop-types';
import { Input, Button, IconButton } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import SearchIcon from '@material-ui/icons/Search';


const SearchBarView = ({
  autoFocus, placeholder, query, realTimeSearch,
  handleQueryChange, handleKeyPress, handleSearch, handleClear,
}) => (
  <div className="search-bar">
    <Input
      placeholder={placeholder}
      disableUnderline
      classes={{ root: 'input-root', input: 'input' }}
      value={query}
      onChange={handleQueryChange}
      autoFocus={autoFocus}
      // onKeyPress={(event) => {
      //   if (event.key === 'Enter' && onEnter) onEnter();
      // }}
      onKeyPress={handleKeyPress}
    />
    {query.length > 0 && (
      <IconButton
        className="clear-search"
        onClick={handleClear}
      >
        <CancelOutlinedIcon />
      </IconButton>
    )}
    {!realTimeSearch && (
      <Button
        variant="contained"
        color="primary"
        className="search-button"
        onClick={handleSearch}
      >
        {'Search'}
        <SearchIcon
          className="search-icon"
        />
      </Button>
    )}
  </div>
);

SearchBarView.propTypes = {
  autoFocus: PropTypes.bool,
  handleClear: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleQueryChange: PropTypes.func,
  handleSearch: PropTypes.func,
  // onEnter: PropTypes.func,
  placeholder: PropTypes.string,
  query: PropTypes.string.isRequired,
  realTimeSearch: PropTypes.bool,
};


export default SearchBarView;
