import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography, TableRow, TableCell } from '@material-ui/core';

import {
  getOrderItemUrl, getOrderItemTypeName, getOrderItemDescription,
  getOrderItemPrice, getOrderItemQuantity, getOrderItemSubtotal,
} from 'service/utility/order';
import { formatPriceWithoutTax } from 'service/utility/pricing';
import { getProductAdditionalDetails } from 'service/utility/product';


const OrderItemRow = ({ location, order, orderItem }) => {
  const orderItemUrl = getOrderItemUrl(orderItem);
  const orderItemTypeName = getOrderItemTypeName(orderItem);
  const orderItemDescription = getOrderItemDescription(orderItem);
  const additionalDetails = getProductAdditionalDetails(orderItem.product, orderItem.productVariant);
  const orderItemQuantity = getOrderItemQuantity(orderItem);
  const orderItemPrice = getOrderItemPrice(order, orderItem, location);
  const orderItemSubtotal = getOrderItemSubtotal(order, orderItem, location);

  return (
    <TableRow key={orderItem.id}>
      <TableCell>
        {orderItemUrl ? (
          <Link to={orderItemUrl}>
            <Typography>
              {orderItemTypeName}
            </Typography>
          </Link>
        ) : (
          <Typography>
            {orderItemTypeName}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Typography>
          {orderItemDescription}
          {additionalDetails && (
            <span className="item-additional-details">
              {additionalDetails}
            </span>
          )}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>
          {formatPriceWithoutTax(orderItemPrice, location, { currency: order.currency })}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>
          {orderItemQuantity}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>
          {formatPriceWithoutTax(orderItemSubtotal, location, { currency: order.currency })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

OrderItemRow.propTypes = {
  location: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  orderItem: PropTypes.object.isRequired,
};


export default OrderItemRow;
