import React from 'react';
import { PropTypes } from 'prop-types';

import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';
import { Spinner } from '../../statusIndicators';


const HAS_HOLD_END_DATE_PICKLIST_VALUES = [
  {
    label: 'All',
    value: 0,
  },
  {
    label: 'Has Resume Date',
    value: '@true',
  },
  {
    label: 'No Resume Date',
    value: '@false',
  },
];


class HasHoldEndDateFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      holdEndDate: (
        this.props.hasOwnProperty('holdEndDate') && this.props.holdEndDate !== 'null'
          ? this.props.holdEndDate
          : 0
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['holdEndDate'])) {
      this.handleValueChange();
    }
  }


  getOwnFilterMap = () => ({
    holdEndDate: this.state.holdEndDate === 0 ? null : this.state.holdEndDate,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ holdEndDate: update.value });
    }
  };

  render() {
    const { loading, holdEndDate } = this.state;

    return loading ? (
      <Spinner />
    ) : (
      <PicklistField
        fullWidth
        className="has-hold-end-date-filter"
        label="Resume Date"
        value={holdEndDate}
        onChange={this.handleChange}
        picklistValues={HAS_HOLD_END_DATE_PICKLIST_VALUES}
      />
    );
  }
}

HasHoldEndDateFilter.propTypes = {
  holdEndDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onValueChange: PropTypes.func,
};

HasHoldEndDateFilter.ownName = 'HasHoldEndDateFilter';
HasHoldEndDateFilter.ownFilters = ['holdEndDate'];


export default HasHoldEndDateFilter;
