import React from 'react';
import { PropTypes } from 'prop-types';


const Header = ({ left, center, right }) => (
  <header id="header">
    <div className="actions-group actions-left">
      {left}
    </div>
    <div className="app-header-center">
      {center}
    </div>
    <div className="actions-group actions-right">
      {right}
    </div>
  </header>
);

Header.propTypes = {
  center: PropTypes.any,
  left: PropTypes.any,
  right: PropTypes.any,
};


export default Header;
