import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


const SuccessBadge = (props) => (
  <div className={classnames('success-badge', { 'badge-sm': props.size === 'sm' })}>
    <CheckCircleIcon fontSize="inherit" />
  </div>
);

SuccessBadge.propTypes = {
  size: PropTypes.string,
};

SuccessBadge.defaultProps = {
  size: 'md',
};


export default SuccessBadge;
