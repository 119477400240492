import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonBase } from '@material-ui/core';


const LogoButton = ({ src }) => (
  <ButtonBase
    component={Link}
    to="/"
    classes={{ root: 'logo-button' }}
  >
    <img
      className="logo-img"
      src={src}
      alt="APPROACH!"
      width="210"
      height="44"
    />
  </ButtonBase>
);

LogoButton.propTypes = {
  src: PropTypes.any,
};


export default LogoButton;
