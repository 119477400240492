import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import * as authSelectors from 'app/redux/auth/selectors';
import { staffHasPermission } from 'service/staff';

import PrivateRouteView from './PrivateRouteView';


const PrivateRoute = ({ currentStaff, permission, ...rest }) => {
  const hasPermission = staffHasPermission(currentStaff, permission);

  return (
    <PrivateRouteView
      hasPermission={hasPermission}
      {...rest}
    />
  );
};

PrivateRoute.propTypes = {
  currentStaff: PropTypes.object.isRequired,
  permission: PropTypes.string,
};


const mapStateToProps = (state) => ({
  currentStaff: authSelectors.currentStaff(state),
});

export default connect(mapStateToProps, null)(PrivateRoute);
