import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography, Paper } from '@material-ui/core';

import { RemoveFab } from 'common/buttons';
import { FLAT_DISCOUNT, PERCENT_DISCOUNT } from 'constants/discounts';
import { formatPriceWithoutTax } from 'service/utility/pricing';


const CartDiscount = (props) => {
  const { type, amount, discountValue, disabled } = props;

  const handleDiscountRemove = () => {
    props.putCartDiscount({
      [type]: 0,
    });
  };

  const getDiscount = () => {
    switch (type) {
      case (PERCENT_DISCOUNT):
        return `-${amount}%`;
      case (FLAT_DISCOUNT):
        return formatPriceWithoutTax(-amount, props.currentLocation);
      default:
        return null;
    }
  };

  const getDiscountValue = () => {
    switch (type) {
      case (PERCENT_DISCOUNT):
        return formatPriceWithoutTax(-discountValue, props.currentLocation);
      case (FLAT_DISCOUNT):
        return formatPriceWithoutTax(-discountValue, props.currentLocation);
      default:
        return null;
    }
  };


  return (
    <Paper
      className="cart-item"
      elevation={0}
    >
      <div className="cart-item-col image discount">
        <Typography
          variant="body1"
          color="inherit"
        >
          {getDiscount()}
        </Typography>
      </div>
      <div className="cart-item-col details">
        <span className="item-name">
          {'Cart Discount'}
        </span>
      </div>
      <div className="cart-item-col total discount">
        <Typography
          variant="body1"
          color="inherit"
        >
          {getDiscountValue()}
        </Typography>
      </div>
      {!disabled && (
        <RemoveFab onClick={() => handleDiscountRemove()} />
      )}
    </Paper>
  );
};

CartDiscount.propTypes = {
  amount: PropTypes.number.isRequired,
  currentLocation: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  discountValue: PropTypes.number.isRequired,
  putCartDiscount: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};


export default CartDiscount;
