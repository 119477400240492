import React from 'react';
import { PropTypes } from 'prop-types';
import { IconButton, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const ShowJsonDialog = ({ text, onClose }) => (
  <Dialog
    open
    fullWidth
    maxWidth="md"
    className="show-json-dialog"
  >
    <DialogTitle className="p-4" />
    <IconButton
      aria-label="Close Dialog"
      className="close-dialog-button"
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
    <DialogContent>
      <span className="json-text">
        {text}
      </span>
    </DialogContent>
  </Dialog>
);

ShowJsonDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};


export default ShowJsonDialog;
