import { take, call, select } from 'redux-saga/effects';

import { COMPLETE_CART } from '../actions';
import { activeCartIdSelector } from '../selectors';
import { createNewEmptyCart } from './cartSagas';


function *completeCartSaga() {
  while (true) {
    const { callback } = yield take(COMPLETE_CART);

    const cartId = yield select(activeCartIdSelector);
    if (cartId) {
      yield call(createNewEmptyCart);
    }

    if (callback) {
      callback();
    }
  }
}


export default completeCartSaga;
