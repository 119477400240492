import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import {
  Button, Select, MenuItem, FormControl, InputLabel, Paper, Chip, Avatar,
  List, ListItem, ListItemText, Typography, IconButton,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import FilterListIcon from '@material-ui/icons/FilterList';

import { testAgainst } from 'service/utility/stringFormatters';

import { SearchBar } from '../../searches';


const getField = (field) => {
  if (typeof field === 'object') {
    return field.field;
  }

  if (typeof field === 'string') {
    return field;
  }

  return null;
};

const getFieldLabel = (field) => {
  if (typeof field === 'object') {
    return field.label;
  }

  if (typeof field === 'string') {
    return field;
  }

  return null;
};

const filterItems = (items, filters) => (
  !filters
    ? items
    : filters.reduce((a, b) => a.filter((item) => b.options[b.active].filterFunc(item)), items)
);

const getItems = (initialItems, fields, sortBy, sortAsc, searchString, filters) => {
  const filteredItems = filterItems(initialItems, filters);

  return [...filteredItems].filter(
    (a) => fields.some((field) => testAgainst(searchString, a[getField(field)]))
  ).sort(
    (a, b) => (
      a[sortBy] < b[sortBy]
        ? (sortAsc ? -1 : 1)
        : a[sortBy] > b[sortBy]
          ? (sortAsc ? 1 : -1)
          : 0
    )
  );
};


const DEFAULT_ITEMS_PER_PAGE = 20;

class DataList extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState(this.getState());
    }
  }

  getState() {
    const { data: initialItems, fields, filters, item, itemsPerPage, searchable, sortable } = this.props;
    const sortBy = fields.length ? getField(fields[0]) : null;
    const sortAsc = true;
    const searchString = '';
    const currentPage = 1;
    const items = getItems(initialItems, fields, sortBy, sortAsc, searchString, filters);

    return {
      item,
      fields,
      initialItems,
      sortBy,
      sortAsc,
      searchString,
      currentPage,
      itemsPerPage,
      filters,
      searchable,
      sortable,
      items,
    };
  }

  getCurrentPage() {
    const currentPageItems = this.props.pagination
      ? this.state.items.slice(this.getCurrentPageStart(), this.getCurrentPageEnd())
      : this.state.items;

    return (
      <div className="list-wrapper">
        <List className="list">
          {currentPageItems.map((item, index) => React.cloneElement(this.state.item, {
            value: item,
            key: `_DL_C_${item.id || index}`,
          }))}
        </List>
      </div>
    );
  }

  getFilters() {
    const { filters } = this.state;

    if (!filters) return null;

    return (
      <div className="filters-wrap">
        <FilterListIcon />
        {filters.map((filterConfig, fidx) => (
          <FormControl
            key={`filter_${fidx}`}
            classes={{ root: 'filter-control' }}
          >
            <InputLabel>
              {filterConfig.label}
            </InputLabel>
            <Select
              value={filterConfig.active}
              onChange={(e) => this.handleFilterChange(fidx, e.target.value)}
            >
              {filterConfig.options.map((option, oidx) => (
                <MenuItem
                  key={`filter_${fidx}_${oidx}`}
                  value={oidx}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
      </div>
    );
  }

  getPlaceholder() {
    const { fields } = this.props;
    const fieldsLen = fields.length;

    return !fieldsLen
      ? 'Search'
      : `Search by ${fieldsLen === 1
        ? `${getFieldLabel(fields[0])}.`
        : fields.map((field, pos) => (
          pos === (fieldsLen - 2)
            ? `${getFieldLabel(field)} `
            : pos !== (fieldsLen - 1)
              ? `${getFieldLabel(field)}, `
              : `or ${getFieldLabel(field)}.`
        )).join('')}`;
  }

  getPagination() {
    if (!this.props.pagination) return null;

    const totalItems = this.state.items.length;
    const pageCount = this.getPageCount();
    const currentPageStart = this.getCurrentPageStart() + 1;
    const currentPageEnd = this.getCurrentPageEnd();

    return (
      <div className="pagination">
        <div className="info">
          <Typography
            variant="body2"
            color="inherit"
          >
            {`Showing ${currentPageStart} to ${currentPageEnd} of ${totalItems} entries`}
          </Typography>
        </div>
        <div className="page-buttons">
          <Button
            size="small"
            key="_DL_PB_Prev_"
            title="Prev"
            onClick={this.goToPrevPage}
            disabled={this.isFirstPage()}
          >
            {'Prev'}
          </Button>
          {Array.from({ length: pageCount }, (v, i) => {
            const p = i + 1;
            const isCurrentPage = p === this.state.currentPage;

            return (
              <Button
                size="small"
                key={`_DL_PB_${p}`}
                title={`Page ${p}`}
                variant={isCurrentPage ? 'contained' : 'text'}
                disabled={isCurrentPage}
                classes={{ root: classnames('page-button', { current: isCurrentPage }) }}
                onClick={isCurrentPage ? null : () => this.goToPage(p)}
              >
                {p}
              </Button>
            );
          })}
          <Button
            size="small"
            key="_DL_PB_Next_"
            title="Next"
            onClick={this.goToNextPage}
            disabled={this.isLastPage()}
          >
            {'Next'}
          </Button>
        </div>
      </div>
    );
  }

  getNavigationIcon() {
    return this.state.sortAsc ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;
  }

  getPageCount() {
    return Math.max(Math.ceil(this.state.items.length / this.state.itemsPerPage), 1);
  }

  getCurrentPageStart() {
    return (this.state.currentPage - 1) * this.state.itemsPerPage;
  }

  getCurrentPageEnd() {
    return Math.min(this.state.items.length, this.state.currentPage * this.state.itemsPerPage);
  }

  sortBy(fieldName) {
    this.setState((prevState) => {
      const sortBy = fieldName;
      const sortAsc = fieldName === prevState.sortBy ? !prevState.sortAsc : true;
      const { searchString } = prevState;
      const items = getItems(
        prevState.initialItems, prevState.fields, sortBy, sortAsc, searchString, prevState.filters,
      );

      return {
        sortBy,
        sortAsc,
        searchString,
        items,
      };
    });
  }

  filter(typed) {
    this.setState((prevState) => {
      const { sortBy, sortAsc } = prevState;
      const searchString = typed;
      const items = getItems(
        prevState.initialItems, prevState.fields, sortBy, sortAsc, searchString, prevState.filters,
      );

      return {
        sortBy,
        sortAsc,
        searchString,
        items,
        currentPage: 1,
      };
    });
  }

  handleFilterChange = (filterIdx, val) => {
    this.setState((prevState) => {
      const filters = [...prevState.filters];

      filters[filterIdx].active = val;

      const items = getItems(
        prevState.initialItems, prevState.fields,
        prevState.sortBy, prevState.sortAsc, prevState.searchString, filters,
      );

      return {
        filters,
        items,
      };
    });
  };


  toggleOrder = () => {
    this.sortBy(this.state.sortBy);
  };

  goToPage(i) {
    this.setState({
      currentPage: i,
    });
  }

  goToPrevPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1,
    }));
  };

  goToNextPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1,
    }));
  };

  isFirstPage = () => (
    this.state.currentPage === 1
  );

  isLastPage = () => (
    this.state.currentPage === this.getPageCount()
  );

  render() {
    const { action, sortByDropdown, noItemsMessage } = this.props;
    const { fields, searchable, sortable, sortBy, searchString } = this.state;

    return (
      <Paper classes={{ root: 'data-list' }}>
        {fields.length && (searchable || action || sortable) && (
          <div className="header">
            <div className="search-row">
              {searchable && (
                <SearchBar
                  placeholder={this.getPlaceholder()}
                  onQueryChange={(val) => this.filter(val)}
                  query={searchString}
                  realTimeSearch
                />
              )}
              {action && (
                <div className="action-wrap">
                  {action}
                </div>
              )}
            </div>
            {sortable ? (
              sortByDropdown ? (
                <div>
                  <FormControl className="sort-by-wrap">
                    <InputLabel htmlFor="sort-by">
                      {'Sort By'}
                    </InputLabel>
                    <Select
                      value={sortBy || 'none'}
                      onChange={(e) => this.sortBy(e.target.value)}
                    >
                      <MenuItem value="none">
                        {'--'}
                      </MenuItem>
                      {fields.map((field, idx) => (
                        <MenuItem
                          key={`DLSB_${idx}`}
                          value={getField(field)}
                        >
                          {this.getFieldLabel(field)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="order-wrap">
                    {Boolean(sortBy) && (
                      <IconButton onClick={this.toggleOrder}>
                        {this.getNavigationIcon()}
                      </IconButton>
                    )}
                  </div>
                </div>
              ) : (
                <div className="filter-row">
                  {fields.map((item) => {
                    const chipField = getField(item);
                    const chipLabel = this.getFieldLabel(item);

                    return (
                      <Chip
                        key={`OC_${chipField}`}
                        onClick={() => this.sortBy(chipField)}
                        label={chipLabel}
                        avatar={sortBy === chipField ? (
                          <Avatar>
                            {this.getNavigationIcon()}
                          </Avatar>
                        ) : null}
                        classes={{ root: 'order-chip' }}
                      />
                    );
                  })}
                  {this.getFilters()}
                </div>
              )
            ) : null}
          </div>
        )}

        {this.props.data.length === 0 && (
          <ListItem>
            <ListItemText
              primary={noItemsMessage || 'No Results Found'}
            />
          </ListItem>
        )}

        {this.getCurrentPage()}
        {this.getPagination()}
      </Paper>
    );
  }
}

DataList.propTypes = {
  action: PropTypes.element,
  data: PropTypes.array.isRequired,
  fields: PropTypes.array,
  filters: PropTypes.array,
  item: PropTypes.element.isRequired,
  itemsPerPage: PropTypes.number,
  noItemsMessage: PropTypes.string,
  pagination: PropTypes.bool,
  searchable: PropTypes.bool,
  sortable: PropTypes.bool,
  sortByDropdown: PropTypes.bool,
};

DataList.defaultProps = {
  fields: [],
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
  pagination: true,
  searchable: true,
  sortable: true,
  sortByDropdown: false,
};


export default DataList;
