import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { components } from 'react-select';

import * as API from 'service/api';

import GenericSearch from '../GenericSearch';
import GiftCardTypeDisplayItem from './GiftCardTypeDisplayItem';
import GiftCardTypeSearchItem from './GiftCardTypeSearchItem';


const getOptions = (input) => API.findGiftCardType(`q=${input}&isActive=1`).then(
  ({ data: { data } }) => data,
);

const getOptionValue = (option) => option.id;

const getOptionLabel = (option) => option.name;

/* eslint-disable */
const generateOption = (OptionComponent, currentLocation) => (props) => (
  <components.Option { ...props}>
    <OptionComponent
      {...props}
      currentLocation={currentLocation}
    />
  </components.Option>
);

const generateSingleValue = (SingleValueComponent, currentLocation) => (props) => (
  <SingleValueComponent
    {...props}
    currentLocation={currentLocation}
  />
);
/* eslint-enable */


const GiftCardTypeSearch = ({ className, currentLocation, ...rest }) => {
  const suggestionItem = generateOption(GiftCardTypeSearchItem, currentLocation);
  const displayItem = generateSingleValue(GiftCardTypeDisplayItem, currentLocation);

  return (
    <GenericSearch
      suggestionItem={suggestionItem}
      displayItem={displayItem}
      getOptions={getOptions}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      className={classnames('giftcard-type-search', { [className]: Boolean(className) })}
      {...rest}
    />
  );
};

GiftCardTypeSearch.propTypes = {
  autoFocus: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  className: PropTypes.string,
  currentLocation: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.object,
};

GiftCardTypeSearch.defaultProps = {
  autoFocus: false,
  cacheOptions: true,
  className: '',
  disabled: false,
  error: '',
  fullWidth: true,
  placeholder: '',
  required: false,
};


export default GiftCardTypeSearch;
