import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { TextField, MenuItem } from '@material-ui/core';

import US_STATES from 'constants/usStates';
import { isRequired } from 'service/utility/errorMessages';


class StateField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount() {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.required !== this.props.required || prevProps.value !== this.props.value) {
      const { value: rawValue, onChange } = this.props;
      const value = rawValue || '';
      const error = this.validateSelf(value);

      if (onChange) {
        onChange({ error });
      }
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }

  validateSelf = (value) => {
    const { required, label } = this.props;

    if (!value && required) return isRequired(label);

    return null;
  };

  handleLeaveFocus = () => {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ error });
    }
  };

  handleChangeSelf = (event) => {
    const rawValue = event.target.value;
    const { onChange } = this.props;
    const value = rawValue || '';

    if (onChange) {
      onChange({ value });
    }
  };

  render() {
    const { error, onChange, label, required, value: rawValue, className, ...rest } = this.props;
    const { blurred } = this.state;

    const showError = blurred && Boolean(error);
    const value = rawValue || '';
    const stateFieldClassName = classnames('state-field', { [className]: Boolean(className) });

    return (
      <TextField
        select
        error={showError}
        helperText={error}
        required={required}
        label={label}
        value={value}
        onChange={this.handleChangeSelf}
        onBlur={this.handleLeaveFocus}
        className={stateFieldClassName}
        {...rest}
      >
        {US_STATES.map((option) => (
          <MenuItem
            key={option}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

StateField.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
};

StateField.defaultProps = {
  error: '',
  label: 'State',
  margin: 'dense',
  required: false,
  value: '',
  variant: 'outlined',
};


export default StateField;
