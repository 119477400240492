import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { useHistory, Link } from 'react-router-dom';
import { Button, IconButton } from '@material-ui/core';

import BackArrowIcon from 'assets/BackArrowIcon';


const BackButton = ({
  icon, label, absolute, dense, onClick, to, ...rest
}) => {
  const history = useHistory();

  const ButtonComponent = icon ? IconButton : Button;
  const onClick_ = () => (to ? null : onClick ? onClick() : history.goBack());
  const component = to ? Link : 'button';
  const to_ = to || null;
  const label_ = icon ? null : label ?? 'Back';
  const contentIcon = icon ? <BackArrowIcon /> : null;
  const startIconProps = icon ? {} : { startIcon: <BackArrowIcon /> };

  return (
    <div className={classnames('back-button-wrapper', { dense, absolute })}>
      <ButtonComponent
        className="back-button"
        variant="text"
        color="inherit"
        component={component}
        to={to_}
        onClick={onClick_}
        {...startIconProps}
        {...rest}
      >
        {label_}
        {contentIcon}
      </ButtonComponent>
    </div>
  );
};

BackButton.propTypes = {
  absolute: PropTypes.bool,
  dense: PropTypes.bool,
  icon: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

BackButton.defaultProps = {
  absolute: false,
  dense: false,
};


export default BackButton;
