import React from 'react';
import { PropTypes } from 'prop-types';

import { somePropsChanged } from 'service/utility';
import { isRequired } from 'service/utility/errorMessages';

import AirbnbDatepicker from '../AirbnbDatepicker';


class DateRange extends React.PureComponent {
  componentDidMount() {
    const { value, onChange } = this.props;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentDidUpdate(prevProps) {
    if (somePropsChanged(prevProps, this.props, ['required', 'value'])) {
      const { value, onChange } = this.props;
      const error = this.validateSelf(value);

      if (onChange) {
        onChange({ error });
      }
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }

  validateSelf = (value) => {
    const { required, label } = this.props;

    if (!value.startDate && required) return isRequired(label);

    return null;
  };

  handleDatesChange = (startDate, endDate) => {
    const { onChange } = this.props;

    if (!onChange) return;

    const value = { startDate, endDate };
    const error = this.validateSelf(value);

    onChange({ value, error });
  };


  render() {
    const { error, label, onChange, required, value, ...rest } = this.props;

    return (
      <AirbnbDatepicker
        displayAsField
        startDate={value.startDate}
        endDate={value.endDate}
        error={error}
        setDates={this.handleDatesChange}
        label={label}
        {...rest}
      />
    );
  }
}

DateRange.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.object,
};

DateRange.defaultProps = {
  error: '',
  required: false,
};


export default DateRange;
