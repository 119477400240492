import React from 'react';
import { PropTypes } from 'prop-types';
import { Switch } from 'react-router-dom';

import { getSubRoutes2 } from 'service/navigation';

import { SuspenseFallback } from '../../statusIndicators';


const SwitchWithRoutes = ({
  rootPath, subroutes, propsForAll = {}, passthroughProps, ...rest
}) => (
  <React.Suspense fallback={<SuspenseFallback />}>
    <Switch>
      {getSubRoutes2({
        rootPath,
        subroutes,
        propsForAll: {
          ...propsForAll,
          ...(passthroughProps ? rest : {}),
        },
      })}
    </Switch>
  </React.Suspense>
);

SwitchWithRoutes.propTypes = {
  passthroughProps: PropTypes.bool,
  propsForAll: PropTypes.object,
  rootPath: PropTypes.string.isRequired,
  subroutes: PropTypes.array.isRequired,
};


export default SwitchWithRoutes;
