import React from 'react';
import { PropTypes } from 'prop-types';

import { SUPPORT_REQUEST_STATUSES } from 'constants/support';
import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


const REQUEST_STATUS_PICKLIST_VALUES = [
  {
    label: 'All',
    value: 0,
  },
  ...SUPPORT_REQUEST_STATUSES.map((requestStatus) => ({
    label: requestStatus.label,
    value: requestStatus.value,
  })),
];


class RequestStatusFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      requestStatus: (
        this.props.hasOwnProperty('status') && this.props.status !== 'null'
          ? this.props.status
          : 0
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['requestStatus'])) {
      this.handleValueChange();
    }
  }

  getOwnFilterMap = () => ({
    status: this.state.requestStatus === 0 ? null : this.state.requestStatus,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleChange = (update) => {
    if (!update.hasOwnProperty('value')) return;

    this.setState({
      requestStatus: update.value,
    });
  };

  render() {
    const { requestStatus } = this.state;

    return (
      <PicklistField
        value={requestStatus}
        onChange={this.handleChange}
        picklistValues={REQUEST_STATUS_PICKLIST_VALUES}
      />
    );
  }
}

RequestStatusFilter.propTypes = {
  onValueChange: PropTypes.func,
  status: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

RequestStatusFilter.ownName = 'RequestStatusFilter';
RequestStatusFilter.ownFilters = ['status'];


export default RequestStatusFilter;
