import React from 'react';
import { PropTypes } from 'prop-types';
import { Popover, ListItem, ListItemText, Divider } from '@material-ui/core';

import { COLOR_PICKER_WIDTH, CALENDAR_COLOR_MATRIX } from 'constants/ui';

import ColorCell from './ColorCell';


const getAnchorPosition = (anchorEl) => {
  if (!anchorEl) return null;

  const rect = anchorEl.getBoundingClientRect();
  const anchorPosition = {
    top: rect.top,
    left: rect.right + 10,
  };

  if (rect.right + COLOR_PICKER_WIDTH > document.documentElement.clientWidth) {
    anchorPosition.left = rect.left - 10;
  }

  return anchorPosition;
};

const getAnchorOrigin = (anchorEl) => {
  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  if (anchorEl) {
    const rect = anchorEl.getBoundingClientRect();

    if (rect.right + COLOR_PICKER_WIDTH > document.documentElement.clientWidth) {
      anchorOrigin.horizontal = 'left';
    }
  }

  return anchorOrigin;
};

const getTransformOrigin = (anchorEl) => {
  const transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  };

  if (anchorEl) {
    const rect = anchorEl.getBoundingClientRect();

    if (rect.right + COLOR_PICKER_WIDTH > document.documentElement.clientWidth) {
      transformOrigin.horizontal = 'right';
    }
  }

  return transformOrigin;
};


const CalendarListItemHamburgerMenu = ({ open, anchorEl, onClose, onRemove, onSelect, selectedColor }) => (
  <Popover
    open={open}
    anchorReference="anchorPosition"
    anchorPosition={getAnchorPosition(anchorEl)}
    anchorOrigin={getAnchorOrigin(anchorEl)}
    transformOrigin={getTransformOrigin(anchorEl)}
    PaperProps={{ style: { width: `${COLOR_PICKER_WIDTH}px` } }}
    onClose={onClose}
    classes={{ paper: 'calendar-color-picker' }}
  >
    {onRemove && (
      <ListItem
        button
        dense
        onClick={onRemove}
      >
        <ListItemText primary="Remove from list" />
      </ListItem>
    )}
    <Divider />
    <div className="calendar-color-picker-colors">
      {CALENDAR_COLOR_MATRIX.map((row, idx) => (
        <div
          key={`CC_${idx}`}
          className="calendar-color-picker-row"
        >
          {row.map(({ hex: color }, idx2) => (
            <ColorCell
              key={`CC_${idx}_${idx2}`}
              color={color}
              isSelected={color === selectedColor}
              onSelect={onSelect}
            />
          ))}
        </div>
      ))}
    </div>
  </Popover>
);

CalendarListItemHamburgerMenu.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedColor: PropTypes.string,
};


export default CalendarListItemHamburgerMenu;
