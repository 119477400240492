import React from 'react';
import { PropTypes } from 'prop-types';


const Center = ({ children }) => (
  <section id="center-section">
    {children}
  </section>
);

Center.propTypes = {
  children: PropTypes.any,
};


export default Center;
