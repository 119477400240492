import { take, select, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getErrorMessage } from 'service/utility';

import { PUT_CART_PROMOCODE, setCartLoading } from '../actions';
import { activeCartIdSelector } from '../selectors';
import { putCartPromoCode, loadCart } from './cartSagas';


function *putCartPromoCodeSaga() {
  while (true) {
    const { promoCode } = yield take(PUT_CART_PROMOCODE);

    const cartId = yield select(activeCartIdSelector);
    if (!cartId) return null;

    const toast1 = toast('Setting promo code...');

    try {
      yield put(setCartLoading(true));
      const response = yield call(putCartPromoCode, cartId, promoCode);

      toast.dismiss(toast1);
      if (response.success) {
        yield call(loadCart, cartId);
      } else {
        toast.error(response.errorMessage);
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('putCartPromoCodeSaga error: ');
      console.log(errorMessage);

      toast.dismiss(toast1);
      toast.error(errorMessage);
    } finally {
      yield put(setCartLoading(false));
    }
  }
}


export default putCartPromoCodeSaga;
