import { getItemTypeName } from 'constants/itemTypes';

import { getRoutePath } from '../navigation';
import { getPriceProp, getSubtotalProp, getNetAmountProp } from './pricing';
import { routeWithParams } from '.';


export const getOrderUrl = (order) => (
  routeWithParams(
    getRoutePath('CUSTOMER_ORDER'),
    { id: order.customerId || 'guest', orderId: order.id }
  )
);

export const getOrderItemUrl = (orderItem) => {
  if (orderItem.type === 'PRODUCT') {
    return routeWithParams(
      getRoutePath('PRODUCT_DETAIL'),
      { id: orderItem.productId },
    );
  }

  if (orderItem.type === 'MEMBERSHIP' || orderItem.type === 'MEMBERSHIP_RESUME') {
    return routeWithParams(
      getRoutePath('CUSTOMER_MEMBERSHIP'),
      { id: orderItem.assignedCustomerId, membershipId: orderItem.membershipId },
    );
  }

  if (orderItem.type === 'PASS') {
    return routeWithParams(
      getRoutePath('CUSTOMER_PASS'),
      { id: orderItem.assignedCustomerId, passId: orderItem.passId },
    );
  }

  if (orderItem.type === 'EVENT_BOOKING_TICKET' || orderItem.type === 'EVENT_SERIES_TICKET') {
    return routeWithParams(
      getRoutePath('CUSTOMER_TICKET'),
      { id: orderItem.assignedCustomerId, ticketId: orderItem.passId },
    );
  }

  if ((orderItem.type === 'EVENT_BOOKING_INVOICE' || orderItem.type === 'FEE') && orderItem.bookingId) {
    return routeWithParams(
      getRoutePath('BOOKING_DETAIL'),
      { bookingId: orderItem.bookingId },
    );
  }

  return null;
};

export const getOrderItemTypeName = (orderItem) => (
  getItemTypeName(orderItem.type)
);

export const getOrderItemDescription = (orderItem) => {
  const { metaJSON, description } = orderItem;
  const meta = metaJSON ? JSON.parse(metaJSON) : {};
  const typeInfo = meta.typeInfo || meta;

  return description || (typeInfo && typeInfo.description) || 'No description';
};

export const getOrderItemQuantity = (orderItem) => (
  orderItem.quantity
);

export const getOrderItemPrice = (order, orderItem, location) => {
  if (order.vrsn < 4) {
    return orderItem.price;
  }

  return orderItem[getPriceProp(location)];
};

export const getOrderItemSubtotal = (order, orderItem, location) => {
  if (order.vrsn < 4) {
    return orderItem.subtotal;
  }

  return orderItem[getSubtotalProp(location)];
};

export const getOrderItemRefundableAmount = (order, orderItem, location) => {
  if (order.vrsn < 2) {
    return orderItem.salesAmount;
  }

  return orderItem[getNetAmountProp(location)];
};

export const getOrderSourceLabel = (order) => {
  if (!order) return '';

  switch (order.type) {
    case 'instore': return 'In store';
    case 'online': return 'Online';
    case 'online-guest': return 'Online - Guest';
    case 'auto': return 'Automatic Payment';
    default: return 'Unknown Source';
  }
};

export const isPortalGuestCheckout = (order) => Boolean(order.sessionId);
