import React from 'react';
import { PropTypes } from 'prop-types';
import BarcodeReader2 from 'react-barcode-reader';


const BarcodeReader = (props) => {
  const handleError = (input, error) => {
    console.log('BarcodeReader: there was an error:');
    console.log(input, error);

    if (props.onError) {
      props.onError(input, error);
    }
  };

  const handleScan = (data) => {
    console.log('BarcodeReader: scanned the following:');
    console.log(data);

    // const match = data.match(/^[%;]([^?]*)\?$/);

    // if (match) {
    if (props.onScan) {
      // props.onScan(match[1]);
      props.onScan(data);
    }
    // }
  };


  return (
    <BarcodeReader2
      onError={handleError}
      onScan={handleScan}
      minLength={props.minLength}
      // startChar={[59, 37]}
      // endChar={[63]}
      // avgTimeByChar={1000}
      // timeBeforeScanTest={1000}
    />
  );
};

BarcodeReader.propTypes = {
  minLength: PropTypes.number,
  onError: PropTypes.func,
  onScan: PropTypes.func,
};

BarcodeReader.defaultProps = {
  minLength: 4,
};


export default BarcodeReader;
