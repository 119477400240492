import React from 'react';
import { PropTypes } from 'prop-types';
import { MenuItem, ListItemText } from '@material-ui/core';


const ActivitySearchItem = ({ children, isFocused, data, innerProps }) => (
  <MenuItem
    selected={isFocused}
    onClick={innerProps.onClick}
    className="activity-search-item"
  >
    <ListItemText
      primary={children.toString()}
      secondary={data.description}
    />
  </MenuItem>
);

ActivitySearchItem.propTypes = {
  children: PropTypes.any,
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool,
};


export default ActivitySearchItem;
