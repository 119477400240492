import { take, put, select } from 'redux-saga/effects';

import { CLOSE_CHECKIN_DIALOG, hideCheckinDialog } from '../actions/dialog';
import { checkinDialogCallback } from '../selectors';


function *closeCheckinDialogSaga() {
  while (true) {
    yield take(CLOSE_CHECKIN_DIALOG);
    const callback = yield select(checkinDialogCallback);
    if (callback) {
      callback();
    }
    yield put(hideCheckinDialog());
  }
}


export default closeCheckinDialogSaga;
