import { ordinal } from 'service/utility/numbers';

import dictionary from './dictionary.json';


const i18nConfig = {
  resources: dictionary,
  lng: ['en', 'de'],
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
    format: (value, format/*, lng*/) => {
      if (format === 'ordinal_en') {
        if (value === -1) {
          return 'last';
        }

        return ordinal(value);
      }

      if (format === 'ordinal_de') {
        if (value === -1) {
          return 'letzten';
        }

        return `${value}.`;
      }

      return value;
    },
  },
};

export default i18nConfig;
