import React from 'react';
import { PropTypes } from 'prop-types';
import NumberFormat from 'react-number-format';

import * as timeUtils from 'service/utility/timeUtils';


class BirthdateFormatCustom extends React.Component {
  onValueChange = (values) => {
    if (
      timeUtils.isIncompleteIso8601Date(values.formattedValue) ||
      !timeUtils.isValidLocalDateString(values.formattedValue)
    ) {
      this.props.onChange(values.formattedValue);
    } else {
      this.props.onChange(timeUtils.iso8601FromLocalDateString(values.formattedValue));
    }
  };

  render() {
    const { inputRef, onChange, value: rawValue, ...rest } = this.props;
    const rawValue2 = rawValue === null ? null : timeUtils.iso8601Date(rawValue);

    const value = (
      timeUtils.isValidIso8601Date(rawValue2)
        ? timeUtils.formatShortDatePadded(rawValue2)
        : rawValue2
    );
    const format = timeUtils.getShortDateFormat();
    const placeholder = timeUtils.getShortDatePlaceholder();
    const mask = timeUtils.getShortDateMask();

    return (
      <NumberFormat
        {...rest}
        getInputRef={inputRef}
        onValueChange={this.onValueChange}
        decimalScale={0}
        type="tel"
        format={format}
        placeholder={placeholder}
        mask={mask}
        value={value}
      />
    );
  }
}

BirthdateFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};


export default BirthdateFormatCustom;
