import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { TableRow, TableCell, Typography } from '@material-ui/core';

import { formatPriceWithoutTax } from 'service/utility/pricing';


const PriceRow = ({ type, label, formatAsCurrency, value, orderLocation, currentLocation, currency }) => (
  <TableRow
    className={classnames('price-row', { 'total-row': type === 'total', 'discount-row': type === 'discount', 'promo-code-row': type === 'promoCode' })}
  >
    <TableCell>
      {label && (
        <Typography
          variant="body1"
          align="right"
          className="price-row-label"
        >
          {label}
        </Typography>
      )}
    </TableCell>
    <TableCell>
      <Typography
        variant="body1"
        align="right"
        className="price-row-value"
      >
        {formatAsCurrency
          ? formatPriceWithoutTax(value, orderLocation || currentLocation, { currency })
          : value}
      </Typography>
    </TableCell>
  </TableRow>
);

PriceRow.propTypes = {
  currency: PropTypes.string,
  currentLocation: PropTypes.object.isRequired,
  formatAsCurrency: PropTypes.bool,
  label: PropTypes.string,
  orderLocation: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.any,
};


export default PriceRow;
