import React from 'react';
import PropTypes from 'prop-types';

import LockScreenDialogView from './LockScreenDialogView';


class LockScreenDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      typing: false,
    };
  }

  onPasswordChange = (password) => {
    this.setState({
      password,
      typing: true,
    });
  };

  onSubmitPassword = () => {
    this.props.unlockScreen(this.state.password);
    this.setState({
      password: '',
      typing: false,
    });
  };

  render() {
    const { currentUser, errorMessage, isLocked, logOutUser } = this.props;
    const { password, typing } = this.state;

    return (
      <LockScreenDialogView
        currentUser={currentUser}
        errorMessage={typing ? '' : errorMessage}
        isLocked={isLocked}
        logOutUserFromLock={logOutUser}
        password={password}
        handlePasswordChange={this.onPasswordChange}
        handleSubmit={this.onSubmitPassword}
      />
    );
  }
}

LockScreenDialog.propTypes = {
  currentUser: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  isLocked: PropTypes.bool.isRequired,
  logOutUser: PropTypes.func.isRequired,
  unlockScreen: PropTypes.func.isRequired,
};


export default LockScreenDialog;
