import React from 'react';
import { PropTypes } from 'prop-types';
import { ListItemText } from '@material-ui/core';

import { dashJoin } from 'service/utility';
import { formatItemPrice } from 'service/utility/pricing';


const TicketTypeListItemText = ({ data, currentLocation }) => (
  <ListItemText
    primary={dashJoin([data.name, data.description])}
    secondary={formatItemPrice(data, currentLocation)}
  />
);

TicketTypeListItemText.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};


export default TicketTypeListItemText;
