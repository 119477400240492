import { take, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';

import { ASSIGN_STAFF_TO_CART, setCartLoading } from '../actions';
import { loadCart } from './cartSagas';


function *assignStaffToCartSaga() {
  while (true) {
    const { staffId, cartId } = yield take(ASSIGN_STAFF_TO_CART);

    if (!staffId || !cartId) return null;

    console.log(`Assigning cart with UUID = ${cartId} to staff with id = ${staffId}`);
    const toast1 = toast('Assigning cart...');

    try {
      yield put(setCartLoading(true));
      yield call(API.assignCart, cartId, { staffId });
      yield call(loadCart, cartId);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.assignCart error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    } finally {
      toast.dismiss(toast1);
      yield put(setCartLoading(false));
    }
  }
}


export default assignStaffToCartSaga;
