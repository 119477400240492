import { makeActionCreator } from 'service/utility/redux';


export const ASSIGN_CUSTOMER_TO_CURRENT_CART = 'ASSIGN_CUSTOMER_TO_CURRENT_CART';
export const assignCustomerToCurrentCart = makeActionCreator(ASSIGN_CUSTOMER_TO_CURRENT_CART, 'customer');

export const ASSIGN_STAFF_TO_CART = 'ASSIGN_STAFF_TO_CART';
export const assignStaffToCart = makeActionCreator(ASSIGN_STAFF_TO_CART, 'staffId', 'cartId');

export const CLEAR_CART = 'CLEAR_CART';
export const clearCart = makeActionCreator(CLEAR_CART);

export const COMPLETE_CART = 'COMPLETE_CART';
export const completeCart = makeActionCreator(COMPLETE_CART, 'callback');

export const CREATE_NEW_CART = 'CREATE_NEW_CART';
export const createNewCart = makeActionCreator(CREATE_NEW_CART);

export const DELETE_CART = 'DELETE_CART';
export const deleteCart = makeActionCreator(DELETE_CART);

export const FINISH_INITIAL_CART_LOAD = 'FINISH_INITIAL_CART_LOAD';
export const finishInitialCartLoad = makeActionCreator(FINISH_INITIAL_CART_LOAD);

export const FOCUS_ON_CUSTOMER_CART = 'FOCUS_ON_CUSTOMER_CART';
export const focusOnCustomerCart = makeActionCreator(FOCUS_ON_CUSTOMER_CART, 'customerId');

export const INITIAL_CART_LOAD = 'INITIAL_CART_LOAD';
export const initialCartLoad = makeActionCreator(INITIAL_CART_LOAD);

export const PUT_CART_DISCOUNT = 'PUT_CART_DISCOUNT';
export const putCartDiscount = makeActionCreator(PUT_CART_DISCOUNT, 'discount');

export const PUT_CART_PROMOCODE = 'PUT_CART_PROMOCODE';
export const putCartPromoCode = makeActionCreator(PUT_CART_PROMOCODE, 'promoCode');

export const PUT_ITEMS_IN_CART = 'PUT_ITEMS_IN_CART';
export const putItemsInCart = makeActionCreator(PUT_ITEMS_IN_CART, 'items');

export const PUT_ITEMS_IN_CUSTOMER_CART = 'PUT_ITEMS_IN_CUSTOMER_CART';
export const putItemsInCustomerCart = makeActionCreator(PUT_ITEMS_IN_CUSTOMER_CART, 'items', 'customerId', 'callback');

export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const removeItemFromCart = makeActionCreator(REMOVE_ITEM_FROM_CART, 'itemId');

export const REMOVE_PROMOCODE_FROM_CART = 'REMOVE_PROMOCODE_FROM_CART';
export const removePromoCodeFromCart = makeActionCreator(REMOVE_PROMOCODE_FROM_CART);

export const SET_ACTIVE_CART = 'SET_ACTIVE_CART';
export const setActiveCart = makeActionCreator(SET_ACTIVE_CART, 'cart');

export const SET_CART_LOADING = 'SET_CART_LOADING';
export const setCartLoading = makeActionCreator(SET_CART_LOADING, 'isLoading');

export const UNASSIGN_CART_CUSTOMER = 'UNASSIGN_CART_CUSTOMER';
export const unassignCartCustomer = makeActionCreator(UNASSIGN_CART_CUSTOMER);

export const UNASSIGN_CART_STAFF = 'UNASSIGN_CART_STAFF';
export const unassignCartStaff = makeActionCreator(UNASSIGN_CART_STAFF);

export const UPDATE_CART_ITEM_QUANTITY = 'UPDATE_CART_ITEM_QUANTITY';
export const updateCartItemQuantity = makeActionCreator(UPDATE_CART_ITEM_QUANTITY, 'itemId', 'quantity');
