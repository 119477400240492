import { take, call, put, all } from 'redux-saga/effects';

import * as API from 'service/api';

import { setPaymentStatus, CANCEL_ORDER } from '../actions';


function *cancelOrder(payload) {
  try {
    const { orderId, transactions, itemsToCancel } = payload;

    console.log('Starting Cancel Order');

    yield put(setPaymentStatus({
      message: 'Voiding Transactions...',
      started: true,
    }));

    console.log('Voiding Transactions; transactions:');
    console.log(transactions);

    const promises = [];

    transactions.forEach((transaction) => {
      const { UUID: transactionId, locationId, customerId, staffId, type, status } = transaction;

      if (type === 'sale' && (status === 'success' || status === 'submitted')) {
        const payload2 = {
          locationId,
          customerId,
          staffId,
        };

        promises.push(call(API.voidTransaction, transactionId, payload2));
      }
    });

    if (promises.length) {
      const voidResponses = yield all(promises);

      console.log('Void Transactions finished; responses:');
      console.log(voidResponses);
    }

    yield put(setPaymentStatus({
      message: 'Cancelling Order...',
      started: true,
    }));

    console.log('Cancelling order; items to cancel:');
    console.log(itemsToCancel);

    const cancelBody = {};

    if (itemsToCancel) {
      cancelBody.items = itemsToCancel;
    }

    yield call(API.cancelOrder, orderId, cancelBody);

    yield put(setPaymentStatus({
      message: 'Order Successfully Cancelled!',
      started: true,
      finished: true,
      success: true,
    }));

    return {
      isSuccessful: true,
    };
  } catch (error) {
    console.log('cancelOrder error: ', error);

    yield put(setPaymentStatus({
      message: 'Order Cancellation Failed.',
      started: true,
      finished: true,
      success: false,
    }));

    return {
      errorMessage: 'Sorry, There was an error cancelling this order.',
    };
  }
}

function *cancelOrderSaga() {
  while (true) {
    const { payload, callback } = yield take(CANCEL_ORDER);

    const result = yield call(cancelOrder, payload);

    if (callback) {
      console.log('cancelOrder result: ', result);

      yield call(callback, result);
    }
  }
}


export default cancelOrderSaga;
