import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as authSelectors from 'app/redux/auth/selectors';
import { layoutActions } from 'app/redux/ui/actions';
import * as uiSelectors from 'app/redux/ui/selectors';
import * as stateSelectors from 'app/redux/state/selectors';
import MOUNTAIN from 'assets/mountain-wireframe.svg';
import { SideNav } from 'common/navigation';
import { getNavConfig } from 'config/navigation';
import { getStaffNavConfig } from 'service/staff';


class SideNav2 extends React.Component {
  handleNavItemClick = () => {
    const { isLeftDrawerOpen, isLeftDrawerPermanent, setLeftDrawerOpen } = this.props;

    if (!isLeftDrawerPermanent && isLeftDrawerOpen) {
      setLeftDrawerOpen(false);
    }
  };

  render() {
    const { currentStaff, currentLocation } = this.props;
    const navConfig = getNavConfig();
    const staffNavConfig = getStaffNavConfig(navConfig, currentStaff, currentLocation);

    return (
      <SideNav
        navConfig={staffNavConfig}
        onItemClick={this.handleNavItemClick}
        background={
          <img
            src={MOUNTAIN}
            className="mountain-background"
            alt="mountain"
          />
        }
      />
    );
  }
}

SideNav2.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  currentStaff: PropTypes.object.isRequired,
  isLeftDrawerOpen: PropTypes.bool.isRequired,
  isLeftDrawerPermanent: PropTypes.bool.isRequired,
  setLeftDrawerOpen: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  currentLocation: stateSelectors.currentLocation(state),
  currentStaff: authSelectors.currentStaff(state),
  isLeftDrawerOpen: uiSelectors.isLeftDrawerOpen(state),
  isLeftDrawerPermanent: uiSelectors.isLeftDrawerPermanent(state),
});

const mapDispatchToProps = (dispatch) => ({
  setLeftDrawerOpen: (isOpen) => dispatch(layoutActions.setLeftDrawerOpen(isOpen)),
});


const SideNav3 = withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNav2));

export default SideNav3;
