import React from 'react';
import { PropTypes } from 'prop-types';
import { Fab } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';


class RemoveFab extends React.Component {
  handleClick = (event) => {
    event.stopPropagation();
    this.props.onClick();
  };

  render() {
    return (
      <Fab
        className="remove-fab"
        onClick={this.handleClick}
      >
        <ClearIcon />
      </Fab>
    );
  }
}

RemoveFab.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default RemoveFab;
