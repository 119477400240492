import { toast } from 'react-toastify';

import * as API from '../api';
import firebaseService from '../firebase';
import { getErrorMessage } from '../utility';


const uploadImage = async (giftCardTypeId, uploaded64) => {
  if (!uploaded64) return null;

  try {
    const imageURL = await firebaseService.uploadImage(`giftCardType/${giftCardTypeId}`, uploaded64);

    return imageURL;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('Error uploading giftCard type image; error = ');
    console.log(errorMessage);

    toast.error(errorMessage);
    return null;
  }
};

const uploadImageAndUpdate = async (giftCardTypeId, uploaded64) => {
  if (!uploaded64) return;

  const imageURL = await uploadImage(giftCardTypeId, uploaded64);

  if (!imageURL) return;

  try {
    await API.putGiftCardType({
      id: giftCardTypeId,
      imageURL,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('Error updating giftCard type; error = ');
    console.log(errorMessage);

    toast.error(errorMessage);
  }
};


const GiftCardTypeModel = {
  uploadImage,
  uploadImageAndUpdate,
};

export default GiftCardTypeModel;
