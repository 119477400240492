import React from 'react';
import { PropTypes } from 'prop-types';

import CountryField from './CountryField';
import TextField from './TextField';
import StateField from './StateField';
import PostalCodeField from './PostalCodeField';


class Address extends React.PureComponent {
  handleChange = (varName) => (update) => {
    const { onChange } = this.props;

    if (!onChange) return;

    const hasNewValue = update.hasOwnProperty('value');
    const hasNewError = update.hasOwnProperty('error');

    if (!hasNewValue && !hasNewError) return;

    const compositeUpdate = {};

    if (hasNewValue) {
      compositeUpdate.values = {
        [varName]: update.value,
      };
    }

    if (hasNewError) {
      compositeUpdate.errors = {
        [varName]: update.error,
      };
    }

    onChange(compositeUpdate);
  };

  handleCountryCodeChange = this.handleChange('countryCode');

  handleAddress1Change = this.handleChange('address1');

  handleAddress2Change = this.handleChange('address2');

  handleStateChange = this.handleChange('state');

  handleCityChange = this.handleChange('city');

  handlePostalCodeChange = this.handleChange('postalCode');


  render() {
    const { currentLocation, value, error } = this.props;

    const stateLabel = value.countryCode === 'US' ? 'State' : value.countryCode === 'CA' ? 'Province' : 'State/Province/Region';
    const useUsStates = value.countryCode === 'US';
    const cityLabel = value.countryCode === 'US' || value.countryCode === 'CA' ? 'City' : 'City/Town';
    const postalCodeLabel = value.countryCode === 'US' ? 'Zip Code' : value.countryCode === 'CA' ? 'Postal Code' : 'Zip/Postal Code';

    return (
      <>
        <div className="field-wrapper">
          <CountryField
            fullWidth
            label="Country"
            value={value.countryCode}
            error={error.countryCode}
            onChange={this.handleCountryCodeChange}
            currentLocation={currentLocation}
          />
        </div>
        <div className="field-wrapper">
          <TextField
            fullWidth
            label="Address 1"
            value={value.address1}
            error={error.address1}
            onChange={this.handleAddress1Change}
          />
        </div>
        <div className="field-wrapper">
          <TextField
            fullWidth
            label="Address 2"
            value={value.address2}
            error={error.address2}
            onChange={this.handleAddress2Change}
          />
        </div>
        <div className="field-wrapper">
          {useUsStates ? (
            <StateField
              label={stateLabel}
              value={value.state}
              error={error.state}
              onChange={this.handleStateChange}
            />
          ) : (
            <TextField
              fullWidth
              label={stateLabel}
              value={value.state}
              error={error.state}
              onChange={this.handleStateChange}
            />
          )}
        </div>
        <div className="field-wrapper">
          <TextField
            fullWidth
            label={cityLabel}
            value={value.city}
            error={error.city}
            onChange={this.handleCityChange}
          />
        </div>
        <div className="field-wrapper">
          <PostalCodeField
            label={postalCodeLabel}
            value={value.postalCode}
            error={error.postalCode}
            onChange={this.handlePostalCodeChange}
          />
        </div>
      </>
    );
  }
}

Address.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  error: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.object,
};


export default Address;
