import React from 'react';
import { connect } from 'react-redux';

import * as authActions from 'app/redux/auth/actions';
import * as authSelectors from 'app/redux/auth/selectors';
import * as cartActions from 'app/redux/cart/actions';
import * as cartSelectors from 'app/redux/cart/selectors';
import * as settingsActions from 'app/redux/settings/actions';
import * as settingsSelectors from 'app/redux/settings/selectors';
import * as stateActions from 'app/redux/state/actions';
import * as stateSelectors from 'app/redux/state/selectors';
import * as transactionActions from 'app/redux/transactions/actions';
import * as transactionSelectors from 'app/redux/transactions/selectors';
import { dialogActions, layoutActions } from 'app/redux/ui/actions';
import * as uiSelectors from 'app/redux/ui/selectors';
import { SwitchWithRoutes } from 'common/navigation';
import { generateRouteConfig } from 'config/routes';


const Routes = (props) => {
  const routeConfig = generateRouteConfig(props);

  return (
    <SwitchWithRoutes
      rootPath=""
      subroutes={routeConfig}
    />
  );
};


const mapStateToProps = (state) => ({
  activeCart: cartSelectors.activeCartSelector(state),
  activeCartId: cartSelectors.activeCartIdSelector(state),
  activeCartCustomer: cartSelectors.activeCartCustomerSelector(state),
  areRevenueCategoriesRequired: settingsSelectors.areRevenueCategoriesRequired(state),
  activeUsers: authSelectors.activeUsersSelector(state),
  checkoutOrderOnlyAtLocationOfPurchase: settingsSelectors.checkoutOrderOnlyAtLocationOfPurchase(state),
  currentDevice: transactionSelectors.paymentDevice(state),
  currentLocation: stateSelectors.currentLocation(state),
  currentStaff: authSelectors.currentStaff(state),
  currentUser: authSelectors.currentUser(state),
  device: transactionSelectors.paymentDevice(state),
  isAdHocChargeActive: settingsSelectors.isAdHocChargeActive(state),
  isCartLoading: cartSelectors.isCartLoading(state),
  isStoreCreditActive: settingsSelectors.isStoreCreditActive(state),
  isPointsActive: settingsSelectors.isPointsActive(state),
  paymentStatus: transactionSelectors.paymentStatus(state),
  rightDrawer: uiSelectors.rightDrawer(state),
  timeZone: stateSelectors.timeZone(state),
});

const mapDispatchToProps = (dispatch) => ({
  assignCustomerToCurrentCart: (customer) => dispatch(cartActions.assignCustomerToCurrentCart(customer)),
  assignStaffToCart: (staffId, cartId) => dispatch(cartActions.assignStaffToCart(staffId, cartId)),
  cancelOrder: (payload, callback) => dispatch(transactionActions.cancelOrder(payload, callback)),
  clearCart: () => dispatch(cartActions.clearCart()),
  completeCart: (callback) => dispatch(cartActions.completeCart(callback)),
  confirmAction: (callback, options) => dispatch(dialogActions.confirmAction(callback, options)),
  createNewCart: () => dispatch(cartActions.createNewCart()),
  deleteCart: () => dispatch(cartActions.deleteCart()),
  focusOnCustomerCart: (customerId) => dispatch(cartActions.focusOnCustomerCart(customerId)),
  newOrderPayment: (payload, callback) => dispatch(transactionActions.newOrderPayment(payload, callback)),
  putCartDiscount: (discount) => dispatch(cartActions.putCartDiscount(discount)),
  putCartPromoCode: (promoCode) => dispatch(cartActions.putCartPromoCode(promoCode)),
  putItemsInCart: (items) => dispatch(cartActions.putItemsInCart(items)),
  // eslint-disable-next-line max-len
  putItemsInCustomerCart: (items, customerId, callback) => dispatch(cartActions.putItemsInCustomerCart(items, customerId, callback)),
  refundItem: (order, itemType, itemId) => dispatch(dialogActions.refundItem(order, itemType, itemId)),
  removeItemFromCart: (itemId) => dispatch(cartActions.removeItemFromCart(itemId)),
  removePromoCodeFromCart: () => dispatch(cartActions.removePromoCodeFromCart()),
  resetPaymentStatus: () => dispatch(transactionActions.resetPaymentStatus()),
  setActiveCart: (cart) => dispatch(cartActions.setActiveCart(cart)),
  setAddCardDialogCallback: (callback) => dispatch(dialogActions.setAddCardDialogCallback(callback)),
  setCheckinDialogCallback: (callback) => dispatch(dialogActions.setCheckinDialogCallback(callback)),
  setCheckinSettings: (settings) => dispatch(settingsActions.setCheckinSettings(settings)),
  setCurrentLocation: (location) => dispatch(stateActions.setCurrentLocation(location)),
  setCurrentStaff: (staff) => dispatch(authActions.setCurrentStaff(staff)),
  setCurrentUser: (user) => dispatch(authActions.setCurrentUser(user)),
  setGeneralSettings: (settings) => dispatch(settingsActions.setGeneralSettings(settings)),
  setPaymentStatus: (payload) => dispatch(transactionActions.setPaymentStatus(payload)),
  setRefundDialogCallback: (callback) => dispatch(dialogActions.setRefundDialogCallback(callback)),
  setRightDrawerView: (view) => dispatch(layoutActions.setRightDrawerView(view)),
  showAddCardDialog: (customerId) => dispatch(dialogActions.showAddCardDialog(customerId)),
  showCheckinDialogCustomer: (customerId) => dispatch(dialogActions.showCheckinDialogCustomer(customerId)),
  showCheckinDialogSearch: (event) => dispatch(dialogActions.showCheckinDialogSearch(event)),
  showCreateAdHocChargeDialog: (customer) => dispatch(dialogActions.showCreateAdHocChargeDialog(customer)),
  showCopyToClipboardDialog: (text) => dispatch(dialogActions.showCopyToClipboardDialog(text)),
  showNewGiftCardDialog: (customer) => dispatch(dialogActions.showNewGiftCardDialog(customer)),
  showRefundOrderDialog: (order) => dispatch(dialogActions.showRefundOrderDialog(order)),
  terminateOrderPayment: () => dispatch(transactionActions.terminateOrderPayment()),
  toggleCustomerDialog: (id) => dispatch(dialogActions.toggleCustomerDialog(id)),
  toggleRightDrawer: () => dispatch(layoutActions.toggleRightDrawer()),
  unassignCartCustomer: () => dispatch(cartActions.unassignCartCustomer()),
  unassignCartStaff: () => dispatch(cartActions.unassignCartStaff()),
  updateCartItemQuantity: (itemId, qty) => dispatch(cartActions.updateCartItemQuantity(itemId, qty)),
  userImageHasChanged: (imageURL) => dispatch(authActions.userImageHasChanged(imageURL)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
