import React from 'react';
import { CircularProgress } from '@material-ui/core';


const ButtonSpinner = () => (
  <div className="button-spinner-container">
    <CircularProgress
      size={24}
      color="inherit"
    />
  </div>
);


export default ButtonSpinner;
