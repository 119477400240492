import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import * as authActions from 'app/redux/auth/actions';
import * as authSelectors from 'app/redux/auth/selectors';
import * as stateActions from 'app/redux/state/actions';
import * as stateSelectors from 'app/redux/state/selectors';
import * as transactionActions from 'app/redux/transactions/actions';
import { getRoutePath } from 'service/navigation';

import AuthRouteView from './AuthRouteView';


class AuthRoute extends React.Component {
  checkAuthentication = () => {
    const { currentUser, setCurrentUser, history } = this.props;

    if (currentUser.isLogged) {
      return true;
    }

    let cbActive = true;

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (cbActive) {
        cbActive = false;

        if (user) {
          setCurrentUser(user);
        } else {
          history.push(getRoutePath('LOGIN'));
        }
      }
    });

    return false;
  };

  handleLocationSelect = (location) => {
    this.props.changeLocation(location);
  };

  handleDeviceSelect = (device) => {
    this.props.setPaymentDevice(device);
  };


  render() {
    const { changeLocation, currentLocation, currentUser, setCurrentUser, setPaymentDevice, ...rest } = this.props;

    const isAuthenticated = this.checkAuthentication();

    return (
      <AuthRouteView
        currentLocation={currentLocation}
        isAuthenticated={isAuthenticated}
        handleLocationSelect={this.handleLocationSelect}
        handleDeviceSelect={this.handleDeviceSelect}
        {...rest}
      />
    );
  }
}

AuthRoute.propTypes = {
  changeLocation: PropTypes.func.isRequired,
  currentLocation: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  setPaymentDevice: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  currentLocation: stateSelectors.currentLocation(state),
  currentUser: authSelectors.currentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeLocation: (location) => dispatch(stateActions.changeLocation(location)),
  setCurrentUser: (user) => dispatch(authActions.setCurrentUser(user)),
  setPaymentDevice: (payload) => dispatch(transactionActions.setPaymentDevice(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthRoute));
