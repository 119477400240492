import React from 'react';
import { PropTypes } from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';


class ColorCell extends React.Component {
  handleClick = () => {
    this.props.onSelect(this.props.color);
  };

  render() {
    const { color, isSelected } = this.props;

    return (
      <div
        className="calendar-color-picker-item"
        style={{ backgroundColor: color }}
        onClick={this.handleClick}
      >
        {isSelected && (
          <span className="calendar-color-picker-selected">
            <CheckIcon
              color="inherit"
              fontSize="inherit"
            />
          </span>
        )}
      </div>
    );
  }
}

ColorCell.propTypes = {
  color: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};


export default ColorCell;
