import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';


countries.registerLocale(enLocale);

export const COUNTRY_MAP = countries.getNames('en');
export const COUNTRY_PICKLIST_VALUES = Object.keys(COUNTRY_MAP).map((alpha2) => ({
  label: COUNTRY_MAP[alpha2],
  value: alpha2,
}));
