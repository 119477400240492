import { v1 as uuidv1 } from 'uuid';

import { FLAT_DISCOUNT, PERCENT_DISCOUNT } from 'constants/discounts';

import { op } from '../utility';
import {
  getDiscountPercentageProp, getDiscountFlatProp, getSubtotalProp,
  getTotalDiscountProp, getDiscountAmountProp,
  getCartDiscount, getCartDiscountType,
  getItemDiscountType,
} from '../utility/pricing';


const getItemForNewOrder = (item) => {
  const {
    id, type, discountFlat, discountPercentage, quantity,
    productId, productVariantId, price,
    membershipTypeId, assignedCustomerId, waiveSignupFee, doNotProrate,
    isTaxExempt, termsCheckedDT, paymentCardId,
    passTypeId,
    description,
    amount, to, from, message, recipientEmail, externalId,
    ticketTypeId, eventId, bookingId, startDT, customerId,
  } = item;
  const payload = { type, discountFlat, discountPercentage };

  switch (type) {
    case 'PRODUCT':
      payload.productId = productId;
      payload.quantity = quantity;
      payload.price = price;
      payload.metaJSON = '';

      if (id) {
        payload.id = id;
      }

      if (productVariantId) {
        payload.productVariantId = productVariantId;
      }

      break;
    case 'MEMBERSHIP':
      payload.membershipTypeId = membershipTypeId;
      payload.assignedCustomerId = assignedCustomerId;
      payload.waiveSignupFee = waiveSignupFee;
      payload.doNotProrate = doNotProrate;

      if (id) {
        payload.id = id;
      }

      if (termsCheckedDT) {
        payload.termsCheckedDT = termsCheckedDT;
      }

      if (paymentCardId) {
        payload.paymentCardId = paymentCardId;
      }

      if (isTaxExempt) {
        payload.isTaxExempt = isTaxExempt;
      }

      break;
    case 'PASS':
      payload.passTypeId = passTypeId;
      payload.quantity = quantity;
      payload.assignedCustomerId = assignedCustomerId;

      if (id) {
        payload.id = id;
      }

      if (termsCheckedDT) {
        payload.termsCheckedDT = termsCheckedDT;
      }

      break;
    case 'FEE':
      payload.price = price;
      payload.description = description;

      if (id) {
        payload.id = id;
      }

      if (isTaxExempt) {
        payload.isTaxExempt = Boolean(isTaxExempt);
      }

      break;
    case 'GIFT_CARD':
      payload.amount = amount;
      payload.to = to;
      payload.from = from;
      payload.message = message;

      if (id) {
        payload.id = id;
      }

      if (recipientEmail) {
        payload.recipientEmail = recipientEmail;
      }

      if (externalId) {
        payload.externalId = externalId;
      }

      if (assignedCustomerId) {
        payload.assignedCustomerId = assignedCustomerId;
      }

      break;
    case 'EVENT_BOOKING_TICKET':
      payload.ticketTypeId = ticketTypeId;
      payload.quantity = quantity;
      payload.customerId = customerId;
      payload.assignedCustomerId = assignedCustomerId;

      if (id) {
        payload.id = id;
      }

      if (eventId) {
        payload.eventId = eventId;
      }

      if (bookingId) {
        payload.bookingId = bookingId;
      }

      if (startDT) {
        payload.startDT = startDT;
      }

      break;
    case 'EVENT_SERIES_TICKET':
      payload.ticketTypeId = ticketTypeId;
      payload.quantity = quantity;
      payload.customerId = customerId;
      payload.assignedCustomerId = assignedCustomerId;
      payload.eventId = eventId;

      if (id) {
        payload.id = id;
      }

      break;
    case 'EVENT_BOOKING_INVOICE':
      payload.bookingId = bookingId;
      payload.price = price;

      if (id) {
        payload.id = id;
      }

      break;
    default:
  }

  return payload;
};

const getItemsFromExistingOrder = (order) => {
  const orderItems = order.items || order.orderItem || [];
  const items = orderItems.map((item) => {
    const { type, product, metaJSON } = item;
    const payload = { ...item };

    if (!payload.UUID) {
      payload.UUID = uuidv1();
    }

    switch (type) {
      case 'PRODUCT':
        payload.productId = product.id;

        break;
      case 'MEMBERSHIP':
        if (!payload.typeInfo) {
          payload.typeInfo = JSON.parse(metaJSON).typeInfo;
        }

        break;
      case 'PASS':
        if (!payload.typeInfo) {
          payload.typeInfo = JSON.parse(metaJSON).typeInfo;
        }

        break;
      case 'FEE':

        break;
      case 'GIFT_CARD':

        break;
      case 'EVENT_BOOKING_TICKET':

        break;
      case 'EVENT_SERIES_TICKET':

        break;
      case 'EVENT_BOOKING_INVOICE':

        break;
      default:
    }

    return payload;
  });

  return items;
};


class CartModel {
  constructor(data_) {
    const data = (!data_ || typeof data_ !== 'object') ? {} : data_;

    if (
      (data.hasOwnProperty('items') && Array.isArray(data.items)) ||
      (data.hasOwnProperty('orderItem') && Array.isArray(data.orderItem))
    ) {
      // it's an order
      this.data = {
        order: data,
        totals: {
          subtotal: data.subtotal,
          total: data.total,
          totalDiscount: data.totalDiscount,
          totalTax: data.totalTax,
          items: getItemsFromExistingOrder(data),
        },
        customerId: data.customerId,
        customer: data.customer,
      };
    } else {
      this.data = { ...data };
    }

    this.data.discountPercentage = data[getDiscountPercentageProp(data.location)];
    this.data.discountFlat = data[getDiscountFlatProp(data.location)];
  }

  getLocation = () => this.data.location;

  getCustomer = () => this.data.customer;

  getCustomerId = () => this.data.customerId;

  getTotalsField = (fieldName, defaultValue) => op(this.data.totals, fieldName, defaultValue);

  getTaxNameField = (fieldName, defaultValue) => op(this.data.location, fieldName, defaultValue);

  getSubtotal = () => this.getTotalsField(getSubtotalProp(this.data.location), 0);

  getTotal = () => this.getTotalsField('total', 0);

  getTotalPaid = () => (this.hasOrder() ? this.data.order.trxSumTotal : 0);

  getAmountDue = () => (this.hasOrder() ? this.data.order.amountDue : this.getTotal());

  getTotalDiscount = () => this.getTotalsField(getTotalDiscountProp(this.data.location), 0);

  getTotalTax = () => this.getTotalsField('totalTax', 0);

  getTotalCityTax = () => this.getTotalsField('cityTaxTotal', 0);

  getTotalLocalTax = () => this.getTotalsField('localeTaxTotal', 0);

  getTotalStateTax = () => this.getTotalsField('stateTaxTotal', 0);

  getTotalAdditionalTax = () => this.getTotalsField('additionalTaxTotal', 0);

  getCityTaxName = () => this.getTaxNameField('cityTaxName', null);

  getLocalTaxName = () => this.getTaxNameField('localeTaxName', null);

  getStateTaxName = () => this.getTaxNameField('stateTaxName', null);

  getItems = () => this.getTotalsField('items', []);

  getItemsWithoutUUIDS = () => this.getItems().map(({ UUID, ...rest }) => ({ ...rest }));

  getItemCount = () => this.getItems().length;

  isEmpty = () => this.getItemCount() === 0;

  isReal = () => Boolean(this.data.UUID);

  getDiscountType = () => getCartDiscountType(this.data);

  getDiscountAmount = () => getCartDiscount(this.data) || 0;

  hasDiscount = () => this.getDiscountAmount() > 0;

  getDiscountValue = () => {
    const discountType = this.getDiscountType();

    return (
      discountType === FLAT_DISCOUNT
        ? this.getDiscountFlatValue()
        : discountType === PERCENT_DISCOUNT
          ? this.getDiscountPercentageValue()
          : 0
    );
  };

  getDiscountFlatAmount = () => this.data.discountFlat || 0;

  getDiscountFlatValue = () => this.data.discountFlat || 0;

  getDiscountPercentageAmount = () => this.data.discountPercentage || 0;

  getDiscountPercentageValue = () => (
    this.getTotalsField(getDiscountAmountProp(this.data.location), 0) -
    this.getTotalsField(getDiscountFlatProp(this.data.location), 0)
  );

  getPromoCode = () => this.data.promocode;

  hasPromoCode = () => Boolean(this.data.promocode);

  getPromoCodeType = () => this.data.promocode?.type ?? null;

  getPromoCodeCode = () => this.data.promocode?.code ?? null;

  hasCustomer = () => Boolean(this.data.customer);

  requiresCustomer = (ITEM_TYPES_REQUIRING_CUSTOMER) => this.getItems().some(
    (item) => ITEM_TYPES_REQUIRING_CUSTOMER.includes(item.type)
  );

  itemsHaveDiscounts = () => this.getItems().some(
    (item) => getItemDiscountType(item, this.data.location)
  );

  hasOrder = () => Boolean(this.data.order);

  getItemsForNewOrder = () => this.getItems().map(getItemForNewOrder);

  getNewOrderConfig = (staffId, sendReceipt) => {
    const config = {
      cartId: this.data.id,
      customerId: this.data.customerId,
      location: this.data.location,
      staffId,
      description: 'New order',
      items: this.getItemsForNewOrder(),
      discountPercentage: this.data.discountPercentage,
      discountFlat: this.data.discountFlat,
    };

    if (this.data.customerId) {
      config.sendReceipt = sendReceipt;
    }

    return config;
  };
}


export default CartModel;
