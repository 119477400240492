import { createReducer } from 'service/utility/redux';

import { FINISH_INITIAL_CART_LOAD } from '../actions';


const initialState = false;

const finishedInitialCartLoad = createReducer(initialState, {
  [FINISH_INITIAL_CART_LOAD]: () => true,
});


export default finishedInitialCartLoad;
