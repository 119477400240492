import React from 'react';
import { PropTypes } from 'prop-types';
import NumberFormat from 'react-number-format';

import * as timeUtils from 'service/utility/timeUtils';


class DateFormatCustom extends React.Component {
  onValueChange = (values) => {
    this.props.onChange({
      target: {
        value: values.formattedValue,
      },
    });
  };

  render() {
    const { inputRef, onChange, ...rest } = this.props;

    const format = timeUtils.getShortDateFormat();
    const placeholder = timeUtils.getShortDatePlaceholder();
    const mask = timeUtils.getShortDateMask();

    return (
      <NumberFormat
        {...rest}
        getInputRef={inputRef}
        onValueChange={this.onValueChange}
        decimalScale={0}
        type="tel"
        format={format}
        placeholder={placeholder}
        mask={mask}
      />
    );
  }
}

DateFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};


export default DateFormatCustom;
