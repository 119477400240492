import { createReducer } from 'service/utility/redux';

import { SET_ACTIVE_CART } from '../actions';


const initialState = null;

const activeCart = createReducer(initialState, {
  [SET_ACTIVE_CART]: (state, { cart }) => cart,
});


export default activeCart;
