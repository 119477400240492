import React from 'react';
import { PropTypes } from 'prop-types';


const CenterColumn = ({ children }) => (
  <div className="h-100 fixed-header">
    <div className="main-app-container">
      <section className="app-page-container">
        {children}
      </section>
    </div>
  </div>
);

CenterColumn.propTypes = {
  children: PropTypes.any,
};


export default CenterColumn;
