import React from 'react';
import { PropTypes } from 'prop-types';
import { List } from '@material-ui/core';

import { NestedNavItem, NavItem, NavDivider } from './components';


const SideNav = ({ background, navConfig, onItemClick }) => (
  <div className="sidenav-content-wrapper">
    <List
      component="nav"
      dense
      classes={{ root: 'sidenav-content' }}
    >
      {navConfig.map((item, idx) => (
        item.isDivider ? (
          <NavDivider key={`SNI_${idx}`} />
        ) : item.subNav ? (
          <NestedNavItem
            key={`SNI_${idx}`}
            item={item}
            onItemClick={onItemClick}
          />
        ) : (
          <NavItem
            key={`SNI_${idx}`}
            item={item}
            onItemClick={onItemClick}
          />
        )
      ))}
    </List>
    {background}
  </div>
);

SideNav.propTypes = {
  background: PropTypes.any.isRequired,
  navConfig: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
};


export default SideNav;
