import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import { noMoreThan100Percent } from 'service/utility/errorMessages';

import NumberField from './NumberField';


class PercentageField extends React.PureComponent {
  validate = (value) => (
    !this.props.allowMoreThan100Percent && value > 100
      ? noMoreThan100Percent()
      : null
  );

  render() {
    const { allowMoreThan100Percent, className, ...rest } = this.props;

    const percentageFieldClassName = classnames('percentage-field', { [className]: Boolean(className) });

    return (
      <NumberField
        {...rest}
        inputProps={{
          suffix: '%',
          decimalScale: 2,
        }}
        validate={this.validate}
        className={percentageFieldClassName}
      />
    );
  }
}

PercentageField.propTypes = {
  allowMoreThan100Percent: PropTypes.bool,
  className: PropTypes.string,
};

PercentageField.defaultProps = {
  allowMoreThan100Percent: false,
};


export default PercentageField;
