import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import { TIMEZONE_PICKLIST_VALUES } from 'constants/timezones';

import PicklistField from './PicklistField';


class TimezoneField extends React.PureComponent {
  componentDidMount() {
    if (!this.props.value) {
      this.props.onChange({ value: this.props.timeZone });
    }
  }


  render() {
    const { timeZone, dispatch, className, ...rest } = this.props;

    const timezoneFieldClassName = classnames('timezone-field', { [className]: Boolean(className) });

    return (
      <PicklistField
        {...rest}
        picklistValues={TIMEZONE_PICKLIST_VALUES}
        className={timezoneFieldClassName}
      />
    );
  }
}

TimezoneField.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func,
  onChange: PropTypes.func,
  timeZone: PropTypes.string.isRequired,
  value: PropTypes.string,
};

TimezoneField.defaultProps = {
  value: '',
};


export default TimezoneField;
