import React from 'react';
import { PropTypes } from 'prop-types';

import CartHeader from './CartHeader';
import OrderHeader from './OrderHeader';


const Header = (props) => (
  props.orderExists ? (
    <OrderHeader cartOrOrder={props.cartOrOrder} />
  ) : (
    <CartHeader
      onAssignCustomer={props.onAssignCustomer}
      checkout={props.checkout}
    />
  )
);

Header.propTypes = {
  cartOrOrder: PropTypes.object,
  checkout: PropTypes.bool,
  onAssignCustomer: PropTypes.func.isRequired,
  orderExists: PropTypes.bool.isRequired,
};


export default Header;
