import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { layoutActions } from 'app/redux/ui/actions';
import * as uiSelectors from 'app/redux/ui/selectors';


class ToggleLeftDrawer extends React.Component {
  handleClick = () => {
    this.props.setLeftDrawerOpen(!this.props.isLeftDrawerOpen);
  };

  render() {
    return (
      <IconButton
        className="toggle-left-drawer"
        onClick={this.handleClick}
      >
        <MenuIcon />
      </IconButton>
    );
  }
}

ToggleLeftDrawer.propTypes = {
  isLeftDrawerOpen: PropTypes.bool.isRequired,
  setLeftDrawerOpen: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  isLeftDrawerOpen: uiSelectors.isLeftDrawerOpen(state),
});

const mapDispatchToProps = (dispatch) => ({
  setLeftDrawerOpen: (isOpen) => dispatch(layoutActions.setLeftDrawerOpen(isOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToggleLeftDrawer);
