import { take, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';

import { CLEAR_CART, setCartLoading } from '../actions';
import { activeCartIdSelector } from '../selectors';
import { loadCart } from './cartSagas';


function *clearCartSaga() {
  while (true) {
    yield take(CLEAR_CART);

    const cartId = yield select(activeCartIdSelector);
    if (!cartId) return null;

    console.log(`Clearing cart with UUID = ${cartId}`);
    const toast1 = toast('Clearing cart...');

    try {
      yield put(setCartLoading(true));
      yield call(API.clearCart, cartId);
      yield call(loadCart, cartId);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('putItemsInCartSaga error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    } finally {
      toast.dismiss(toast1);
      yield put(setCartLoading(false));
    }
  }
}


export default clearCartSaga;
