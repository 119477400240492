import React from 'react';
import { PropTypes } from 'prop-types';
import { Portal } from '@material-ui/core';


const PortalWrapper = ({ children }) => (
  <Portal>
    <div>
      {children}
    </div>
  </Portal>
);

PortalWrapper.propTypes = {
  children: PropTypes.any,
};


export default PortalWrapper;
