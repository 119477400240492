import React from 'react';
import { PropTypes } from 'prop-types';
import { Route } from 'react-router-dom';

import { ChangeLocationDialog } from 'common/dialogs';


const AuthRouteView = ({
  currentLocation, handleDeviceSelect, handleLocationSelect, isAuthenticated, ...rest
}) => {
  if (!isAuthenticated) return null;

  if (!currentLocation) {
    return (
      <ChangeLocationDialog
        open
        onLocationSelect={handleLocationSelect}
        onDeviceSelect={handleDeviceSelect}
        currentLocation={currentLocation}
      />
    );
  }

  return (
    <Route {...rest} />
  );
};

AuthRouteView.propTypes = {
  currentLocation: PropTypes.object,
  handleDeviceSelect: PropTypes.func.isRequired,
  handleLocationSelect: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};


export default AuthRouteView;
