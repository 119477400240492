import React from 'react';
import { PropTypes } from 'prop-types';

import { somePropsChanged } from 'service/utility';

import { MembershipTypeSearch } from '../../searches';


class MembershipTypeFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      membershipType: null,
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['membershipType'])) {
      this.handleValueChange();
    }
  }

  getOwnFilterMap = () => ({
    membershipTypeId: this.state.membershipType === null ? null : this.state.membershipType.id,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleMembershipTypeChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ membershipType: update.value });
    }
  };


  render() {
    const { membershipType } = this.state;
    const { filterByIsActive } = this.props;

    return (
      <MembershipTypeSearch
        label="Type"
        value={membershipType}
        onChange={this.handleMembershipTypeChange}
        className="membership-type-filter"
        placeholder="Search for a Membership Type"
        filterByIsActive={filterByIsActive}
      />
    );
  }
}

MembershipTypeFilter.propTypes = {
  filterByIsActive: PropTypes.bool,
  onValueChange: PropTypes.func,
};

MembershipTypeFilter.ownName = 'MembershipTypeFilter';
MembershipTypeFilter.ownFilters = ['membershipTypeId'];


export default MembershipTypeFilter;
