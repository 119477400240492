import { createReducer } from 'service/utility/redux';

import {
  SHOW_ADD_CARD_DIALOG, SET_ADD_CARD_DIALOG_CALLBACK, HIDE_ADD_CARD_DIALOG,
} from '../actions/dialog';


const initState = {
  isOpen: false,
  customerId: null,
  callback: null,
};

const addCardDialog = createReducer(initState, {
  [SHOW_ADD_CARD_DIALOG](state, { customerId }) {
    return {
      ...state,
      isOpen: true,
      customerId,
    };
  },
  [SET_ADD_CARD_DIALOG_CALLBACK](state, { callback }) {
    return {
      ...state,
      callback,
    };
  },
  [HIDE_ADD_CARD_DIALOG]() {
    return initState;
  },
});


export default addCardDialog;
