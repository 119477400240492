import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import { TextField } from '@material-ui/core';

import { somePropsChanged } from 'service/utility';
import { isRequired } from 'service/utility/errorMessages';
import * as timeUtils from 'service/utility/timeUtils';

import DateFormatCustom from '../../DateFormatCustom';
import PortalWrapper from '../../PortalWrapper';


class DatetimeField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount() {
    const { value: stringValue, onChange } = this.props;

    const dateValue = (
      timeUtils.isEmptyIso8601Date(stringValue)
        ? null
        : timeUtils.formatIso8601Date(stringValue, this.props.timeZone)
    );
    const error = this.validateSelf(dateValue);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentDidUpdate(prevProps) {
    if (somePropsChanged(prevProps, this.props, ['required', 'value'])) {
      const { value: stringValue, onChange } = this.props;

      const dateValue = (
        timeUtils.isEmptyIso8601Date(stringValue)
          ? null
          : timeUtils.formatIso8601Date(stringValue, this.props.timeZone)
      );
      const error = this.validateSelf(dateValue);

      if (onChange) {
        onChange({ error });
      }
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }

  validateSelf = (value) => {
    const { required, label } = this.props;

    if (!value && required) return isRequired(label);

    return null;
  };

  handleDateBlur = () => {
    const { value: stringValue, onChange } = this.props;
    const dateValue = (
      timeUtils.isEmptyIso8601Date(stringValue)
        ? null
        : timeUtils.formatIso8601Date(stringValue, this.props.timeZone)
    );
    const error = this.validateSelf(dateValue);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ error });
    }
  };

  handleDateChange = (jsDateValue) => {
    const { value: stringValue, onChange } = this.props;
    const dateValue = (
      jsDateValue
        ? timeUtils.dateYearMonthDay(jsDateValue)
        : null
    );
    const timeValue = (
      stringValue
        ? timeUtils.iso8601Time(timeUtils.iso8601FromStringInTimeZone(stringValue, this.props.timeZone))
        : null
    );
    const value = timeUtils.iso8601StringInUTC(
      timeUtils.composeIso8601DateTime(dateValue, timeValue),
      this.props.timeZone,
    );

    if (onChange) {
      onChange({ value });
    }
  };

  handleTimeChange = (jsDateValue) => {
    const { value: stringValue, onChange } = this.props;
    const dateValue = (
      timeUtils.isEmptyIso8601Date(stringValue)
        ? null
        : timeUtils.iso8601Date(timeUtils.iso8601FromStringInTimeZone(stringValue, this.props.timeZone))
    );
    const timeValue = (
      jsDateValue
        ? timeUtils.dateHoursAndMinutes(jsDateValue)
        : null
    );
    const value = timeUtils.iso8601StringInUTC(
      timeUtils.composeIso8601DateTime(dateValue, timeValue),
      this.props.timeZone,
    );

    if (onChange) {
      onChange({ value });
    }
  };

  render() {
    const {
      autoFocus, className, disabled, dispatch, error, fullWidth,
      label, margin, narrow, onChange, required, timeZone, value: stringValue, variant,
      DatePickerProps, TimePickerProps, ...rest
    } = this.props;
    const { blurred } = this.state;

    const datetimeFieldClassName = classnames('datetime-field', { [className]: Boolean(className) });
    const dateFieldClassName = classnames('date-field', { narrow });
    const timeFieldClassName = classnames('time-field', { narrow });
    const dateFormat = timeUtils.getShortDateDatePickerFormat();
    const timeFormat = timeUtils.getSimpleTimeDatePickerFormat();
    const jsDateValue = (
      timeUtils.isEmptyIso8601Date(stringValue)
        ? null
        : timeUtils.localDateFromIso8601Date(timeUtils.formatIso8601Date(stringValue, timeZone))
    );
    const timeValue = (
      !stringValue
        ? timeUtils.EMPTY_ISO8601_TIME
        : timeUtils.formatIso8601Time(stringValue, timeZone)
    );
    const jsTimeValue = timeUtils.localDateFromIso8601Time(timeValue);
    const showError = blurred && Boolean(error);

    return (
      <div className={datetimeFieldClassName}>
        <div className={dateFieldClassName}>
          <DatePicker
            dateFormat={dateFormat}
            selected={jsDateValue}
            onChange={this.handleDateChange}
            onBlur={this.handleDateBlur}
            required={required}
            autoFocus={autoFocus}
            customInput={
              <TextField
                error={showError}
                helperText={error}
                required={required}
                fullWidth={fullWidth}
                label={label}
                InputLabelProps={{
                  shrink: true,
                }}
                margin={margin}
                variant={variant}
                InputProps={{
                  inputComponent: DateFormatCustom,
                }}
                className="inline-field"
                {...rest}
              />
            }
            disabled={disabled}
            popperContainer={PortalWrapper}
            {...DatePickerProps}
          />
        </div>
        <div className={timeFieldClassName}>
          <DatePicker
            dateFormat={timeFormat}
            selected={jsTimeValue}
            onChange={this.handleTimeChange}
            autoFocus={autoFocus}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            customInput={
              <TextField
                fullWidth={fullWidth}
                InputLabelProps={{
                  shrink: true,
                }}
                margin={margin}
                variant={variant}
                InputProps={{
                  readOnly: true,
                }}
                className="inline-field"
                {...rest}
              />
            }
            disabled={disabled}
            popperContainer={PortalWrapper}
            {...TimePickerProps}
          />
        </div>
      </div>
    );
  }
}

DatetimeField.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  DatePickerProps: PropTypes.object,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  margin: PropTypes.string,
  narrow: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  TimePickerProps: PropTypes.object,
  timeZone: PropTypes.string.isRequired,
  value: PropTypes.string,
  variant: PropTypes.string,
};

DatetimeField.defaultProps = {
  autoFocus: false,
  DatePickerProps: {},
  error: '',
  fullWidth: false,
  margin: 'dense',
  narrow: true,
  required: false,
  TimePickerProps: {},
  value: '',
  variant: 'outlined',
};


export default DatetimeField;
