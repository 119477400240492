import React from 'react';
import { PropTypes } from 'prop-types';
import { MenuItem, ListItemText } from '@material-ui/core';


const CertificationSearchItem = ({ children, isFocused, data, innerProps }) => (
  <MenuItem
    selected={isFocused}
    onClick={innerProps.onClick}
    className="certification-search-item"
  >
    <ListItemText
      primary={children.toString()}
      secondary={data.description}
    />
  </MenuItem>
);

CertificationSearchItem.propTypes = {
  children: PropTypes.any.isRequired,
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool.isRequired,
};


export default CertificationSearchItem;
