import React from 'react';
import { isObject, isEqual, cloneDeep } from 'lodash';
import { Settings } from 'luxon';
import i18next from 'i18next';
import { compressAccurately } from 'image-conversion';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Button } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import enUS from 'date-fns/locale/en-US';
import enCA from 'date-fns/locale/en-CA';
import enAU from 'date-fns/locale/en-AU';
import enNZ from 'date-fns/locale/en-NZ';
import deDE from 'date-fns/locale/de';

import { MAX_IMAGE_SIZE } from 'constants/ui';

import { getCountryName } from '../i18n';


/* eslint-disable */
export const useEffectAsync = (effect, inputs, cleanup) => {
  React.useEffect(() => {
    effect();
    if (cleanup) {
      return cleanup;
    }
  }, inputs);
};
/* eslint-enable */

export const readAsData = (inputFile) => {
  const fileReader = new FileReader();

  return new Promise((resolve) => {
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.readAsDataURL(inputFile);
  });
};

export const pollAsyncFunction = (fn, check, interval) => {
  let resolve, reject, cancelled;

  const promise = new Promise((resolveFromPromise, rejectFromPromise) => {
    resolve = resolveFromPromise;
    reject = rejectFromPromise;
  });

  // recursive poll function
  const poll = async () => {
    try {
      if (!cancelled) {
        const startTime = Number(new Date());
        const endTime = startTime + interval;
        const res = await fn(); // call polling function

        if (check(res)) {
          // check passed
          resolve(res);
        } else if (Number(new Date()) > endTime) {
          // try again if interval time has passed;
          poll();
        } else {
          // wait a bit longer before polling
          const diffTime = endTime - Number(new Date());

          setTimeout(poll, diffTime);
        }
      } else {
        // cancelled!
        resolve();
      }
    } catch (err) {
      reject(err);
    }
  };

  poll();

  return ({
    // this function actually returns both a promise and a cancel fn used to cancel function
    promise,
    cancel: () => {
      cancelled = true;
      reject({ reason: 'cancelled' });
    },
  });
};

export const loadDynamicScript = (scriptId, url, callback) => {
  const existingScript = document.getElementById(scriptId);

  if (!existingScript) {
    const script = document.createElement('script');

    script.src = url;
    script.id = scriptId;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

export const removeDOMElement = (elementId) => {
  const existingElement = document.getElementById(elementId);

  if (existingElement) {
    existingElement.parentNode.removeChild(existingElement);
  }
};

export const getErrorMessage = (error) => {
  if (typeof error === 'string') return error;
  if (typeof error !== 'object') return JSON.stringify(error);

  const { message: regularErrorMessage, response } = error;

  if (typeof response === 'string') return response;
  if (typeof response === 'object') {
    const { data } = response;

    if (typeof data === 'string') return data;
    if (typeof data === 'object') {
      const { message } = data;

      if (typeof message === 'string') return message;
    }
  }

  if (typeof regularErrorMessage === 'string') return regularErrorMessage;
  if (typeof response !== 'object') return JSON.stringify(response);

  return 'Unknown error';
};

export const getNewRelatedCheckboxesInitState = (field) => (data) => ({
  ...data,
  [field.selectedName]: [],
});

export const getEditRelatedCheckboxesInitState = (field) => (data) => ({
  ...data,
  [field.selectedName]: data[field.name] ? data[field.name].map((item) => item.id) : [],
});

export const getNewCheckboxInitState = (field) => (data) => ({
  ...data,
  [field.name]: true,
});

export const getNewRelatedObjectInitState = (field) => (data) => ({
  ...data,
  [field.selectedName]: {},
});

export const getEditRelatedObjectInitState = (field) => (data) => ({
  ...data,
  [field.selectedName]: data[field.selectedName] || {},
});

export const getNewFieldInitState = (field, value) => (data) => ({
  ...data,
  [field.name]: value,
});

export const getNewFieldsInitState = (fields, values) => (data) => ({
  ...data,
  ...fields.reduce((a, b, i) => { a[b.name] = values[i]; return a; }, {}),
});

export const getCheckinStatus = (status) => (
  status === 'failed' ? (
    'Failed'
  ) : status === 'cancelled' ? (
    'Cancelled'
  ) : status === 'void' ? (
    'Voided'
  ) : status === 'approved' ? (
    'Checked in'
  ) : status === 'pending' ? (
    'Pending'
  ) : ''
);

export const getCheckinStatusButton = (status, checkinFunc, voidFunc, disabled) => (
  status === 'failed' ? (
    <Button
      color="secondary"
      onClick={checkinFunc}
      disabled={disabled}
    >
      {'Retry Checkin'}
    </Button>
  ) : status === 'cancelled' ? (
    <Button
      color="primary"
      onClick={checkinFunc}
      disabled={disabled}
    >
      {'Check In'}
    </Button>
  ) : status === 'void' ? (
    <Button
      color="primary"
      onClick={checkinFunc}
      disabled={disabled}
    >
      {'Check In'}
    </Button>
  ) : status === 'approved' ? (
    voidFunc ? (
      <Button
        color="secondary"
        onClick={voidFunc}
        disabled={disabled}
      >
        {'Void Checkin'}
      </Button>
    ) : (
      <Button
        disabled
        classes={{ root: 'color-success' }}
      >
        {'Checked In'}
      </Button>
    )
  ) : status === 'pending' ? (
    <Button disabled>
      {'Pending'}
    </Button>
  ) : status === 'disabled' ? (
    <Button disabled>
      {'Check In'}
    </Button>
  ) : (
    <Button
      color="primary"
      onClick={checkinFunc}
      disabled={disabled}
    >
      {'Check In'}
    </Button>
  )
);

export const fieldSort = (field, desc) => (a, b) => {
  const a2 = a[field];
  const a3 = typeof a2 === 'string' ? a2.toUpperCase() : a2;
  const b2 = b[field];
  const b3 = typeof b2 === 'string' ? b2.toUpperCase() : b2;

  return (desc ? a3 > b3 : a3 < b3) ? -1 : (desc ? a3 < b3 : a3 > b3) ? 1 : 0;
};

export const getNavSecondaryIcon = (
  rootObject, loadingCondition, LoadingComponent, errorCondition,
) => (
  rootObject
    ? loadingCondition
      ? () => LoadingComponent
      : errorCondition
        ? ErrorIcon
        : null
    : null
);

export const objectHasErrors = (o) => Object.values(o).some((e) => e);

export const commaJoin = (arr) => arr.filter((e) => e).join(',');

export const commaSpaceJoin = (arr) => arr.filter((e) => e).join(', ');

export const dashJoin = (arr) => arr.filter((e) => e).join(' – ');

export const getAddressArray = (o, includeCountry) => {
  const response = [];

  if (isObject(o) && Boolean(o)) {
    const { address1, address2, city, state, postalCode, countryCode } = o;

    if (address1 || address2) {
      const row1 = [address1, address2];

      response.push(commaSpaceJoin(row1));
    }
    if (city || state || postalCode || (includeCountry && countryCode)) {
      const row2 = [city, state, postalCode];

      if (includeCountry && countryCode) {
        row2.push(getCountryName(countryCode));
      }

      response.push(commaSpaceJoin(row2));
    }
  }

  return response;
};

export const getBillingAddressArray = (o, includeCountry) => {
  const response = [];

  if (isObject(o) && Boolean(o)) {
    const {
      billingAddress1, billingAddress2, billingCity, billingState, billingPostalCode, billingCountryCode,
    } = o;

    if (billingAddress1 || billingAddress2) {
      const row1 = [billingAddress1, billingAddress2];

      response.push(commaSpaceJoin(row1));
    }
    if (billingCity || billingState || billingPostalCode || (includeCountry && billingCountryCode)) {
      const row2 = [billingCity, billingState, billingPostalCode];

      if (includeCountry && billingCountryCode) {
        row2.push(getCountryName(billingCountryCode));
      }

      response.push(commaSpaceJoin(row2));
    }
  }

  return response;
};

export const rem2px = (rem) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

export const op = (o, fld, dv = null) => (o && typeof o === 'object' ? o[fld] ?? dv : dv);

export const isNullish = (x) => ((x ?? null) === null);

export const getMap = (arr, key = 'id') => (
  arr.reduce((a, b) => {
    if (typeof key === 'function') {
      a[key(b)] = b;
    } else {
      a[b[key]] = b;
    }

    return a;
  }, {})
);

export const copyObjectWithoutRelated = (o) => {
  const o2 = {}, keys = Object.keys(o);

  for (const key of keys) {
    if (!isObject(o[key])) {
      o2[key] = o[key];
    }
  }

  return o2;
};

////////////////////////////////////////////////////////////////

export const setNewLocale = (newLocale) => {
  if (!['en-AU', 'en-CA', 'en-NZ', 'de-DE', 'en-US'].includes(newLocale)) {
    throw new Error(`${newLocale} locale not supported`);
  }

  Settings.defaultLocale = newLocale;
  i18next.changeLanguage(newLocale);

  const lang = (
    newLocale === 'en-US'
      ? enUS
      : newLocale === 'en-CA'
        ? enCA
        : newLocale === 'en-AU'
          ? enAU
          : newLocale === 'en-NZ'
            ? enNZ
            : deDE
  );

  registerLocale(newLocale, lang);
  setDefaultLocale(newLocale);
  // try {
  //   const lang = require(`date-fns/locale/${newLocale}`).default;

  //   registerLocale(newLocale, lang);
  //   setDefaultLocale(newLocale);
  // } catch (error) {
  //   const shortLocale = newLocale.substring(0, 2);

  //   try {
  //     const lang = require(`date-fns/locale/${shortLocale}`).default;

  //     registerLocale(shortLocale, lang);
  //     setDefaultLocale(shortLocale);
  //   } catch (error2) {
  //     //
  //   }
  // }
};

////////////////////////////////////////////////////////////////

export const getNotificationCardHeight = (notification) => {
  const extraHeight = notification._expanded ? 97 : 0;

  switch (notification.type) {
    case 'info':
      return 156 + extraHeight;
    case 'warning':
      return 156 + extraHeight;
    case 'error':
      return 184 + extraHeight;
    case 'checkin':
      return 224 + extraHeight;
    default:
      return null;
  }
};

export const routeWithParams = (route, o) => Object.keys(o).reduce((s, p) => s.replace(new RegExp(`:${p}(?=/|$)`), o[p]), route);

export const copyProps = (source, props, a = {}) => {
  for (let i = 0, l = props.length; i < l; i += 1) {
    const key = props[i];

    a[key] = source[key] ?? null;
  }

  return a;
};

export const removeProps = (source, props) => {
  const a = {};

  for (let i = 0, keys = Object.keys(source), l = keys.length; i < l; i += 1) {
    const key = keys[i];

    if (!props.includes(key)) {
      a[key] = source[key];
    }
  }

  return a;
};

export const setProps = (value, props, a = {}) => {
  for (let i = 0, l = props.length; i < l; i += 1) {
    const key = props[i];

    a[key] = cloneDeep(value);
  }

  return a;
};

export const somePropsChanged = (o1, o2, props) => (
  props.some((prop) => !isEqual(o1[prop], o2[prop]))
);

export const updateURLWithFilters = (filters, history) => {
  const { pathname } = window.location;
  const params = new URLSearchParams();

  Object.keys(filters).forEach((key) => params.append(key, filters[key]));

  const queryString = params.toString();
  const newUrl = `${pathname}${queryString && `?${queryString}`}`;

  history.replace(newUrl);
};

export const parseNull = (s) => (
  s === 'null' ? null : s
);

export const sleep = (ms) => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export const scrollToTop = () => {
  const el = document.getElementById('content');

  if (el) {
    el.scrollTo(0, 0);
  }
};

export const isSafari = () => (
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
);

export const downloadCSV = (csv, ...rest) => {
  const type = isSafari() ? 'application/csv' : 'text/csv';
  const blob = new Blob(['\uFEFF', csv], { type });
  const dataURI = `data:${type};charset=utf-8,\uFEFF${csv}`;
  const URL = window.URL || window.webkitURL;
  const URI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);
  window.open(URI, ...rest);
};

export const blobToBase64 = (newFile, callback) => {
  const reader = new FileReader();

  reader.onload = () => {
    const fileAsBase64 = reader.result;

    if (callback) {
      callback(fileAsBase64);
    }
  };

  reader.readAsDataURL(newFile);
};

export const compressFileOrBlobToBase64 = async (newFileOrBlob, callback, callCallbackOnlyIfSmaller) => {
  const imageSize = MAX_IMAGE_SIZE;
  let finalBlob = newFileOrBlob;
  if (finalBlob.size > imageSize * 1024) {
    finalBlob = await compressAccurately(finalBlob, imageSize);
  }
  if ((callCallbackOnlyIfSmaller && finalBlob.size < newFileOrBlob.size) || !callCallbackOnlyIfSmaller) {
    blobToBase64(finalBlob, callback);
  }
};
