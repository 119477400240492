import { createReducer } from 'service/utility/redux';

import { SET_CART_LOADING } from '../actions';


const initialState = false;

const loading = createReducer(initialState, {
  [SET_CART_LOADING]: (state, { isLoading }) => isLoading,
});


export default loading;
