import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { onSnapshot as fbFsOnSnapshot } from 'firebase/firestore';
import { IconButton, Badge } from '@material-ui/core';
import HeadsetMicRoundedIcon from '@material-ui/icons/HeadsetMicRounded';

import * as authSelectors from 'app/redux/auth/selectors';
import * as stateSelectors from 'app/redux/state/selectors';
import { layoutActions } from 'app/redux/ui/actions';
import * as uiSelectors from 'app/redux/ui/selectors';
import firebaseService from 'service/firebase';
import { getRouteByName } from 'service/navigation';
import { staffHasPermission } from 'service/staff';


class ToggleChat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unreadMessageCount: '…',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.createFirebaseRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentLocation.id !== prevProps.currentLocation.id) {
      this.cancelFirebaseRef();
      this.createFirebaseRef();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.cancelFirebaseRef();
  }

  createFirebaseRef() {
    const { currentLocation } = this.props;

    const docRef = firebaseService.getLocationFsDoc(currentLocation.id);

    this._unsubscribe = fbFsOnSnapshot(docRef, (doc) => {
      let unreadMessageCount = 0;

      if (doc.exists()) {
        unreadMessageCount = doc.data()?.unreadMessageCount ?? 0;
      }

      if (this._isMounted) {
        this.setState({ unreadMessageCount });
      }
    });
  }

  cancelFirebaseRef() {
    if (this._unsubscribe) {
      this._unsubscribe();
    }

    if (this._isMounted) {
      this.setState({
        unreadMessageCount: '…',
      });
    }
  }

  handleClick = () => {
    const { rightDrawer, setRightDrawerView, toggleRightDrawer } = this.props;

    if (rightDrawer.view !== 'chat') {
      setRightDrawerView('chat');
      if (!rightDrawer.isOpen) {
        toggleRightDrawer();
      }
    } else {
      toggleRightDrawer();
    }
  };

  render() {
    const { rightDrawer, disabled, currentStaff } = this.props;
    const { unreadMessageCount } = this.state;

    const canAccessChat = staffHasPermission(currentStaff, getRouteByName('SUPPORT')?.permission);

    if (!canAccessChat) return null;

    const activeTab = !disabled && rightDrawer.isOpen && rightDrawer.view === 'chat';

    return (
      <div className={classnames('chat-toggle', { active: activeTab, disabled })}>
        <IconButton
          onClick={this.handleClick}
          color="inherit"
          classes={{ root: 'toggle-icon-button' }}
        >
          <Badge
            color="secondary"
            badgeContent={unreadMessageCount}
            classes={{ badge: 'notification-count-badge' }}
          >
            <HeadsetMicRoundedIcon fontSize="inherit" />
          </Badge>
        </IconButton>
      </div>
    );
  }
}

ToggleChat.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  currentStaff: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  rightDrawer: PropTypes.object.isRequired,
  setRightDrawerView: PropTypes.func.isRequired,
  toggleRightDrawer: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  currentStaff: authSelectors.currentStaff(state),
  currentLocation: stateSelectors.currentLocation(state),
  rightDrawer: uiSelectors.rightDrawer(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRightDrawerView: (view) => dispatch(layoutActions.setRightDrawerView(view)),
  toggleRightDrawer: () => dispatch(layoutActions.toggleRightDrawer()),
});

const ToggleChat2 = connect(mapStateToProps, mapDispatchToProps)(ToggleChat);


export default ToggleChat2;
