import lockScreenSaga from './lockScreenSaga';
import setCurrentUserSaga from './loginSagas';
import logOutAndResetCacheSaga from './logOutAndResetCacheSaga';
import logOutSaga from './logOutSaga';


const authSagas = [
  lockScreenSaga,
  logOutAndResetCacheSaga,
  logOutSaga,
  setCurrentUserSaga,
];

export default authSagas;
