import React from 'react';

import LOGO from 'assets/approach-logo.svg';
import { LogoButton } from 'common/buttons';


const LogoButton2 = () => (
  <LogoButton src={LOGO} />
);


export default LogoButton2;
