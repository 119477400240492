export const IS_ACTIVE_TOOLTIP = `
  Available for purchase.  This must be checked to purchase in the main app or the portal as well.
`;
export const FOR_SALE_USE_ONLINE_TOOLTIP = `
  When selected, the public will be able to view, click and buy this.
  After this is purchased by a customer, the assigned customer will be able to interact with this on your portal.
`;
export const FOR_USE_ONLINE_ONLY_TOOLTIP = `
  When selected, the public will not be able to view, click and buy this.
  However, if this is owned by a customer, the assigned customer will be able to interact with this on your portal.
  Inactive or active membership/pass types that have been purchased previously will be usable in the portal if this is checked as long as the membership itself is active.
`;
export const GUEST_PASSES_TOOLTIP = `
  Guest Passes will update on the subsequent billing day.
`;
export const ALL_DAY_PASS_TOOLTIP = `
  This setting permits unlimited check-ins at applicable events at all participating locations in the same business day.
`;
export const AGE_REQUIREMENT_TOOLTIP = `
  Please note: if there is a minimum/maximum age set and the customer does not meet the age requirements, their check-in will fail.
`;
export const PURCHASE_MEMBERSHIP_OR_PASS = `
  Purchase Membership or Pass
`;
export const DO_NOT_PRORATE_TOOLTIP = `
Selecting this option will ensure that this membership, when purchased from the portal, will always be purchased at full price instead of the prorated amount.
`;
export const STAFF_IS_ACTIVE_TOOLTIP = `
  This setting manages which staff members appear in the staff list. If set to inactive, it will not appear in the list, unless the filter is adjusted to include inactive.
  Setting a staff member to inactive retains the staff, but the staff won't be able to login as their email has lost authorization. 
`;
export const STAFF_ROLE_IS_ACTIVE_TOOLTIP = `
  This setting manages which staff roles appear in the staff role list view. If set to inactive, they will not appear in the staff role list, unless the filter is adjusted to include inactive staff roles. Setting a staff role to inactive retains the staff role and all its settings, but staff can no longer be assigned this role, however, staff which already are assigned this staff role will continue to use the permissions associated with it.
`;
export const CERTIFICATION_IS_ACTIVE_TOOLTIP = `
  This setting manages which certifications appear in the certifications list view. If set to inactive, it will not appear in the list, unless the filter is adjusted to include inactive.
  Setting a certification to inactive retains the certification, but customers can no longer be assigned this certification; however, customers which already have this certification will continue having this certification.
`;
export const LOCATION_IS_ACTIVE_TOOLTIP = `
  This setting manages which locations appear in the locations list. If set to inactive, it will not appear in the list, unless the filter is adjusted to include inactive.
  Setting a location to inactive retains the location and all it's related information, events, memberships, passes, etc. But it can not longer be accessed from the change location dialog.
`;
export const DOCUMENT_TEMPLATE_IS_ACTIVE_TOOLTIP = `
  When active, Document templates can be listed and are available for customers to sign, when inactive, document templates will be hidden.
`;
export const PASS_TYPE_IS_ACTIVE_TOOLTIP = `
  This setting manages which pass types appear in the pass types list. If set to inactive, it will not appear in the list, unless the filter is adjusted to include inactive.
  Setting a pass type to inactive retains the pass type, but it's no longer sellable; however, customers that already have this pass type will continue having it and can use it as a check-in method.
`;
export const MEMBERSHIP_TYPE_IS_ACTIVE_TOOLTIP = `
  This setting manages which membership types appear in the membership types list. If set to inactive, it will not appear in the list, unless the filter is adjusted to include inactive.
  Setting a membership type to inactive retains the membership type, but it's no longer sellable; however, customers who already have this membership type will continue having it and can use it as a check in method.
`;
export const GIFT_CARD_TYPE_IS_ACTIVE_TOOLTIP = `
  This setting manages which gift card types appear in the gift card types list. If set to inactive, it will not appear in the list, unless the filter is adjusted to include inactive.
  Setting a gift card type to inactive retains the gift card types, but it's no longer sellable; however, customers who already have this gift card type will continue having it and can use it as a purchase method.
`;
export const PRODUCT_IS_ACTIVE_TOOLTIP = `
  This setting manages which products appear in the products list. If set to inactive, it will not appear in the list, unless the filter is adjusted to include inactive.
  Setting a product to inactive retains the product, but it's no longer sellable. 
`;
export const VARIANT_TYPE_IS_ACTIVE_TOOLTIP = `
  This setting manages which variant types appear in the variant types list. If set to inactive, it will not appear in the list, unless the filter is adjusted to include inactive.
  Setting a variant type to inactive retains the variant type, but it can no longer be assigned to those products; however, products which already have this variant type will continue having it and are still sellable. 
`;
export const VARIANT_TYPE_OPTION_IS_ACTIVE_TOOLTIP = `
  This setting manages which variant options appear in the variant options list. If set to inactive, it will not appear in the list, unless the filter is adjusted to include inactive.
  Setting a variant option to inactive retains the variant option, but it can no longer be assigned to those products; however, products which already have this variant option will continue having it and are still sellable. 
`;
export const REVENUE_CATEGORY_IS_ACTIVE_TOOLTIP = `
  This setting manages which revenue categories appear in the revenue categories list. If set to inactive, it will not appear in the list, unless the filter is adjusted to include inactive.
  Setting a revenue category to inactive retains the revenue category, but you will no longer be able to select this revenue category. Please note: inactive revenue categories will continue to display in revenue category reports, QuickBooks mapping and the cash drawer. 
`;
export const CALENDAR_IS_ACTIVE_TOOLTIP = `
  When active, Calendars can be used for scheduling events or activities, when inactive, calendars will be hidden.
`;
export const PRICING_TIER_IS_ACTIVE_TOOLTIP = `
  This setting manages which pricing tiers appear in the pricing tier list. If set to inactive, it will not appear in the list, unless the filter is adjusted to include inactive.
  Setting a pricing tier to inactive retains the pricing tier, but it can no longer be assigned to events; however, events which already have this pricing tier will continue having it. 
`;
export const TICKET_TYPE_IS_ACTIVE_TOOLTIP = `
  This setting manages which ticket types appear in the ticket types list. If set to inactive, it will not appear in the list, unless the filter is adjusted to include inactive.
  Setting a ticket types to inactive retains the ticket type, but it can no longer be assigned to events; however, events which already have this ticket type will continue having it.
`;
export const CATEGORY_IS_ACTIVE_TOOLTIP = `
  This setting manages which categories appear in the categories list. If set to inactive, it will not appear in the list, unless the filter is adjusted to include inactive.
  Setting a category to inactive retains the category, but it can no longer be assigned to events; however, events which already have this category will continue having it.
`;
export const CATEGORY_IS_PUBLIC_TOOLTIP = `
  When selected, the public will be able to view this category, and all events associated with it from the customer portal; Please note: deactivating a category won't deactivate asociated events.
`;
export const RESUME_DATE_TOOLTIP = `
  By setting the resume date, this membership record will be added to the resume list under CRM. This list helps staff on a daily basis to identify which memberships need to be resumed and then resume them.
`;
export const MAX_CHECKINS_TOOLTIP = `
  This field sets the maximum amount of check-ins that can be done with this membership type per customer per billing period. If left blank, an unlimited amount of check-ins can be done per billing period.
`;
export const PRICE_UPDATE_MEMBERSHIP_TYPE_TOOLTIP = `
  If you change the price of a membership type, it will only effect memberships that are sold after the change. All memberships that were sold before the price change will still charge the associated customers with the original price. Please submit a data update request to update current members to the new price as well.
`;
export const MEMBERSHIP_PRE_SALE_TOOLTIP = `
  When checked on the portal checkout screen, the “Detailed Billing Info” is hidden for this membership type to prevent confusion about how this membership type will be billed.
  Also when checked, do not prorate is automatically checked as well.
`;
export const CUSTOM_TAXES_TOOLTIP = `
  If the tax field is left blank, the location tax setting will be used. Use zero tax if you do not want to use the location tax setting.
`;
export const DISABLED_TAXES_TOOLTIP = `
  Product tax settings are disabled due to product variants being enabled. Please update product variant settings.
`;
export const REDEEM_POINTS = `
  Redeem points for products or passes
`;
