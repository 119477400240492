import { createReducer } from 'service/utility/redux';

import { SET_PAYMENT_DEVICE } from '../actions';


const initState = {
  device: null,
};

const transactionsReducer = createReducer(initState, {
  [SET_PAYMENT_DEVICE](state, { payload: device }) {
    return {
      device,
    };
  },
});

export default transactionsReducer;
