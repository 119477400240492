import { getEnv } from 'service/env';


export const APPROACH_SAVINGS_QUOTE_EMAIL = 'payments@approach.app';

const APP_CONFIG = {
  localhost: {
    apiRoot: 'https://localhost:3000',
    docsRoot: 'https://q5iejd5d3c.execute-api.us-east-1.amazonaws.com/dev',
    firebase: {
      apiKey: 'AIzaSyD8XgnPxHMGf0KS2ZWjTo8Vxa61ysQmwHM',
      authDomain: 'approach-dev.firebaseapp.com',
      databaseURL: 'https://approach-dev.firebaseio.com',
      projectId: 'approach-dev',
      storageBucket: 'approach-dev.appspot.com',
      messagingSenderId: '476730786973',
    },
    emergePay: 'https://assets.emergepay-sandbox.chargeitpro.com/cip-hosted-fields.js',
    clearent: 'https://hpp-sb.clearent.net/js/clearent.js',
    fullSteam: 'https://hostedpayments-ext.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js',
    widgetsRoot: 'https://www.widgets.testing.tilefive.com',
    widgetsApiKey: 'eVeRMVQZeD4DDDU0tdf9p5PfJzzFRE7j1cb110kw',
    termsOfUse: 'https://approach.app/eula',
    privacyPolicy: 'https://approach.app/privacy',
    merchantTrack: 'https://merchanttrack-ext.fullsteampay.net/Account/SingleSignOn',
  },
  testing: {
    apiRoot: {
      'us-east-1': 'https://app.api.testing.tilefive.com',
      'ap-southeast-2': 'https://app.api.au-d.tilefive.com',
    },
    docsRoot: {
      'us-east-1': 'https://docs.api.testing.tilefive.com',
      'ap-southeast-2': 'https://docs.api.au-d.tilefive.com',
    },
    firebase: {
      apiKey: 'AIzaSyD8XgnPxHMGf0KS2ZWjTo8Vxa61ysQmwHM',
      authDomain: 'approach-dev.firebaseapp.com',
      databaseURL: 'https://approach-dev.firebaseio.com',
      projectId: 'approach-dev',
      storageBucket: 'approach-dev.appspot.com',
      messagingSenderId: '476730786973',
    },
    emergePay: 'https://assets.emergepay-sandbox.chargeitpro.com/cip-hosted-fields.js',
    clearent: 'https://hpp-sb.clearent.net/js/clearent.js',
    fullSteam: 'https://hostedpayments-ext.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js',
    widgetsRoot: 'https://www.widgets.testing.tilefive.com',
    widgetsApiKey: 'eVeRMVQZeD4DDDU0tdf9p5PfJzzFRE7j1cb110kw',
    termsOfUse: 'https://approach.app/eula',
    privacyPolicy: 'https://approach.app/privacy',
    merchantTrack: 'https://merchanttrack-ext.fullsteampay.net/Account/SingleSignOn',
  },
  staging: {
    apiRoot: {
      'us-east-1': 'https://app.api.staging.tilefive.com',
      'ap-southeast-2': 'https://app.api.au-s.tilefive.com',
    },
    docsRoot: {
      'us-east-1': 'https://docs.api.staging.tilefive.com',
      'ap-southeast-2': 'https://docs.api.au-s.tilefive.com',
    },
    firebase: {
      apiKey: 'AIzaSyBkVhB9zG7OgFZUjBZB5tJJ-LLTcxZYYsI',
      authDomain: 'approach-stage.firebaseapp.com',
      databaseURL: 'https://approach-stage.firebaseio.com',
      projectId: 'approach-stage',
      storageBucket: 'approach-stage.appspot.com',
      messagingSenderId: '257264323242',
    },
    emergePay: 'https://assets.emergepay-sandbox.chargeitpro.com/cip-hosted-fields.js',
    clearent: 'https://hpp-sb.clearent.net/js/clearent.js',
    fullSteam: 'https://hostedpayments-ext.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js',
    widgetsRoot: 'https://www.widgets.staging.tilefive.com',
    widgetsApiKey: 'fsHlriEoQY8oxluJLm3vp3nERawSTRQYapppB5mN',
    termsOfUse: 'https://approach.app/eula',
    privacyPolicy: 'https://approach.app/privacy',
    merchantTrack: 'https://merchanttrack-ext.fullsteampay.net/Account/SingleSignOn',
  },
  production: {
    apiRoot: {
      'us-east-1': 'https://app.api.prod.tilefive.com',
      'ap-southeast-2': 'https://app.api.au-p.tilefive.com',
    },
    docsRoot: {
      'us-east-1': 'https://docs.api.prod.tilefive.com',
      'ap-southeast-2': 'https://docs.api.au-p.tilefive.com',
    },
    firebase: {
      apiKey: 'AIzaSyAbHcdTCEcSekzXcCGtoC3rXOL_N37VyFI',
      authDomain: 'approach-production.firebaseapp.com',
      databaseURL: 'https://approach-production.firebaseio.com',
      projectId: 'approach-production',
      storageBucket: 'approach-production.appspot.com',
      messagingSenderId: '414043972075',
    },
    emergePay: 'https://assets.emergepay.chargeitpro.com/cip-hosted-fields.js',
    clearent: 'https://hpp.clearent.net/js/clearent.js',
    fullSteam: 'https://hostedpayments.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js',
    widgetsRoot: 'https://frontend.widgets.prod.tilefive.com',
    widgetsApiKey: 'OQ2z4Q3jSU1BW3y9dyfEW5FlEFu1ozIj7jE27qjy',
    termsOfUse: 'https://approach.app/eula',
    privacyPolicy: 'https://approach.app/privacy',
    merchantTrack: 'https://merchanttrack.fullsteampay.net/Account/SingleSignOn',
  },
};

const getAppConfig = () => {
  const env = getEnv();

  if (env === 'production') return APP_CONFIG.production;
  if (env === 'test' || env === 'staging') return APP_CONFIG.staging;
  if (env === 'development') return APP_CONFIG.testing;
  if (env === 'testing') return APP_CONFIG.testing;

  return APP_CONFIG.localhost;
};

const AppConfig = getAppConfig();

export default AppConfig;
