import { race, take, call, put } from 'redux-saga/effects';

import * as API from 'service/api';
import { pollAsyncFunction, getErrorMessage } from 'service/utility';

import {
  setPaymentStatus, resetPaymentStatus, NEW_REFUND, TERMINATE_REFUND, terminateRefund,
} from '../actions';


function *pollTransaction(transactionUUID) {
  const { promise: pollingPromise, cancel: pollingCancel } = pollAsyncFunction(
    async () => {
      console.log('Attempting to poll Transaction with UUId = ', transactionUUID);
      const result = await API.getTransactionById(transactionUUID);

      console.log('Transaction Polling Result: ', result);

      return result;
    },
    ({ data: { isComplete } }) => (isComplete),
    2000,
  );

  const pollingRes = yield race({
    result: pollingPromise,
    terminated: take(TERMINATE_REFUND),
  });

  if (pollingRes.terminated) {
    pollingCancel();
  }

  return pollingRes;
}

function *refundOrder(orderId, transactionBody) {
  try {
    const result = yield call(API.refundOrder, orderId, transactionBody);

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('Error refunding order: ', errorMessage);

    yield put(terminateRefund({
      error: errorMessage,
      errorMessage: 'Sorry, there was an error processing this order.',
    }));
  }

  return null;
}

function *newRefund(payload) {
  try {
    yield put(setPaymentStatus({
      message: 'Initiating Refund...',
      started: true,
    }));

    const {
      orderId, locationId, method, paymentCardId, parentTransactionId, deviceId, items, itemsToCancel,
    } = payload;

    const transactionBody = {
      locationId,
      notes: 'Optional reason for refund',
      items,
      method,
    };

    if (paymentCardId) {
      transactionBody.paymentCardId = paymentCardId;
    }

    if (deviceId) {
      transactionBody.deviceId = deviceId;
    }

    if (parentTransactionId) {
      transactionBody.parentTransactionId = parentTransactionId;
    }

    console.log('Refunding Order; POST Body: ', transactionBody);

    const { data: transaction } = yield call(refundOrder, orderId, transactionBody);

    console.log('Refund Order Response: ', transaction);

    yield put(setPaymentStatus({
      message: 'Refund Pending...',
      started: true,
    }));

    const pollingResponse = yield call(pollTransaction, transaction.uuid);

    console.log('Transaction Polling Final Response: ', pollingResponse);

    if (pollingResponse.result) {
      const { data: { isSuccessful, statusDescription } } = pollingResponse.result;

      if (itemsToCancel) {
        const cancelBody = {
          items: itemsToCancel,
        };

        console.log('Cancelling items; POST Body: ', cancelBody);

        yield call(API.cancelOrder, orderId, cancelBody);
      }

      // signal that polling is over and set result
      yield put(setPaymentStatus({
        message: isSuccessful ? 'Refund Successful!' : `Refund Failed.${statusDescription ? ` Reason: ${statusDescription}` : ''}`,
        started: true,
        finished: true,
        success: Boolean(isSuccessful),
      }));

      return {
        transactionResult: pollingResponse.result,
      };
    }
  } catch (error) {
    console.log('newRefund error: ', error);
  }

  return null;
}

function *refundSaga() {
  while (true) {
    const { payload, callback } = yield take(NEW_REFUND);

    const { result, terminated } = yield race({
      result: call(newRefund, payload),
      terminated: take(TERMINATE_REFUND),
    });

    if (callback) {
      if (result) {
        console.log('newRefund result: ', result);

        yield call(callback, result);
      } else if (terminated) {
        // terminated for some reason.
        console.log('newRefund was terminated');

        // set payment status UI.
        yield put(resetPaymentStatus());
        yield call(callback, terminated.payload);
      }
    }
  }
}


export default refundSaga;
