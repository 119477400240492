import { take, call } from 'redux-saga/effects';

import { FOCUS_ON_CUSTOMER_CART } from '../actions';
import { focusOnCustomerCart } from './cartSagas';


function *focusOnCustomerCartSaga() {
  while (true) {
    const { customerId } = yield take(FOCUS_ON_CUSTOMER_CART);

    if (!customerId) return null;

    yield call(focusOnCustomerCart, customerId);
  }
}


export default focusOnCustomerCartSaga;
