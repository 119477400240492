import { take, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';

import { UPDATE_CART_ITEM_QUANTITY, setCartLoading } from '../actions';
import { activeCartIdSelector } from '../selectors';
import { loadCart } from './cartSagas';


function *updateCartItemQuantitySaga() {
  while (true) {
    const { itemId, quantity } = yield take(UPDATE_CART_ITEM_QUANTITY);

    const cartId = yield select(activeCartIdSelector);
    if (!cartId) return null;

    console.log(`Updating quantity to ${quantity} for the item with UUID = ${itemId} in the cart`);
    const toast1 = toast('Updating quantity...');

    try {
      yield put(setCartLoading(true));
      yield call(API.updateItemQuantity, cartId, itemId, quantity);
      yield call(loadCart, cartId);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('updateCartItemQuantitySaga error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    } finally {
      toast.dismiss(toast1);
      yield put(setCartLoading(false));
    }
  }
}


export default updateCartItemQuantitySaga;
