import React from 'react';
import { PropTypes } from 'prop-types';
import { ListItemText } from '@material-ui/core';


const MembershipTypeListItemText = ({ data }) => (
  <ListItemText
    primary={data.name}
    secondary={data.description}
  />
);

MembershipTypeListItemText.propTypes = {
  data: PropTypes.object.isRequired,
};


export default MembershipTypeListItemText;
