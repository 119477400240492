import clm from 'country-locale-map';
import { DateTime, Info as LuxonInfo } from 'luxon';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { COUNTRY_MAP } from 'constants/countries';
import { COUNTRY_TO_CURRENCY_MAP } from 'constants/currencies';
import { DEFAULT_COUNTRY_CODE, DEFAULT_CURRENCY_CODE, DEFAULT_LOCALE } from 'constants/i18n';
import { TIMEZONE_MAP } from 'constants/timezones';


let i18nInstance = null;

export const initI18n = (config) => {
  if (i18nInstance) return;
  i18n.use(initReactI18next).init(config);
  i18nInstance = i18n;
};

export const getI18nInstance = () => i18nInstance;

export const getCountryName = (countryCode) => (
  COUNTRY_MAP[countryCode] || ''
);

export const getCurrencyCodeByCountry = (countryCode) => (
  COUNTRY_TO_CURRENCY_MAP[countryCode] || null
);


export const getLocationCountry = (location) => (
  (location?.countryCode ?? DEFAULT_COUNTRY_CODE).toUpperCase()
);

export const getLocationCountryName = (location) => (
  getCountryName(getLocationCountry(location))
);

export const getLocationLocale = (location) => (
  clm.getLocaleByAlpha2(getLocationCountry(location)).replace(/_/, '-')
);

export const locationUsesPriceBeforeTax = (location) => (
  getLocationCountry(location) === 'US' || getLocationCountry(location) === 'CA'
);

export const locationRequiresEmergencyContact = (location) => (
  getLocationCountry(location) === 'US' || getLocationCountry(location) === 'CA'
);

export const locationSavesCardAfterPurchase = (location) => (
  getLocationCountry(location) === 'US' || getLocationCountry(location) === 'CA'
);

export const getLocationRequiredTaxIdName = (location) => {
  if (getLocationCountry(location) === 'AU') {
    return 'ABN';
  }

  if (getLocationCountry(location) === 'US') {
    return null;
  }

  return 'VAT ID';
};

export const locationRequiresVatId = (location) => (
  getLocationCountry(location) !== 'US'
);

export const getLocationCurrencyCode = (location) => (
  getCurrencyCodeByCountry(getLocationCountry(location)) || DEFAULT_CURRENCY_CODE
);

export const getLocationCurrencySymbol = (location) => {
  const locale = getLocationLocale(location) || DEFAULT_LOCALE;
  const currencyCode = getLocationCurrencyCode(location);

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
  }).format('0').replace(/0/, '');
};

export const getLocationCurrencyDecimalPlaces = (location) => (
  getLocationCountry(location) === 'US' || getLocationCountry(location) === 'CA'
    ? 2
    // : 3
    : 2
);

export const getLocationTimezone = (location) => (
  (location?.timeZone) || DateTime.local().zoneName
);

export const getTimezoneName = (timeZone) => TIMEZONE_MAP[timeZone] || '';

export const getLocationTimezoneName = (location) => (
  getTimezoneName(getLocationTimezone(location))
);

export const getLocationFirstDOW = (location) => {
  const countryCode = getLocationCountry(location);

  if (countryCode === 'US' || countryCode === 'CA') return 6;

  return 0;
};

export const getLocationWeekdaysDisplayOrder = (location) => {
  const firstDOW = getLocationFirstDOW(location);
  const dow = [0, 1, 2, 3, 4, 5, 6];
  const idx = dow.indexOf(firstDOW);

  return dow.slice(idx).concat(dow.slice(0, idx));
};

export const getCalendarMonthsLong = () => LuxonInfo.months('long');
export const getCalendarWeekdaysLong = () => LuxonInfo.weekdays('long');
export const getCalendarWeekdaysShort = () => LuxonInfo.weekdays('short');
export const getCalendarWeekdaysNarrow = () => LuxonInfo.weekdays('narrow');
