import React from 'react';
import { PropTypes } from 'prop-types';
import { TableHead, TableCell, TableRow, TableSortLabel, Checkbox } from '@material-ui/core';


const DataTableHead = ({
  columnConfig, tableHasExpansionPanel, order, orderBy, onSortBy,
  selectable, selectedItemCount, pageSize, onSelectAll,
}) => (
  <TableHead classes={{ root: 'data-table-head' }}>
    <TableRow>
      {selectable && (
        <TableCell classes={{ root: 'data-table-cell min-width' }}>
          <Checkbox
            checked={selectedItemCount > 0}
            indeterminate={selectedItemCount > 0 && selectedItemCount < pageSize}
            onChange={onSelectAll}
          />
        </TableCell>
      )}
      {columnConfig.map((col, idx) => (
        <TableCell
          key={idx}
          align={col.align || 'inherit'}
          classes={{ root: 'data-table-cell' }}
        >
          {col.notSortable ? (
            col.label || ''
          ) : (
            <TableSortLabel
              active={orderBy ? orderBy === col.sortString : false}
              direction={order === 'ASC' ? 'asc' : 'desc'}
              onClick={onSortBy ? () => onSortBy(col.sortString) : null}
            >
              {col.label || ''}
            </TableSortLabel>
          )}
        </TableCell>
      ))}
      {tableHasExpansionPanel && (
        <TableCell classes={{ root: 'data-table-cell' }} />
      )}
    </TableRow>
  </TableHead>
);

DataTableHead.propTypes = {
  columnConfig: PropTypes.array.isRequired,
  onSelectAll: PropTypes.func,
  onSortBy: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  pageSize: PropTypes.number,
  selectable: PropTypes.bool,
  selectedItemCount: PropTypes.number,
  tableHasExpansionPanel: PropTypes.bool,
};


export default DataTableHead;
