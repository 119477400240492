import React from 'react';
import { SvgIcon } from '@material-ui/core';


const Barcode = () => (
  <SvgIcon viewBox="0 0 512 512">
    <g>
      <rect y="94.103" fill="#000000" width="48.569" height="323.795" />
      <rect x="72.854" y="94.103" fill="#000000" width="24.284" height="323.795" />
      <polygon fill="#000000" points="121.423,94.103 121.423,417.897 145.708,417.897 169.992,417.897 169.992,94.103 145.708,94.103   " />
      <polygon fill="#000000" points="194.277,94.103 194.277,417.897 218.561,417.897 242.846,417.897 242.846,94.103 218.561,94.103   " />
      <rect x="267.13" y="94.103" fill="#000000" width="24.285" height="323.795" />
      <polygon fill="#000000" points="339.984,94.103 315.7,94.103 315.7,417.897 339.984,417.897 364.269,417.897 388.554,417.897 388.554,94.103 364.269,94.103" />
      <polygon fill="#000000" points="412.838,94.103 412.838,417.897 437.122,417.897 462.083,417.897 462.083,94.103 437.122,94.103   " />
      <rect x="487.044" y="94.103" fill="#000000" width="24.956" height="323.795" />
    </g>
  </SvgIcon>
);


export default Barcode;
