import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import * as API from 'service/api';
import { formatName } from 'service/utility/stringFormatters';

import GenericSearch from '../GenericSearch';
import CustomerSearchItem from './CustomerSearchItem';


const getOptionValue = (option) => option.id;

const getOptionLabel = (option) => formatName(option);


class CustomerSearch extends React.Component {
  getOptions = (searchString) => {
    const { filter } = this.props;

    return API.searchCustomers(`q=${encodeURIComponent(searchString)}`).then(
      ({ data: { data } }) => (filter ? filter(data) : data),
    );
  };

  render() {
    const { className, ...rest } = this.props;

    return (
      <GenericSearch
        suggestionItem={CustomerSearchItem}
        getOptions={this.getOptions}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        className={classnames('customer-search', { [className]: Boolean(className) })}
        {...rest}
      />
    );
  }
}

CustomerSearch.propTypes = {
  autoFocus: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  filter: PropTypes.func,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.object,
};

CustomerSearch.defaultProps = {
  autoFocus: false,
  cacheOptions: true,
  className: '',
  disabled: false,
  error: '',
  fullWidth: true,
  required: false,
};


export default CustomerSearch;
