import React from 'react';
import { PropTypes } from 'prop-types';
import { MenuItem, ListItemText } from '@material-ui/core';


const CalendarSearchItem = ({ children, isFocused, innerProps }) => (
  <MenuItem
    selected={isFocused}
    onClick={innerProps.onClick}
    className="calendar-search-item"
  >
    <ListItemText primary={children.toString()} />
  </MenuItem>
);

CalendarSearchItem.propTypes = {
  children: PropTypes.any,
  innerProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool,
};


export default CalendarSearchItem;
