import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { Switch, NavLink, Redirect, Route } from 'react-router-dom';
import {
  List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction,
  Divider, IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { SuspenseFallback } from '../../statusIndicators';
import NestedNavItem from './NestedNavItem';


class NestedVertRouter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };
  }

  // eslint-disable-next-line react/display-name
  renderLink = React.forwardRef((itemProps, ref) => (
    <NavLink
      exact
      activeClassName="active-menu-item"
      {...itemProps}
      innerRef={ref}
    />
  ));

  handleMenuToggle = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  render() {
    const { navConfig, collapsible, routeComponent, ...propsToPass } = this.props;
    const { collapsed } = this.state;

    const RouteComponent = routeComponent || Route;
    const { vertRouterPath, defaultPath } = propsToPass;
    const sideConfig = navConfig.filter((e) => !e.hide).map((e) => {
      if (e.passthroughProps && e.subroutes) {
        return e.subroutes.filter((ee) => !ee.hide).map((ee) => ({
          ...ee,
          relPath: `${e.relPath}${ee.relPath}`,
        }));
      }

      return [e];
    }).flat();
    const routeConfig = navConfig.filter(
      (e) => !e.subheader && !e.divider && (e.subroutes || (e.component && e.hasOwnProperty('relPath')))
    ).map((e) => {
      if (e.subroutes) {
        return e.subroutes.map((ee) => ({
          ...ee,
          relPath: `${e.relPath}${ee.relPath}`,
        }));
      }

      return [e];
    }).flat();

    return (
      <div className="nested-vert-router">
        <List
          component="nav"
          className={classnames('vert-menu', { collapsed })}
          subheader={
            collapsible ? (
              <IconButton
                className="vert-menu-hamburger"
                onClick={this.handleMenuToggle}
              >
                <MenuIcon />
              </IconButton>
            ) : null
          }
        >
          {sideConfig.map((item, idx) => (
            item.subheader ? (
              <ListItem
                key={`VM_${idx}`}
                component="div"
                classes={{ root: 'nested-vert-menu-item nested-vert-subheader' }}
              >
                {item.icon && (
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                )}
                <ListItemText
                  inset={!item.icon}
                  primary={item.label}
                  classes={{ root: classnames('item-text', { 'with-icon': Boolean(item.icon) }), primary: 'primary' }}
                />
              </ListItem>
            ) : item.divider ? (
              <Divider
                key={`VM_${idx}`}
                className="my-3"
              />
            ) : item.subroutes ? (
              <NestedNavItem
                key={`VM_${idx}`}
                item={item}
                rootPath={item.absPath || `${vertRouterPath}${item.relPath}`}
              />
            ) : (
              <ListItem
                key={`VM_${idx}`}
                button
                classes={{ root: 'nested-vert-menu-item' }}
                component={this.renderLink}
                to={item.absPath || `${vertRouterPath}${item.relPath}`}
              >
                {item.icon && (
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                )}
                <ListItemText
                  inset={!item.icon}
                  primary={item.label}
                  classes={{
                    root: classnames('item-text', { 'with-icon': Boolean(item.icon) }),
                    primary: 'primary',
                  }}
                />
                {item.secondaryIcon && (
                  <ListItemSecondaryAction classes={{ root: 'color-danger' }}>
                    <item.secondaryIcon />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            )
          ))}
        </List>
        <div className="router-content">
          <React.Suspense fallback={<SuspenseFallback />}>
            <Switch>
              {defaultPath && (
                <RouteComponent
                  exact
                  path={vertRouterPath}
                  render={() => <Redirect to={`${vertRouterPath}${defaultPath}`} />}
                />
              )}
              {routeConfig.map((route, idx) => {
                const RouteComp = route.component;
                const routePropsToPass = route.propsToPass ?? {};

                return (
                  <RouteComponent
                    exact={route.exact}
                    key={`NRI_${idx}`}
                    path={`${vertRouterPath}${route.relPath}`}
                    permission={route.permission}
                    render={(routeProps) => (
                      <RouteComp
                        {...routeProps}
                        {...propsToPass}
                        {...routePropsToPass}
                      />
                    )}
                  />
                );
              })}
            </Switch>
          </React.Suspense>
        </div>
      </div>
    );
  }
}

NestedVertRouter.propTypes = {
  collapsible: PropTypes.bool,
  defaultPath: PropTypes.string,
  navConfig: PropTypes.array.isRequired,
  routeComponent: PropTypes.elementType,
  vertRouterPath: PropTypes.string.isRequired,
};


export default NestedVertRouter;
