import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import CancelIcon from '@material-ui/icons/Cancel';


const FailureBadge = (props) => (
  <div className={classnames('failure-badge', { 'badge-sm': props.size === 'sm' })}>
    <CancelIcon fontSize="inherit" />
  </div>
);

FailureBadge.propTypes = {
  size: PropTypes.string,
};

FailureBadge.defaultProps = {
  size: 'md',
};


export default FailureBadge;
