import { createReducer } from 'service/utility/redux';

import { SET_PAYMENT_STATUS, RESET_PAYMENT_STATUS } from '../actions';


const initState = {
  message: null,
  started: false,
  success: false,
  finished: false,
  actions: {},
};

const paymentStatusReducer = createReducer(initState, {
  [SET_PAYMENT_STATUS](state, { payload }) {
    return {
      ...state,
      ...payload,
    };
  },
  [RESET_PAYMENT_STATUS]() {
    return initState;
  },
});

export default paymentStatusReducer;
