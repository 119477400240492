import React from 'react';
import { PropTypes } from 'prop-types';
import Check from '@material-ui/icons/Check';


class Color extends React.PureComponent {
  handleClick = () => {
    this.props.onSelect(this.props.value);
  };

  render() {
    const { value, isSelected } = this.props;

    return (
      <div
        className="calendar-color-picker-item"
        style={{ backgroundColor: value }}
        onClick={this.handleClick}
      >
        {isSelected && (
          <span className="calendar-color-picker-selected">
            <Check
              color="inherit"
              fontSize="inherit"
            />
          </span>
        )}
      </div>
    );
  }
}

Color.propTypes = {
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
};


export default Color;
