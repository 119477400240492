import { put, take, call } from 'redux-saga/effects';

import firebaseService from 'service/firebase';

import { LOG_OUT_USER, authLoggedOutSuccess } from '../actions';


function *logOutSaga() {
  while (true) {
    yield take(LOG_OUT_USER);

    try {
      yield call(firebaseService.logOut);
      yield put(authLoggedOutSuccess());
    } catch (error) {
      console.log('firebaseService.logOut error: ', error);
    }
  }
}


export default logOutSaga;
