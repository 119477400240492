import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import * as stateSelectors from 'app/redux/state/selectors';
import { injectRegion as injectRegionAxios } from 'service/axios';
import { injectRegion as injectRegionFirebase, getFirebaseApp } from 'service/firebase';


class Services extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      axiosConfigFinished: false,
      firebaseConfigFinished: false,
    };
  }

  componentDidMount() {
    this.configAxios();
    this.configFirebase();
  }

  configAxios = () => {
    injectRegionAxios(this.props.region);
    this.setState({ axiosConfigFinished: true });
  };

  configFirebase = () => {
    injectRegionFirebase(this.props.region);
    getFirebaseApp();
    this.setState({ firebaseConfigFinished: true });
  };

  render() {
    const { children } = this.props;
    const { axiosConfigFinished, firebaseConfigFinished } = this.state;

    if (!axiosConfigFinished || !firebaseConfigFinished) return null;

    return children;
  }
}

Services.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.array,
  ]),
  region: PropTypes.string.isRequired,
};


const mapStateToProps = (state) => ({
  region: stateSelectors.region(state),
});

export default connect(mapStateToProps, null)(Services);
