import React from 'react';
import { PropTypes } from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Paper, Card, CardContent, CardActions, Button } from '@material-ui/core';

import * as API from 'service/api';
import { getLocationTimezone } from 'service/i18n';
import { getRoutePath } from 'service/navigation';
import { routeWithParams } from 'service/utility';
import { getOrderUrl, getOrderSourceLabel } from 'service/utility/order';
import { formatPriceWithoutTax } from 'service/utility/pricing';
import { formatName } from 'service/utility/stringFormatters';
import * as timeUtils from 'service/utility/timeUtils';

import {
  DateRangeFilter, LocationFilter, OrderSourceFilter, OrderStatusFilter,
} from '../../listFilters';
import { Spinner } from '../../statusIndicators';
import { OrderItemsTable } from '../../tables';
import DynamicDataList from '../DynamicDataList';


const OrderList = (props) => {
  const history = useHistory();
  const timeZone = getLocationTimezone(props.currentLocation);
  //const endDate = timeUtils.iso8601Today(timeZone);
  //const startDate = timeUtils.addMonthsToIso8601Date(endDate, -1);

  const [orderMap, setOrderMap] = React.useState({});

  const fieldsConfig = [
    {
      label: 'Date',
      sortString: 'createdAt',
      render: (order) => timeUtils.formatShortDate(order.createdAt, timeZone),
      notSearchable: true,
    },
  ];

  if (!props.customerId) {
    fieldsConfig.push(
      {
        label: 'Name',
        notSortable: true,
        render: (order) => (
          order.customerId ? (
            <Link to={routeWithParams(getRoutePath('CUSTOMER_DETAIL'), { id: order.customerId })}>
              {formatName(order.customer)}
            </Link>
          ) : 'Guest'
        ),
      }
    );
  }

  fieldsConfig.push(
    {
      label: 'Order ID',
      sortString: 'id',
      render: (order) => (
        <Link to={getOrderUrl(order)}>
          {order.id}
        </Link>
      ),
    }
  );

  if (!props.customerId) {
    fieldsConfig.push(
      {
        label: 'Staff',
        sortString: 'staffId',
        render: (order) => order.staffId && (
          <Link to={routeWithParams(getRoutePath('STAFF_DETAIL'), { id: order.staffId })}>
            {formatName(order.staff)}
          </Link>
        ),
      }
    );
  }

  fieldsConfig.push(
    {
      label: 'Source',
      sortString: 'type',
      render: getOrderSourceLabel,
      notSearchable: true,
    },
    {
      label: 'Status',
      sortString: 'status',
      notSearchable: true,
    },
    {
      label: 'Total Amount',
      sortString: 'total',
      align: 'right',
      render: (order) => (
        formatPriceWithoutTax(order.total, props.currentLocation, { currency: order.currency })
      ),
    }
  );

  const handleRowClick = (order) => {
    if (!order.orderItem && !orderMap.hasOwnProperty(order.id)) {
      setOrderMap((prevOrderMap) => ({
        ...prevOrderMap,
        [order.id]: true,
      }));

      API.fetchOrder(order.id).then(({ data: orderWithItems }) => {
        setOrderMap((prevOrderMap) => ({
          ...prevOrderMap,
          [order.id]: orderWithItems,
        }));
      });
    }
  };

  const expansionPanel = (order) => {
    const orderWithItems = order.orderItem ? order : orderMap[order.id];

    if (typeof orderWithItems === 'boolean') {
      return (
        <Paper
          elevation={0}
          square
        >
          <Spinner />
        </Paper>
      );
    }

    if (typeof orderWithItems !== 'object') return null;

    return (
      <Card
        elevation={0}
        square
        classes={{ root: 'order-expansion-panel' }}
      >
        <CardContent classes={{ root: 'card-content' }}>
          <OrderItemsTable
            order={orderWithItems}
            location={props.currentLocation}
          />
        </CardContent>
        <CardActions classes={{ root: 'card-actions' }}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => history.push(getOrderUrl(order))}
          >
            {'View Order'}
          </Button>
        </CardActions>
      </Card>
    );
  };

  return (
    <DynamicDataList
      currentLocation={props.currentLocation}
      className="order-list"
      getAPI={props.customerId ? API.fetchOrders : API.fetchAllOrders}
      getParams={props.customerId ? [props.customerId] : []}
      pageSize={props.pageSize}
      orderBy="createdAt"
      order="DESC"
      fieldsConfig={fieldsConfig}
      expansionPanel={expansionPanel}
      size="small"
      onItemClick={handleRowClick}
      filters={[
        <DateRangeFilter
          key={0}
          timeZone={props.timeZone}
          useDTs
        />,
        <OrderStatusFilter key={1} />,
        <OrderSourceFilter key={2} />,
        <LocationFilter
          key={3}
          currentLocation={props.currentLocation}
        />,
      ]}
    />
  );
};

OrderList.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  customerId: PropTypes.number,
  pageSize: PropTypes.number,
  timeZone: PropTypes.string.isRequired,
};

OrderList.defaultProps = {
  pageSize: 50,
};


export default OrderList;
