import React from 'react';
import { PropTypes } from 'prop-types';
import { matchPath } from 'react-router';
import { throttle } from 'lodash';
import { Drawer } from '@material-ui/core';

import { getFullScreenRoutes } from 'service/navigation';
import styles from 'styles/export.module.scss';


const screenHasDesktopWidth = () => window.matchMedia(`(min-width: ${styles.screenXlMin})`).matches;


class LeftDrawer extends React.Component {
  componentDidMount() {
    this.updateLeftDrawerStatus();
    this._resizeListener = window.addEventListener('resize', this.handleResize());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.updateLeftDrawerStatus();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeListener);
  }

  handleResize = () => throttle(this.updateLeftDrawerStatus, 200);

  updateLeftDrawerStatus = () => {
    const { open, permanent, setOpen, setPermanent } = this.props;
    const isPermanent = screenHasDesktopWidth();
    const fullScreenRoutes = getFullScreenRoutes();

    if (fullScreenRoutes.some(
      (path) => matchPath(this.props.location.pathname, { path, exact: true }))
    ) {
      setPermanent(false);
      setOpen(false);
    } else if (isPermanent) {
      if (!permanent) {
        setPermanent(true);
      }
      if (!open) {
        setOpen(true);
      }
    } else if (permanent) {
      setPermanent(false);
      setOpen(false);
    }
  };

  handleDrawerClose = () => {
    this.props.setOpen(false);
  };

  render() {
    const { open, permanent, children } = this.props;

    return (
      <Drawer
        open={open}
        variant={permanent ? 'permanent' : 'temporary'}
        classes={{ paper: 'left-drawer-paper' }}
        onClose={this.handleDrawerClose}
      >
        {children}
      </Drawer>
    );
  }
}

LeftDrawer.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object.isRequired,
  open: PropTypes.bool,
  permanent: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  setPermanent: PropTypes.func.isRequired,
};


export default LeftDrawer;
