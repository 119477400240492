import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { TextField } from '@material-ui/core';

import { isRequired, isInvalid } from 'service/utility/errorMessages';
import * as timeUtils from 'service/utility/timeUtils';

import BirthdateFormatCustom from './BirthdateFormatCustom';


class BirthdateField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount() {
    const { value: stringValue, onChange } = this.props;
    const value = !stringValue || timeUtils.isEmptyIso8601Date(stringValue) ? null : stringValue;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.required !== this.props.required || prevProps.value !== this.props.value) {
      const { value: stringValue, onChange } = this.props;
      const value = !stringValue || timeUtils.isEmptyIso8601Date(stringValue) ? null : stringValue;
      const error = this.validateSelf(value);

      if (onChange) {
        onChange({ error });
      }
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }

  validateSelf = (value) => {
    const { required, label } = this.props;

    if (!value && required) return isRequired(label);
    if (Boolean(value) && value.length === 10 && !timeUtils.isValidIso8601Date(value)) {
      return isInvalid(label);
    }

    return null;
  };

  handleLeaveFocus = () => {
    const { value: stringValue, onChange } = this.props;
    const value = stringValue || null;
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ error });
    }
  };

  handleChangeSelf = (textValue) => {
    const { onChange } = this.props;
    const value = textValue || null;

    if (onChange) {
      onChange({ value });
    }
  };

  render() {
    const { autoFocus, className, disabled, error, narrow, onChange, ...rest } = this.props;
    const { blurred } = this.state;

    const showError = blurred && Boolean(error);
    const birthdateFieldClassName = classnames('birthdate-field date-field', { narrow, [className]: Boolean(className) });

    return (
      <TextField
        error={showError}
        helperText={error}
        onChange={this.handleChangeSelf}
        onBlur={this.handleLeaveFocus}
        className={birthdateFieldClassName}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputComponent: BirthdateFormatCustom,
        }}
        {...rest}
      />
    );
  }
}

BirthdateField.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  margin: PropTypes.string,
  narrow: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
};

BirthdateField.defaultProps = {
  autoFocus: false,
  error: '',
  fullWidth: false,
  margin: 'dense',
  narrow: false,
  required: false,
  value: '',
  variant: 'outlined',
};


export default BirthdateField;
