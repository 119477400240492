import React from 'react';
import { PropTypes } from 'prop-types';
import { MenuItem, ListItemText } from '@material-ui/core';

import { stripHtmlTags } from 'service/utility/stringFormatters';


const MembershipTypeSearchItem = ({ children, isFocused, data, innerProps }) => (
  <MenuItem
    selected={isFocused}
    onClick={innerProps.onClick}
    className="membership-type-search-item"
  >
    <ListItemText
      primary={children.toString()}
      secondary={stripHtmlTags(data.description)}
    />
  </MenuItem>
);

MembershipTypeSearchItem.propTypes = {
  children: PropTypes.any.isRequired,
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool.isRequired,
};


export default MembershipTypeSearchItem;
