import React from 'react';
import { Divider } from '@material-ui/core';


const NavDivider = () => (
  <Divider className="nav-divider" />
);


export default NavDivider;
