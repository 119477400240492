import React from 'react';
import { PropTypes } from 'prop-types';
import { Table } from '@material-ui/core';

import { DataTableHead, DataTableBody } from './components';


const DataTable = ({
  fieldsConfig, onItemClick, expansionPanel, rowIsExpandable, accordion, onItemToggle,
  size, data, order, orderBy, onSortBy, selectable, selectedItemIds, pageSize,
  onSelectAll, rowIdField,
}) => {
  const showHead = orderBy !== null || fieldsConfig.some((e) => e.label);

  return (
    <Table
      size={size}
      classes={{ root: 'data-table table-dense' }}
    >
      {showHead && (
        <DataTableHead
          columnConfig={fieldsConfig}
          order={order}
          orderBy={orderBy}
          onSortBy={onSortBy}
          tableHasExpansionPanel={Boolean(expansionPanel)}
          selectable={selectable}
          selectedItemCount={selectedItemIds.length}
          pageSize={pageSize}
          onSelectAll={onSelectAll}
        />
      )}
      <DataTableBody
        data={data}
        expansionPanel={expansionPanel}
        rowIsExpandable={rowIsExpandable}
        accordion={accordion}
        onItemClick={onItemClick}
        fieldsConfig={fieldsConfig}
        selectable={selectable}
        selectedItemIds={selectedItemIds}
        onItemToggle={onItemToggle}
        rowIdField={rowIdField}
      />
    </Table>
  );
};

DataTable.propTypes = {
  accordion: PropTypes.bool,
  data: PropTypes.array,
  expansionPanel: PropTypes.func,
  fieldsConfig: PropTypes.array,
  onItemClick: PropTypes.func,
  onItemToggle: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSortBy: PropTypes.func,
  order: PropTypes.string, // 'ASC' or 'DESC'
  orderBy: PropTypes.string,
  pageSize: PropTypes.number,
  rowIdField: PropTypes.string,
  rowIsExpandable: PropTypes.func,
  selectable: PropTypes.bool,
  selectedItemIds: PropTypes.array,
  size: PropTypes.string,
};

DataTable.defaultProps = {
  accordion: false,
  data: [],
  fieldsConfig: [],
  onItemToggle: () => {},
  order: 'ASC',
  orderBy: null,
  rowIdField: 'id',
  rowIsExpandable: () => true,
  selectable: false,
  selectedItemIds: [],
  size: 'medium',
};


export default DataTable;
