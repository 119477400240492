export const isRequired = (field) => `${(field || '').trim() || 'This'} is a required field`;
export const noNegative = (field) => `${field || 'Value'} cannot be negative`;
export const noZero = (field) => `${field || 'Value'} cannot be zero`;
export const isInvalid = (field) => `Invalid value${field ? ` for ${field}` : ''}`;
export const invalidEmail = () => 'Invalid email';
export const invalidPhone = () => 'Invalid phone number';
export const invalidUrl = () => 'Invalid url';
export const noMoreThan = (field, than) => `${field || 'Value'} cannot be more than ${than}`;
export const noLessThan = (field, than) => `${field || 'Value'} cannot be less than ${than}`;
export const notAfter = (field, than) => `${field || 'Value'} cannot be after ${than}`;
export const atLeastOne = (field) => `You must select at least one ${field}`;
export const noMoreThan100Percent = (field) => noMoreThan(field, '100%');
