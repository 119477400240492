import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@material-ui/core';

import { getRoutePath } from 'service/navigation';
import { routeWithParams } from 'service/utility';
import { formatPriceWithoutTax } from 'service/utility/pricing';
import * as stringUtils from 'service/utility/stringFormatters';
import * as timeUtils from 'service/utility/timeUtils';


const InvoiceTable = ({ currentLocation, items, unitPriceAndQuantity }) => (
  items.length > 0 ? (
    <Table classes={{ root: 'table-dense' }}>
      <TableHead>
        <TableRow>
          <TableCell>
            {'Order'}
          </TableCell>
          <TableCell>
            {'Name'}
          </TableCell>
          <TableCell>
            {'Description'}
          </TableCell>
          <TableCell>
            {'Date Issued'}
          </TableCell>
          {unitPriceAndQuantity && (
            <TableCell>
              {'# of guests'}
            </TableCell>
          )}
          <TableCell>
            {'Status'}
          </TableCell>
          <TableCell align="right">
            {'Amount'}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id}>
            <TableCell>
              <Link
                to={routeWithParams(
                  getRoutePath('CUSTOMER_ORDER'),
                  { id: item.customer.id, orderId: item.order.id },
                )}
              >
                <Typography
                  variant="body2"
                  component="p"
                  color="inherit"
                >
                  {`#${item.order.id}`}
                </Typography>
              </Link>
            </TableCell>
            <TableCell>
              {item.customer ? stringUtils.formatName(item.customer) : 'Unknown'}
            </TableCell>
            <TableCell>
              {item.order.description || ''}
            </TableCell>
            <TableCell>
              {timeUtils.formatShortDate(timeUtils.iso8601Date(item.order.orderDate))}
            </TableCell>
            {unitPriceAndQuantity && (
              <TableCell>
                {item.quantity}
              </TableCell>
            )}
            <TableCell>
              {stringUtils.capital(item.order.status)}
            </TableCell>
            <TableCell align="right">
              {formatPriceWithoutTax(item.order.total, currentLocation)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <Typography classes={{ root: 'font-italic w-100 text-center' }}>
      {'No invoices'}
    </Typography>
  )
);

InvoiceTable.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  items: PropTypes.array,
  unitPriceAndQuantity: PropTypes.bool,
};


export default InvoiceTable;
