import React from 'react';
import { PropTypes } from 'prop-types';

import { PARTICIPATION_STATUS } from 'constants/calendar';
import { somePropsChanged } from 'service/utility';
import { capital } from 'service/utility/stringFormatters';

import { PicklistField } from '../../formFields';


const PARTICIPATION_STATUS_PICKLIST_VALUES = [
  {
    label: 'All',
    value: 0,
  },
  ...PARTICIPATION_STATUS.map((status) => ({
    label: capital(status),
    value: status,
  })),
];


class ParticipationStatusFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      participationStatus: (
        this.props.hasOwnProperty('participation') && this.props.participation !== 'null'
          ? this.props.participation
          : 0
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['participationStatus'])) {
      this.handleValueChange();
    }
  }

  handleParticipationChange = (update) => {
    if (!update.hasOwnProperty('value')) return;

    this.setState({
      participationStatus: update.value,
    });
  };

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  getOwnFilterMap = () => ({
    participation: this.state.participationStatus === 0 ? null : this.state.participationStatus,
  });

  render() {
    const { participationStatus } = this.state;

    return (
      <PicklistField
        fullWidth
        label="Participation"
        value={participationStatus}
        onChange={this.handleParticipationChange}
        className="participation-status-filter"
        picklistValues={PARTICIPATION_STATUS_PICKLIST_VALUES}
      />
    );
  }
}

ParticipationStatusFilter.propTypes = {
  onValueChange: PropTypes.func,
  participation: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

ParticipationStatusFilter.ownName = ' ParticipationStatusFilter';
ParticipationStatusFilter.ownFilters = ['participation'];


export default ParticipationStatusFilter;
