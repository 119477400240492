import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography, Paper } from '@material-ui/core';
import CardGiftcard from '@material-ui/icons/CardGiftcard';

import { RemoveFab } from 'common/buttons';
import { formatPriceWithoutTax } from 'service/utility/pricing';
import { formatName } from 'service/utility/stringFormatters';


const CartGiftCard = (props) => {
  const { item, currentLocation, disabled } = props;
  const { UUID, amount, assignedCustomer } = item;

  const handleItemRemove = () => props.onItemRemove(UUID);

  return (
    <Paper
      className="cart-item"
      elevation={0}
    >
      <div className="cart-item-col image">
        <div className="mp-icon-wrapper">
          <CardGiftcard fontSize="large" />
        </div>
      </div>
      <div className="cart-item-col details">
        <span className="item-name">
          {'Gift Card'}
        </span>
        <span className="item-additional-details">
          {`For ${formatName(assignedCustomer)}`}
        </span>
      </div>
      <div className="cart-item-col total">
        <Typography variant="body1">
          {formatPriceWithoutTax(amount, currentLocation)}
        </Typography>
      </div>
      {!disabled && (
        <RemoveFab onClick={() => handleItemRemove()} />
      )}
    </Paper>
  );
};

CartGiftCard.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onItemRemove: PropTypes.func.isRequired,
};


export default CartGiftCard;
