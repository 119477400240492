import React from 'react';
import { PropTypes } from 'prop-types';

import SwitchWithRoutes from '../SwitchWithRoutes';


const SwitchWithRoutesAndLink2 = ({ propsForAll = {}, match, ...rest }) => (
  <SwitchWithRoutes
    propsForAll={{
      ...propsForAll,
      link2: match.url,
    }}
    {...rest}
  />
);

SwitchWithRoutesAndLink2.propTypes = {
  match: PropTypes.object.isRequired,
  propsForAll: PropTypes.object,
};


export default SwitchWithRoutesAndLink2;
