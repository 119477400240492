import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import TextField from './TextField';


const PostalCodeField = ({ className, ...rest }) => (
  <TextField
    {...rest}
    className={classnames('postalcode-field', { [className]: Boolean(className) })}
  />
);

PostalCodeField.propTypes = {
  className: PropTypes.string,
};


export default PostalCodeField;
