import { isUUID } from './stringFormatters';


// eslint-disable-next-line import/prefer-default-export
export const processBarcodeScan = (data) => {
  if (typeof data !== 'string') {
    return null;
  }

  if (isUUID(data)) {
    return {
      UUID: data,
    };
  }

  try {
    const decoded = JSON.parse(data);
    if (typeof decoded !== 'object') {
      return null;
    }

    const { UUID } = decoded;
    if (typeof UUID === 'string' && isUUID(UUID)) {
      return decoded;
    }

    return null;
  } catch (error) {
    return null;
  }
};
