import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';


const ColoredCircle = ({ color, className }) => (
  <div
    className={classnames('colored-circle', { [className]: Boolean(className) })}
    style={{ backgroundColor: color }}
  />
);

ColoredCircle.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};


export default ColoredCircle;
