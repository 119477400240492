import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CardHeader, Avatar, MenuItem } from '@material-ui/core';
import PersonAdd from '@material-ui/icons/PersonAdd';

import * as authSelectors from 'app/redux/auth/selectors';
import * as cartActions from 'app/redux/cart/actions';
import * as cartSelectors from 'app/redux/cart/selectors';
import * as stateSelectors from 'app/redux/state/selectors';
import { dialogActions } from 'app/redux/ui/actions';
import * as settingsSelectors from 'app/redux/settings/selectors';
import { HamburgerButton, HamburgerMenu } from 'common/hamburger';
import { ITEM_TYPES_REQUIRING_CUSTOMER } from 'config/itemTypes';
import { PERMISSIONS } from 'constants/permissions';
import { CartModel } from 'service/models';
import { getRoutePath } from 'service/navigation';
import { staffHasPermission } from 'service/staff';
import { routeWithParams } from 'service/utility';
import { formatName, formatInitials } from 'service/utility/stringFormatters';

import CartSelector from './CartSelector';


class CartHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }


  openMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleClearCart = () => {
    this.closeMenu();

    this.props.confirmAction(
      this.props.clearCart,
      {
        title: 'Clear Cart?',
        message: 'Are you sure you want to delete all items from this cart?',
        confirmLabel: 'clear',
      },
    );
  };

  handleUnassignCustomer = () => {
    this.closeMenu();

    const Cart = new CartModel(this.props.activeCart);

    if (!Cart.requiresCustomer(ITEM_TYPES_REQUIRING_CUSTOMER)) {
      this.props.confirmAction(
        this.props.unassignCartCustomer,
        {
          title: 'Unassign Customer From Cart?',
          confirmLabel: 'Unassign',
        },
      );
    } else {
      this.props.confirmAction(
        () => {},
        {
          title: 'Cannot Unassign Customer From Cart',
          hideCancel: true,
          message: 'This cart contains one or more items (i.e. passes or memberships) that have already been assigned to a customer. If you made a mistake, delete this cart and create a new one.',
        }
      );
    }
  };

  handleUnassignStaff = () => {
    this.closeMenu();

    this.props.confirmAction(
      this.props.unassignCartStaff,
      {
        title: 'Abandon Cart?',
        message: 'Do you really want to abandon this cart?',
        confirmLabel: 'Yes',
      },
    );
  };

  handleDeleteCart = () => {
    this.closeMenu();

    this.props.confirmAction(
      this.props.deleteCart,
      {
        title: 'Delete Cart?',
        message: 'Do you really want to delete this cart?',
        confirmLabel: 'Delete',
      }
    );
  };

  handlePurchasePlans = () => {
    const { history, activeCart } = this.props;

    this.closeMenu();
    history.push(routeWithParams(getRoutePath('CUSTOMER_PURCHASE_PLANS'), { id: activeCart.customer.id }));
  };

  handleAssignCustomer = () => {
    this.closeMenu();

    this.props.onAssignCustomer(true);
  };

  handleViewProfile = () => {
    this.closeMenu();

    this.props.history.push(`${getRoutePath('CUSTOMERS')}/${this.props.activeCart.customer.id}`);
  };

  handleCreateAdHocChargeDialog = () => {
    const { activeCart } = this.props;

    const Cart = new CartModel(activeCart);
    const customer = Cart.getCustomer();

    this.closeMenu();
    this.props.showCreateAdHocChargeDialog(customer);
  };

  render() {
    const {
      checkout, toggleCustomerDialog, isAdHocChargeActive,
      activeCart, activeCartCustomer, activeCartId,
      currentLocation, currentStaff, timeZone,
      assignStaffToCart, confirmAction, createNewCart, setActiveCart,
    } = this.props;
    const { anchorEl } = this.state;

    const Cart = new CartModel(activeCart);
    const customer = Cart.getCustomer();
    const cartHasItems = !Cart.isEmpty();
    const loadedOrder = Cart.hasOrder();
    const cartHasCustomer = Boolean(customer);
    const isRealCart = Cart.isReal();
    const canCreateAdHocCharge = staffHasPermission(currentStaff, PERMISSIONS.CREATE_AD_HOC_CHARGE);

    return (
      <CardHeader
        classes={{ root: 'cart-header', action: 'cart-header-action' }}
        avatar={
          cartHasCustomer ? (
            <Avatar
              onClick={() => toggleCustomerDialog(customer.id)}
              title={formatName(customer)}
              src={customer.imageURL || null}
            >
              {!customer.imageURL && (
                <span>
                  {formatInitials(customer)}
                </span>
              )}
            </Avatar>
          ) : (
            <Avatar
              onClick={this.handleAssignCustomer}
              title="Assign Customer to Cart"
            >
              <PersonAdd />
            </Avatar>
          )
        }
        title={
          <CartSelector
            checkout={checkout}
            activeCartCustomer={activeCartCustomer}
            activeCartId={activeCartId}
            currentLocation={currentLocation}
            currentStaff={currentStaff}
            timeZone={timeZone}
            assignStaffToCart={assignStaffToCart}
            confirmAction={confirmAction}
            createNewCart={createNewCart}
            setActiveCart={setActiveCart}
          />
        }
        action={
          !checkout && (
            <>
              <HamburgerButton
                onClick={this.openMenu}
                isActive={Boolean(anchorEl)}
              />
              <HamburgerMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.closeMenu}
              >
                {cartHasCustomer && !loadedOrder && (
                  <MenuItem onClick={this.handlePurchasePlans}>
                    {'Purchase Membership or Pass'}
                  </MenuItem>
                )}
                {!cartHasCustomer && (
                  <MenuItem onClick={this.handleAssignCustomer}>
                    {'Assign Customer'}
                  </MenuItem>
                )}
                {cartHasItems && (
                  <MenuItem onClick={this.handleClearCart}>
                    {'Clear Cart'}
                  </MenuItem>
                )}
                {cartHasCustomer && (
                  <MenuItem onClick={this.handleViewProfile}>
                    {'View Profile'}
                  </MenuItem>
                )}
                {cartHasCustomer && !loadedOrder && (
                  <MenuItem onClick={this.handleUnassignCustomer}>
                    {'Unassign Customer'}
                  </MenuItem>
                )}
                {isRealCart && (
                  <MenuItem onClick={this.handleUnassignStaff}>
                    {'Abandon Cart'}
                  </MenuItem>
                )}
                {isRealCart && (
                  <MenuItem onClick={this.handleDeleteCart}>
                    {'Delete Cart'}
                  </MenuItem>
                )}
                {cartHasCustomer && isAdHocChargeActive && canCreateAdHocCharge && (
                  <MenuItem onClick={this.handleCreateAdHocChargeDialog}>
                    {'Create Ad Hoc Charge'}
                  </MenuItem>
                )}
              </HamburgerMenu>
            </>
          )
        }
      />
    );
  }
}

CartHeader.propTypes = {
  activeCart: PropTypes.object,
  activeCartCustomer: PropTypes.object,
  activeCartId: PropTypes.string,
  assignStaffToCart: PropTypes.func.isRequired,
  checkout: PropTypes.bool,
  clearCart: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  createNewCart: PropTypes.func.isRequired,
  currentLocation: PropTypes.object.isRequired,
  currentStaff: PropTypes.object.isRequired,
  deleteCart: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isAdHocChargeActive: PropTypes.bool,
  onAssignCustomer: PropTypes.func.isRequired,
  setActiveCart: PropTypes.func.isRequired,
  showCreateAdHocChargeDialog: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
  toggleCustomerDialog: PropTypes.func.isRequired,
  unassignCartCustomer: PropTypes.func.isRequired,
  unassignCartStaff: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  activeCart: cartSelectors.activeCartSelector(state),
  activeCartCustomer: cartSelectors.activeCartCustomerSelector(state),
  activeCartId: cartSelectors.activeCartIdSelector(state),
  currentLocation: stateSelectors.currentLocation(state),
  currentStaff: authSelectors.currentStaff(state),
  isAdHocChargeActive: settingsSelectors.isAdHocChargeActive(state),
  timeZone: stateSelectors.timeZone(state),
});

const mapDispatchToProps = (dispatch) => ({
  assignStaffToCart: (staffId, cartId) => dispatch(cartActions.assignStaffToCart(staffId, cartId)),
  clearCart: () => dispatch(cartActions.clearCart()),
  confirmAction: (callback, options) => dispatch(dialogActions.confirmAction(callback, options)),
  createNewCart: () => dispatch(cartActions.createNewCart()),
  deleteCart: () => dispatch(cartActions.deleteCart()),
  setActiveCart: (cart) => dispatch(cartActions.setActiveCart(cart)),
  showCreateAdHocChargeDialog: (customer) => dispatch(dialogActions.showCreateAdHocChargeDialog(customer)),
  toggleCustomerDialog: (customerId) => dispatch(dialogActions.toggleCustomerDialog(customerId)),
  unassignCartCustomer: () => dispatch(cartActions.unassignCartCustomer()),
  unassignCartStaff: () => dispatch(cartActions.unassignCartStaff()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartHeader));
