import { put, take, call } from 'redux-saga/effects';

import firebaseService from 'service/firebase';
import { clearLocalStorage } from 'service/storage';

import { LOG_OUT_AND_RESET_CACHE, authLoggedOutSuccess, setActiveUsers } from '../actions';


function *logOutAndResetCacheSaga() {
  while (true) {
    yield take(LOG_OUT_AND_RESET_CACHE);

    try {
      yield call(firebaseService.logOut);
      yield put(authLoggedOutSuccess());
      yield put(setActiveUsers([], {}));
      yield call(clearLocalStorage);
    } catch (error) {
      console.log('firebaseService.logOut error: ', error);
    }
  }
}


export default logOutAndResetCacheSaga;
