import { createReducer } from 'service/utility/redux';

import { SET_CONFIRM_ACTION_DIALOG } from '../actions/dialog';


const initState = {
  isOpen: false,
  title: '',
  message: '',
  cancelLabel: 'Cancel',
  confirmLabel: 'OK',
};

const confirmActionDialog = createReducer(initState, {
  [SET_CONFIRM_ACTION_DIALOG](state, { isOpen, options }) {
    return isOpen ? ({
      isOpen: true,
      title: options.title ? options.title : initState.title,
      message: options.message ? options.message : initState.message,
      cancelLabel: options.cancelLabel ? options.cancelLabel : initState.cancelLabel,
      confirmLabel: options.confirmLabel ? options.confirmLabel : initState.confirmLabel,
      hideConfirm: Boolean(options.hideConfirm),
      hideCancel: Boolean(options.hideCancel),
    }) : ({
      ...state,
      isOpen: false,
    });
  },
});


export default confirmActionDialog;
