import React from 'react';
import { PropTypes } from 'prop-types';
import { MenuItem, ListItemText } from '@material-ui/core';

import { formatGuestsInterval, formatPricingTierPrice } from 'service/utility/pricingTier';
import { stripHtmlTags } from 'service/utility/stringFormatters';


const PricingTierSearchItem = ({ children, isFocused, data, innerProps, currentLocation }) => (
  <MenuItem
    selected={isFocused}
    onClick={innerProps.onClick}
    className="pricing-tier-search-item"
  >
    <ListItemText
      primary={children.toString()}
      secondary={`${stripHtmlTags(data.description)} (${formatGuestsInterval(data)} guests / ${formatPricingTierPrice(data, currentLocation)})`}
    />
  </MenuItem>
);

PricingTierSearchItem.propTypes = {
  children: PropTypes.any,
  currentLocation: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool,
};


export default PricingTierSearchItem;
