import React from 'react';
import { PropTypes } from 'prop-types';
import {
  RadioGroup, Radio, FormControl, FormLabel, FormControlLabel, Typography,
} from '@material-ui/core';

import { TAX_FIELDS, TAX_FIELDS2 } from 'constants/taxFields';
import { /*CUSTOM_TAXES_TOOLTIP, */DISABLED_TAXES_TOOLTIP } from 'constants/tooltips';
import { locationUsesPriceBeforeTax } from 'service/i18n';
import { copyProps, setProps } from 'service/utility';
import { calculatePriceWithTax, calculatePriceWithoutTax } from 'service/utility/pricing';

import { /*InfoTooltipLabel, */InfoTooltip } from '../tooltips';
import CurrencyField from './CurrencyField';
import TaxField from './TaxField';
import TextField from './TextField';


class PriceAndTaxes extends React.PureComponent {
  constructor(props) {
    super(props);

    const { value } = this.props;

    this.localValues = copyProps(value, TAX_FIELDS2);

    this.state = {
      taxesOption: (
        value.isTaxExempt
          ? 'TAX_EXEMPT'
          : TAX_FIELDS.every((taxField) => (value[taxField] ?? null) === null)
            ? 'LOCATION_TAX'
            : 'CUSTOM'
      ),
    };
  }

  handleTaxesOptionChange = (event) => {
    const { onChange, value, currentLocation } = this.props;

    const taxesOption = event.target.value;

    this.setState((prevState) => {
      if (taxesOption === prevState.taxesOption) return null;

      if (prevState.taxesOption === 'CUSTOM') {
        this.localValues = copyProps(value, TAX_FIELDS2);
      }

      return {
        taxesOption,
      };
    }, () => {
      if (onChange) {
        const taxes = {
          isTaxExempt: taxesOption === 'TAX_EXEMPT' ? 1 : 0,
        };

        if (taxesOption !== 'CUSTOM') {
          setProps(null, TAX_FIELDS2, taxes);
        } else {
          copyProps(this.localValues, TAX_FIELDS2, taxes);
        }

        const taxInclusivePrice = calculatePriceWithTax(value.price, taxes, currentLocation);

        const compositeUpdate = {
          values: {
            ...taxes,
            taxInclusivePrice,
          },
        };

        onChange(compositeUpdate);
      }
    });
  };

  handleChange = (varName) => (update) => {
    const { onChange, value, error, currentLocation } = this.props;

    if (!onChange) return;

    const hasNewValue = update.hasOwnProperty('value');
    const hasNewError = update.hasOwnProperty('error');

    if (!hasNewValue && !hasNewError) return;

    const newValue = update.value;
    const newError = update.error;
    const oldValue = value[varName];
    const oldError = error[varName];
    const valueHasChanged = oldValue !== newValue;
    const errorHasChanged = oldError !== newError;

    if (
      (!hasNewValue || (hasNewValue && !valueHasChanged)) &&
      (!hasNewError || (hasNewError && !errorHasChanged))
    ) {
      return;
    }

    const compositeUpdate = {};

    if (hasNewValue && valueHasChanged) {
      compositeUpdate.values = {
        [varName]: newValue,
      };

      if (!locationUsesPriceBeforeTax(currentLocation)) {
        if (varName === 'taxInclusivePrice') {
          compositeUpdate.values.price = calculatePriceWithoutTax(
            newValue,
            value,
            currentLocation,
          );
        } else {
          compositeUpdate.values.taxInclusivePrice = calculatePriceWithTax(
            varName === 'price' ? newValue : value.price,
            { ...value, [varName]: newValue },
            currentLocation
          );
        }
      }
    }

    if (hasNewError && errorHasChanged) {
      compositeUpdate.errors = {
        [varName]: newError,
      };
    }

    onChange(compositeUpdate);
  };

  handlePriceChange = this.handleChange('price');

  handleStateTaxRateChange = this.handleChange('stateTaxRate');

  handleCityTaxRateChange = this.handleChange('cityTaxRate');

  handleLocaleTaxRateChange = this.handleChange('localeTaxRate');

  handleAdditionalTaxRateChange = this.handleChange('additionalTaxRate');

  handleAdditionalTaxNameChange = this.handleChange('additionalTaxName');

  handleTaxInclusivePriceChange = this.handleChange('taxInclusivePrice');

  render() {
    const { currentLocation, isTaxDisabled, value, error, priceNote } = this.props;
    const { taxesOption } = this.state;

    return (
      <>
        <>
          <div className="field-wrapper">
            <CurrencyField
              fullWidth
              required
              label="Price"
              value={value.price}
              error={error.price}
              onChange={this.handlePriceChange}
              currentLocation={currentLocation}
            />
          </div>
          {priceNote && (
            <div className="mb-4">
              <span className="font-italic text-small">
                {priceNote}
              </span>
            </div>
          )}
        </>
        <div className="field-wrapper">
          <FormControl component="fieldset">
            <div className="d-flex flex-row align-items-center">
              <FormLabel className="mb-0 mr-1">
                {'Taxes'}
              </FormLabel>
              {isTaxDisabled && (
                <InfoTooltip>
                  {DISABLED_TAXES_TOOLTIP}
                </InfoTooltip>
              )}
            </div>
            <RadioGroup
              value={taxesOption}
              onChange={this.handleTaxesOptionChange}
            >
              <FormControlLabel
                value="LOCATION_TAX"
                control={<Radio />}
                label={
                  <Typography display="inline">
                    {'Same as Location Tax'}
                  </Typography>
                }
                className="mb-0"
                disabled={isTaxDisabled}
              />
              <FormControlLabel
                value="TAX_EXEMPT"
                control={<Radio />}
                label={
                  <Typography display="inline">
                    {'Is Tax Exempt'}
                  </Typography>
                }
                className="mb-0"
                disabled={isTaxDisabled}
              />
              <FormControlLabel
                value="CUSTOM"
                control={<Radio />}
                label={
                  <Typography display="inline">
                    {'Custom'}
                  </Typography>
                }
                // label={
                //   <InfoTooltipLabel
                //     display="inline"
                //     label="Custom"
                //     tooltip={CUSTOM_TAXES_TOOLTIP}
                //   />
                // }
                className="mb-0"
                disabled={isTaxDisabled}
              />
            </RadioGroup>
          </FormControl>
        </div>
        {taxesOption === 'CUSTOM' && (
          <>
            <div className="field-wrapper">
              <TaxField
                fullWidth
                label="State/Province Tax"
                value={value.stateTaxRate}
                error={error.stateTaxRate}
                onChange={this.handleStateTaxRateChange}
                disabled={isTaxDisabled}
              />
            </div>
            <div className="field-wrapper">
              <TaxField
                fullWidth
                label="City Tax"
                value={value.cityTaxRate}
                error={error.cityTaxRate}
                onChange={this.handleCityTaxRateChange}
                disabled={isTaxDisabled}
              />
            </div>
            <div className="field-wrapper">
              <TaxField
                fullWidth
                label="Local Tax"
                value={value.localeTaxRate}
                error={error.localeTaxRate}
                onChange={this.handleLocaleTaxRateChange}
                disabled={isTaxDisabled}
              />
            </div>
            <div className="field-wrapper">
              <TaxField
                fullWidth
                label="Additional Tax"
                value={value.additionalTaxRate}
                error={error.additionalTaxRate}
                onChange={this.handleAdditionalTaxRateChange}
                disabled={isTaxDisabled}
              />
            </div>
            <div className="field-wrapper">
              <TextField
                fullWidth
                label="Additional Tax Name"
                value={value.additionalTaxName}
                onChange={this.handleAdditionalTaxNameChange}
                disabled={isTaxDisabled}
              />
            </div>
          </>
        )}
        {taxesOption !== 'TAX_EXEMPT' && !locationUsesPriceBeforeTax(currentLocation) && (
          <div className="field-wrapper">
            <CurrencyField
              fullWidth
              label="Tax Inclusive Price"
              value={value.taxInclusivePrice}
              error={error.taxInclusivePrice}
              onChange={this.handleTaxInclusivePriceChange}
              currentLocation={currentLocation}
            />
          </div>
        )}
      </>
    );
  }
}

PriceAndTaxes.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  error: PropTypes.object,
  isTaxDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  priceNote: PropTypes.string,
  value: PropTypes.object,
};


export default PriceAndTaxes;
