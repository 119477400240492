import { take, call, put, select } from 'redux-saga/effects';

import { INITIAL_CART_LOAD, setCartLoading, finishInitialCartLoad } from '../actions';
import { activeCartSelector } from '../selectors';
import { loadCart } from './cartSagas';


function *initialCartLoadSaga() {
  while (true) {
    yield take(INITIAL_CART_LOAD);

    const activeCart = yield select(activeCartSelector);

    if (activeCart) {
      const cartId = activeCart.UUID;

      if (cartId) {
        yield put(setCartLoading(true));
        yield call(loadCart, cartId);
        yield put(setCartLoading(false));
      }
    }

    yield put(finishInitialCartLoad());
  }
}


export default initialCartLoadSaga;
