import React from 'react';
import { PropTypes } from 'prop-types';


class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.log(error, info.componentStack);
  }

  handleRefresh = () => {
    this.setState({ hasError: false }, this.forceUpdate);
  };

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <p>{'Something went wrong. Click the button below to try and refresh.'}</p>
          <button type="button" onClick={this.handleRefresh}>{'Refresh'}</button>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};


export default ErrorBoundary;
