import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { IconButton } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';


const HamburgerButton = (props) => {
  const { className, isActive, ...rest } = props;

  return (
    <IconButton
      aria-haspopup="true"
      aria-label="Hamburger Button"
      className={classnames({ [className]: Boolean(className), 'hamburger-button-active': isActive })}
      {...rest}
    >
      <MoreVert />
    </IconButton>
  );
};

HamburgerButton.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
};


export default HamburgerButton;
