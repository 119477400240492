import React from 'react';
import { PropTypes } from 'prop-types';

import { ISSUE_TYPES } from 'constants/support';
import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


const ISSUE_TYPE_PICKLIST_VALUES = [
  {
    label: 'All',
    value: 0,
  },
  ...ISSUE_TYPES.map((issueType) => ({
    label: issueType.label,
    value: issueType.value,
  })),
];


class IssueTypesFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      issueType: (
        this.props.hasOwnProperty('type') && this.props.type !== 'null'
          ? this.props.type
          : 0
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['issueType'])) {
      this.handleValueChange();
    }
  }

  getOwnFilterMap = () => ({
    type: this.state.issueType === 0 ? null : this.state.issueType,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleChange = (update) => {
    if (!update.hasOwnProperty('value')) return;

    this.setState({
      issueType: update.value,
    });
  };

  render() {
    const { issueType } = this.state;

    return (
      <PicklistField
        className="issue-type-filter"
        fullWidth
        label="Type"
        value={issueType}
        onChange={this.handleChange}
        picklistValues={ISSUE_TYPE_PICKLIST_VALUES}
      />
    );
  }
}

IssueTypesFilter.propTypes = {
  onValueChange: PropTypes.func,
  type: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

IssueTypesFilter.ownName = 'IssueTypesFilter';
IssueTypesFilter.ownFilters = ['type'];


export default IssueTypesFilter;
