import React from 'react';
import { PropTypes } from 'prop-types';
import { ListItem, ListItemText } from '@material-ui/core';


class RevenueCategoryListItem extends React.Component {
  handleClick = () => {
    this.props.onSelect(this.props.revenueCategory.value);
  };

  render() {
    const { revenueCategory, isSelected } = this.props;

    return (
      <ListItem
        button
        selected={isSelected}
        onClick={this.handleClick}
      >
        <ListItemText primary={revenueCategory.label} />
      </ListItem>
    );
  }
}

RevenueCategoryListItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  revenueCategory: PropTypes.object.isRequired,
};


export default RevenueCategoryListItem;
