import React from 'react';
import { PropTypes } from 'prop-types';

import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


const ARCHIVED_PICKLIST_VALUES = [
  {
    label: 'All',
    value: '1,0',
  },
  {
    label: 'Unarchived',
    value: '0',
  },
  {
    label: 'Archived',
    value: '1',
  },
];


class ArchivedFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isArchived: (
        this.props.hasOwnProperty('isArchived') && this.props.isArchived !== 'null'
          ? this.props.isArchived
          : ARCHIVED_PICKLIST_VALUES[0].value
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['isArchived'])) {
      this.handleValueChange();
    }
  }

  getOwnFilterMap = () => ({
    isArchived: this.state.isArchived,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ isArchived: update.value });
    }
  };


  render() {
    const { isArchived } = this.state;

    return (
      <PicklistField
        label="Archived"
        value={isArchived}
        onChange={this.handleChange}
        picklistValues={ARCHIVED_PICKLIST_VALUES}
      />
    );
  }
}

ArchivedFilter.propTypes = {
  isArchived: PropTypes.string,
  onValueChange: PropTypes.func,
};

ArchivedFilter.ownName = 'ArchivedFilter';
ArchivedFilter.ownFilters = ['isArchived'];


export default ArchivedFilter;
