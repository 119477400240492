import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography } from '@material-ui/core';

import { Spinner } from 'common/statusIndicators';

import CartDiscount from './CartDiscount';
import CartFee from './CartFee';
import CartGiftCard from './CartGiftCard';
import CartInvoice from './CartInvoice';
import CartMembership from './CartMembership';
import CartMultiDayTicket from './CartMultiDayTicket';
import CartPass from './CartPass';
import CartProduct from './CartProduct';
import CartTicket from './CartTicket';


const CART_ITEM_TYPES = {
  GIFT_CARD: CartGiftCard,
  PASS: CartPass,
  MEMBERSHIP: CartMembership,
  MEMBERSHIP_RESUME: CartMembership,
  FEE: CartFee,
  EVENT_BOOKING_TICKET: CartTicket,
  EVENT_SERIES_TICKET: CartMultiDayTicket,
  EVENT_BOOKING_INVOICE: CartInvoice,
  PRODUCT: CartProduct,
};


const CartItems = ({
  cartDiscountAmount, cartDiscountType, cartDiscountValue, cartItems,
  currentLocation, hasPromoCode, loading,
  onCartDiscountPut, onItemRemove, onQuantityUpdate, orderExists,
}) => (
  <div className="cart-items-scrollbar styled-scrollbar">
    <div className="cart-items">
      {loading ? (
        <Spinner />
      ) : cartItems.length > 0 ? (
        <>
          {cartItems.map((item) => {
            const CartItem = CART_ITEM_TYPES[item.type];

            return (
              <CartItem
                key={item.UUID}
                item={item}
                disabled={orderExists}
                onQuantityUpdate={onQuantityUpdate}
                onItemRemove={onItemRemove}
                currentLocation={currentLocation}
              />
            );
          })}
          {!hasPromoCode && cartDiscountAmount > 0 && (
            <CartDiscount
              amount={cartDiscountAmount}
              type={cartDiscountType}
              disabled={orderExists}
              discountValue={cartDiscountValue}
              putCartDiscount={onCartDiscountPut}
            />
          )}
        </>
      ) : (
        <Typography
          align="center"
          color="textSecondary"
          classes={{ root: 'font-italic' }}
        >
          {'Cart is empty.'}
        </Typography>
      )}
    </div>
  </div>
);

CartItems.propTypes = {
  cartDiscountAmount: PropTypes.number,
  cartDiscountType: PropTypes.string,
  cartDiscountValue: PropTypes.number,
  cartItems: PropTypes.array.isRequired,
  currentLocation: PropTypes.object.isRequired,
  hasPromoCode: PropTypes.bool,
  loading: PropTypes.bool,
  onCartDiscountPut: PropTypes.func.isRequired,
  onItemRemove: PropTypes.func.isRequired,
  onQuantityUpdate: PropTypes.func.isRequired,
  orderExists: PropTypes.bool.isRequired,
};


export default CartItems;
