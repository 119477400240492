import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import * as API from 'service/api';
import { formatName } from 'service/utility/stringFormatters';

import GenericSearch from '../GenericSearch';
import StaffSearchItem from './StaffSearchItem';


const getOptionValue = (option) => option.id;

const getOptionLabel = (option) => formatName(option);


class StaffSearch extends React.Component {
  getOptions = (input) => {
    const { filter } = this.props;

    return API.findStaff(`q=${input}`).then(
      ({ data: { data } }) => (filter ? filter(data) : data),
    );
  };

  render() {
    const { className, ...rest } = this.props;

    return (
      <GenericSearch
        suggestionItem={StaffSearchItem}
        getOptions={this.getOptions}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        className={classnames('staff-search', { [className]: Boolean(className) })}
        {...rest}
      />
    );
  }
}

StaffSearch.propTypes = {
  autoFocus: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  filter: PropTypes.func,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.object,
};

StaffSearch.defaultProps = {
  autoFocus: false,
  cacheOptions: true,
  className: '',
  disabled: false,
  error: '',
  fullWidth: false,
  required: false,
};


export default StaffSearch;
