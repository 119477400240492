import { createReducer } from 'service/utility/redux';

import {
  SEARCH_CUSTOMERS, CHECKIN_CUSTOMER, CHECKIN_OTHER,
  SET_CHECKIN_DIALOG_PHASE, SET_CHECKIN_DIALOG_CALLBACK,
  SHOW_CHECKIN_DIALOG_SEARCH, SHOW_CHECKIN_DIALOG_CUSTOMER,
  CHECKIN_DIALOG_SELECT_CUSTOMER, CHECKIN_DIALOG_SELECT_EVENT, HIDE_CHECKIN_DIALOG,
} from '../actions/dialog';


const initState = {
  isOpen: false,
  checkinPhase: SEARCH_CUSTOMERS,
  minCheckinPhase: SEARCH_CUSTOMERS,
  customerId: null,
  bookingId: null,
  groupId: null,
  event: null,
  callback: null,
};

const checkinDialog = createReducer(initState, {
  [SET_CHECKIN_DIALOG_PHASE](state, { phase }) {
    return {
      ...state,
      checkinPhase: phase,
    };
  },
  [SET_CHECKIN_DIALOG_CALLBACK](state, { callback }) {
    return {
      ...state,
      callback,
    };
  },
  [SHOW_CHECKIN_DIALOG_SEARCH](state, { bookingId = null }) {
    return {
      ...state,
      isOpen: true,
      checkinPhase: SEARCH_CUSTOMERS,
      bookingId,
    };
  },
  [SHOW_CHECKIN_DIALOG_CUSTOMER](state, { customerId, bookingId = null, groupId = null }) {
    return {
      ...state,
      isOpen: true,
      checkinPhase: CHECKIN_CUSTOMER,
      minCheckinPhase: CHECKIN_CUSTOMER,
      customerId,
      bookingId,
      groupId,
    };
  },
  [CHECKIN_DIALOG_SELECT_CUSTOMER](state, { customerId }) {
    return {
      ...state,
      checkinPhase: CHECKIN_CUSTOMER,
      customerId,
    };
  },
  [CHECKIN_DIALOG_SELECT_EVENT](state, { event }) {
    return {
      ...state,
      checkinPhase: CHECKIN_OTHER,
      event,
    };
  },
  [HIDE_CHECKIN_DIALOG]() {
    return initState;
  },
});


export default checkinDialog;
