import React from 'react';
import { PropTypes } from 'prop-types';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input/max';

import { isNullish } from 'service/utility';


const getCountryConfig = (currentCountry, value) => {
  const countryConfig = {};

  if (value === '' || isNullish(value)) {
    countryConfig.country = currentCountry;
    countryConfig.isLocalNumber = false;
  } else {
    const parsed = parsePhoneNumber(value);

    if (parsed) {
      if (parsed.country) {
        countryConfig.country = parsed.country;
        countryConfig.isLocalNumber = true;
      } else {
        countryConfig.country = currentCountry;
        countryConfig.isLocalNumber = false;
      }
    }
  }

  return countryConfig;
};


class NumberFormatCustom extends React.Component {
  onValueChange = (phone) => {
    this.props.onChange({
      target: {
        value: phone,
      },
    });
  };

  render() {
    const { inputRef, onChange, value, currentCountry, ...rest } = this.props;
    const { country, isLocalNumber } = getCountryConfig(currentCountry, value);

    return (
      <PhoneInput
        {...rest}
        value={value}
        defaultCountry={currentCountry}
        country={country}
        displayInitialValueAsLocalNumber={isLocalNumber}
        limitMaxLength
        onChange={this.onValueChange}
      />
    );
  }
}

NumberFormatCustom.propTypes = {
  currentCountry: PropTypes.string,
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};


export default NumberFormatCustom;
