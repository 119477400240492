import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import * as API from 'service/api';

import GenericSearch from '../GenericSearch';
import CalendarSearchItem from './CalendarSearchItem';


const getOptionValue = (option) => option.id;

const getOptionLabel = (option) => option.name;


class CalendarSearch extends React.Component {
  getOptions = (input) => {
    const { currentLocation, filter } = this.props;

    return API.getCalendarsByLocation(currentLocation.id).then(
      ({ data: calendarList }) => {
        const data = calendarList.filter(
          (calendar) => calendar.name.substring(0, input.length).toUpperCase() === input.toUpperCase()
        );

        return (filter ? filter(data) : data);
      },
    );
  };

  render() {
    const { className, ...rest } = this.props;

    return (
      <GenericSearch
        suggestionItem={CalendarSearchItem}
        getOptions={this.getOptions}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        className={classnames('calendar-search', { [className]: Boolean(className) })}
        {...rest}
      />
    );
  }
}

CalendarSearch.propTypes = {
  autoFocus: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  className: PropTypes.string,
  currentLocation: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  filter: PropTypes.func,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.object,
};

CalendarSearch.defaultProps = {
  autoFocus: false,
  cacheOptions: true,
  className: '',
  disabled: false,
  error: '',
  fullWidth: true,
  placeholder: '',
  required: false,
};


export default CalendarSearch;
