import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { Paper, ListItem, ListItemText, Typography, Link } from '@material-ui/core';

import { somePropsChanged } from 'service/utility';

import { HamburgerButton, HamburgerMenu } from '../../hamburger';
import { SearchBar } from '../../searches';
import { Spinner, StatusOverlay } from '../../statusIndicators';
import DataTable from '../DataTable';
import MassAction from './MassAction';


const compare = (a, b, order) => (
  a < b
    ? (order === 'ASC' ? -1 : 1)
    : a > b
      ? (order === 'ASC' ? 1 : -1)
      : 0
);

const getSearchedData = (data, searchString, fieldsConfig) => {
  if (!searchString) return data;

  const searchableFields = fieldsConfig.filter((field) => !field.notSortable);
  if (!searchableFields.length) return data;

  const regex = RegExp(searchString.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
  return data.filter((item) => searchableFields.some((field) => regex.test(item[field.sortString])));
};

const getFilteredData = (data, filters) => {
  const filterKeys = Object.keys(filters);
  if (!filterKeys.length) return data;

  return filterKeys.reduce((a, filterKey) => {
    const filterValue = filters[filterKey];

    if (filterValue === null) {
      return a;
    }

    return a.filter((item) => item[filterKey] === filterValue);
  }, data);
};

const getProcessedData = (fieldsConfig, data, order, orderBy, searchString, filters) => {
  const searchedData = getSearchedData(data, searchString, fieldsConfig);
  const filteredData = getFilteredData(searchedData, filters);

  return orderBy ? filteredData.sort((a, b) => compare(a[orderBy], b[orderBy], order)) : filteredData;
};


class StaticDataTable extends React.Component {
  constructor(props) {
    super(props);

    this.filters = this.constructor.getFilters(this.props.filters);

    this.state = {
      data: [],
      order: this.props.order,
      orderBy: this.props.orderBy,
      searchString: '',
      filters: {},
      selectedItemIds: this.props.selectedItemIds,
      hamburgerAnchorEl: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted && !this.props.loading) {
      this.updateData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._isMounted && somePropsChanged(prevProps, this.props, ['loading', 'data'])) {
      this.updateData();
    }

    if (this.props.onSelectedItemsChange && somePropsChanged(prevState, this.state, ['selectedItemIds'])) {
      this.props.onSelectedItemsChange(this.state.selectedItemIds);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateData = () => {
    const { fieldsConfig, data } = this.props;

    this.setState((prevState) => {
      const { order, orderBy, searchString, filters } = prevState;
      const newData = getProcessedData(fieldsConfig, data, order, orderBy, searchString, filters);

      return {
        data: newData,
      };
    });
  };

  handleSortBy = (newOrderBy) => {
    const { fieldsConfig, data } = this.props;

    this.setState((prevState) => {
      const { order, orderBy, searchString, filters } = prevState;
      const newOrder = (orderBy === newOrderBy && order === 'ASC') ? 'DESC' : 'ASC';
      const newData = getProcessedData(fieldsConfig, data, newOrder, newOrderBy, searchString, filters);

      return {
        data: newData,
        order: newOrder,
        orderBy: newOrderBy,
      };
    });
  };

  handleFilter = (newSearchString) => {
    const { fieldsConfig, data } = this.props;

    this.setState((prevState) => {
      const { order, orderBy, filters } = prevState;
      const newData = getProcessedData(fieldsConfig, data, order, orderBy, newSearchString, filters);

      return {
        data: newData,
        searchString: newSearchString,
      };
    });
  };

  handleFilterValueChange = (filterKey, update, callback) => {
    const { fieldsConfig, data } = this.props;

    this.setState((prevState) => {
      const { order, orderBy, searchString, filters } = prevState;
      const newFilters = {
        ...filters,
        ...update,
      };
      const newData = getProcessedData(fieldsConfig, data, order, orderBy, searchString, newFilters);

      return {
        data: newData,
        filters: newFilters,
      };
    }, () => {
      if (callback) {
        callback(update);
      }
    });
  };

  getPlaceholder = () => {
    const { searchBy, fieldsConfig } = this.props;
    const sortableFields = fieldsConfig.filter((field) => !field.notSortable);
    const fieldsLen = sortableFields.length;

    return !fieldsLen
      ? 'Search'
      : `Search ${searchBy ? `${searchBy} ` : ''}by ${fieldsLen === 1
        ? `${sortableFields[0].label}.`
        : sortableFields.map(
          (field, pos) => (
            pos === (fieldsLen - 2)
              ? `${field.label} `
              : pos !== (fieldsLen - 1)
                ? `${field.label}, `
                : `or ${field.label}.`
          )
        ).join('')}`;
  };

  handleHamburgerClick = (event) => {
    this.setState({
      hamburgerAnchorEl: event.currentTarget,
    });
  };

  closeHamburgerMenu = () => {
    this.setState({
      hamburgerAnchorEl: null,
    });
  };

  handleSelectAll = () => {
    this.setState(
      (prevState) => ({
        selectedItemIds: (
          prevState.selectedItemIds.length === 0
            ? prevState.data.map((e) => e[this.props.rowIdField])
            : []
        ),
      })
    );
  };

  handleSelectAll2 = () => {
    this.setState(
      (prevState) => ({
        selectedItemIds: (
          prevState.selectedItemIds.length === prevState.data.length
            ? []
            : prevState.data.map((e) => e[this.props.rowIdField])
        ),
      })
    );
  };

  handleItemToggle = (itemId) => {
    this.setState(
      (prevState) => {
        const newSelectedItemIds = [...prevState.selectedItemIds];
        const currentIndex = newSelectedItemIds.indexOf(itemId);

        if (currentIndex === -1) {
          newSelectedItemIds.push(itemId);
        } else {
          newSelectedItemIds.splice(currentIndex, 1);
        }

        return ({
          selectedItemIds: newSelectedItemIds,
        });
      }
    );
  };


  render() {
    const {
      loading, updating, fieldsConfig, onItemClick, expansionPanel, accordion,
      size, className, newButton, noItemsMessage, searchable, massActions, selectable,
      rowIdField,
    } = this.props;
    const {
      data, order, orderBy, searchString, selectedItemIds, hamburgerAnchorEl,
    } = this.state;

    return (
      <Paper className={classnames('ds-data-table', { [className]: Boolean(className) })}>
        {updating && !loading && (
          <StatusOverlay>
            <Spinner size={60} />
          </StatusOverlay>
        )}

        <div className="ds-data-table-header">
          {(searchable || this.filters.length > 0 || newButton) && (
            <div className="header-row">
              {searchable && (
                <div className="header-row-section center-section">
                  <SearchBar
                    placeholder={this.getPlaceholder()}
                    onQueryChange={this.handleFilter}
                    query={searchString}
                    realTimeSearch
                  />
                </div>
              )}
              {(this.filters.length > 0 || newButton) && (
                <div className="header-row-section right-section">
                  {this.filters.map((filter, idx) => {
                    const key = `_DDLF_${idx}`;

                    return (
                      <div
                        key={key}
                        className="header-row-item"
                      >
                        {React.cloneElement(
                          filter,
                          {
                            onValueChange: this.handleFilterValueChange,
                          },
                        )}
                      </div>
                    );
                  })}
                  {newButton && (
                    <div className="header-row-item new-button">
                      {newButton}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {selectedItemIds.length > 0 && (
            <div className="mass-action-row">
              <div className="center-section">
                <Typography variant="body2">
                  {selectedItemIds.length === data.length ? (
                    `All ${selectedItemIds.length} items in this view are selected.`
                  ) : (
                    `${selectedItemIds.length} items selected.`
                  )}
                  &ensp;
                  <Link
                    component="button"
                    variant="body2"
                    className="font-weight-thick"
                    style={{ verticalAlign: 'unset' }}
                    onClick={this.handleSelectAll2}
                  >
                    {selectedItemIds.length === data.length ? (
                      'Deselect all.'
                    ) : (
                      'Select all.'
                    )}
                  </Link>
                </Typography>
              </div>
              {massActions.length > 0 && (
                <HamburgerButton
                  onClick={this.handleHamburgerClick}
                  isActive={Boolean(hamburgerAnchorEl)}
                />
              )}
              <HamburgerMenu
                anchorEl={hamburgerAnchorEl}
                open={Boolean(hamburgerAnchorEl)}
                onClose={this.closeHamburgerMenu}
              >
                {massActions.map((massAction, idx) => (
                  <MassAction
                    key={`_MA_${idx}`}
                    massAction={massAction}
                    selectedItemIds={selectedItemIds}
                    closeHamburgerMenu={this.closeHamburgerMenu}
                  />
                ))}
              </HamburgerMenu>
            </div>
          )}
        </div>

        <div className="ds-data-table-content">
          {loading ? (
            <Spinner size={60} />
          ) : data.length === 0 ? (
            <ListItem classes={{ root: 'text-center' }}>
              <ListItemText primary={noItemsMessage || 'No Results Found'} />
            </ListItem>
          ) : (
            <DataTable
              size={size}
              data={data}
              fieldsConfig={fieldsConfig}
              onItemClick={onItemClick}
              order={order}
              orderBy={orderBy}
              onSortBy={this.handleSortBy}
              expansionPanel={expansionPanel}
              accordion={accordion}
              selectable={selectable || massActions.length > 0}
              selectedItemIds={selectedItemIds}
              onSelectAll={this.handleSelectAll}
              onItemToggle={this.handleItemToggle}
              rowIdField={rowIdField}
            />
          )}
        </div>
      </Paper>
    );
  }
}

StaticDataTable.propTypes = {
  accordion: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  expansionPanel: PropTypes.func,
  fieldsConfig: PropTypes.array,
  filters: PropTypes.array,
  loading: PropTypes.bool,
  massActions: PropTypes.array,
  newButton: PropTypes.element,
  noItemsMessage: PropTypes.string,
  onItemClick: PropTypes.func,
  onSelectedItemsChange: PropTypes.func,
  order: PropTypes.string, // 'ASC' or 'DESC'
  orderBy: PropTypes.string,
  rowIdField: PropTypes.string,
  searchable: PropTypes.bool,
  searchBy: PropTypes.string,
  selectable: PropTypes.bool,
  selectedItemIds: PropTypes.array,
  size: PropTypes.string,
  updating: PropTypes.bool,
};

StaticDataTable.defaultProps = {
  accordion: false,
  filters: [],
  massActions: [],
  order: 'ASC',
  orderBy: null,
  rowIdField: 'id',
  searchable: true,
  searchBy: null,
  selectable: false,
  selectedItemIds: [],
  size: 'medium',
};

StaticDataTable.getFilters = (filterComponents) => {
  const urlParams = new URLSearchParams(window.location.search);

  return filterComponents.map((e) => {
    const eProps = {};
    const filterNames = (
      typeof e.type.ownFilters === 'function'
        ? e.type.ownFilters(e.props)
        : e.type.ownFilters
    );

    filterNames.forEach((filterName) => {
      if (urlParams.has(filterName)) {
        eProps[filterName] = urlParams.get(filterName);
      }
    });

    return React.cloneElement(e, eProps);
  });
};


export default StaticDataTable;
