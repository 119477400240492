import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';


const AlertDialog = ({ children, closeText, fullScreen, onClose, open, title }) => (
  <Dialog
    fullScreen={fullScreen}
    open={open}
    onClose={onClose}
    classes={{ root: 'alert-dialog' }}
  >
    {title && (
      <DialogTitle>
        {title}
      </DialogTitle>
    )}
    <DialogContent>
      {children}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        {closeText}
      </Button>
    </DialogActions>
  </Dialog>
);

AlertDialog.propTypes = {
  children: PropTypes.any,
  closeText: PropTypes.string,
  fullScreen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
};

AlertDialog.defaultProps = {
  closeText: 'Close',
  fullScreen: false,
  open: false,
  title: '',
};


export default AlertDialog;
