import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import { InfoTooltip } from '../tooltips';
import NumberField from './NumberField';


const TaxField = ({ className, tooltip, fullWidth, ...rest }) => (
  <div className={classnames('d-flex flex-row align-items-center cml-1', { 'w-100': fullWidth })}>
    <NumberField
      fullWidth={fullWidth}
      {...rest}
      inputProps={{
        suffix: '%',
        decimalScale: 3,
      }}
      className={classnames('tax-field', { [className]: Boolean(className) })}
    />
    {tooltip && (
      <InfoTooltip>
        {tooltip}
      </InfoTooltip>
    )}
  </div>
);

TaxField.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  tooltip: PropTypes.string,
};


export default TaxField;
