import { take, select, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getErrorMessage } from 'service/utility';

import { REMOVE_PROMOCODE_FROM_CART, setCartLoading } from '../actions';
import { activeCartIdSelector } from '../selectors';
import { removePromoCodeFromCart, loadCart } from './cartSagas';


function *removePromoCodeFromCartSaga() {
  while (true) {
    yield take(REMOVE_PROMOCODE_FROM_CART);

    const cartId = yield select(activeCartIdSelector);
    if (!cartId) return null;

    const toast1 = toast('Removing promo code...');

    try {
      yield put(setCartLoading(true));
      yield call(removePromoCodeFromCart, cartId);
      yield call(loadCart, cartId);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('removePromoCodeFromCartSaga error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    } finally {
      toast.dismiss(toast1);
      yield put(setCartLoading(false));
    }
  }
}


export default removePromoCodeFromCartSaga;
