import { createReducer } from 'service/utility/redux';

import { SET_LEFT_DRAWER_OPEN, SET_LEFT_DRAWER_PERMANENT } from '../actions/layout';


const initState = {
  isOpen: true,
  isPermanent: true,
};

const leftDrawer = createReducer(initState, {
  [SET_LEFT_DRAWER_OPEN](state, { isOpen }) {
    return {
      ...state,
      isOpen,
    };
  },
  [SET_LEFT_DRAWER_PERMANENT](state, { isPermanent }) {
    return {
      ...state,
      isPermanent,
    };
  },
});

export default leftDrawer;
