export const BILLING_CONFIG = [
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Weekly', value: 'WEEKLY' },
  { label: 'Date of Purchase', value: 'DOP' },
];

export const BILLING_DAYS = [...Array(29).keys()].slice(1).map((num) => ({ value: num, label: num }));

export const MEMBERSHIP_STATUSES = [
  'active',
  'unpaid',
  'hold',
  'cancelled',
  'cancelled-void',
  'expired',
];

export const BILLING_FIELDS = [
  'billingType',
  'billingDayOfWeek',
  'billingDayOfMonth',
  'durationType',
  'duration',
];

export const BILLING_DAYS_OF_WEEK = [
  { label: 'Sunday', value: 0 },
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
];

export const DURATION_TYPES = [
  { label: 'Weeks', value: 'WEEK' },
  { label: 'Months', value: 'MONTH' },
  { label: 'Years', value: 'YEAR' },
];
