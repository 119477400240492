import React from 'react';
import { PropTypes } from 'prop-types';
import { Chip, Avatar } from '@material-ui/core';

import { formatName } from 'service/utility/stringFormatters';


const PersonChip = ({ data, onRemove }) => (
  <Chip
    avatar={<Avatar src={data.imageURL} />}
    label={formatName(data)}
    onDelete={onRemove}
    className="person-chip"
  />
);

PersonChip.propTypes = {
  data: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};


export default PersonChip;
