import { take, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';

import { DELETE_CART, setCartLoading } from '../actions';
import { activeCartIdSelector } from '../selectors';
import { createNewEmptyCart } from './cartSagas';


function *deleteCartSaga() {
  while (true) {
    yield take(DELETE_CART);

    const cartId = yield select(activeCartIdSelector);
    if (!cartId) return null;

    const toast1 = toast('Deleting cart...');

    try {
      yield put(setCartLoading(true));
      yield call(API.deleteCart, cartId);
      yield call(createNewEmptyCart);

      console.log(`Deleted cart with UUID = ${cartId}`);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('deleteCartSaga error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    } finally {
      toast.dismiss(toast1);
      yield put(setCartLoading(false));
    }
  }
}


export default deleteCartSaga;
