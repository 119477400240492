import { toast } from 'react-toastify';

import firebaseService from '../firebase';
import { getErrorMessage } from '../utility';


const stateKey = 'state';

export const loadState = () => {
  const serializedState = localStorage.getItem(stateKey);
  if (!serializedState) return {};

  const allState = JSON.parse(serializedState);
  return allState;
};

export const saveState = (state) => {
  localStorage.setItem(stateKey, JSON.stringify({
    app: {
      currentLocation: state.app.currentLocation,
    },
    auth: {
      currentUser: {
        currentUserUID: state.auth.currentUser.currentUserUID,
        currentUserEmail: state.auth.currentUser.currentUserEmail,
        imageURL: state.auth.currentUser.imageURL,
      },
      currentStaff: state.auth.currentStaff,
      lockScreen: {
        isLocked: state.auth.lockScreen.isLocked,
      },
      activeUsers: state.auth.activeUsers,
    },
    carts: {
      activeCart: {
        UUID: state.carts.activeCart?.UUID ?? null,
      },
    },
    transactions: {
      device: state.transactions.device,
    },
  }));
};

export const uploadImage = async (object, id, uploaded64) => {
  if (!object || !id || !uploaded64) return null;

  try {
    const imageURL = await firebaseService.uploadImage(`${object}/${id}`, uploaded64);

    return imageURL;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log(`Error uploading ${object} image; error = `);
    console.log(errorMessage);

    toast.error(errorMessage);

    return null;
  }
};

export const clearLocalStorage = () => {
  localStorage.removeItem(stateKey);
};
