import React from 'react';
import { PropTypes } from 'prop-types';

import SearchBarView from './SearchBarView';


class SearchBar extends React.Component {
  handleQueryChange = (event) => {
    this.props.onQueryChange?.(event.target.value);
  };

  handleSearch = () => {
    this.props.onSearch?.(this.props.query);
  };

  handleClear = () => {
    this.props.onQueryChange?.('');
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSearch();
    }
  };

  render() {
    return (
      <SearchBarView
        query={this.props.query}
        placeholder={this.props.placeholder}
        autoFocus={this.props.autoFocus}
        realTimeSearch={this.props.realTimeSearch}
        handleQueryChange={this.handleQueryChange}
        handleSearch={this.handleSearch}
        handleClear={this.handleClear}
        handleKeyPress={this.handleKeyPress}
      />
    );
  }
}

SearchBar.propTypes = {
  autoFocus: PropTypes.bool,
  onQueryChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  query: PropTypes.string.isRequired,
  realTimeSearch: PropTypes.bool,
};

SearchBar.defaultProps = {
  autoFocus: false,
  placeholder: 'Search...',
  realTimeSearch: false,
};


export default SearchBar;
