import React from 'react';
import { SvgIcon } from '@material-ui/core';


const BackArrowIcon = () => (
  <SvgIcon viewBox="0 0 512 512">
    <path
      d="M491.318,235.318H20.682C9.26,235.318,0,244.578,0,256c0,11.423,9.26,20.682,20.682,20.682h470.636
c11.423,0,20.682-9.259,20.682-20.682C512,244.578,502.741,235.318,491.318,235.318z"
    />
    <path
      d="M49.932,256L211.795,94.136c8.077-8.077,8.077-21.172,0-29.249c-8.077-8.076-21.172-8.076-29.249,0L6.058,241.375
c-8.077,8.077-8.077,21.172,0,29.249l176.488,176.488c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.625-6.058
c8.077-8.077,8.077-21.172,0-29.249L49.932,256z"
    />
  </SvgIcon>
);

export default BackArrowIcon;
