import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import * as stateActions from 'app/redux/state/actions';
import * as stateSelectors from 'app/redux/state/selectors';
import * as API from 'service/api';
import { getNamespace } from 'service/utility/urls';


class Region extends React.Component {
  componentDidMount() {
    this.loadRegion();
  }

  loadRegion = async () => {
    const { setRegion } = this.props;

    try {
      console.log('Region: getting regions');
      const { data } = await API.getRegion(getNamespace());

      console.log('Region: received regions; response: ', data);

      setRegion(data.DEFAULT_REGION);
    } catch (error) {
      console.log('Region: getRegion error: ', error);
    }
  };

  render() {
    const { region, children } = this.props;

    if (!region) return null;

    return children;
  }
}

Region.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.array,
  ]),
  region: PropTypes.string,
  setRegion: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  region: stateSelectors.region(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRegion: (region) => dispatch(stateActions.setRegion(region)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Region);
