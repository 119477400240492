import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import * as stateSelectors from 'app/redux/state/selectors';
import { dialogActions, scanningActions } from 'app/redux/ui/actions';
import * as uiSelectors from 'app/redux/ui/selectors';
import { QRScanner } from 'common/barcodes';
import { BARCODE_MIN_LENGHT } from 'config/barcodes';
import * as API from 'service/api';
import { processBarcodeScan } from 'service/utility/barcode';


const QRCodeReader = (props) => {
  const handleError = (input, error) => {
    console.log(input, error);
  };

  const handleByType = async (type, UUID) => {
    switch (type) {
      case 'ticket':
        props.showEventCheckinDialog(UUID);
        break;
      case 'customer':
        await API.quickCheckinCustomerToGA(props.currentLocation.id, UUID);
        break;
      default:
    }
  };

  const handleScan = (data) => {
    console.log('QRCodeReader: scanned the following:');
    console.log(data);

    const processed = processBarcodeScan(data);

    if (processed) {
      const { type, UUID } = processed;

      handleByType(type, UUID);
    }
  };


  return (
    <QRScanner
      isQrScannerOn={props.isQrScannerOn}
      minLength={BARCODE_MIN_LENGHT}
      onError={handleError}
      onScan={handleScan}
      onToggleQrScanner={props.toggleQrScanner}
    />
  );
};

QRCodeReader.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  isQrScannerOn: PropTypes.bool.isRequired,
  showEventCheckinDialog: PropTypes.func.isRequired,
  toggleQrScanner: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  currentLocation: stateSelectors.currentLocation(state),
  isQrScannerOn: uiSelectors.isQrScannerOn(state),
  rightDrawer: uiSelectors.rightDrawer(state),
});

const mapDispatchToProps = (dispatch) => ({
  showEventCheckinDialog: (ticketUUID) => dispatch(dialogActions.showEventCheckinDialog(ticketUUID)),
  toggleQrScanner: () => dispatch(scanningActions.toggleQrScanner()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeReader);
