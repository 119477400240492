import { combineReducers } from 'redux';

import activeUsers from './activeUsers';
import currentUser from './currentUser';
import currentStaff from './currentStaff';
import lockScreen from './lockScreen';


const authReducer = combineReducers({
  currentStaff,
  currentUser,
  activeUsers,
  lockScreen,
});

export default authReducer;
