import React from 'react';
import { PropTypes } from 'prop-types';

import { ORDER_STATUSES } from 'constants/orders';
import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


const ORDER_STATUS_PICKLIST_VALUES = [
  {
    label: 'All',
    value: 0,
  },
  ...ORDER_STATUSES.map((orderStatus) => ({
    label: orderStatus.name,
    value: orderStatus.value,
  })),
];


class OrderStatusFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      orderStatus: (
        this.props.hasOwnProperty('status') && this.props.status !== 'null'
          ? this.props.status
          : 0
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['orderStatus'])) {
      this.handleValueChange();
    }
  }

  handleOrderStatusChange = (update) => {
    if (!update.hasOwnProperty('value')) return;

    this.setState({
      orderStatus: update.value,
    });
  };

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  getOwnFilterMap = () => ({
    status: this.state.orderStatus === 0 ? null : this.state.orderStatus,
  });

  render() {
    const { orderStatus } = this.state;

    return (
      <PicklistField
        label="Status"
        value={orderStatus}
        onChange={this.handleOrderStatusChange}
        picklistValues={ORDER_STATUS_PICKLIST_VALUES}
      />
    );
  }
}

OrderStatusFilter.propTypes = {
  onValueChange: PropTypes.func,
  status: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

OrderStatusFilter.ownName = 'OrderStatusFilter';
OrderStatusFilter.ownFilters = ['status'];


export default OrderStatusFilter;
