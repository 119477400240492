import { createReducer } from 'service/utility/redux';

import { SET_ACTIVE_USERS } from '../actions';


const initialState = {
  keys: [],
  data: {},
};

const activeUsers = createReducer(initialState, {
  [SET_ACTIVE_USERS](state, { keys, data }) {
    return {
      ...state,
      keys,
      data,
    };
  },
});

export default activeUsers;
