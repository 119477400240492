import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import authReducer from 'app/redux/auth/reducers';
import authSagas from 'app/redux/auth/sagas';
import cartReducer from 'app/redux/cart/reducers';
import cartSagas from 'app/redux/cart/sagas';
import serviceWorkerReducer from 'app/redux/serviceWorker/reducers';
import settingsReducer from 'app/redux/settings/reducers';
import { currentLocationReducer, regionReducer } from 'app/redux/state/reducers';
import stateSagas from 'app/redux/state/sagas';
import { paymentStatusReducer, transactionsReducer } from 'app/redux/transactions/reducers';
import transactionSagas from 'app/redux/transactions/sagas';
import {
  addCardDialogReducer,
  alertOnNotesDialogReducer,
  checkinDialogReducer,
  confirmActionDialogReducer,
  copyToClipboardDialogReducer,
  createAdHocChargeDialogReducer,
  customerDialogReducer,
  eventCheckinDialogReducer,
  leftDrawerReducer,
  newGiftCardDialogReducer,
  qrScannerReducer,
  refundOrderDialogReducer,
  rightDrawerReducer,
} from 'app/redux/ui/reducers';
import uiSagas from 'app/redux/ui/sagas';
import { loadState } from 'service/storage';


const getStore = () => {
  const appReducer = combineReducers({
    alertOnNotesDialog: alertOnNotesDialogReducer,
    currentLocation: currentLocationReducer,
    customerDialog: customerDialogReducer,
    qrScanner: qrScannerReducer,
    region: regionReducer,
    serviceWorker: serviceWorkerReducer,
  });
  const layoutReducer = combineReducers({
    leftDrawer: leftDrawerReducer,
    rightDrawer: rightDrawerReducer,
  });

  const rootReducer = combineReducers({
    addCardDialog: addCardDialogReducer,
    app: appReducer,
    auth: authReducer,
    carts: cartReducer,
    confirmActionDialog: confirmActionDialogReducer,
    copyToClipboardDialog: copyToClipboardDialogReducer,
    checkinDialog: checkinDialogReducer,
    createAdHocChargeDialog: createAdHocChargeDialogReducer,
    eventCheckinDialog: eventCheckinDialogReducer,
    layout: layoutReducer,
    newGiftCardDialog: newGiftCardDialogReducer,
    paymentStatus: paymentStatusReducer,
    refundOrderDialog: refundOrderDialogReducer,
    settings: settingsReducer,
    transactions: transactionsReducer,
  });

  const sagaMiddleware = createSagaMiddleware();
  const localState = loadState();
  const enhancers = compose(
    applyMiddleware(
      sagaMiddleware,
      thunk,
    ),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  );

  const store = createStore(
    rootReducer,
    localState,
    enhancers,
  );

  const sagasList = [
    ...authSagas,
    ...cartSagas,
    ...stateSagas,
    ...transactionSagas,
    ...uiSagas,
  ];

  sagasList.forEach(sagaMiddleware.run.bind(sagaMiddleware));

  return store;
};

const AppStore = getStore();

export default AppStore;
