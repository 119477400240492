import timezones from 'google-timezones-json';


const timezonePicklistValues = Object.keys(timezones).map((timezoneId) => ({
  label: timezones[timezoneId],
  value: timezoneId,
}));

export const TIMEZONE_MAP = timezones;
export const TIMEZONE_PICKLIST_VALUES = timezonePicklistValues;
