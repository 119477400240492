import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { onSnapshot as fbFsOnSnapshot, setDoc as fbFsSetDoc } from 'firebase/firestore';

import * as authSelectors from 'app/redux/auth/selectors';
import * as cartActions from 'app/redux/cart/actions';
import * as cartSelectors from 'app/redux/cart/selectors';
import * as stateSelectors from 'app/redux/state/selectors';
import * as transactionSelectors from 'app/redux/transactions/selectors';
import { dialogActions } from 'app/redux/ui/actions';
import { FLAT_DISCOUNT, PERCENT_DISCOUNT } from 'constants/discounts';
import firebaseService from 'service/firebase';
import { CartModel } from 'service/models';
import { commaSpaceJoin } from 'service/utility';
import { getDiscountPercentageProp, getDiscountFlatProp } from 'service/utility/pricing';
import * as timeUtils from 'service/utility/timeUtils';

import AssignCustomerDialog from './AssignCustomerDialog';
import CartItems from './CartItems';
import Header from './Header';


class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAssignCustomerDialog: false,
    };
  }

  componentDidMount() {
    this.cartsLastUpdatedDT = null;

    this.subscribe();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentLocation.id !== prevProps.currentLocation.id) {
      this.subscribe();
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  getActiveCart = () => this.props.activeCart;

  getCurrentStaff = () => this.props.currentStaff;

  getCartOrOrder = () => this.props.order || this.props.activeCart;

  getCartModel = () => new CartModel(this.getCartOrOrder());

  subscribe = () => {
    const { currentLocation, confirmAction, order, createNewCart } = this.props;

    this.unsubscribe();
    if (order) return;

    const docRef = firebaseService.getLocationFsDoc(currentLocation.id);
    this._unsubscribe = fbFsOnSnapshot(
      docRef,
      (doc) => {
        if (!doc.exists()) {
          fbFsSetDoc(docRef, { cartsLastUpdatedDT: timeUtils.iso8601NowInUTC() }, { merge: true });
          return;
        }

        const fsData = doc.data();
        const { cartsLastUpdatedDT, stolenCartId, stolenCartStaffId } = fsData;

        if (cartsLastUpdatedDT !== this.cartsLastUpdatedDT) {
          console.log(commaSpaceJoin([
            `cartsLastUpdatedDT=${cartsLastUpdatedDT}`,
            `stolenCartId=${stolenCartId}`,
            `stolenCartStaffId=${stolenCartStaffId}`,
          ]));
          const activeCart = this.getActiveCart();
          const currentStaff = this.getCurrentStaff();

          if (
            activeCart && activeCart.UUID &&
            activeCart.UUID === stolenCartId &&
            currentStaff.id !== stolenCartStaffId
          ) {
            // My cart was just stolen!
            confirmAction(
              createNewCart,
              {
                hideCancel: true,
                title: 'A Staff Member took over your current cart.',
                message: 'A new empty one will be created.',
              },
            );
          }

          this.cartsLastUpdatedDT = cartsLastUpdatedDT;
        }
      }
    );
  };

  unsubscribe = () => {
    this.cartsLastUpdatedDT = null;

    if (this._unsubscribe) {
      this._unsubscribe();
      this._unsubscribe = null;
    }
  };

  handleQuantityUpdate = (itemId, quantity) => {
    this.props.updateCartItemQuantity(itemId, quantity);
  };

  handleItemRemove = (itemId) => {
    this.props.removeItemFromCart(itemId);
  };

  handleCartDiscountPut = (payload) => {
    const ActiveCart = this.getCartModel();
    const cartLocation = ActiveCart.getLocation();
    const discount = {};

    if (payload.hasOwnProperty(PERCENT_DISCOUNT)) {
      discount[getDiscountPercentageProp(cartLocation)] = payload[PERCENT_DISCOUNT];
    } else {
      discount[getDiscountFlatProp(cartLocation)] = payload[FLAT_DISCOUNT];
    }

    this.props.putCartDiscount(discount);
  };

  openAssignCustomerDialog = () => {
    this.setState({ showAssignCustomerDialog: true });
  };

  closeAssignCustomerDialog = () => {
    this.setState({ showAssignCustomerDialog: false });
  };

  render() {
    const { checkout, isCartLoading, isOrderLoading, currentLocation } = this.props;
    const { showAssignCustomerDialog } = this.state;

    const ActiveCart = this.getCartModel();
    const orderExists = ActiveCart.hasOrder();
    const cartOrOrder = this.getCartOrOrder();
    const cartItems = ActiveCart.getItems();
    const cartDiscountType = ActiveCart.getDiscountType();
    const cartDiscountAmount = ActiveCart.getDiscountAmount();
    const cartDiscountValue = ActiveCart.getDiscountValue();
    const hasPromoCode = ActiveCart.hasPromoCode();

    return (
      <div className="cart">
        <Header
          orderExists={orderExists}
          cartOrOrder={cartOrOrder}
          checkout={checkout}
          onAssignCustomer={this.openAssignCustomerDialog}
        />
        <CartItems
          hasPromoCode={hasPromoCode}
          cartDiscountAmount={cartDiscountAmount}
          cartDiscountType={cartDiscountType}
          cartDiscountValue={cartDiscountValue}
          cartItems={cartItems}
          loading={isCartLoading || isOrderLoading}
          onCartDiscountPut={this.handleCartDiscountPut}
          onItemRemove={this.handleItemRemove}
          onQuantityUpdate={this.handleQuantityUpdate}
          orderExists={orderExists}
          currentLocation={currentLocation}
        />
        {showAssignCustomerDialog && (
          <AssignCustomerDialog
            open
            onClose={this.closeAssignCustomerDialog}
          />
        )}
      </div>
    );
  }
}

Cart.propTypes = {
  activeCart: PropTypes.object,
  checkout: PropTypes.bool,
  confirmAction: PropTypes.func.isRequired,
  createNewCart: PropTypes.func.isRequired,
  currentLocation: PropTypes.object.isRequired,
  currentStaff: PropTypes.object.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  isOrderLoading: PropTypes.bool,
  order: PropTypes.object,
  putCartDiscount: PropTypes.func.isRequired,
  removeItemFromCart: PropTypes.func.isRequired,
  updateCartItemQuantity: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  activeCart: cartSelectors.activeCartSelector(state),
  isCartLoading: cartSelectors.isCartLoading(state),
  currentLocation: stateSelectors.currentLocation(state),
  currentStaff: authSelectors.currentStaff(state),
  paymentStatus: transactionSelectors.paymentStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  confirmAction: (callback, options) => dispatch(dialogActions.confirmAction(callback, options)),
  createNewCart: () => dispatch(cartActions.createNewCart()),
  putCartDiscount: (discount) => dispatch(cartActions.putCartDiscount(discount)),
  removeItemFromCart: (itemId) => dispatch(cartActions.removeItemFromCart(itemId)),
  updateCartItemQuantity: (itemId, qty) => dispatch(cartActions.updateCartItemQuantity(itemId, qty)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));
