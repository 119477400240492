import React from 'react';
import { PropTypes } from 'prop-types';
import { Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent, Button } from '@material-ui/core';


const ConfirmActionDialog = ({
  isOpen, title, message, cancelLabel, confirmLabel, hideConfirm, hideCancel,
  actionConfirmed, actionCancelled,
}) => (
  <Dialog
    open={isOpen}
    aria-labelledby="confirm"
    onClose={actionCancelled}
  >
    <DialogTitle>
      {title || 'Are you Sure?'}
    </DialogTitle>
    {message && (
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
    )}
    <DialogActions>
      {!hideCancel && (
        <Button onClick={actionCancelled}>
          {cancelLabel || 'Cancel'}
        </Button>
      )}
      {!hideConfirm && (
        <Button
          color="primary"
          onClick={actionConfirmed}
        >
          {confirmLabel || 'OK'}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

ConfirmActionDialog.propTypes = {
  actionCancelled: PropTypes.func.isRequired,
  actionConfirmed: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  hideCancel: PropTypes.bool,
  hideConfirm: PropTypes.bool,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
};


export default ConfirmActionDialog;
