import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Card, CardContent, CardActions, TextField, Button,
  ListItem, ListItemAvatar, Avatar, ListItemText, Typography,
} from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonIcon from '@material-ui/icons/Person';


const LockScreenDialogView = ({
  currentUser, errorMessage, isLocked, logOutUserFromLock, password, handlePasswordChange, handleSubmit,
}) => (
  <Dialog
    fullScreen
    open={isLocked}
    classes={{ root: 'lock-screen', paper: 'lock-screen-paper' }}
  >
    <Card className="lock-screen-card">
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={currentUser.currentUserEmail} />
      </ListItem>
      <CardContent className="lock-screen-card-content">
        <TextField
          label="Password"
          name="password"
          type="password"
          autoComplete=""
          autoFocus
          onChange={(e) => handlePasswordChange(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') handleSubmit();
          }}
          value={password}
          error={Boolean(errorMessage && errorMessage !== 'Logging in...')}
          helperText={errorMessage}
          className="password-field"
        />
        <Button
          className="unlock-button"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          <LockOpenIcon className="unlock-button-icon" />
          {'Unlock'}
        </Button>
      </CardContent>
      <CardActions className="lock-screen-card-actions">
        <Typography
          className="logout-link pseudo-link"
          onClick={logOutUserFromLock}
          align="center"
        >
          {`Not you? Sign out ${currentUser.currentUserEmail}`}
        </Typography>
      </CardActions>
    </Card>
  </Dialog>
);

LockScreenDialogView.propTypes = {
  currentUser: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  handlePasswordChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLocked: PropTypes.bool.isRequired,
  logOutUserFromLock: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
};


export default LockScreenDialogView;
