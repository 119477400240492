import { take, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';

import { REMOVE_ITEM_FROM_CART, setCartLoading } from '../actions';
import { activeCartIdSelector } from '../selectors';
import { loadCart } from './cartSagas';


function *removeItemFromCartSaga() {
  while (true) {
    const { itemId } = yield take(REMOVE_ITEM_FROM_CART);

    const cartId = yield select(activeCartIdSelector);
    if (!cartId) return null;

    console.log(`Removing the item with UUID = ${itemId} from the cart`);
    const toast1 = toast('Removing from cart...');

    try {
      yield put(setCartLoading(true));
      yield call(API.removeCartItem, cartId, itemId);
      yield call(loadCart, cartId);

      toast.dismiss(toast1);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('removeItemFromCartSaga error: ');
      console.log(errorMessage);

      toast.dismiss(toast1);
      toast.error(errorMessage);
    } finally {
      yield put(setCartLoading(false));
    }
  }
}


export default removeItemFromCartSaga;
