import React from 'react';
import { PropTypes } from 'prop-types';
import { IconButton } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';


const DeepLinkButton = ({ disabled, onClick }) => (
  <IconButton color="primary" onClick={onClick} disabled={disabled}>
    <LanguageIcon />
  </IconButton>
);

DeepLinkButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};


export default DeepLinkButton;
