export const ITEM_TYPES_REQUIRING_CUSTOMER = [
  'PASS', 'MEMBERSHIP', 'EVENT_BOOKING_TICKET', 'EVENT_SERIES_TICKET', 'EVENT_BOOKING_INVOICE',
];

export const CANCELLABLE_ITEM_TYPES = [
  'PASS', 'MEMBERSHIP', 'EVENT_BOOKING_TICKET', 'EVENT_SERIES_TICKET', 'GIFT_CARD', 'PRODUCT',
];

export const ASSIGNABLE_ITEM_TYPES = [
  'PASS', 'MEMBERSHIP', 'PASS_OR_MEMBERSHIP', 'EVENT_BOOKING_TICKET', 'EVENT_SERIES_TICKET',
];

export const CHECKIN_AFTER_PURCHASE_ITEM_TYPES = [
  'PASS', 'MEMBERSHIP',
];

export const PROMO_CODE_ITEM_TYPES = [
  'PASS', 'MEMBERSHIP', 'PRODUCT', 'EVENT_BOOKING_TICKET', 'PRICING_TIER',
];
