import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';

import { List, ListItem, ListItemText, ListItemIcon, Collapse } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


class NestedNavItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.props.item.subNav.some(
        (path) => this.props.location.pathname.indexOf(path.linkTo) === 0
      ),
    };
  }

  // eslint-disable-next-line react/display-name
  renderLink = React.forwardRef((itemProps, ref) => {
    const { className, ...rest } = itemProps;

    return (
      <NavLink
        exact
        className={`${className} nav-item`}
        activeClassName="active-nav-item"
        {...rest}
        innerRef={ref}
      />
    );
  });

  handleClick = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { onItemClick, item: { label, icon: Icon, subNav } } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <ListItem
          button
          classes={{ root: classnames('nav-item', { 'nav-item-open': isOpen }) }}
          onClick={this.handleClick}
        >
          {Icon && (
            <ListItemIcon classes={{ root: 'nav-icon' }}>
              <Icon />
            </ListItemIcon>
          )}
          <ListItemText
            primary={label || null}
            classes={{ root: 'nav-text-wrapper', primary: 'nav-text' }}
          />
          {isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </ListItem>
        <Collapse
          in={isOpen}
          timeout="auto"
          unmountOnExit
        >
          {subNav.length > 0 && (
            <List
              dense
              component="div"
              disablePadding
            >
              {subNav.map((item) => (
                <ListItem
                  key={`NI_${item.label}`}
                  button
                  onClick={onItemClick}
                  to={item.linkTo}
                  component={this.renderLink}
                >
                  <ListItemText
                    inset
                    primary={item.label}
                    classes={{ root: 'nav-text-wrapper-inset', primary: 'nav-text' }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Collapse>
      </>
    );
  }
}

NestedNavItem.propTypes = {
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired,
};


export default withRouter(NestedNavItem);
