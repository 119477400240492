import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';


class NotesButton extends React.Component {
  handleClick = () => {
    this.props.onClick(this.props.customer.id);
  };

  render() {
    const { notes } = this.props;

    if (!notes.length) return null;

    const danger = notes.some((note) => note.priority === 3);

    return (
      <div
        className={classnames('pseudo-link notes-link cml-1 mr-1', { danger })}
        onClick={this.handleClick}
      >
        <span>
          {'NOTES'}
        </span>
        {danger && (
          <InfoOutlinedIcon fontSize="small" />
        )}
      </div>
    );
  }
}

NotesButton.propTypes = {
  customer: PropTypes.object.isRequired,
  notes: PropTypes.array,
  onClick: PropTypes.func.isRequired,
};

NotesButton.defaultProps = {
  notes: [],
};


export default NotesButton;
