import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  MenuList, MenuItem, Button, Avatar, Popper, Grow, Paper,
  ClickAwayListener, ListItemText, ListItemIcon,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import * as authActions from 'app/redux/auth/actions';
import * as authSelectors from 'app/redux/auth/selectors';
import { dialogActions } from 'app/redux/ui/actions';
import { getRoutePath } from 'service/navigation';
import { formatName, formatInitials } from 'service/utility/stringFormatters';


class UserIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
    };
  }

  handleToggle = () => {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen,
    }));
  };

  handleCloseMenu = (event) => {
    if (event && event.target && this.anchorEl.contains(event.target)) return;

    this.setState({ isMenuOpen: false });
  };

  logOutUser = () => {
    this.handleCloseMenu();
    this.props.logOutUser();
  };

  lockScreen = () => {
    this.handleCloseMenu();
    this.props.lockScreen();
  };

  logOutAndResetCache = () => {
    this.handleCloseMenu();

    this.props.confirmAction(
      this.props.logOutAndResetCache,
      {
        title: 'Warning: This will reset the application cache.',
        confirmLabel: 'Yes',
        message: 'Are you sure you want to log out and proceed?',
      },
    );
  };

  render() {
    const { currentUser, currentStaff } = this.props;
    const { isMenuOpen } = this.state;

    return (
      <div className="user-icon">
        <Button
          ref={(node) => { this.anchorEl = node; }}
          onClick={this.handleToggle}
          color="inherit"
          classes={{ root: classnames('user-icon-button', { pushed: isMenuOpen }) }}
        >
          <Avatar
            src={currentUser.imageURL}
            classes={{ root: 'user-icon-avatar' }}
          />
          <span className="user-icon-button-label">
            {formatInitials(currentStaff)}
          </span>
        </Button>
        <Popper
          className="user-icon-popper"
          open={isMenuOpen}
          anchorEl={this.anchorEl}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper
                square
                classes={{ root: 'menu-paper' }}
              >
                <ClickAwayListener onClickAway={this.handleCloseMenu}>
                  <MenuList classes={{ root: 'menu-list' }}>
                    <MenuItem
                      classes={{ root: 'menu-item' }}
                      divider
                      button={false}
                    >
                      <ListItemText
                        primary={formatName(currentStaff)}
                        secondary={currentUser.currentUserEmail}
                        classes={{
                          primary: 'menu-item-primary',
                          secondary: 'menu-item-secondary',
                        }}
                      />
                    </MenuItem>
                    <MenuItem
                      classes={{ root: 'menu-item' }}
                      button
                      divider
                      component={Link}
                      to={getRoutePath('PROFILE')}
                    >
                      <ListItemIcon className="menu-item-icon">
                        <PersonOutlineIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Profile & Settings"
                        classes={{ primary: 'menu-item-text' }}
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={this.logOutUser}
                      classes={{ root: 'menu-item' }}
                      divider
                    >
                      <ListItemIcon className="menu-item-icon">
                        <ExitToAppIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Log Out"
                        classes={{ primary: 'menu-item-text' }}
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={this.lockScreen}
                      classes={{ root: 'menu-item lock-item' }}
                    >
                      <ListItemText
                        primary="Lock Screen (Ctrl + Shift + L)"
                        classes={{ primary: 'menu-item-text' }}
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={this.logOutAndResetCache}
                      classes={{ root: 'menu-item reset-item' }}
                      divider
                    >
                      <ListItemIcon className="menu-item-icon">
                        <RotateLeftIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Log Out and Reset Cache"
                        classes={{ primary: 'menu-item-text' }}
                      />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

UserIcon.propTypes = {
  confirmAction: PropTypes.func.isRequired,
  currentStaff: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  lockScreen: PropTypes.func.isRequired,
  logOutAndResetCache: PropTypes.func.isRequired,
  logOutUser: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  currentUser: authSelectors.currentUser(state),
  currentStaff: authSelectors.currentStaff(state),
});

const mapDispatchToProps = (dispatch) => ({
  confirmAction: (callback, options) => dispatch(
    dialogActions.confirmAction(callback, options)
  ),
  lockScreen: () => dispatch(authActions.lockScreen()),
  logOutAndResetCache: () => dispatch(authActions.logOutAndResetCache()),
  logOutUser: () => dispatch(authActions.logOutUser()),
});

const UserIcon2 = connect(mapStateToProps, mapDispatchToProps)(UserIcon);


export default UserIcon2;
