import React from 'react';
import { PropTypes } from 'prop-types';
import { isEqual } from 'lodash';
import { TableBody } from '@material-ui/core';

import DataTableRow from './DataTableRow';


class DataTableBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({
        expanded: [],
      });
    }
  }

  handleRowClick = (item) => {
    const {
      expansionPanel, rowIsExpandable, accordion, onItemClick, rowIdField,
    } = this.props;

    const key = item[rowIdField];
    const rowHasExpansionPanel = Boolean(expansionPanel) && rowIsExpandable(item);

    if (onItemClick) {
      onItemClick(item);
    }

    if (rowHasExpansionPanel) {
      this.setState((prevState) => {
        const { expanded } = prevState;
        const newExpanded = (
          accordion
            ? expanded.includes(key)
              ? []
              : [key]
            : expanded.includes(key)
              ? expanded.filter((e) => e !== key)
              : expanded.concat([key])
        );

        return {
          expanded: newExpanded,
        };
      });
    }
  };

  getDataTableRow = (item) => {
    const {
      fieldsConfig, expansionPanel, rowIsExpandable, onItemClick,
      selectable, selectedItemIds, onItemToggle, rowIdField,
    } = this.props;

    const itemId = item[rowIdField];
    const isExpanded = this.state.expanded.includes(itemId);
    const rowHasExpansionPanel = Boolean(expansionPanel) && rowIsExpandable(item);

    return (
      <DataTableRow
        key={itemId}
        data={item}
        onClick={this.handleRowClick}
        showPointer={Boolean(onItemClick) || rowHasExpansionPanel}
        selectable={selectable}
        selected={selectedItemIds.includes(itemId)}
        onItemToggle={onItemToggle}
        fieldsConfig={fieldsConfig}
        expansionPanel={expansionPanel}
        hasExpansionPanel={rowHasExpansionPanel}
        isExpanded={isExpanded}
      />
    );
  };

  render() {
    return (
      <TableBody className="data-table-body">
        {this.props.data.map(this.getDataTableRow)}
      </TableBody>
    );
  }
}

DataTableBody.propTypes = {
  accordion: PropTypes.bool,
  data: PropTypes.array,
  expansionPanel: PropTypes.func,
  fieldsConfig: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  onItemToggle: PropTypes.func.isRequired,
  rowIdField: PropTypes.string.isRequired,
  rowIsExpandable: PropTypes.func.isRequired,
  selectable: PropTypes.bool.isRequired,
  selectedItemIds: PropTypes.array,
};


export default DataTableBody;
