import { FLAT_DISCOUNT, PERCENT_DISCOUNT } from 'constants/discounts';

import {
  getLocationLocale, getLocationCurrencyCode,
  locationUsesPriceBeforeTax, getLocationCurrencyDecimalPlaces,
} from '../i18n';
import { op, isNullish } from '.';
import { roundTo2Decimals, formatPercent } from './numbers';


export const formatPriceWithoutTax = (value, location, options) => {
  if (isNullish(value)) return '--';

  const number = Number(value);
  const locale = getLocationLocale(location);
  const currencyCode = options?.currency ?? getLocationCurrencyCode(location);
  const currencyDecimalPlaces = options?.decimals ?? getLocationCurrencyDecimalPlaces(location);

  return new Intl.NumberFormat(
    locale,
    { style: 'currency', currency: currencyCode, minimumFractionDigits: currencyDecimalPlaces }
  ).format(number);
};

export const getCumulativeTaxRate = (item, location) => {
  const isTaxExempt = op(item, 'isTaxExempt', false);

  if (isTaxExempt) return 0;

  const locationLocaleTaxRate = op(location, 'localeTax', 0);
  const itemLocaleTaxRate = op(item, 'localeTaxRate', locationLocaleTaxRate);
  const locationStateTaxRate = op(location, 'stateTax', 0);
  const itemStateTaxRate = op(item, 'stateTaxRate', locationStateTaxRate);
  const locationCityTaxRate = op(location, 'cityTax', 0);
  const itemCityTaxRate = op(item, 'cityTaxRate', locationCityTaxRate);
  const locationAdditionalTaxRate = op(location, 'additionalTax', 0);
  const itemAdditionalTaxRate = op(item, 'additionalTaxRate', locationAdditionalTaxRate);

  return (
    Number(itemLocaleTaxRate) +
    Number(itemStateTaxRate) +
    Number(itemCityTaxRate) +
    Number(itemAdditionalTaxRate)
  );
};

export const calculateTax = (value, taxRate) => {
  if (value === null || typeof value === 'undefined') return null;

  const tax = roundTo2Decimals(Number(value) * taxRate / 100);

  return tax;
};

export const calculateValueWithoutTax = (valueWithTax, taxRate) => {
  if (valueWithTax === null || typeof valueWithTax === 'undefined') return null;

  const value = roundTo2Decimals(Number(valueWithTax) * 100 / (100 + taxRate));

  return value;
};

export const calculateValueWithTax = (value, taxRate) => {
  if (value === null || typeof value === 'undefined') return null;

  const valueWithTax = roundTo2Decimals(Number(value) * (100 + taxRate) / 100);

  return valueWithTax;
};

export const calculatePriceWithoutTax = (valueWithTax, item, location) => {
  if (isNullish(valueWithTax)) return null;

  const taxRate = getCumulativeTaxRate(item, location);
  const value = calculateValueWithoutTax(valueWithTax, taxRate);

  return value;
};

export const calculatePriceWithTax = (value, item, location) => {
  if (isNullish(value)) return null;

  const taxRate = getCumulativeTaxRate(item, location);
  const valueWithTax = calculateValueWithTax(value, taxRate);

  return valueWithTax;
};

export const formatTax = (value, taxRate, location) => {
  const tax = calculateTax(value, taxRate);

  return formatPriceWithoutTax(tax, location);
};

export const formatValueWithTax = (value, taxRate, location) => {
  const valueWithTax = calculateValueWithTax(value, taxRate);

  return formatPriceWithoutTax(valueWithTax, location);
};

export const formatPriceWithTax = (value, item, location) => {
  const valueWithTax = calculatePriceWithTax(value, item, location);

  return formatPriceWithoutTax(valueWithTax, location);
};

export const formatPrice = (value, item, location) => {
  if (isNullish(value)) return '--';

  return (
    locationUsesPriceBeforeTax(location)
      ? formatPriceWithoutTax(value, location)
      : formatPriceWithTax(value, item, location)
  );
};

export const getItemPrice = (item, location) => {
  if (locationUsesPriceBeforeTax(location)) {
    return item.price;
  }

  if (item.hasOwnProperty('taxInclusivePrice') && item.taxInclusivePrice !== null) {
    return item.taxInclusivePrice;
  }

  return calculatePriceWithTax(item.price, item, location);
};

export const formatItemPrice = (item, location) => {
  const itemPrice = getItemPrice(item, location);

  return formatPriceWithoutTax(itemPrice, location);
};

export const getPriceProp = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'price'
    : 'taxInclusivePrice'
);

export const getPriceLabel = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'Price'
    : 'Price with Tax'
);

export const getDiscountPercentageProp = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'discountPercentage'
    : 'taxInclusiveDiscountPercentage'
);

export const getDiscountFlatProp = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'discountFlat'
    : 'taxInclusiveDiscountFlat'
);

export const getDiscountAmountProp = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'discountAmount'
    : 'taxInclusiveDiscountAmount'
);

export const getSubtotalProp = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'subtotal'
    : 'taxInclusiveSubtotal'
);

export const getTotalDiscountProp = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'totalDiscount'
    : 'taxInclusiveTotalDiscount'
);

export const getAmountProp = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'amount'
    : 'taxInclusiveAmount'
);

export const getNetAmountProp = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'netAmount'
    : 'netTotal'
);

export const getBalanceProp = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'balanceAmount'
    : 'balanceTotal'
);

export const getHoldFeeProp = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'onHoldMonthlyDues'
    : 'taxInclusiveHoldFee'
);

export const getHoldFeeLabel = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'Hold Fee'
    : 'Tax Inclusive Hold Fee'
);

export const getMembershipTypeHoldFee = (membershipType, location) => (
  membershipType[getHoldFeeProp(location)]
);

export const formatMembershipTypeHoldFee = (membershipType, location) => {
  const holdFee = getMembershipTypeHoldFee(membershipType, location);

  return formatPriceWithoutTax(holdFee, location);
};

export const getSignupFeeProp = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'signupFee'
    : 'taxInclusiveSignupFee'
);

export const getSignupFeeLabel = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'Sign Up Fee'
    : 'Tax Inclusive Sign Up Fee'
);

export const getMembershipTypeSignupFee = (membershipType, location) => (
  membershipType[getSignupFeeProp(location)]
);

export const formatMembershipTypeSignupFee = (membershipType, location) => {
  const signupFee = getMembershipTypeSignupFee(membershipType, location);

  return formatPriceWithoutTax(signupFee, location);
};

export const getCancellationFeeProp = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'cancellationFee'
    : 'taxInclusiveCancellationFee'
);

export const getCancellationFeeLabel = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'Cancellation Fee'
    : 'Tax Inclusive Cancellation Fee'
);

export const getMembershipTypeCancellationFee = (membershipType, location) => (
  membershipType[getCancellationFeeProp(location)]
);

export const formatMembershipTypeCancellationFee = (membershipType, location) => {
  const cancellationFee = getMembershipTypeCancellationFee(membershipType, location);

  return formatPriceWithoutTax(cancellationFee, location);
};

export const getPBTMembershipDiscountText = (membership, location) => {
  if (membership.discountFlat) {
    return formatPriceWithoutTax(membership.discountFlat, location);
  }

  if (membership.discountPercentage) {
    return `${membership.discountPercentage}%`;
  }

  return 'None';
};

export const getMembershipDiscountText = (membership, location) => {
  if (locationUsesPriceBeforeTax(location)) {
    return getPBTMembershipDiscountText(membership, location);
  }

  if (membership.taxInclusiveDiscountFlat) {
    return formatPriceWithoutTax(membership.taxInclusiveDiscountFlat, location);
  }

  if (membership.taxInclusiveDiscountPercentage) {
    return `${membership.taxInclusiveDiscountPercentage}%`;
  }

  return getPBTMembershipDiscountText(membership, location);
};

export const getPBTMembershipDiscountType = (membership) => {
  if (membership.discountFlat) {
    return 'flat';
  }

  if (membership.discountPercentage) {
    return 'percent';
  }

  return null;
};

export const getMembershipDiscountType = (membership, location) => {
  if (locationUsesPriceBeforeTax(location)) {
    return getPBTMembershipDiscountType(membership);
  }

  if (membership.taxInclusiveDiscountFlat) {
    return 'flat';
  }

  if (membership.taxInclusiveDiscountPercentage) {
    return 'percent';
  }

  return getPBTMembershipDiscountType(membership);
};


export const getCartDiscount = (item) => (
  op(item, 'discountFlat')
    ? item.discountFlat
    : op(item, 'discountPercentage')
      ? item.discountPercentage
      : null
);

export const getCartDiscountType = (item) => (
  op(item, 'discountFlat')
    ? FLAT_DISCOUNT
    : op(item, 'discountPercentage')
      ? PERCENT_DISCOUNT
      : null
);

export const getItemDiscountType = (item, location) => {
  const discountFlatProp = getDiscountFlatProp(location);
  const discountPercentageProp = getDiscountPercentageProp(location);

  return (
    op(item, discountFlatProp)
      ? FLAT_DISCOUNT
      : op(item, discountPercentageProp)
        ? PERCENT_DISCOUNT
        : null
  );
};

export const formatItemDiscount = (item, location) => {
  const discountFlatProp = getDiscountFlatProp(location);
  const discountPercentageProp = getDiscountPercentageProp(location);

  return (
    op(item, discountFlatProp)
      ? formatPriceWithoutTax(item[discountFlatProp])
      : op(item, discountPercentageProp)
        ? formatPercent(item[discountPercentageProp])
        : null
  );
};

export const getProductVariantPrice = (productVariant, product, location) => {
  if (locationUsesPriceBeforeTax(location)) {
    return productVariant.price;
  }

  if (productVariant.hasOwnProperty('taxInclusivePrice') && productVariant.taxInclusivePrice !== null) {
    return productVariant.taxInclusivePrice;
  }

  return calculatePriceWithTax(productVariant.price, product, location);
};

export const formatProductVariantPrice = (productVariant, product, location) => {
  const productVariantPrice = getProductVariantPrice(productVariant, product, location);

  return formatPriceWithoutTax(productVariantPrice, location);
};

export const getItemPriceRaw = (item, location) => {
  if (locationUsesPriceBeforeTax(location)) {
    return item.price;
  }

  if (item.hasOwnProperty('taxInclusivePrice') && item.taxInclusivePrice !== null) {
    return item.taxInclusivePrice;
  }

  return item.price;
};

export const getItemDiscountPercentageProp = (item, itemType, location) => {
  if (locationUsesPriceBeforeTax(location)) {
    return 'discountPercentage';
  }

  if (itemType.hasOwnProperty('taxInclusivePrice') && itemType.taxInclusivePrice !== null) {
    return 'taxInclusiveDiscountPercentage';
  }

  return 'discountPercentage';
};

export const getItemDiscountFlatProp = (item, itemType, location) => {
  if (locationUsesPriceBeforeTax(location)) {
    return 'discountFlat';
  }

  if (itemType.hasOwnProperty('taxInclusivePrice') && itemType.taxInclusivePrice !== null) {
    return 'taxInclusiveDiscountFlat';
  }

  return 'discountFlat';
};

export const getItemDiscountPercentage = (item, itemType, location) => (
  item[getItemDiscountPercentageProp(item, itemType, location)]
);

export const getItemDiscountFlat = (item, itemType, location) => (
  item[getItemDiscountFlatProp(item, itemType, location)]
);

export const getItemSubtotal = (item, itemType, location) => {
  if (locationUsesPriceBeforeTax(location)) {
    return item.subtotal;
  }

  if (itemType.hasOwnProperty('taxInclusivePrice') && itemType.taxInclusivePrice !== null) {
    return item.taxInclusiveSubtotal;
  }

  return item.subtotal;
};

export const getItemTotalDiscount = (item, itemType, location) => {
  if (locationUsesPriceBeforeTax(location)) {
    return item.totalDiscount;
  }

  if (itemType.hasOwnProperty('taxInclusivePrice') && itemType.taxInclusivePrice !== null) {
    return item.taxInclusiveTotalDiscount;
  }

  return item.totalDiscount;
};
