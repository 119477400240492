import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography } from '@material-ui/core';

import InfoTooltip from '../InfoTooltip';


const InfoTooltipLabel = (props) => (
  <div className="d-flex flex-row justify-content-start align-items-center cml-1">
    <Typography display="inline">
      {props.label}
    </Typography>
    <InfoTooltip>
      {props.tooltip}
    </InfoTooltip>
  </div>
);

InfoTooltipLabel.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.any.isRequired,
};


export default InfoTooltipLabel;
