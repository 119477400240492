import { put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';

import { confirmAction } from '../../ui/actions/dialog';
import { setActiveCart, setCartLoading } from '../actions';
import { currentStaff as currentStaffSelector } from '../../auth/selectors';
import { currentLocation as currentLocationSelector } from '../../state/selectors';
import { activeCartSelector } from '../selectors';


export function *createNewEmptyCart() {
  yield put(setActiveCart(null));
}

export function *loadCart(cartId) {
  try {
    const currentLocation = yield select(currentLocationSelector);
    const currentLocationId = currentLocation?.id ?? null;
    if (!currentLocationId) return null;

    const currentStaff = yield select(currentStaffSelector);
    const currentStaffId = currentStaff?.id ?? null;
    if (!currentStaffId) return null;

    const { data: cart } = yield call(API.getCart, cartId);
    console.log(`Retrieved cart with UUID = ${cartId}:`);
    console.log(cart);

    if (cart.locationId === currentLocationId && cart.staffId === currentStaffId) {
      yield put(setActiveCart(cart));
    } else {
      yield put(confirmAction(null, {
        hideConfirm: true,
        cancelLabel: 'Ok',
        title: 'A Staff Member took over your current cart.',
        message: 'A new empty one will be created.',
      }));
      yield call(createNewEmptyCart);
    }

    return cart;
  } catch (error) {
    if (error.response.status === 404) {
      yield call(createNewEmptyCart);
    } else {
      const errorMessage = getErrorMessage(error);

      console.log('API.getCart error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    }

    return null;
  }
}

export function *createCart(customerId) {
  try {
    const currentLocation = yield select(currentLocationSelector);
    const currentLocationId = currentLocation?.id ?? null;
    if (!currentLocationId) return null;

    const currentStaff = yield select(currentStaffSelector);
    const currentStaffId = currentStaff?.id ?? null;
    if (!currentStaffId) return null;

    const payload = {
      locationId: currentLocationId,
      staffId: currentStaffId,
      source: 'INSTORE',
    };

    if (customerId) {
      payload.customerId = customerId;
    }

    console.log(`Creating cart for staff with id=${currentStaffId}${
      customerId ? ` and for customer with id=${customerId}` : ''
    } at location with id=${currentLocationId}`);

    let { data: cart } = yield call(API.putCart, payload);
    const cartId = cart.UUID;

    if (!cartId) {
      throw new Error('There was an error creating the cart. Please try again.');
    }

    console.log(`Created cart with UUID = ${cartId}`);

    if (customerId) {
      cart = yield call(loadCart, cartId);
    }

    return cart;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('API.putCart error: ');
    console.log(errorMessage);

    toast.error(errorMessage);

    return null;
  }
}

export function *unassignCustomerFromCart(cartId) {
  try {
    const payload = {
      UUID: cartId,
      customerId: null,
    };

    console.log(`Unassigning customer from cart with UUID = ${cartId}`);

    yield call(API.putCart, payload);
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('API.putCart error: ');
    console.log(errorMessage);

    toast.error(errorMessage);
  }
}

export function *unassignStaffFromCart(cartId) {
  try {
    const payload = {
      UUID: cartId,
      staffId: null,
    };

    console.log(`Unassigning staff from cart with UUID = ${cartId}`);

    yield call(API.putCart, payload);
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('API.putCart error: ');
    console.log(errorMessage);

    toast.error(errorMessage);
  }
}

export function *putCartDiscount(cartId, discount) {
  try {
    const payload = {
      UUID: cartId,
      ...discount,
    };

    console.log(`Adding ${JSON.stringify(discount)} to cart with UUID = ${cartId}`);

    yield call(API.putCart, payload);
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('API.putCart error: ');
    console.log(errorMessage);

    toast.error(errorMessage);
  }
}

export function *putCartPromoCode(cartId, promoCode) {
  try {
    const payload = {
      promocode: promoCode,
    };

    console.log(`Adding the promo code ${promoCode} to cart with UUID = ${cartId}`);
    const { data } = yield call(API.addCartPromoCode, cartId, payload);

    return data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('*putCartPromoCode error: ');
    console.log(errorMessage);

    toast.error(errorMessage);

    return null;
  }
}

export function *removePromoCodeFromCart(cartId) {
  try {
    console.log(`Removing the promo code from the cart with UUID = ${cartId}`);

    yield call(API.removeCartPromoCode, cartId);
    yield call(loadCart, cartId);
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('*removePromoCodeFromCart error: ');
    console.log(errorMessage);

    toast.error(errorMessage);
  }
}

export function *createNewCart(customerId) {
  const toast1 = toast('Creating cart...');

  try {
    yield put(setCartLoading(true));
    const newCart = yield call(createCart, customerId);
    const newCartId = newCart.UUID;

    if (newCartId) {
      yield put(setActiveCart(newCart));

      return newCart;
    }

    return null;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('createCartSaga error: ');
    console.log(errorMessage);

    toast.error(errorMessage);

    return null;
  } finally {
    toast.dismiss(toast1);
    yield put(setCartLoading(false));
  }
}

export function *focusOnCustomerCart(customerId) {
  if (!customerId) return null;

  const activeCart = yield select(activeCartSelector);
  if (activeCart?.customerId === customerId) return null;

  const newCart = yield call(createNewCart, customerId);
  return newCart;
}

export function *putItemsInCart(items, cartId) {
  console.log(`Adding the following items to the cart with UUID = ${cartId} :`);
  console.log(items);

  const toast1 = toast('Adding to cart...');

  try {
    yield put(setCartLoading(true));
    yield call(API.addCartItems, cartId, items);
    yield call(loadCart, cartId);

    toast.dismiss(toast1);
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('putItemsInCartSaga error: ');
    console.log(errorMessage);

    toast.dismiss(toast1);
    toast.error(errorMessage);
  } finally {
    yield put(setCartLoading(false));
  }
}
