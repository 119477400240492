import { take, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';

import { ASSIGN_CUSTOMER_TO_CURRENT_CART, setCartLoading } from '../actions';
import { activeCartIdSelector } from '../selectors';
import { loadCart, createNewCart } from './cartSagas';


function *assignCustomerToCartSaga() {
  while (true) {
    const { customer: { id: customerId } } = yield take(ASSIGN_CUSTOMER_TO_CURRENT_CART);

    const cartId = yield select(activeCartIdSelector);

    if (!cartId) {
      yield call(createNewCart, customerId);
    } else {
      console.log(`Assigning cart with UUID = ${cartId} to customer with id = ${customerId}`);
      const toast1 = toast('Assigning cart...');

      try {
        yield put(setCartLoading(true));
        yield call(API.putCart, { UUID: cartId, customerId });
        yield call(loadCart, cartId);
      } catch (error) {
        const errorMessage = getErrorMessage(error);

        console.log('API.putCart error: ');
        console.log(errorMessage);

        toast.error(errorMessage);
      } finally {
        toast.dismiss(toast1);
        yield put(setCartLoading(false));
      }
    }
  }
}


export default assignCustomerToCartSaga;
