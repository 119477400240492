import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { List } from '@material-ui/core';


class SearchList extends React.Component {
  componentDidMount() {
    const { listValidate, items, onChange } = this.props;

    if (listValidate) {
      const error = listValidate(items);

      onChange({ error });
    }
  }

  componentDidUpdate(prevProps) {
    const { listValidate, items, onChange } = this.props;

    if ((prevProps.items.length !== items.length) && listValidate) {
      const error = listValidate(items);

      onChange({ error });
    }
  }

  handleChange = (update) => {
    const { listValidate, items, onChange } = this.props;

    const value = [...items];
    const { error: componentError } = update;

    if (update.value) {
      value.push(update.value);
    }

    const listError = listValidate && listValidate(value);
    const error = componentError || listError || null;
    const newUpdate = {
      value,
      error,
    };

    if (update.value) {
      newUpdate.addedItem = update.value;
    }

    onChange(newUpdate);
  };

  handleItemRemove = (idx) => {
    const { listValidate, items, onChange } = this.props;

    const removedItem = { ...items[idx] };
    const value = items.filter((e, i) => i !== idx);
    const listError = listValidate && listValidate(value);
    const error = listError || null;

    onChange({ value, error, removedItem });
  };

  filterOutExistingItems = (returnedItems) => returnedItems.filter(
    (returnedItem) => this.props.items.findIndex((item) => returnedItem.id === item.id) === -1
  );

  render() {
    const {
      allowDuplicates, autoFocus, className, error, outlined, items,
      listItem: ListItem, listItemProps = {},
      label, searchComponent: SearchComponent, searchComponentProps = {}, validate,
    } = this.props;

    const listContainerClassNames = classnames(
      'search-list-container',
      {
        outlined: Boolean(outlined),
        'has-items': items.length > 0,
        'has-error': Boolean(error),
      }
    );

    const listClassNames = classnames(
      'search-list',
      {
        [className]: Boolean(className),
      }
    );

    return (
      <div className={listContainerClassNames}>
        <SearchComponent
          label={label}
          value={null}
          onChange={this.handleChange}
          error={error}
          autoFocus={autoFocus}
          filter={allowDuplicates ? null : this.filterOutExistingItems}
          cacheOptions={Boolean(allowDuplicates)}
          validate={validate}
          {...searchComponentProps}
        />
        <List
          disablePadding
          classes={{ root: listClassNames }}
        >
          {items.map((item, idx) => (
            <ListItem
              key={`LI_${idx}`}
              data={item}
              onRemove={() => this.handleItemRemove(idx)}
              {...listItemProps}
            />
          ))}
        </List>
      </div>
    );
  }
}

SearchList.propTypes = {
  allowDuplicates: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  items: PropTypes.array.isRequired,
  label: PropTypes.string,
  listItem: PropTypes.any.isRequired,
  listItemProps: PropTypes.object,
  listValidate: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  outlined: PropTypes.bool,
  searchComponent: PropTypes.any.isRequired,
  searchComponentProps: PropTypes.object,
  validate: PropTypes.func,
};

SearchList.defaultProps = {
  allowDuplicates: false,
};


export default SearchList;
