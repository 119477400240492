import { combineReducers } from 'redux';

import activeCart from './activeCart';
import finishedInitialCartLoad from './finishedInitialCartLoad';
import loading from './loading';


const cartReducer = combineReducers({
  activeCart,
  finishedInitialCartLoad,
  loading,
});

export default cartReducer;
