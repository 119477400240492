import React from 'react';
import { PropTypes } from 'prop-types';

import { POINTS_STATUS_LIST } from 'constants/points';
import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


const STATUS_PICKLIST_VALUES = [
  {
    label: 'All',
    value: 0,
  },
  ...POINTS_STATUS_LIST.map((status) => ({
    label: status.label,
    value: status.value,
  })),
];


class PointsStatusFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      status: (
        this.props.hasOwnProperty('status') && this.props.status !== 'null'
          ? this.props.status
          : 0
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['status'])) {
      this.handleValueChange();
    }
  }

  handleStatusChange = (update) => {
    if (!update.hasOwnProperty('value')) return;

    this.setState({
      status: update.value,
    });
  };

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  getOwnFilterMap = () => ({
    status: this.state.status === 0 ? null : this.state.status,
  });

  render() {
    const { status } = this.state;

    return (
      <PicklistField
        label="Status"
        value={status}
        onChange={this.handleStatusChange}
        picklistValues={STATUS_PICKLIST_VALUES}
      />
    );
  }
}

PointsStatusFilter.propTypes = {
  onValueChange: PropTypes.func,
  status: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

PointsStatusFilter.ownName = 'PointsStatusFilter';
PointsStatusFilter.ownFilters = ['status'];


export default PointsStatusFilter;
