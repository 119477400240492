import { makeActionCreator } from 'service/utility/redux';


export const SET_SETTINGS_LOADED = 'SET_SETTINGS_LOADED';
export const setSettingsLoaded = makeActionCreator(SET_SETTINGS_LOADED);

export const SET_CHECKIN_SETTINGS = 'SET_CHECKIN_SETTINGS';
export const setCheckinSettings = makeActionCreator(SET_CHECKIN_SETTINGS, 'settings');

export const SET_GENERAL_SETTINGS = 'SET_GENERAL_SETTINGS';
export const setGeneralSettings = makeActionCreator(SET_GENERAL_SETTINGS, 'settings');
