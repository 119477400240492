import React from 'react';
import { PropTypes } from 'prop-types';
import { ListItem, ListItemText } from '@material-ui/core';

import { formatAddress } from 'service/utility/stringFormatters';


class LocationListItem extends React.Component {
  handleClick = () => {
    this.props.onClick(this.props.value);
  };

  render() {
    const { value } = this.props;

    return (
      <ListItem
        button
        onClick={this.handleClick}
        divider
      >
        <ListItemText
          primary={value.name}
          secondary={formatAddress(value)}
        />
      </ListItem>
    );
  }
}

LocationListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};


export default LocationListItem;
