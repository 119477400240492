import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import { invalidUrl } from 'service/utility/errorMessages';
import * as validations from 'service/utility/validations';

import TextField from './TextField';


const validate = (value) => (Boolean(value) && !validations.url(value) ? invalidUrl() : null);


const UrlField = ({ className, ...rest }) => (
  <TextField
    {...rest}
    validate={validate}
    className={classnames('url-field', { [className]: Boolean(className) })}
  />
);

UrlField.propTypes = {
  className: PropTypes.string,
};


export default UrlField;
