import React from 'react';
import { PropTypes } from 'prop-types';

import PriceRow from '../PriceRow';


const DiscountTable = ({
  currentLocation, subtotal, totalDiscount, discountPercentage,
}) => (
  <div className="discount-table-wrapper">
    <table>
      <tbody>
        <PriceRow
          formatAsCurrency
          label=""
          value={subtotal}
          currentLocation={currentLocation}
        />
        {totalDiscount > 0 && (
          <PriceRow
            formatAsCurrency
            label={discountPercentage ? `Discount (-${discountPercentage}%)` : 'Discount(flat)'}
            value={-totalDiscount}
            currentLocation={currentLocation}
          />
        )}
      </tbody>
    </table>
  </div>
);

DiscountTable.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  discountPercentage: PropTypes.number,
  subtotal: PropTypes.number,
  totalDiscount: PropTypes.number,
};


export default DiscountTable;
