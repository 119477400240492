import { take, call } from 'redux-saga/effects';

import { CREATE_NEW_CART } from '../actions';
import { createNewEmptyCart } from './cartSagas';


function *createNewCartSaga() {
  while (true) {
    yield take(CREATE_NEW_CART);
    yield call(createNewEmptyCart);
  }
}


export default createNewCartSaga;
