import React from 'react';
import { PropTypes } from 'prop-types';

import { commaSpaceJoin } from 'service/utility';


const SubmissionCell = ({ submission, question }) => {
  const formResponses = submission.formResponses || submission.FormResponses;
  const response = formResponses.length > 0 ? (
    commaSpaceJoin(
      formResponses.filter((formResponse) => (
        formResponse.questionId === question.id
      )).map((formResponse) => (
        formResponse.textResponse ||
        formResponse.Response?.response ||
        formResponse.response?.response
      ))
    )
  ) : '';

  return (
    <span>
      {response}
    </span>
  );
};

SubmissionCell.propTypes = {
  question: PropTypes.object.isRequired,
  submission: PropTypes.object.isRequired,
};


export default SubmissionCell;
