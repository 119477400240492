export const POINTS_SOURCE_TYPES = {
  REDEEMED: 'REDEEMED',
  STAFF: 'STAFF_ADJUSTMENT',
  CHECKIN: 'CHECKIN',
  PURCHASE: 'PURCHASE',
};

export const POINTS_SOURCE_TYPE_LIST = [
  { label: 'Redeemed', value: POINTS_SOURCE_TYPES.REDEEMED },
  { label: 'Staff Adjustment', value: POINTS_SOURCE_TYPES.STAFF },
  { label: 'Checkin', value: POINTS_SOURCE_TYPES.CHECKIN },
  { label: 'Purchase', value: POINTS_SOURCE_TYPES.PURCHASE },
];

const POINTS_STATUSES = {
  COMPLETED: 'COMPLETED',
  REFUNDED: 'REFUNDED',
};

export const POINTS_STATUS_LIST = [
  { label: 'Completed', value: POINTS_STATUSES.COMPLETED },
  { label: 'Refunded', value: POINTS_STATUSES.REFUNDED },
];

export const POINTS_VIEWS = [
  'Products',
  'Passes',
];

export const POINTS_CUSTOMER_ELIGIBILITY = {
  ALL: 'All',
  NONE: 'None',
  SELECTED: 'Selected',
};

export const POINTS_SETTINGS_SECTIONS = {
  CUSTOMER_ELIGIBILITY: 'customerEligibility',
  SELECTED_MEMBERSHIPS: 'selectedMemberships',
  PURCHASES: 'purchases',
  CHECKINS: 'checkins',
  REFERRALS: 'referrals',
  AGING: 'agingInDays',
};
