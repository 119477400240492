import { toast } from 'react-toastify';

import firebaseService from '../firebase';
import { getErrorMessage } from '../utility';


const uploadImage = async (staffId, uploaded64) => {
  if (!uploaded64) return null;

  try {
    const imageURL = await firebaseService.uploadImage(`staff/${staffId}`, uploaded64);

    return imageURL;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('Error uploading staff image; error = ');
    console.log(errorMessage);

    toast.error(errorMessage);
    return null;
  }
};


const StaffModel = {
  uploadImage,
};


export default StaffModel;
