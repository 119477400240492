export const TRANSACTION_TIMEOUT = 720000;

export const TRANSACTION_STATUSES = [
  {
    name: 'Success',
    value: 'success',
  },
  {
    name: 'Pending',
    value: 'pending',
  },
  {
    name: 'Submitted',
    value: 'submitted',
  },
  {
    name: 'Fail',
    value: 'fail',
  },
  {
    name: 'Error',
    value: 'error',
  },
  {
    name: 'Cancelled',
    value: 'cancelled',
  },
  {
    name: 'Returned',
    value: 'returned',
  },
  {
    name: 'Chargeback',
    value: 'chargeback',
  },
];
