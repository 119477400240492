import React from 'react';
import { PropTypes } from 'prop-types';

import * as API from 'service/api';
import { somePropsChanged } from 'service/utility';

import { Checkboxes } from '../../formFields';


class VariantsFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      locationPicklistValues: [],
      variantIds: null,
    };
  }

  componentDidMount() {
    this.setState({
      variantIds: (
        this.props[this.props.fieldName] &&
        this.props[this.props.fieldName] !== null &&
        this.props[this.props.fieldName].length > 0
          ? this.props[this.props.fieldName]
        : []
      ),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['variantIds'])) {
      this.handleValueChange();
    }
  }

  getOwnFilterMap = () => {
    const { fieldName } = this.props;

    return {
      [fieldName]: this.state.variantIds.length > 0 ? this.state.variantIds : null,
    };
  };

  handleValueChange = () => {
    this.props.onValueChange(
      typeof this.constructor.ownName === 'function' ? this.constructor.ownName(this.props) : this.constructor.ownName,
      this.getOwnFilterMap(),
      this.props.onChangeCallback,
    );
  };

  handleVariantsChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ variantIds: update.value });
    }
  };


  render() {
    const { variantTypeId, variantTypeName } = this.props;
    const { variantIds } = this.state;

    if (!variantIds) return null;

    return (
      <Checkboxes
        className="variants-filter"
        label={variantTypeName}
        getOptionsAPI={API.findVariant}
        getParams={[variantTypeId]}
        value={variantIds}
        onChange={this.handleVariantsChange}
      />
    );
  }
}

VariantsFilter.propTypes = {
  fieldName: PropTypes.string,
  onChangeCallback: PropTypes.func,
  onValueChange: PropTypes.func,
  variantTypeId: PropTypes.number,
  variantTypeName: PropTypes.string,
};

VariantsFilter.ownName = (props) => props.fieldName;
VariantsFilter.ownFilters = (props) => [props.fieldName];


export default VariantsFilter;
