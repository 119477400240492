import React from 'react';
import { PropTypes } from 'prop-types';
import NumberFormat from 'react-number-format';


class NumberFormatCustom extends React.Component {
  onValueChange = (values) => {
    this.props.onChange({
      target: {
        value: values.value,
      },
    });
  };

  render() {
    const { inputRef, onChange, ...rest } = this.props;

    return (
      <NumberFormat
        {...rest}
        getInputRef={inputRef}
        onValueChange={this.onValueChange}
      />
    );
  }
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};


export default NumberFormatCustom;
