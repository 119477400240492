import { createReducer } from 'service/utility/redux';

import { SHOW_COPY_TO_CLIPBOARD_DIALOG, HIDE_COPY_TO_CLIPBOARD_DIALOG } from '../actions/dialog';


const initState = {
  isOpen: false,
  text: null,
};

const copyToClipboardDialog = createReducer(initState, {
  [SHOW_COPY_TO_CLIPBOARD_DIALOG](state, { text }) {
    return {
      ...state,
      isOpen: true,
      text,
    };
  },
  [HIDE_COPY_TO_CLIPBOARD_DIALOG](state) {
    return {
      ...state,
      isOpen: false,
      text: null,
    };
  },
});


export default copyToClipboardDialog;
