import React from 'react';
import { PropTypes } from 'prop-types';
import { MenuItem, ListItemText } from '@material-ui/core';

import { formatPriceWithoutTax } from 'service/utility/pricing';


const GiftCardTypeSearchItem = ({ currentLocation, isFocused, data, innerProps }) => (
  <MenuItem
    selected={isFocused}
    onClick={innerProps.onClick}
    className="giftcard-type-search-item"
  >
    <ListItemText
      primary={data.name}
      secondary={`Value: ${formatPriceWithoutTax(data.value, currentLocation)}; Price: ${formatPriceWithoutTax(data.price, currentLocation)}`}
    />
  </MenuItem>
);

GiftCardTypeSearchItem.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool,
};


export default GiftCardTypeSearchItem;
