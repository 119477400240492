import React from 'react';
import { PropTypes } from 'prop-types';

import { ORDER_SOURCES } from 'constants/orders';
import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


const ORDER_SOURCE_PICKLIST_VALUES = [
  {
    label: 'All',
    value: 0,
  },
  ...ORDER_SOURCES.map((orderSource) => ({
    label: orderSource.name,
    value: orderSource.value,
  })),
];


class OrderSourceFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      orderSource: (
        this.props.hasOwnProperty('type') && this.props.type !== 'null'
          ? this.props.type
          : 0
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['orderSource'])) {
      this.handleValueChange();
    }
  }

  handleOrderSourceChange = (update) => {
    if (!update.hasOwnProperty('value')) return;

    this.setState({
      orderSource: update.value,
    });
  };

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  getOwnFilterMap = () => ({
    type: this.state.orderSource === 0 ? null : this.state.orderSource,
  });

  render() {
    const { orderSource } = this.state;

    return (
      <PicklistField
        label="Source"
        value={orderSource}
        onChange={this.handleOrderSourceChange}
        picklistValues={ORDER_SOURCE_PICKLIST_VALUES}
      />
    );
  }
}

OrderSourceFilter.propTypes = {
  onValueChange: PropTypes.func,
  type: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

OrderSourceFilter.ownName = 'OrderSourceFilter';
OrderSourceFilter.ownFilters = ['type'];


export default OrderSourceFilter;
