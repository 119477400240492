import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


const ButtonSuccess = () => (
  <div className="button-success-container">
    <CheckCircleIcon fontSize="inherit" />
  </div>
);


export default ButtonSuccess;
