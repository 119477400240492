import React from 'react';
import { PropTypes } from 'prop-types';

import { MEMBERSHIP_STATUSES } from 'constants/memberships';
import { somePropsChanged } from 'service/utility';
import { capital } from 'service/utility/stringFormatters';

import { PicklistField } from '../../formFields';


const MEMBERSHIP_STATUS_PICKLIST_VALUES = [
  {
    label: 'All',
    value: 0,
  },
  ...MEMBERSHIP_STATUSES.map((status) => ({
    label: capital(status),
    value: status,
  })),
];


class MembershipStatusFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      membershipStatus: (
        this.props.hasOwnProperty('status') && this.props.status !== 'null'
          ? this.props.status
          : 0
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['membershipStatus'])) {
      this.handleValueChange();
    }
  }

  getOwnFilterMap = () => ({
    status: this.state.membershipStatus === 0 ? null : this.state.membershipStatus,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleMembershipStatusChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ membershipStatus: update.value });
    }
  };


  render() {
    const { membershipStatus } = this.state;

    return (
      <PicklistField
        fullWidth
        label="Status"
        value={membershipStatus}
        onChange={this.handleMembershipStatusChange}
        picklistValues={MEMBERSHIP_STATUS_PICKLIST_VALUES}
      />
    );
  }
}

MembershipStatusFilter.propTypes = {
  onValueChange: PropTypes.func,
  status: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

MembershipStatusFilter.ownName = 'MembershipStatusFilter';
MembershipStatusFilter.ownFilters = ['status'];


export default MembershipStatusFilter;
