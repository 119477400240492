import { getEnv } from '../env';


export const getNamespace = () => {
  const { host, protocol } = window.location;

  if (host.indexOf('localhost') > -1 || protocol === 'file:' || host.indexOf('bs-local.com') > -1) {
    return 'alpha1';
  }

  const bearer = host.split('.')[0];

  return bearer;
};

export const getPortalURL = () => {
  const env = getEnv();

  if (env === 'production') {
    return `https://${getNamespace()}.portal.approach.app`;
  }

  if (env === 'test' || env === 'staging') {
    return `https://${getNamespace()}.portal.staging.tilefive.com`;
  }

  if (env === 'development' || env === 'testing') {
    return `https://${getNamespace()}.portal.testing.tilefive.com`;
  }

  return 'https://alpha1.portal.testing.tilefive.com';
};

export const removeTrailingSlash = (str) => (
  str.endsWith('/')
    ? str.slice(0, -1)
    : str
);
