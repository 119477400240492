import { createTheme } from '@material-ui/core/styles';

import styles from 'styles/export.module.scss';


const THEME_CONFIG = {
  palette: {
    primary: {
      light: styles.primaryLight,
      main: styles.primary,
      dark: styles.primaryDark,
      contrastText: '#fff',
    },
    secondary: {
      light: styles.secondaryLight,
      main: styles.secondary,
      dark: styles.secondaryDark,
      contrastText: styles.white,
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: styles.textPrimary,
      secondary: styles.textSecondary,
      disabled: styles.textDisabled,
      hint: styles.textDisabled,
    },
    background: {
      paper: styles.white,
      default: styles.bright,
    },
    action: {
      active: styles.themeActionActive,
      hover: styles.themeActionHover,
      selected: styles.themeActionSelected,
      disabled: styles.themeActionDisabled,
      disabledBackground: styles.themeActionDisabledBackground,
    },
    divider: styles.listItemDividerColor,
    success: {
      main: styles.success,
    },
  },
  overrides: {
    MuiCardHeader: {
      title: {
        fontWeight: 400,
        color: styles.textPrimary,
      },
    },
    MuiCardContent: {
      root: {
        backgroundColor: 'inherit',
      },
    },
    MuiDialogContent: {
      root: {
        backgroundColor: 'inherit',
      },
    },
    MuiFormHelperText: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
  },
  props: {
    MuiBadge: {
      overlap: 'rectangular',
    },
  },
  shadows: [
    styles.themeShadow0,
    styles.themeShadow1,
    styles.themeShadow2,
    styles.themeShadow3,
    styles.themeShadow4,
    styles.themeShadow5,
    styles.themeShadow6,
    styles.themeShadow7,
    styles.themeShadow8,
    styles.themeShadow9,
    styles.themeShadow10,
    styles.themeShadow11,
    styles.themeShadow12,
    styles.themeShadow13,
    styles.themeShadow14,
    styles.themeShadow15,
    styles.themeShadow16,
    styles.themeShadow17,
    styles.themeShadow18,
    styles.themeShadow19,
    styles.themeShadow20,
    styles.themeShadow21,
    styles.themeShadow22,
    styles.themeShadow23,
    styles.themeShadow24,
  ],
  typography: {
    useNextVariants: true,
    fontFamily: styles.fontFamily,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    // scale
    // subheading: { fontWeight: 300 },
    // title: { fontWeight: 300 },
  },
  status: {
    success: styles.success,
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: Number(styles.gridBreakpointxs),
      sm: Number(styles.gridBreakpointsm),
      md: Number(styles.gridBreakpointmd),
      lg: Number(styles.gridBreakpointlg),
      xl: Number(styles.gridBreakpointxl),
    },
  },
  spacing: 4,
};

const theme = createTheme(THEME_CONFIG);


export default theme;
