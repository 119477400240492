import { put, take, call, select, race } from 'redux-saga/effects';

import firebaseService from 'service/firebase';

import {
  LOCK_SCREEN, UNLOCK_SCREEN, LOG_OUT_USER_FROM_LOCK,
  setLockScreen, setLockScreenMessage, authLoggedOutSuccess,
} from '../actions';
import { lockScreenIsLocked } from '../selectors';


function *logOutUser() {
  yield take(LOG_OUT_USER_FROM_LOCK);

  try {
    yield call(firebaseService.logOut);
    yield put(authLoggedOutSuccess()); // remove user from redux-state;
    yield put(setLockScreen(false)); // remove lockScreen from redux-state;
  } catch (error) {
    console.log('firebaseService.logOut error: ', error);
  }
}

function *waitForPassword() {
  const { pass } = yield take(UNLOCK_SCREEN);

  try {
    yield put(setLockScreenMessage('Logging in...'));
    yield call(firebaseService.reauthenticateWithCredential, pass);
    yield put(setLockScreenMessage(''));
    yield put(setLockScreen(false));
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || 'Wrong Password';

    yield put(setLockScreenMessage(message));
  }
}

function *lockScreenSaga() {
  while (true) {
    const isLocked = yield select(lockScreenIsLocked);

    if (isLocked) {
      yield race({
        waitForPassTask: call(waitForPassword),
        logout: call(logOutUser),
      });
    } else {
      yield take(LOCK_SCREEN);
      yield put(setLockScreen(true));
    }
  }
}


export default lockScreenSaga;
