import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';

import { List, ListItem, ListItemText, ListItemIcon, Collapse } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


class NestedNavItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.props.item.subroutes.some(
        (path) => this.props.location.pathname.indexOf(path.linkTo) === 0
      ),
    };
  }

  // eslint-disable-next-line react/display-name
  renderLink = React.forwardRef((itemProps, ref) => (
    <NavLink
      exact
      activeClassName="active-menu-item"
      {...itemProps}
      innerRef={ref}
    />
  ));

  handleClick = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { rootPath, item } = this.props;
    const { isOpen } = this.state;

    const RightIcon = isOpen ? ExpandMoreIcon : ChevronRightIcon;

    return (
      <>
        <ListItem
          button
          classes={{ root: classnames('nested-vert-menu-item', { 'nav-item-open': isOpen }) }}
          onClick={this.handleClick}
        >
          {item.icon && (
            <ListItemIcon>
              <item.icon />
            </ListItemIcon>
          )}
          <ListItemText
            inset={!item.icon}
            primary={item.label}
            classes={{
              root: classnames('item-text', { 'with-icon': Boolean(item.icon) }),
              primary: 'primary',
            }}
          />
          <RightIcon className="mr-n3" />
        </ListItem>
        <Collapse
          in={isOpen}
          timeout="auto"
          unmountOnExit
        >
          {item.subroutes.length > 0 && (
            <List
              dense
              component="div"
              disablePadding
            >
              {item.subroutes.map((subitem) => (
                <ListItem
                  key={`NI_${subitem.label}`}
                  button
                  to={`${rootPath}${subitem.relPath}`}
                  classes={{ root: 'nested-vert-menu-item nested-vert-menu-subitem' }}
                  component={this.renderLink}
                >
                  <ListItemText
                    inset
                    primary={subitem.label}
                    classes={{
                      root: classnames('item-text', { 'with-icon': Boolean(item.icon) }),
                      primary: 'primary',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Collapse>
      </>
    );
  }
}

NestedNavItem.propTypes = {
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
};


export default withRouter(NestedNavItem);
