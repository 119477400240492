import React from 'react';
import { PropTypes } from 'prop-types';


const CenterSection = ({ children }) => (
  <div className="fixed-content-wrapper">
    <main className="fixed-content">
      {children}
    </main>
  </div>
);

CenterSection.propTypes = {
  children: PropTypes.any,
};


export default CenterSection;
