const PAYMENT_METHODS = [
  {
    name: 'Token',
    value: 'token',
  },
  {
    name: 'Cash',
    value: 'cash',
  },
  {
    name: 'Gift Card',
    value: 'gift-card',
  },
  {
    name: 'Credit',
    value: 'credit',
  },
  {
    name: 'Store Credit',
    value: 'store-credit',
  },
];


export default PAYMENT_METHODS;
