import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { TextField } from '@material-ui/core';

import { getLocationCountry } from 'service/i18n';
import { isRequired } from 'service/utility/errorMessages';

import NumberFormatCustom from './NumberFormatCustom';


class PhoneField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount() {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);


    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.required !== this.props.required || prevProps.value !== this.props.value) {
      const { value: rawValue, onChange } = this.props;
      const value = rawValue || '';
      const error = this.validateSelf(value);

      if (onChange) {
        onChange({ error });
      }
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }

  validateSelf = (value) => {
    const { validate, required, label } = this.props;

    if (!value && required) return isRequired(label);

    return validate ? validate(value) : null;
  };

  handleLeaveFocus = () => {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ error });
    }
  };

  handleChangeSelf = (event) => {
    const rawValue = event.target.value;
    const { onChange } = this.props;
    const value = rawValue || '';

    if (onChange) {
      onChange({ value });
    }
  };

  render() {
    const {
      autoFocus, currentLocation, dispatch, error, fullWidth, onChange, label, required,
      validate, value: rawValue, className, ...rest
    } = this.props;
    const { blurred } = this.state;

    const showError = blurred && Boolean(error);
    const value = rawValue || '';
    const phoneFieldClassName = classnames('phone-field', { [className]: Boolean(className) });
    const currentCountry = getLocationCountry(currentLocation);

    return (
      <TextField
        error={showError}
        helperText={error}
        required={required}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        label={label}
        value={value}
        onChange={this.handleChangeSelf}
        onBlur={this.handleLeaveFocus}
        variant="outlined"
        className={phoneFieldClassName}
        margin="dense"
        InputProps={{
          inputComponent: NumberFormatCustom,
          inputProps: {
            currentCountry,
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
      />
    );
  }
}

PhoneField.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  currentLocation: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  validate: PropTypes.func,
  value: PropTypes.string,
};

PhoneField.defaultProps = {
  autoFocus: false,
  error: '',
  fullWidth: false,
  label: 'Phone',
  required: false,
  value: '',
};


export default PhoneField;
