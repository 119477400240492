import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { TextField } from '@material-ui/core';

import { isRequired, noZero, noNegative, isInvalid } from 'service/utility/errorMessages';

import NumberFormatCustom from './NumberFormatCustom';


class NumberField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount() {
    const { value, onChange } = this.props;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.required !== this.props.required || prevProps.value !== this.props.value) {
      const { label, value, onChange } = this.props;
      const error = typeof value === 'string' ? isInvalid(label) : this.validateSelf(value);

      if (onChange) {
        onChange({ error });
      }
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }

  validateSelf = (value) => {
    const { allowNegative, allowZero, validate, required, label } = this.props;

    if (value === null && required) return isRequired(label);
    if (value === 0 && !allowZero) return noZero(label);
    if (value < 0 && !allowNegative) return noNegative(label);

    return validate ? validate(value) : null;
  };

  handleLeaveFocus = () => {
    const { value, onChange } = this.props;
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ error });
    }
  };

  handleChangeSelf = (event) => {
    const rawValue = event.target.value;
    const { onChange } = this.props;
    const value = rawValue === '' || rawValue === null ? null : Number.isNaN(Number(rawValue)) ? rawValue : Number(rawValue);

    if (onChange) {
      onChange({ value });
    }
  };

  render() {
    const {
      allowDecimals, allowNegative, allowZero, className, error, inline,
      inputProps, InputProps, onChange, validate, value: rawValue, ...rest
    } = this.props;
    const { blurred } = this.state;

    const showError = blurred && Boolean(error);
    const numberFieldClassName = classnames('number-field', { 'inline-field': inline, [className]: Boolean(className) });
    const _inputProps = {
      ...(allowDecimals ? {} : { decimalScale: 0 }),
      ...inputProps,
    };
    const _InputProps = {
      ...InputProps,
      inputProps: _inputProps,
      inputComponent: NumberFormatCustom,
    };
    const value = rawValue === null ? '' : rawValue;

    return (
      <TextField
        error={showError}
        helperText={error}
        onChange={this.handleChangeSelf}
        onBlur={this.handleLeaveFocus}
        className={numberFieldClassName}
        InputProps={_InputProps}
        value={value}
        {...rest}
      />
    );
  }
}

NumberField.propTypes = {
  allowDecimals: PropTypes.bool,
  allowNegative: PropTypes.bool,
  allowZero: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  inline: PropTypes.bool,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  label: PropTypes.string,
  margin: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  validate: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  variant: PropTypes.string,
};

NumberField.defaultProps = {
  allowDecimals: true,
  allowNegative: true,
  allowZero: true,
  autoFocus: false,
  error: '',
  fullWidth: false,
  inline: false,
  inputProps: {},
  InputProps: {},
  margin: 'dense',
  required: false,
  value: null,
  variant: 'outlined',
};


export default NumberField;
