import { commaSpaceJoin } from '.';


export const isProductVariant = (item) => (
  Boolean(item.productId)
);

export const productHasInventory = (product) => (
  Boolean(product) && Boolean(product.hasInventory)
);

export const productHasVariants = (product) => (
  Boolean(product) && Boolean(product.hasVariants)
);

export const productHasDefinedVariantTypes = (product) => (
  productHasVariants(product) && Boolean(product.variantTypeId1)
);

export const getProductAdditionalDetails = (product, productVariant) => {
  if (productVariant && productHasDefinedVariantTypes(product)) {
    const variantTypes = [], variants = [];
    let i = 0, b = true;

    while (b) {
      const variantType = product[`variantType${i + 1}`];
      const variant = productVariant[`variant${i + 1}`];

      if (variantType) {
        i += 1;
        variantTypes.push(variantType);
        variants.push(variant);
      } else {
        b = false;
      }
    }

    return `(${commaSpaceJoin(variantTypes.map(
      (variantType, idx) => `${variantType.name}: ${variants[idx].name}`
    ))})`;
  }

  return '';
};

export const getProductVariantDetails = (productVariant) => {
  if (productVariant && productHasDefinedVariantTypes(productVariant.product)) {
    const variantTypes = [], variants = [];
    let i = 0, b = true;

    while (b) {
      const variant = productVariant[`variant${i + 1}`];

      if (variant) {
        i += 1;
        variantTypes.push(variant.variantType);
        variants.push(variant);
      } else {
        b = false;
      }
    }

    return `(${commaSpaceJoin(variantTypes.map(
      (variantType, idx) => `${variantType.name}: ${variants[idx].name}`
    ))})`;
  }

  return '';
};

export const getProductVariantTypeIds = (product) => {
  let i = 0, b = true;
  const variantTypeIds = [];

  while (b) {
    const variantTypeId = product[`variantTypeId${i + 1}`];

    if (variantTypeId) {
      i += 1;
      variantTypeIds.push(variantTypeId);
    } else {
      b = false;
    }
  }

  return variantTypeIds;
};

export const getProductVariantTypes = (product) => {
  let i = 0, b = true;
  const variantTypes = [];

  while (b) {
    const variantType = product[`variantType${i + 1}`];

    if (variantType) {
      i += 1;
      variantTypes.push(variantType);
    } else {
      b = false;
    }
  }

  return variantTypes;
};

export const getProductVariantKey = (productVariant) => {
  let i = 0, b = true, key = '';

  while (b) {
    const variant = productVariant[`variant${i + 1}`];

    if (variant) {
      i += 1;
      key = `${key}_${variant.id}`;
    } else {
      b = false;
    }
  }

  return key;
};

export const getProductVariantKey2 = (productVariant, locationId) => (
  `${getProductVariantKey(productVariant)}__${locationId}`
);
