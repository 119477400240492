import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';

import * as API from 'service/api';
import { getErrorMessage, somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


class LocationFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      locationPicklistValues: [],
      locationId: (
        this.props.hasOwnProperty('locationId')
          ? this.props.locationId !== 'null'
            ? Number(this.props.locationId)
            : 0
          : this.props.currentLocation.id
      ),
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.handleValueChange();
    this.loadLocations();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['locationId'])) {
      this.handleValueChange();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadLocations = async () => {
    const { validLocationIds } = this.props;

    try {
      const { data: locations } = await API.fetchLocations();

      const validLocations = (
        validLocationIds.length > 0
          ? locations.filter((location) => validLocationIds.includes(location.id))
          : [...locations]
      );
      const locationPicklistValues = [{
        label: 'All Locations',
        value: 0,
      }].concat(validLocations.map((location) => ({
        label: location.name,
        value: location.id,
      })));

      if (this._isMounted) {
        this.setState({
          locationPicklistValues,
          loading: false,
        });
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.fetchLocations error: ');
      console.log(errorMessage);

      toast.error(errorMessage);

      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }
  };

  getOwnFilterMap = () => ({
    locationId: this.state.locationId === 0 ? null : this.state.locationId,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
      this.props.onChangeCallback,
    );
  };

  handleLocationChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ locationId: update.value });
    }
  };


  render() {
    const { loading, locationId, locationPicklistValues } = this.state;

    return loading ? (
      <CircularProgress />
    ) : (
      <PicklistField
        fullWidth
        label="Location"
        value={locationId}
        onChange={this.handleLocationChange}
        picklistValues={locationPicklistValues}
      />
    );
  }
}

LocationFilter.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  locationId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChangeCallback: PropTypes.func,
  onValueChange: PropTypes.func,
  validLocationIds: PropTypes.array,
};

LocationFilter.defaultProps = {
  validLocationIds: [],
};

LocationFilter.ownName = 'LocationFilter';
LocationFilter.ownFilters = ['locationId'];


export default LocationFilter;
