import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  MenuList, MenuItem, Button, ListItem, ListItemText, Popper, Grow, Paper, ClickAwayListener,
} from '@material-ui/core';
import Business from '@material-ui/icons/Business';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import * as stateActions from 'app/redux/state/actions';
import * as stateSelectors from 'app/redux/state/selectors';
import * as transactionActions from 'app/redux/transactions/actions';
import * as transactionSelectors from 'app/redux/transactions/selectors';
import { ChangeLocationDialog } from 'common/dialogs';
import { getRoutePath } from 'service/navigation';
import * as stripeAPI from 'service/oauth/stripe';
import { formatAddress } from 'service/utility/stringFormatters';


const LocationListItem = ({ value, handleClick }) => (
  <ListItem
    button
    onClick={() => handleClick(value)}
    divider
  >
    <ListItemText
      primary={value.name}
      secondary={formatAddress(value)}
    />
  </ListItem>
);

LocationListItem.propTypes = {
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};


const DeviceListItem = ({ value, handleClick }) => (
  <ListItem
    button
    onClick={() => handleClick(value)}
    divider
  >
    <ListItemText
      primary={value.name}
      secondary=""
    />
  </ListItem>
);

DeviceListItem.propTypes = {
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};


class LocationSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
      isDialogOpen: false,
      buttonWidth: null,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({
      buttonWidth: window.getComputedStyle(this.anchorEl).getPropertyValue('width'),
    }), 0);
  }


  handleLocationSelect = (location) => {
    this.props.changeLocation(location);
  };

  handleDeviceSelect = (device) => {
    this.props.setPaymentDevice(device);
  };

  handleToggle = () => this.setState((state) => ({ isMenuOpen: !state.isMenuOpen }));

  handleCloseMenu = (event) => {
    if (this.anchorEl.contains(event.target)) return;
    this.setState({ isMenuOpen: false });
  };

  handleOpenDialog = () => this.setState({ isDialogOpen: true, isMenuOpen: false });

  handleCloseDialog = () => this.setState({ isDialogOpen: false });

  hasCardOptions = () => {
    const { currentLocation: { settings }, device: { merchant } } = this.props;

    return (
      settings && settings[merchant] &&
      settings[merchant].CardNotPresent && settings[merchant].CardNotPresent.connected &&
      settings[merchant].CardPresent && settings[merchant].CardPresent.connected
    );
  };

  handleStripeOauth = () => {
    stripeAPI.authorize(
      this.props.currentLocation.id,
      `${window.location.origin}${getRoutePath('STRIPE_CALLBACK')}?retUrl=${window.location.pathname}`
    ).then(
      ({ data }) => {
        window.location = data;
      }
    );
  };

  render() {
    const { currentLocation, device } = this.props;
    const { isMenuOpen, isDialogOpen, buttonWidth } = this.state;

    return (
      <div className="location-selector">
        <Button
          ref={(node) => { this.anchorEl = node; }}
          onClick={this.handleToggle}
          size="small"
          color="inherit"
          classes={{
            root: classnames('location-selector-button', { pushed: isMenuOpen }),
            label: 'location-selector-button-inner',
          }}
        >
          <Business />
          <ListItemText
            primary={currentLocation ? currentLocation.name : 'Set Location'}
            secondary={device ? device.name : null}
            classes={{
              root: 'set-location-text',
              primary: 'location-name',
              secondary: 'device-name',
            }}
          />
          <KeyboardArrowDown fontSize="large" />
        </Button>
        <Popper
          open={isMenuOpen}
          anchorEl={this.anchorEl}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                width: buttonWidth,
              }}
            >
              <Paper
                square
                classes={{ root: 'menu-paper' }}
              >
                <ClickAwayListener onClickAway={this.handleCloseMenu}>
                  <MenuList classes={{ root: 'menu-list' }}>
                    <MenuItem
                      onClick={this.handleOpenDialog}
                      classes={{ root: 'menu-item' }}
                    >
                      {'Set Location'}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
                {device && device.merchant && device.merchant.toLowerCase() === 'stripe' && (
                  <ClickAwayListener onClickAway={this.handleCloseMenu}>
                    <MenuList classes={{ root: 'menu-list' }}>
                      <MenuItem
                        onClick={this.handleStripeOauth}
                        classes={{ root: 'menu-item' }}
                      >
                        {this.hasCardOptions() ? 'Refresh Stripe' : 'Connect Stripe'}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
        {isDialogOpen && (
          <ChangeLocationDialog
            open
            onLocationSelect={this.handleLocationSelect}
            onDeviceSelect={this.handleDeviceSelect}
            onClose={this.handleCloseDialog}
            currentLocation={currentLocation}
          />
        )}
      </div>
    );
  }
}

LocationSelector.propTypes = {
  changeLocation: PropTypes.func.isRequired,
  currentLocation: PropTypes.object,
  device: PropTypes.object,
  setPaymentDevice: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  currentLocation: stateSelectors.currentLocation(state),
  device: transactionSelectors.paymentDevice(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeLocation: (location) => dispatch(stateActions.changeLocation(location)),
  setPaymentDevice: (payload) => dispatch(transactionActions.setPaymentDevice(payload)),
});

const LocationSelector2 = connect(mapStateToProps, mapDispatchToProps)(LocationSelector);


export default LocationSelector2;
