import React from 'react';
import { PropTypes } from 'prop-types';
import {
  AppBar, Toolbar, Typography, Button, Dialog, DialogContent, DialogActions,
} from '@material-ui/core';

import { productHasInventory } from 'service/utility/product';

import { ProductSearch } from '../../searches';
import { StatusOverlay, Spinner } from '../../statusIndicators';


const ProductSearchDialog = (props) => {
  const filterOutProducts = (products) => products.filter(
    (product) => productHasInventory(product) && props.existingProductIds.indexOf(product.id) === -1
  );

  return (
    <Dialog
      open={props.isOpen}
      maxWidth="sm"
      onClose={props.onDialogClose}
      classes={{ root: 'product-search-dialog' }}
    >
      <AppBar
        position="static"
        classes={{ root: 'dialog-appbar' }}
      >
        <Toolbar classes={{ root: 'dialog-toolbar' }}>
          <Typography
            variant="h6"
            color="inherit"
            classes={{ root: 'dialog-title' }}
          >
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent classes={{ root: 'dialog-content' }}>
        {props.loading && (
          <StatusOverlay>
            <Spinner size={60} />
          </StatusOverlay>
        )}
        <ProductSearch
          autoFocus
          onChange={(update) => update.value && props.onProductSelect(update.value)}
          filter={filterOutProducts}
        />
      </DialogContent>
      <DialogActions classes={{ root: 'dialog-actions' }}>
        <Button onClick={props.onDialogClose}>
          {'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProductSearchDialog.propTypes = {
  existingProductIds: PropTypes.array.isRequired,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  onDialogClose: PropTypes.func.isRequired,
  onProductSelect: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};


export default ProductSearchDialog;
