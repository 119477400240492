import React from 'react';
import { SvgIcon } from '@material-ui/core';


const PassIcon = () => (
  <SvgIcon
    viewBox="0 0 512 512"
    fontSize="inherit"
  >
    <path
      d="M501.333,202.667c5.896,0,10.667-4.771,10.667-10.667v-85.333C512,83.135,492.865,64,469.333,64H42.667
      C19.135,64,0,83.135,0,106.667V192c0,5.896,4.771,10.667,10.667,10.667C40.073,202.667,64,226.594,64,256
      s-23.927,53.333-53.333,53.333C4.771,309.333,0,314.104,0,320v85.333C0,428.865,19.135,448,42.667,448h426.667
      C492.865,448,512,428.865,512,405.333V320c0-5.896-4.771-10.667-10.667-10.667C471.927,309.333,448,285.406,448,256
      S471.927,202.667,501.333,202.667z M426.667,256c0,37.552,27.865,68.719,64,73.906v75.427c0,11.76-9.573,21.333-21.333,21.333
      H42.667c-11.76,0-21.333-9.573-21.333-21.333v-75.427c36.135-5.188,64-36.354,64-73.906s-27.865-68.719-64-73.906v-75.427
      c0-11.76,9.573-21.333,21.333-21.333h426.667c11.76,0,21.333,9.573,21.333,21.333v75.427
      C454.531,187.281,426.667,218.448,426.667,256z"
    />
    <path
      d="M138.667,104c-5.896,0-10.667,4.771-10.667,10.667v24c0,5.896,4.771,10.667,10.667,10.667
      c5.896,0,10.667-4.771,10.667-10.667v-24C149.333,108.771,144.563,104,138.667,104z"
    />
    <path
      d="M138.667,192c-5.896,0-10.667,4.771-10.667,10.667V224c0,5.896,4.771,10.667,10.667,10.667
      c5.896,0,10.667-4.771,10.667-10.667v-21.333C149.333,196.771,144.563,192,138.667,192z"
    />
    <path
      d="M138.667,277.333c-5.896,0-10.667,4.771-10.667,10.667v21.333c0,5.896,4.771,10.667,10.667,10.667
      c5.896,0,10.667-4.771,10.667-10.667V288C149.333,282.104,144.563,277.333,138.667,277.333z"
    />
    <path
      d="M138.667,362.667c-5.896,0-10.667,4.771-10.667,10.667v21.333c0,5.896,4.771,10.667,10.667,10.667
      c5.896,0,10.667-4.771,10.667-10.667v-21.333C149.333,367.438,144.563,362.667,138.667,362.667z"
    />
    <path
      d="M373.333,170.667H224c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h149.333
      c5.896,0,10.667-4.771,10.667-10.667C384,175.438,379.229,170.667,373.333,170.667z"
    />
    <path
      d="M373.333,245.333H224c-5.896,0-10.667,4.771-10.667,10.667s4.771,10.667,10.667,10.667h149.333
      c5.896,0,10.667-4.771,10.667-10.667S379.229,245.333,373.333,245.333z"
    />
    <path
      d="M373.333,320H224c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h149.333
      c5.896,0,10.667-4.771,10.667-10.667C384,324.771,379.229,320,373.333,320z"
    />
  </SvgIcon>
);

export default PassIcon;
