import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { IconButton, SvgIcon, Badge } from '@material-ui/core';

import * as cartSelectors from 'app/redux/cart/selectors';
import { layoutActions } from 'app/redux/ui/actions';
import * as uiSelectors from 'app/redux/ui/selectors';
import { CartModel } from 'service/models';


class ToggleCart extends React.Component {
  handleClick = () => {
    const { rightDrawer, setRightDrawerView, toggleRightDrawer } = this.props;

    if (rightDrawer.view !== 'cart') {
      setRightDrawerView('cart');
      if (!rightDrawer.isOpen) {
        toggleRightDrawer();
      }
    } else {
      toggleRightDrawer();
    }
  };

  render() {
    const { rightDrawer, activeCart, isCartLoading, disabled } = this.props;

    const itemsCount = activeCart ? new CartModel(activeCart).getItemCount() : 0;
    const activeTab = !disabled && rightDrawer.isOpen && rightDrawer.view === 'cart';

    return (
      <div className={classnames('cart-toggle', { active: activeTab, disabled })}>
        <IconButton
          onClick={this.handleClick}
          color="inherit"
          classes={{ root: 'toggle-icon-button' }}
        >
          <Badge
            color="secondary"
            badgeContent={itemsCount}
            showZero
            invisible={isCartLoading}
            classes={{ badge: classnames('item-count-badge', { 'zero-item-count': !itemsCount }) }}
          >
            <SvgIcon
              viewBox="0 0 260.293 260.293"
              fontSize="inherit"
            >
              <path
                d="M258.727,57.459c-1.42-1.837-3.612-2.913-5.934-2.913H62.004l-8.333-32.055c-0.859-3.306-3.843-5.613-7.259-5.613H7.5
                c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h33.112l8.333,32.055c0,0.001,0,0.001,0.001,0.002l29.381,112.969
                c0.859,3.305,3.843,5.612,7.258,5.612h137.822c3.415,0,6.399-2.307,7.258-5.612l29.385-112.971
                C260.636,61.687,260.147,59.295,258.727,57.459z M117.877,167.517H91.385l-5.892-22.652h32.384V167.517z M117.877,129.864H81.592
                l-5.895-22.667h42.18V129.864z M117.877,92.197H71.795l-5.891-22.651h51.973V92.197z M176.119,167.517h-43.242v-22.652h43.242
                V167.517z M176.119,129.864h-43.242v-22.667h43.242V129.864z M176.119,92.197h-43.242V69.546h43.242V92.197z M217.609,167.517
                h-26.49v-22.652h32.382L217.609,167.517z M227.403,129.864h-36.284v-22.667h42.18L227.403,129.864z M237.201,92.197h-46.081V69.546
                h51.974L237.201,92.197z"
              />
              <path
                d="M105.482,188.62c-15.106,0-27.396,12.29-27.396,27.395c0,15.108,12.29,27.4,27.396,27.4
                c15.105,0,27.395-12.292,27.395-27.4C132.877,200.91,120.588,188.62,105.482,188.62z M105.482,228.415
                c-6.835,0-12.396-5.563-12.396-12.4c0-6.835,5.561-12.395,12.396-12.395c6.834,0,12.395,5.561,12.395,12.395
                C117.877,222.853,112.317,228.415,105.482,228.415z"
              />
              <path
                d="M203.512,188.62c-15.104,0-27.392,12.29-27.392,27.395c0,15.108,12.288,27.4,27.392,27.4
                c15.107,0,27.396-12.292,27.396-27.4C230.908,200.91,218.618,188.62,203.512,188.62z M203.512,228.415
                c-6.833,0-12.392-5.563-12.392-12.4c0-6.835,5.559-12.395,12.392-12.395c6.836,0,12.396,5.561,12.396,12.395
                C215.908,222.853,210.347,228.415,203.512,228.415z"
              />
            </SvgIcon>
          </Badge>
        </IconButton>
      </div>
    );
  }
}

ToggleCart.propTypes = {
  activeCart: PropTypes.object,
  disabled: PropTypes.bool,
  isCartLoading: PropTypes.bool.isRequired,
  rightDrawer: PropTypes.object.isRequired,
  setRightDrawerView: PropTypes.func.isRequired,
  toggleRightDrawer: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  activeCart: cartSelectors.activeCartSelector(state),
  isCartLoading: cartSelectors.isCartLoading(state),
  rightDrawer: uiSelectors.rightDrawer(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRightDrawerView: (view) => dispatch(layoutActions.setRightDrawerView(view)),
  toggleRightDrawer: () => dispatch(layoutActions.toggleRightDrawer()),
});

const ToggleCart2 = connect(mapStateToProps, mapDispatchToProps)(ToggleCart);


export default ToggleCart2;
