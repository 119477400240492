import { put, take, call, race } from 'redux-saga/effects';
import {
  CONFIRM_ACTION, ACTION_CONFIRMED, ACTION_CANCELLED, setConfirmActionDialog,
} from '../actions/dialog';


function *awaitConfirmation(callback) {
  yield take(ACTION_CONFIRMED);
  callback();
}

function *confirmActionSaga() {
  while (true) {
    const { callback, options } = yield take(CONFIRM_ACTION);

    try {
      yield put(setConfirmActionDialog(true, options));

      if (callback) {
        yield race({
          awaitConfirmation: call(awaitConfirmation, callback),
          cancel: take(ACTION_CANCELLED),
        });
      } else {
        yield take(ACTION_CANCELLED);
      }
      yield put(setConfirmActionDialog(false));
    } catch (error) {
      console.log('confirmActionSaga error: ', error);
    }
  }
}


export default confirmActionSaga;
