export const ORDER_STATUSES = [
  {
    name: 'Completed',
    value: 'completed',
  },
  {
    name: 'Pending',
    value: 'pending',
  },
];

export const ORDER_SOURCES = [
  {
    name: 'In store',
    value: 'instore',
  },
  {
    name: 'Online',
    value: 'online',
  },
  {
    name: 'Online - Guest',
    value: 'online-guest',
  },
  {
    name: 'Automatic Payment',
    value: 'auto',
  },
];
