import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { FormControl, FormLabel } from '@material-ui/core';

import { COLOR_PICKER_WIDTH, CALENDAR_COLOR_MATRIX } from 'constants/ui';

import Color from './Color';


class ColorField extends React.PureComponent {
  handleSelect = (value) => {
    if (this.props.onChange) {
      this.props.onChange({ value });
    }
  };

  render() {
    const { required, label, value, className } = this.props;

    const colorFieldClassName = classnames('color-field', { [className]: Boolean(className) });

    return (
      <FormControl
        className={colorFieldClassName}
        component="fieldset"
        required={required}
      >
        <FormLabel component="legend">
          {label}
        </FormLabel>
        <div
          className="calendar-color-picker"
          style={{ width: `${COLOR_PICKER_WIDTH}px` }}
        >
          <div className="calendar-color-picker-colors">
            {CALENDAR_COLOR_MATRIX.map((row, idx) => (
              <div
                key={`CC_${idx}`}
                className="calendar-color-picker-row"
              >
                {row.map((item, idx2) => (
                  <Color
                    key={`CC_${idx}_${idx2}`}
                    value={item.hex}
                    isSelected={item.hex === value}
                    onSelect={this.handleSelect}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </FormControl>
    );
  }
}

ColorField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
};

ColorField.defaultProps = {
  label: '',
  required: false,
  value: '',
};


export default ColorField;
