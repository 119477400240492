import React from 'react';
import { PropTypes } from 'prop-types';
import { Route } from 'react-router-dom';


const PrivateRouteView = ({ hasPermission, ...rest }) => {
  if (!hasPermission) {
    return (
      <span>
        {'Not Authorized.'}
      </span>
    );
  }

  return (
    <Route {...rest} />
  );
};

PrivateRouteView.propTypes = {
  hasPermission: PropTypes.bool.isRequired,
};


export default PrivateRouteView;
