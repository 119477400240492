import { defaultStaffRoles } from 'constants/staff';

import { getRouteByPath } from '../navigation';


export const staffHasPermission = (staff, permission) => {
  if (!permission) return true;
  if (!staff || !staff.role) return false;

  const currentStaffPermissions = (
    defaultStaffRoles[staff.roleId]
      ? defaultStaffRoles[staff.roleId].permissions
      : staff.role.permissions
  );

  if (!currentStaffPermissions) return false;

  return currentStaffPermissions.some(
    (staffPermission) => staffPermission.UID === permission
  );
};

const staffHasAccess = (staff, path) => {
  const route = getRouteByPath(path);

  if (!route) return false;

  return staffHasPermission(staff, route.permission);
};

const hasAccess = (staff, navItem, location) => {
  if (navItem.locationFilter && !navItem.locationFilter(location)) return false;
  if (!navItem.linkTo) return true;

  return staffHasAccess(staff, navItem.linkTo);
};

export const getStaffNavConfig = (navConfig, staff, location) => {
  const staffNavConfig = [];

  navConfig.forEach((navItem_) => {
    const navItem = { ...navItem_ };

    if (navItem.subNav) {
      navItem.subNav = navItem.subNav.filter((subNavItem) => hasAccess(staff, subNavItem, location));

      if (navItem.subNav.length > 0) {
        staffNavConfig.push(navItem);
      }
    } else if (hasAccess(staff, navItem, location)) {
      staffNavConfig.push(navItem);
    }
  });

  return staffNavConfig;
};
