export const TAX_FIELDS = [
  'stateTaxRate',
  'cityTaxRate',
  'localeTaxRate',
  'additionalTaxRate',
];

export const TAX_FIELDS2 = [
  ...TAX_FIELDS,
  'additionalTaxName',
];
