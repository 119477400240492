import { createReducer } from 'service/utility/redux';

import { TOGGLE_RIGHT_DRAWER, SET_RIGHT_DRAWER_VIEW } from '../actions/layout';


const initState = {
  isOpen: true,
  view: 'notifications',
};

const rightDrawer = createReducer(initState, {
  [TOGGLE_RIGHT_DRAWER](state) {
    return {
      ...state,
      isOpen: !state.isOpen,
    };
  },
  [SET_RIGHT_DRAWER_VIEW](state, { view }) {
    return {
      ...state,
      view,
    };
  },
});

export default rightDrawer;
