import React from 'react';
import { PropTypes } from 'prop-types';

import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


const RECURRING_PICKLIST_VALUES = [
  {
    label: 'Recurring',
    value: 1,
  },
  {
    label: 'Non Recurring',
    value: 0,
  },
  {
    label: 'All',
    value: -1,
  },
];


class IsRecurringFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isRecurring: (
        this.props.hasOwnProperty('isRecurring') && this.props.isRecurring !== 'null'
          ? Number(this.props.isRecurring)
          : -1
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['isRecurring'])) {
      this.handleValueChange();
    }
  }

  getOwnFilterMap = () => ({
    isRecurring: this.state.isRecurring === -1 ? null : this.state.isRecurring,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ isRecurring: update.value });
    }
  };


  render() {
    const { isRecurring } = this.state;

    return (
      <PicklistField
        fullWidth
        label="Recurrence"
        value={isRecurring}
        onChange={this.handleChange}
        picklistValues={RECURRING_PICKLIST_VALUES}
        className="is-recurring-filter"
      />
    );
  }
}

IsRecurringFilter.propTypes = {
  isRecurring: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onValueChange: PropTypes.func,
};

IsRecurringFilter.ownName = 'IsRecurringFilter';
IsRecurringFilter.ownFilters = ['isRecurring'];


export default IsRecurringFilter;
