import { createReducer } from 'service/utility/redux';

import { SET_LOCK_SCREEN, SET_LOCK_SCREEN_MESSAGE } from '../actions';


const initState = {
  isLocked: false,
  errorMessage: '',
};

const lockScreen = createReducer(initState, {
  [SET_LOCK_SCREEN](state, { isLocked }) {
    return {
      ...state,
      isLocked,
    };
  },
  [SET_LOCK_SCREEN_MESSAGE](state, { errorMessage }) {
    return {
      ...state,
      errorMessage,
    };
  },
});

export default lockScreen;
