import { locationUsesPriceBeforeTax } from '../i18n';
import { formatPriceWithoutTax } from './pricing';
import { stripHtmlTags } from './stringFormatters';


export const getDepositField = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'deposit'
    : 'taxInclusiveDeposit'
);

export const getPricePerHeadField = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'pricePerHead'
    : 'taxInclusivePricePerHead'
);

export const getFlatFeeField = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'flatFee'
    : 'taxInclusiveFlatFee'
);

export const getDeposit = (pricingTier, location) => (
  pricingTier[getDepositField(location)]
);

export const getPricePerHead = (pricingTier, location) => (
  pricingTier[getPricePerHeadField(location)]
);

export const getFlatFee = (pricingTier, location) => (
  pricingTier[getFlatFeeField(location)]
);

export const getDepositLabel = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'Deposit'
    : 'Tax Inclusive Deposit'
);

export const getPricePerHeadLabel = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'Price Per Head'
    : 'Tax Inclusive Price Per Head'
);

export const getFlatFeeLabel = (location) => (
  locationUsesPriceBeforeTax(location)
    ? 'Price'
    : 'Tax Inclusive Price'
);

export const formatGuestsInterval = (pricingTier) => (
  `${pricingTier.minGuests}${pricingTier.maxGuests ? ` - ${pricingTier.maxGuests}` : '+'}`
);

export const formatPricingTierDeposit = (pricingTier, location) => {
  const deposit = getDeposit(pricingTier, location);

  return formatPriceWithoutTax(deposit, location);
};

export const formatPricePerHead = (pricePerHead, location) => (
  `${formatPriceWithoutTax(pricePerHead, location)} a person`
);

export const formatFlatFee = (flatFee, location) => (
  formatPriceWithoutTax(flatFee, location)
);

export const formatPricingTierPrice = (pricingTier, location) => {
  const pricePerHead = getPricePerHead(pricingTier, location);
  const flatFee = getFlatFee(pricingTier, location);

  return (
    pricePerHead
      ? formatPricePerHead(pricePerHead, location)
      : formatFlatFee(flatFee, location)
  );
};

export const formatPricingTier = (pricingTier, location) => (
  `${stripHtmlTags(pricingTier.description)} ` +
  `(${formatGuestsInterval(pricingTier)} guests / ${formatPricingTierPrice(pricingTier, location)})`
);

export const getPricingTierDeposit = (pricingTier, location) => {
  if (locationUsesPriceBeforeTax(location)) {
    return pricingTier.deposit;
  }

  if (
    pricingTier.hasOwnProperty('taxInclusiveDeposit') &&
    pricingTier.taxInclusiveDeposit !== null
  ) {
    return pricingTier.taxInclusiveDeposit;
  }

  return pricingTier.deposit;
};
