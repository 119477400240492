import React from 'react';
import { PropTypes } from 'prop-types';

import { somePropsChanged } from 'service/utility';
import * as timeUtils from 'service/utility/timeUtils';

import { DateField } from '../../formFields';


class DateFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      date: (
        this.props.asDTInterval
          ? this.props[this.props.startField] && this.props[this.props.startField] !== 'null'
            ? timeUtils.formatIso8601Date(this.props[this.props.startField], this.props.timeZone)
            : null
          : this.props.date && this.props.date !== 'null'
            ? this.props.date
            : null
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['date'])) {
      this.handleValueChange();
    }
  }

  getOwnFilterMap = () => {
    const { asDTInterval, startField, endField } = this.props;
    const { date } = this.state;

    if (asDTInterval) {
      return {
        [startField]: timeUtils.iso8601MidnightInUTC(date, this.props.timeZone),
        [endField]: timeUtils.iso8601EodInUTC(date, this.props.timeZone),
      };
    }

    return {
      date,
    };
  };

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleSetDate = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ date: update.value });
    }
  };


  render() {
    const { label } = this.props;
    const { date } = this.state;

    return (
      <DateField
        narrow
        label={label}
        value={date}
        onChange={this.handleSetDate}
      />
    );
  }
}

DateFilter.propTypes = {
  asDTInterval: PropTypes.bool,
  date: PropTypes.string,
  endField: PropTypes.string,
  label: PropTypes.string,
  onValueChange: PropTypes.func,
  startField: PropTypes.string,
  timeZone: PropTypes.string.isRequired,
};

DateFilter.defaultProps = {
  endField: 'endDT',
  startField: 'startDT',
};

DateFilter.ownName = 'DateFilter';
DateFilter.ownFilters = (props) => (props.asDTInterval ? [props.startField, props.endField] : ['date']);


export default DateFilter;
