import { toast } from 'react-toastify';

import * as API from '../api';
import firebaseService from '../firebase';
import { getErrorMessage } from '../utility';


const uploadImage = async (productId, uploaded64) => {
  if (!uploaded64) return null;

  try {
    const imageURL = await firebaseService.uploadImage(`product/${productId}`, uploaded64);

    return imageURL;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('Error uploading product image; error = ');
    console.log(errorMessage);

    toast.error(errorMessage);
    return null;
  }
};

const uploadImageAndUpdate = async (productId, uploaded64) => {
  if (!uploaded64) return;

  const imageURL = await uploadImage(productId, uploaded64);

  if (!imageURL) return;

  try {
    await API.putProduct({
      id: productId,
      imageURL,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('Error updating product; error = ');
    console.log(errorMessage);

    toast.error(errorMessage);
  }
};


const ProductModel = {
  uploadImage,
  uploadImageAndUpdate,
};


export default ProductModel;
