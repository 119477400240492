import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import { getOrderUrl } from 'service/utility/order';


const OrderHeader = ({ cartOrOrder }) => (
  <div className="cart-header cart-order-header">
    <Link to={getOrderUrl(cartOrOrder)}>
      {`Order #${cartOrOrder.id}`}
    </Link>
  </div>
);

OrderHeader.propTypes = {
  cartOrOrder: PropTypes.object.isRequired,
};


export default OrderHeader;
