import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { matchPath } from 'react-router';
import { Drawer } from '@material-ui/core';

import { getFullScreenRoutes } from 'service/navigation';


class RightDrawer extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const fullScreenRoutes = getFullScreenRoutes();

      if (fullScreenRoutes.some(
        (path) => matchPath(this.props.location.pathname, { path, exact: true }))
      ) {
        if (this.props.open) {
          this.props.onToggle();
        }
      }
    }
  }

  render() {
    const { open, children } = this.props;

    return (
      <Drawer
        open={open}
        anchor="right"
        variant="permanent"
        classes={{ paper: classnames('right-drawer-paper', { open }) }}
      >
        {children}
      </Drawer>
    );
  }
}

RightDrawer.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool,
};


export default RightDrawer;
