import { createReducer } from 'service/utility/redux';

import { TOGGLE_CUSTOMER_DIALOG } from '../actions/dialog';


const initState = null;

const customerDialog = createReducer(initState, {
  [TOGGLE_CUSTOMER_DIALOG](state, { customerId }) {
    return customerId;
  },
});

export default customerDialog;
