import React from 'react';
import { PropTypes } from 'prop-types';

import { getItemTypeName } from 'constants/itemTypes';
import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


class PromoCodeItemTypeFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      itemType: (
        this.props.hasOwnProperty('type') && this.props.type !== 'null'
          ? this.props.type
          : 0
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['itemType'])) {
      this.handleValueChange();
    }
  }

  getOwnFilterMap = () => ({
    type: this.state.itemType === 0 ? null : this.state.itemType,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleItemTypeChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ itemType: update.value });
    }
  };


  render() {
    const { itemTypes } = this.props;
    const { itemType } = this.state;

    const picklistValues = [
      {
        label: 'All',
        value: 0,
      },
      ...itemTypes.map((it) => ({
        label: getItemTypeName(it),
        value: it,
      })),
    ];

    return (
      <PicklistField
        fullWidth
        className="promo-code-item-type-filter"
        label="Item Type"
        value={itemType}
        onChange={this.handleItemTypeChange}
        picklistValues={picklistValues}
      />
    );
  }
}

PromoCodeItemTypeFilter.propTypes = {
  itemTypes: PropTypes.array.isRequired,
  onValueChange: PropTypes.func,
  type: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

PromoCodeItemTypeFilter.ownName = 'PromoCodeItemTypeFilter';
PromoCodeItemTypeFilter.ownFilters = ['type'];


export default PromoCodeItemTypeFilter;
