import React from 'react';
import { PropTypes } from 'prop-types';

import { processBarcodeScan } from 'service/utility/barcode';

import TextField from '../TextField';


class ScannableField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.firstCharTime = 0;
    this.lastCharTime = 0;
    this.stringWriting = '';
    this.callIsScanner = false;
    this.testTimer = false;
    this.startChar = [];
    this.endChar = [9, 13];
    this.timeBeforeScanTest = 100;
  }

  initScannerDetection = () => {
    this.firstCharTime = 0;
    this.stringWriting = '';
  };

  onScan = (data) => {
    const { onChange } = this.props;
    const processed = processBarcodeScan(data.trim());

    if (processed) {
      const value = processed.UUID;

      if (onChange) {
        onChange({ value, error: null });
      }
    }
  };

  scannerDetectionTest = () => {
    const { minLength, avgTimeByChar } = this.props;

    if (
      this.stringWriting.length >= minLength &&
      this.lastCharTime - this.firstCharTime < this.stringWriting.length * avgTimeByChar
    ) {
      this.onScan(this.stringWriting);
    }

    this.initScannerDetection();

    return true;
  };

  handleKeyPress = (e) => {
    if (this.firstCharTime && this.endChar.indexOf(e.which) !== -1) {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.callIsScanner = true;
    } else if (!this.firstCharTime && this.startChar.indexOf(e.which) !== -1) {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.callIsScanner = false;
    } else {
      if (typeof e.which !== 'undefined') {
        this.stringWriting += String.fromCharCode(e.which);
      }

      this.callIsScanner = false;
    }

    if (!this.firstCharTime) {
      this.firstCharTime = Date.now();
    }

    this.lastCharTime = Date.now();
    if (this.testTimer) clearTimeout(this.testTimer);

    if (this.callIsScanner) {
      this.scannerDetectionTest();

      this.testTimer = false;
    } else {
      this.testTimer = setTimeout(this.scannerDetectionTest, this.timeBeforeScanTest);
    }
  };

  render() {
    const { avgTimeByChar, minLength, ...rest } = this.props;

    return (
      <div className="scannable-field">
        <TextField
          {...rest}
          onKeyPress={this.handleKeyPress}
        />
      </div>
    );
  }
}

ScannableField.propTypes = {
  avgTimeByChar: PropTypes.number,
  minLength: PropTypes.number,
  onChange: PropTypes.func,
};

ScannableField.defaultProps = {
  avgTimeByChar: 30,
  minLength: 6,
};


export default ScannableField;
