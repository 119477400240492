import { makeActionCreator } from 'service/utility/redux';


export const TOGGLE_ALERT_ON_NOTES_DIALOG = 'TOGGLE_ALERT_ON_NOTES_DIALOG';
export const toggleAlertOnNotesDialog = makeActionCreator(TOGGLE_ALERT_ON_NOTES_DIALOG, 'customerId');


// CHECKIN DIALOG PHASES
export const SEARCH_CUSTOMERS = 0;
export const CHECKIN_CUSTOMER = 1;
export const CHECKIN_OTHER = 2;

// CHECKIN DIALOG
export const SET_CHECKIN_DIALOG_PHASE = 'SET_CHECKIN_DIALOG_PHASE';
export const setCheckinDialogPhase = makeActionCreator(SET_CHECKIN_DIALOG_PHASE, 'phase');

export const SET_CHECKIN_DIALOG_CALLBACK = 'SET_CHECKIN_DIALOG_CALLBACK';
export const setCheckinDialogCallback = makeActionCreator(SET_CHECKIN_DIALOG_CALLBACK, 'callback');

export const SHOW_CHECKIN_DIALOG_SEARCH = 'SHOW_CHECKIN_DIALOG_SEARCH';
export const showCheckinDialogSearch = makeActionCreator(SHOW_CHECKIN_DIALOG_SEARCH, 'bookingId');

export const SHOW_CHECKIN_DIALOG_CUSTOMER = 'SHOW_CHECKIN_DIALOG_CUSTOMER';
export const showCheckinDialogCustomer = makeActionCreator(SHOW_CHECKIN_DIALOG_CUSTOMER, 'customerId', 'bookingId', 'groupId');

export const CHECKIN_DIALOG_SELECT_CUSTOMER = 'CHECKIN_DIALOG_SELECT_CUSTOMER';
export const checkinDialogSelectCustomer = makeActionCreator(CHECKIN_DIALOG_SELECT_CUSTOMER, 'customerId');

export const CHECKIN_DIALOG_SELECT_EVENT = 'CHECKIN_DIALOG_SELECT_EVENT';
export const checkinDialogSelectEvent = makeActionCreator(CHECKIN_DIALOG_SELECT_EVENT, 'event');

export const HIDE_CHECKIN_DIALOG = 'HIDE_CHECKIN_DIALOG';
export const hideCheckinDialog = makeActionCreator(HIDE_CHECKIN_DIALOG);

export const CLOSE_CHECKIN_DIALOG = 'CLOSE_CHECKIN_DIALOG';
export const closeCheckinDialog = makeActionCreator(CLOSE_CHECKIN_DIALOG);


export const CONFIRM_ACTION = 'CONFIRM_ACTION';
export const confirmAction = makeActionCreator(CONFIRM_ACTION, 'callback', 'options');

export const SET_CONFIRM_ACTION_DIALOG = 'SET_CONFIRM_ACTION_DIALOG';
export const setConfirmActionDialog = makeActionCreator(SET_CONFIRM_ACTION_DIALOG, 'isOpen', 'options');

export const ACTION_CONFIRMED = 'ACTION_CONFIRMED';
export const actionConfirmed = makeActionCreator(ACTION_CONFIRMED);

export const ACTION_CANCELLED = 'ACTION_CANCELLED';
export const actionCancelled = makeActionCreator(ACTION_CANCELLED);


export const SHOW_COPY_TO_CLIPBOARD_DIALOG = 'SHOW_COPY_TO_CLIPBOARD_DIALOG';
export const showCopyToClipboardDialog = makeActionCreator(SHOW_COPY_TO_CLIPBOARD_DIALOG, 'text');

export const HIDE_COPY_TO_CLIPBOARD_DIALOG = 'HIDE_COPY_TO_CLIPBOARD_DIALOG';
export const hideCopyToClipboardDialog = makeActionCreator(HIDE_COPY_TO_CLIPBOARD_DIALOG);


export const TOGGLE_CUSTOMER_DIALOG = 'TOGGLE_CUSTOMER_DIALOG';
export const toggleCustomerDialog = makeActionCreator(TOGGLE_CUSTOMER_DIALOG, 'customerId');


export const SHOW_EVENT_CHECKIN_DIALOG = 'SHOW_EVENT_CHECKIN_DIALOG';
export const showEventCheckinDialog = makeActionCreator(SHOW_EVENT_CHECKIN_DIALOG, 'ticketUUID');

export const EVENT_CHECKIN_DIALOG_SET_CHECKEDINCUSTOMERID = 'EVENT_CHECKIN_DIALOG_SET_CHECKEDINCUSTOMERID';
export const eventCheckinDialogSetCheckedInCustomerId = makeActionCreator(EVENT_CHECKIN_DIALOG_SET_CHECKEDINCUSTOMERID, 'checkedInCustomerId');

export const HIDE_EVENT_CHECKIN_DIALOG = 'HIDE_EVENT_CHECKIN_DIALOG';
export const hideEventCheckinDialog = makeActionCreator(HIDE_EVENT_CHECKIN_DIALOG);


export const SHOW_NEW_GIFT_CARD_DIALOG = 'SHOW_NEW_GIFT_CARD_DIALOG';
export const showNewGiftCardDialog = makeActionCreator(SHOW_NEW_GIFT_CARD_DIALOG, 'customer');

export const HIDE_NEW_GIFT_CARD_DIALOG = 'HIDE_NEW_GIFT_CARD_DIALOG';
export const hideNewGiftCardDialog = makeActionCreator(HIDE_NEW_GIFT_CARD_DIALOG);


export const SHOW_ADD_CARD_DIALOG = 'SHOW_ADD_CARD_DIALOG';
export const showAddCardDialog = makeActionCreator(SHOW_ADD_CARD_DIALOG, 'customerId');

export const SET_ADD_CARD_DIALOG_CALLBACK = 'SET_ADD_CARD_DIALOG_CALLBACK';
export const setAddCardDialogCallback = makeActionCreator(SET_ADD_CARD_DIALOG_CALLBACK, 'callback');

export const HIDE_ADD_CARD_DIALOG = 'HIDE_ADD_CARD_DIALOG';
export const hideAddCardDialog = makeActionCreator(HIDE_ADD_CARD_DIALOG);

export const CLOSE_ADD_CARD_DIALOG = 'CLOSE_ADD_CARD_DIALOG';
export const closeAddCardDialog = makeActionCreator(CLOSE_ADD_CARD_DIALOG);

export const SHOW_CREATE_AD_HOC_CHARGE_DIALOG = 'SHOW_CREATE_AD_HOC_CHARGE_DIALOG';
export const showCreateAdHocChargeDialog = makeActionCreator(SHOW_CREATE_AD_HOC_CHARGE_DIALOG, 'customer');

export const HIDE_CREATE_AD_HOC_CHARGE_DIALOG = 'HIDE_CREATE_AD_HOC_CHARGE_DIALOG';
export const hideCreateAdHocChargeDialog = makeActionCreator(HIDE_CREATE_AD_HOC_CHARGE_DIALOG);

// REFUND ORDER DIALOG PHASES
export const SELECT_ITEMS = 0;
export const SELECT_AMOUNTS = 1;
export const SELECT_METHODS = 2;
export const REFUND = 3;

// REFUND ORDER DIALOG
export const SET_REFUND_DIALOG_PHASE = 'SET_REFUND_DIALOG_PHASE';
export const setRefundDialogPhase = makeActionCreator(SET_REFUND_DIALOG_PHASE, 'phase');

export const SET_REFUND_DIALOG_CALLBACK = 'SET_REFUND_DIALOG_CALLBACK';
export const setRefundDialogCallback = makeActionCreator(SET_REFUND_DIALOG_CALLBACK, 'callback');

export const SHOW_REFUND_ORDER_DIALOG = 'SHOW_REFUND_ORDER_DIALOG';
export const showRefundOrderDialog = makeActionCreator(SHOW_REFUND_ORDER_DIALOG, 'order');

export const SET_REFUND_DIALOG_METHOD = 'SET_REFUND_DIALOG_METHOD';
export const setRefundDialogMethod = makeActionCreator(SET_REFUND_DIALOG_METHOD, 'method');

export const REFUND_ITEM = 'REFUND_ITEM';
export const refundItem = makeActionCreator(REFUND_ITEM, 'order', 'itemType', 'itemId');

export const REFUND_DIALOG_SELECT_ITEMS = 'REFUND_DIALOG_SELECT_ITEMS';
export const refundDialogSelectItems = makeActionCreator(REFUND_DIALOG_SELECT_ITEMS, 'items');

export const REFUND_DIALOG_SELECT_AMOUNTS = 'REFUND_DIALOG_SELECT_AMOUNTS';
export const refundDialogSelectAmounts = makeActionCreator(REFUND_DIALOG_SELECT_AMOUNTS, 'items', 'method');

export const HIDE_REFUND_DIALOG = 'HIDE_REFUND_DIALOG';
export const hideRefundDialog = makeActionCreator(HIDE_REFUND_DIALOG);

export const CLOSE_REFUND_DIALOG = 'CLOSE_REFUND_DIALOG';
export const closeRefundDialog = makeActionCreator(CLOSE_REFUND_DIALOG);
