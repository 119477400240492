import { take, put, select } from 'redux-saga/effects';

import { CLOSE_REFUND_DIALOG, hideRefundDialog } from '../actions/dialog';
import { refundOrderDialogCallback } from '../selectors';


function *closeRefundDialogSaga() {
  while (true) {
    yield take(CLOSE_REFUND_DIALOG);
    const callback = yield select(refundOrderDialogCallback);
    if (callback) {
      callback();
    }
    yield put(hideRefundDialog());
  }
}


export default closeRefundDialogSaga;
