import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import {
  Button, Dialog, DialogTitle, DialogActions, DialogContent,
  Stepper, Step, StepLabel, Typography,
} from '@material-ui/core';

import * as API from 'service/api';
import { getErrorMessage, somePropsChanged } from 'service/utility';

import { DataList } from '../../lists';
import { Spinner, StatusOverlay } from '../../statusIndicators';
import DeviceListItem from './DeviceListItem';
import LocationListItem from './LocationListItem';


const STEPS = ['Location', 'Device'];
const [LOCATION_STEP, DEVICE_STEP] = [...STEPS.keys()];


class ChangeLocationDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: LOCATION_STEP,
      loadingLocations: true,
      locations: [],
      selectedLocationId: null,
      loadingDevices: false,
      devices: [],
      finishing: false,
    };
  }

  componentDidMount() {
    this.loadLocations();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['selectedLocationId'])) {
      this.loadDevices();
    }
  }

  loadLocations = async () => {
    try {
      console.log('ChangeLocationDialog: loading all active Locations');
      const { data: locations } = await API.fetchLocations('isActive=1');

      console.log('ChangeLocationDialog: loaded all active Locations:');
      console.log(locations);

      this.setState({
        locations,
        loadingLocations: false,
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.fetchLocations error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
      this.setState({ loadingLocations: false });
    }
  };

  loadDevices = async () => {
    const { selectedLocationId: locationId } = this.state;

    this.setState({ loadingDevices: true });

    try {
      console.log(`ChangeLocationDialog: loading Devices for Location with id=${locationId}`);
      const { data: devices } = await API.fetchDevicesByLocationId(locationId);

      const activeDevices = devices.filter((device) => device.isActive === 1);
      console.log('ChangeLocationDialog: loaded all active Devices:', activeDevices);

      this.setState({
        devices: activeDevices,
        loadingDevices: false,
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.fetchDevicesByLocationId error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
      this.setState({ loadingDevices: false });
    }
  };

  handleLocationSelection = (location) => {
    this.setState({
      selectedLocationId: location.id,
      activeStep: DEVICE_STEP,
    });
  };

  handleBackClick = () => {
    this.setState({
      activeStep: LOCATION_STEP,
    });
  };

  finish = async (selectedDevice) => {
    const { onLocationSelect, onDeviceSelect } = this.props;
    const { selectedLocationId } = this.state;

    this.setState({ finishing: true });

    try {
      const { data: location } = await API.fetchLocation(selectedLocationId);

      await onDeviceSelect(selectedDevice);
      await onLocationSelect(location);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.fetchLocation error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    } finally {
      this.setState({ finishing: false });
      this.close();
    }
  };

  close = () => {
    this.setState({ activeStep: LOCATION_STEP });
    if (this.props.onClose) this.props.onClose();
  };

  handleDoneClick = () => {
    this.finish(null);
  };

  render() {
    const { currentLocation, open } = this.props;
    const {
      activeStep, loadingLocations, locations,
      loadingDevices, devices, finishing,
    } = this.state;

    return (
      <Dialog
        classes={{ root: 'change-location-dialog' }}
        open={open}
      >
        <DialogTitle classes={{ root: 'dialog-title' }}>
          {`Change ${STEPS[activeStep]}`}
        </DialogTitle>
        <DialogContent classes={{ root: 'dialog-content' }}>
          <Stepper
            activeStep={activeStep}
            classes={{ root: 'stepper' }}
          >
            {STEPS.map((step, idx) => (
              <Step key={idx}>
                <StepLabel>
                  {`Set ${step}`}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === LOCATION_STEP && (
            loadingLocations ? (
              <Spinner />
            ) : (
              <DataList
                data={locations}
                sortable={false}
                item={
                  <LocationListItem
                    value={{}}
                    onClick={this.handleLocationSelection}
                  />
                }
                fields={['name']}
                itemsPerPage={5}
              />
            )
          )}
          {activeStep === DEVICE_STEP && (
            loadingDevices ? (
              <Spinner />
            ) : devices.length > 0 ? (
              <DataList
                data={devices}
                searchable={false}
                sortable={false}
                item={
                  <DeviceListItem
                    value={{}}
                    onClick={this.finish}
                  />
                }
                fields={['name']}
                itemsPerPage={5}
                filtering={false}
              />
            ) : (
              <div className="p-3">
                <Typography>
                  {'No devices found.'}
                </Typography>
              </div>
            )
          )}
          {finishing && (
            <StatusOverlay>
              <Spinner size={60} />
            </StatusOverlay>
          )}
        </DialogContent>
        <DialogActions>
          {activeStep === DEVICE_STEP && (
            <Button onClick={this.handleBackClick}>
              {'Back'}
            </Button>
          )}
          {activeStep === DEVICE_STEP && !loadingDevices && !devices.length && (
            <Button onClick={this.handleDoneClick}>
              {'Done'}
            </Button>
          )}
          {currentLocation && (
            <Button onClick={this.close}>
              {'Cancel'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

ChangeLocationDialog.propTypes = {
  currentLocation: PropTypes.object,
  onClose: PropTypes.func,
  onDeviceSelect: PropTypes.func.isRequired,
  onLocationSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


export default ChangeLocationDialog;
