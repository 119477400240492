import { take, call, select } from 'redux-saga/effects';

import { PUT_ITEMS_IN_CART } from '../actions';
import { activeCartIdSelector } from '../selectors';
import { putItemsInCart, createNewCart } from './cartSagas';


function *putItemsInCartSaga() {
  while (true) {
    const { items } = yield take(PUT_ITEMS_IN_CART);

    let cartId = yield select(activeCartIdSelector);

    if (!cartId) {
      const newCart = yield call(createNewCart);
      if (!newCart) return;

      cartId = newCart.UUID;
    }

    yield call(putItemsInCart, items, cartId);
  }
}


export default putItemsInCartSaga;
