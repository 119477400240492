import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import { TextField } from '@material-ui/core';

import { isRequired } from 'service/utility/errorMessages';
import * as timeUtils from 'service/utility/timeUtils';

import DateFormatCustom from '../../DateFormatCustom';
import PortalWrapper from '../../PortalWrapper';


class DateField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount() {
    const { value: stringValue, onChange } = this.props;
    const value = !stringValue || timeUtils.isEmptyIso8601Date(stringValue) ? null : stringValue;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.required !== this.props.required || prevProps.value !== this.props.value) {
      const { value: stringValue, onChange } = this.props;
      const value = !stringValue || timeUtils.isEmptyIso8601Date(stringValue) ? null : stringValue;
      const error = this.validateSelf(value);

      if (onChange) {
        onChange({ error });
      }
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }

  validateSelf = (value) => {
    const { required, label } = this.props;

    if (!value && required) return isRequired(label);

    return null;
  };

  handleLeaveFocus = () => {
    const { value: stringValue, onChange } = this.props;
    const value = stringValue || null;
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ error });
    }
  };

  handleChangeSelf = (jsDateValue) => {
    const { onChange } = this.props;
    const value = jsDateValue ? timeUtils.dateYearMonthDay(jsDateValue) : null;

    if (onChange) {
      onChange({ value });
    }
  };

  render() {
    const {
      autoFocus, className, disabled, error, fullWidth, inline, label, margin, narrow, onChange,
      required, value: stringValue, variant, DatePickerProps, ...rest
    } = this.props;
    const { blurred } = this.state;

    const dateFieldClassName = classnames(
      'date-field',
      {
        'inline-field': inline,
        narrow,
        [className]: Boolean(className),
      },
    );
    const dateFormat = timeUtils.getShortDateDatePickerFormat();
    const jsDateValue = (
      !stringValue || timeUtils.isEmptyIso8601Date(stringValue)
        ? null
        : timeUtils.localDateFromIso8601Date(stringValue)
    );
    const showError = blurred && Boolean(error);

    return (
      <div className={dateFieldClassName}>
        <DatePicker
          dateFormat={dateFormat}
          selected={jsDateValue}
          onChange={this.handleChangeSelf}
          onBlur={this.handleLeaveFocus}
          required={required}
          autoFocus={autoFocus}
          customInput={
            <TextField
              error={showError}
              helperText={error}
              required={required}
              fullWidth={fullWidth}
              label={label}
              InputLabelProps={{
                shrink: true,
              }}
              margin={margin}
              variant={variant}
              InputProps={{
                inputComponent: DateFormatCustom,
              }}
              {...rest}
            />
          }
          disabled={disabled}
          popperContainer={PortalWrapper}
          {...DatePickerProps}
        />
      </div>
    );
  }
}

DateField.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  DatePickerProps: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string,
  margin: PropTypes.string,
  narrow: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
};

DateField.defaultProps = {
  autoFocus: false,
  DatePickerProps: {},
  error: '',
  fullWidth: false,
  inline: false,
  margin: 'dense',
  narrow: false,
  required: false,
  value: '',
  variant: 'outlined',
};


export default DateField;
