import React from 'react';
import { PropTypes } from 'prop-types';
import { ListItem, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { MembershipTypeListItemText } from '../../listItems';


const MembershipTypeSearchListItem = ({ data, onRemove }) => (
  <ListItem
    button
    className="membership-type-search-list-item"
  >
    <MembershipTypeListItemText data={data} />
    <IconButton onClick={onRemove}>
      <ClearIcon />
    </IconButton>
  </ListItem>
);

MembershipTypeSearchListItem.propTypes = {
  data: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};


export default MembershipTypeSearchListItem;
