import React from 'react';
import { PropTypes } from 'prop-types';
import { MenuItem } from '@material-ui/core';


class MassAction extends React.Component {
  handleClick = () => {
    this.props.closeHamburgerMenu();
    this.props.massAction.action(this.props.selectedItemIds);
  };

  render() {
    const { massAction } = this.props;

    return (
      <MenuItem onClick={this.handleClick}>
        {massAction.label}
      </MenuItem>
    );
  }
}

MassAction.propTypes = {
  closeHamburgerMenu: PropTypes.func.isRequired,
  massAction: PropTypes.object.isRequired,
  selectedItemIds: PropTypes.array.isRequired,
};


export default MassAction;
