import React from 'react';
import { PropTypes } from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';

import Avatar from './Avatar';


const PersonAvatar = ({ src, size, ...rest }) => (
  <Avatar
    className="person-avatar"
    size={size}
    src={src}
    alt={<PersonIcon fontSize="inherit" />}
    {...rest}
  />
);

PersonAvatar.propTypes = {
  size: PropTypes.string,
  src: PropTypes.string,
};

PersonAvatar.defaultProps = {
  size: 'md',
  src: '',
};


export default PersonAvatar;
