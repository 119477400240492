import React from 'react';
import { PropTypes } from 'prop-types';
import { MenuItem, ListItemAvatar, ListItemText } from '@material-ui/core';

import { formatPhone } from 'service/utility/phoneNumber';

import { PersonAvatar } from '../../avatars';


const StaffSearchItem = ({ children, isFocused, data, innerProps }) => {
  const secondary = (
    data.mobile
      ? formatPhone(data.mobile)
      : data.home
        ? formatPhone(data.home)
        : data.email
          ? data.email
          : null
  );

  return (
    <MenuItem
      selected={isFocused}
      onClick={innerProps.onClick}
      className="staff-search-item"
    >
      <ListItemAvatar>
        <PersonAvatar
          src={data.imageURL}
          size="md"
        />
      </ListItemAvatar>
      <ListItemText
        primary={children.toString()}
        secondary={secondary}
        className="staff-name"
      />
    </MenuItem>
  );
};

StaffSearchItem.propTypes = {
  children: PropTypes.any,
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool,
};


export default StaffSearchItem;
