import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography, Paper } from '@material-ui/core';
import ListAlt from '@material-ui/icons/ListAlt';

import { RemoveFab } from 'common/buttons';
import {
  getItemDiscountPercentage, getItemDiscountFlat, getDiscountAmountProp,
  getSubtotalProp, formatPriceWithoutTax,
} from 'service/utility/pricing';


const CartInvoice = (props) => {
  const { item, currentLocation, disabled } = props;
  const { UUID, typeInfo } = item;

  const discountPercentage = getItemDiscountPercentage(item, typeInfo, currentLocation);
  const discountFlat = getItemDiscountFlat(item, typeInfo, currentLocation);
  const discountAmount = item[getDiscountAmountProp(currentLocation)];
  const subtotal = item[getSubtotalProp(currentLocation)];


  const handleItemRemove = () => props.onItemRemove(UUID);


  return (
    <Paper
      className="cart-item"
      elevation={0}
    >
      <div className="cart-item-col image">
        <div className="mp-icon-wrapper">
          <ListAlt fontSize="inherit" />
        </div>
      </div>
      <div className="cart-item-col details">
        <span className="item-name">
          {'Event Fee'}
        </span>
      </div>
      <div className="cart-item-col total">
        <Typography variant="body1">
          {formatPriceWithoutTax(subtotal, currentLocation)}
        </Typography>
        {discountFlat > 0 && (
          <span className="discount discount-small">
            {`${formatPriceWithoutTax(-discountFlat, currentLocation)} (flat)`}
          </span>
        )}
        {discountPercentage > 0 && (
          <span className="discount discount-small">
            {`${formatPriceWithoutTax(-discountAmount, currentLocation)} (${discountPercentage}%)`}
          </span>
        )}
      </div>
      {!disabled && (
        <RemoveFab onClick={() => handleItemRemove()} />
      )}
    </Paper>
  );
};

CartInvoice.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onItemRemove: PropTypes.func.isRequired,
};

CartInvoice.defaultProps = {
  disabled: false,
};


export default CartInvoice;
