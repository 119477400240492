import { createReducer } from 'service/utility/redux';

import {
  SELECT_ITEMS, SELECT_AMOUNTS, REFUND,
  SET_REFUND_DIALOG_PHASE, SET_REFUND_DIALOG_CALLBACK, SET_REFUND_DIALOG_METHOD,
  SHOW_REFUND_ORDER_DIALOG, REFUND_ITEM,
  REFUND_DIALOG_SELECT_ITEMS, REFUND_DIALOG_SELECT_AMOUNTS,
  HIDE_REFUND_DIALOG,
} from '../actions/dialog';


const initState = {
  isOpen: false,
  refundPhase: SELECT_ITEMS,
  minRefundPhase: SELECT_ITEMS,
  order: null,
  preselectedItem: null,
  selectedItems: null,
  selectedMethod: null,
  callback: null,
};

const refundOrderDialog = createReducer(initState, {
  [SET_REFUND_DIALOG_PHASE](state, { phase }) {
    return {
      ...state,
      refundPhase: phase,
    };
  },
  [SET_REFUND_DIALOG_METHOD](state, { method }) {
    return {
      ...state,
      selectedMethod: method,
    };
  },
  [SET_REFUND_DIALOG_CALLBACK](state, { callback }) {
    return {
      ...state,
      callback,
    };
  },
  [SHOW_REFUND_ORDER_DIALOG](state, { order }) {
    return {
      ...state,
      isOpen: true,
      refundPhase: SELECT_ITEMS,
      order,
    };
  },
  [REFUND_ITEM](state, { order, itemType, itemId }) {
    return {
      ...state,
      isOpen: true,
      refundPhase: SELECT_AMOUNTS,
      minRefundPhase: SELECT_AMOUNTS,
      order,
      preselectedItem: {
        id: itemId,
        type: itemType,
      },
    };
  },
  [REFUND_DIALOG_SELECT_ITEMS](state, { items }) {
    return {
      ...state,
      refundPhase: SELECT_AMOUNTS,
      selectedItems: items,
    };
  },
  [REFUND_DIALOG_SELECT_AMOUNTS](state, { items, method }) {
    return {
      ...state,
      refundPhase: REFUND,
      selectedItems: items,
      selectedMethod: method,
    };
  },
  [HIDE_REFUND_DIALOG]() {
    return initState;
  },
});


export default refundOrderDialog;
