import React from 'react';
import { PropTypes } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core';


class NavItem extends React.Component {
  // eslint-disable-next-line react/display-name
  renderLink = React.forwardRef((itemProps, ref) => {
    const { className, ...rest } = itemProps;

    return (
      <NavLink
        exact
        className={`${className} nav-item`}
        activeClassName="active-nav-item"
        {...rest}
        innerRef={ref}
      />
    );
  });

  render() {
    const { onItemClick, item: { label, icon: Icon, linkTo } } = this.props;

    return (
      <ListItem
        button
        onClick={onItemClick}
        to={linkTo}
        component={this.renderLink}
      >
        <ListItemIcon classes={{ root: 'nav-icon' }}>
          {Icon && (
            <Icon />
          )}
        </ListItemIcon>
        <ListItemText
          primary={label || null}
          classes={{ root: 'nav-text-wrapper', primary: 'nav-text' }}
        />
      </ListItem>
    );
  }
}

NavItem.propTypes = {
  item: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired,
};


export default NavItem;
