import React from 'react';
import { PropTypes } from 'prop-types';
import { ListItem, ListItemText, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { formatName } from 'service/utility/stringFormatters';

import { PersonAvatar } from '../../avatars';


const StaffSearchListItem = ({ data, onRemove }) => (
  <ListItem
    button
    className="staff-search-list-item"
  >
    <PersonAvatar
      src={data.imageURL}
      size="sm"
    />
    <ListItemText
      primary={formatName(data)}
      className="staff-name"
    />
    <IconButton onClick={onRemove}>
      <ClearIcon />
    </IconButton>
  </ListItem>
);

StaffSearchListItem.propTypes = {
  data: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};


export default StaffSearchListItem;
