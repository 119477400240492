import React from 'react';
import { PropTypes } from 'prop-types';
import { Table, TableBody } from '@material-ui/core';

import { locationUsesPriceBeforeTax } from 'service/i18n';

import PriceRow from '../PriceRow';


const TotalsTable = ({
  currentLocation, subtotal, discountFlat, discountPercentage, totalDiscount,
  total, totalLabel, currency, orderVrsn, orderLocation, stateTaxTotal, cityTaxTotal,
  localeTaxTotal, additionalTaxTotal, cityTaxName, localeTaxName, stateTaxName, promoCode,
  tipTotal,
}) => (
  <div className="totals-table-wrapper">
    <Table>
      <TableBody>
        <PriceRow
          formatAsCurrency
          label="Subtotal"
          value={subtotal}
          currency={currency}
          orderLocation={orderLocation}
          currentLocation={currentLocation}
        />
        {discountFlat > 0 && (
          <PriceRow
            type="discount"
            formatAsCurrency
            label="Discount (flat)"
            value={-discountFlat}
            currency={currency}
            orderLocation={orderLocation}
            currentLocation={currentLocation}
          />
        )}
        {discountPercentage > 0 && (
          <PriceRow
            type="discount"
            formatAsCurrency
            label={`Discount (${discountPercentage}%)`}
            value={-subtotal * discountPercentage / 100}
            currency={currency}
            orderLocation={orderLocation}
            currentLocation={currentLocation}
          />
        )}
        {totalDiscount > 0 && (
          <PriceRow
            formatAsCurrency
            label="Total Discounts"
            value={-totalDiscount}
            currency={currency}
            orderLocation={orderLocation}
            currentLocation={currentLocation}
          />
        )}
        {(orderVrsn < 4 || locationUsesPriceBeforeTax(orderLocation || currentLocation)) && (
          <>
            {stateTaxTotal > 0 && (
              <PriceRow
                formatAsCurrency
                label={stateTaxName || 'State Tax'}
                value={stateTaxTotal}
                currency={currency}
                orderLocation={orderLocation}
                currentLocation={currentLocation}
              />
            )}
            {cityTaxTotal > 0 && (
              <PriceRow
                formatAsCurrency
                label={cityTaxName || 'City Tax'}
                value={cityTaxTotal}
                currency={currency}
                orderLocation={orderLocation}
                currentLocation={currentLocation}
              />
            )}
            {localeTaxTotal > 0 && (
              <PriceRow
                formatAsCurrency
                label={localeTaxName || 'Local Tax'}
                value={localeTaxTotal}
                currency={currency}
                orderLocation={orderLocation}
                currentLocation={currentLocation}
              />
            )}
            {additionalTaxTotal > 0 && (
              <PriceRow
                formatAsCurrency
                label="Additional Tax"
                value={additionalTaxTotal}
                currency={currency}
                orderLocation={orderLocation}
                currentLocation={currentLocation}
              />
            )}
          </>
        )}
        {tipTotal > 0 && (
          <PriceRow
            formatAsCurrency
            label="Tips"
            value={tipTotal}
            currency={currency}
            orderLocation={orderLocation}
            currentLocation={currentLocation}
          />
        )}
        <PriceRow
          type="total"
          formatAsCurrency
          label={totalLabel}
          value={total}
          currency={currency}
          orderLocation={orderLocation}
          currentLocation={currentLocation}
        />
        {promoCode && (
          <PriceRow
            type="promoCode"
            label="Promo Code"
            value={promoCode.code}
          />
        )}
      </TableBody>
    </Table>
  </div>
);

TotalsTable.propTypes = {
  additionalTaxTotal: PropTypes.number,
  cityTaxName: PropTypes.string,
  cityTaxTotal: PropTypes.number,
  currency: PropTypes.string,
  currentLocation: PropTypes.object.isRequired,
  discountFlat: PropTypes.number,
  discountPercentage: PropTypes.number,
  localeTaxName: PropTypes.string,
  localeTaxTotal: PropTypes.number,
  orderLocation: PropTypes.object,
  orderVrsn: PropTypes.number,
  promoCode: PropTypes.object,
  stateTaxName: PropTypes.string,
  stateTaxTotal: PropTypes.number,
  subtotal: PropTypes.number,
  tipTotal: PropTypes.number,
  total: PropTypes.number,
  totalDiscount: PropTypes.number,
  totalLabel: PropTypes.string,
};

TotalsTable.defaultProps = {
  orderVrsn: 4,
  totalLabel: 'Total',
};


export default TotalsTable;
