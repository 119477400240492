import React from 'react';
import { PropTypes } from 'prop-types';
import { Menu } from '@material-ui/core';


const HamburgerMenu = (props) => {
  const { children, ...rest } = props;

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...rest}
    >
      {children}
    </Menu>
  );
};

HamburgerMenu.propTypes = {
  children: PropTypes.any,
};


export default HamburgerMenu;
