import React from 'react';
import { PropTypes } from 'prop-types';
import { ListItemText } from '@material-ui/core';

import { formatPricingTier } from 'service/utility/pricingTier';


const PricingTierListItemText = ({ data, currentLocation }) => (
  <ListItemText
    primary={data.name}
    secondary={formatPricingTier(data, currentLocation)}
  />
);

PricingTierListItemText.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};


export default PricingTierListItemText;
