import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { components } from 'react-select';
import Async from 'react-select/async';
import { FormControl, FormHelperText, InputLabel, List } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { isRequired } from 'service/utility/errorMessages';


const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <SearchIcon />
  </components.DropdownIndicator>
);


/* eslint-disable */
const generateSingleValue = (SingleValueComponent) => ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <SingleValueComponent {...props} />
  </components.SingleValue>
);
/* eslint-enable */


const MenuList = (props) => (
  <components.MenuList {...props}>
    <List classes={{ root: 'p-0' }}>
      {props.children}
    </List>
  </components.MenuList>
);

MenuList.propTypes = {
  children: PropTypes.any,
};


class GenericSearch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount() {
    const { value, onChange, getOptionValue } = this.props;
    const optionValue = getOptionValue(value || {});
    const error = this.validateSelf(optionValue);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }

  validateSelf = (value) => {
    const { required, label, validate } = this.props;

    if (!value && required) return isRequired(label);
    if (validate) return validate(value);

    return null;
  };

  handleLeaveFocus = () => {
    const { value, onChange, getOptionValue } = this.props;
    const optionValue = getOptionValue(value || {});
    const error = this.validateSelf(optionValue);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ value, error });
    }
  };

  handleChangeSelf = (value) => {
    const { onChange, getOptionValue } = this.props;
    const optionValue = getOptionValue(value || {});
    const error = this.validateSelf(optionValue);

    if (onChange) {
      onChange({ value, error });
    }
  };

  render() {
    const {
      autoFocus, cacheOptions, className, disabled, error, fullWidth, label, onChange, placeholder, value,
      suggestionItem: SuggestionItem, displayItem: DisplayItem,
      ...rest
    } = this.props;
    const { blurred } = this.state;

    const showError = blurred && Boolean(error);
    const formControlClassName = classnames('generic-search', { [className]: Boolean(className) });
    const inputLabelClassName = classnames('input-label', { disabled: Boolean(disabled) });
    const asyncClassName = classnames('react-select-container', { error: showError });
    const asyncStyles = { menuPortal: (base) => ({ ...base, zIndex: 9999 }) };
    const asyncComponents = { Option: SuggestionItem, DropdownIndicator, MenuList };

    if (DisplayItem) {
      asyncComponents.SingleValue = generateSingleValue(DisplayItem);
    }

    return (
      <FormControl
        className={formControlClassName}
        margin="dense"
        fullWidth={fullWidth}
      >
        {label && (
          <InputLabel
            error={showError}
            className={inputLabelClassName}
            shrink
            variant="outlined"
            required={rest.required}
          >
            {label}
          </InputLabel>
        )}
        <Async
          cacheOptions={cacheOptions}
          isClearable
          isDisabled={disabled}
          value={value}
          getOptionValue={this.props.getOptionValue}
          getOptionLabel={this.props.getOptionLabel}
          loadOptions={this.props.getOptions}
          onChange={this.handleChangeSelf}
          onBlur={this.handleLeaveFocus}
          placeholder={placeholder}
          className={asyncClassName}
          classNamePrefix="react-select"
          components={asyncComponents}
          maxMenuHeight={312}
          openMenuOnClick={false}
          autoFocus={autoFocus}
          menuPosition="absolute"
          menuPlacement="bottom"
          styles={asyncStyles}
          {...rest}
        />
        {error && (
          <FormHelperText
            error={showError}
            variant="outlined"
            margin="dense"
            required
          >
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

GenericSearch.propTypes = {
  autoFocus: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  displayItem: PropTypes.any,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  getOptionLabel: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  suggestionItem: PropTypes.any.isRequired,
  validate: PropTypes.func,
  value: PropTypes.object,
};

GenericSearch.defaultProps = {
  autoFocus: false,
  cacheOptions: true,
  disabled: false,
  error: '',
  fullWidth: true,
  placeholder: '',
  required: false,
};


export default GenericSearch;
