import { toast } from 'react-toastify';

import * as API from '../api';
import firebaseService from '../firebase';
import { getErrorMessage } from '../utility';


const uploadImage = async (membershipTypeId, uploaded64) => {
  if (!uploaded64) return null;

  try {
    const imageURL = await firebaseService.uploadImage(`membershipType/${membershipTypeId}`, uploaded64);

    return imageURL;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('Error uploading membership type image; error = ');
    console.log(errorMessage);

    toast.error(errorMessage);
    return null;
  }
};

const uploadImageAndUpdate = async (membershipTypeId, uploaded64) => {
  if (!uploaded64) return;

  const imageURL = await uploadImage(membershipTypeId, uploaded64);

  if (!imageURL) return;

  try {
    await API.putMembershipType({
      id: membershipTypeId,
      imageURL,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('Error updating membership type; error = ');
    console.log(errorMessage);

    toast.error(errorMessage);
  }
};


const MembershipTypeModel = {
  uploadImage,
  uploadImageAndUpdate,
};


export default MembershipTypeModel;
