import React from 'react';
import { PropTypes } from 'prop-types';
import { Avatar, Chip } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';


class FieldChip extends React.Component {
  handleClick = () => {
    this.props.onSortBy(this.props.field.sortString);
  };

  render() {
    const { field, order } = this.props;

    return (
      <Chip
        classes={{ root: 'order-chip' }}
        label={field.label}
        onClick={this.handleClick}
        avatar={order ? (
          <Avatar>
            {order === 'ASC' ? (
              <ArrowUpwardIcon />
            ) : (
              <ArrowDownwardIcon />
            )}
          </Avatar>
        ) : null}
      />
    );
  }
}

FieldChip.propTypes = {
  field: PropTypes.object.isRequired,
  onSortBy: PropTypes.func.isRequired,
  order: PropTypes.string,
};


export default FieldChip;
