import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';

import { TextField } from '../../formFields';


class CopyToClipboardDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    };
  }

  handleCopyToClipboard = () => {
    this.setState(
      { copied: true },
      () => {
        window.setTimeout(this.resetAndCloseDialog, 750);
      },
    );
  };

  resetAndCloseDialog = () => {
    this.setState({ copied: false });
    this.props.onClose();
  };

  render() {
    const { open, text, onClose } = this.props;
    const { copied } = this.state;

    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        className="copy-to-clipboard-dialog"
        onCancel={onClose}
      >
        <DialogContent className="dialog-content">
          <TextField
            className="html-field"
            value={text}
            fullWidth
            multiline
            minRows={8}
            readOnly
          />
        </DialogContent>
        <DialogActions className="dialog-actions">
          {!copied && (
            <Button onClick={onClose}>
              {'Cancel'}
            </Button>
          )}
          <CopyToClipboard
            text={text}
            onCopy={this.handleCopyToClipboard}
          >
            <Button
              color="primary"
              variant="contained"
              className={classnames('copy-to-clipboard-button', { copied })}
            >
              {copied ? 'Copied!' : 'Copy to Clipboard'}
            </Button>
          </CopyToClipboard>
        </DialogActions>
      </Dialog>
    );
  }
}

CopyToClipboardDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string,
};


export default CopyToClipboardDialog;
