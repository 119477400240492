export const PERMISSIONS = {
  ACTIVATE_DEACTIVATE_MEMBERSHIPS: 'admin_activateDeactivateMemberships',
  ACTIVATE_DEACTIVATE_PASSES: 'admin_activateDeactivatePasses',
  ACTIVATE_DEACTIVATE_PRODUCT: 'admin_activateDeactivateProducts',
  APPLY_DISCOUNTS: 'checkout_applyDiscounts',
  CANCEL_ORDER: 'crm_cancelOrder',
  CANCEL_MEMBERSHIPS: 'crm_cancelMemberships',
  CREATE_CUSTOMER_PROFILE: 'crm_createCustomerProfiles',
  EDIT_DISCOUNT_AND_END_DATE: 'crm_membershipDiscount',
  ADJUST_STORE_CREDIT: 'crm_adjustStoreCredit',
  CONVERT_GIFT_CARDS: 'crm_convertGiftCards',
  EDIT_MEMBERSHIP: 'crm_editingMemberships',
  ADJUST_POINTS: 'crm_adjustPoints',
  REDEEM_REWARDS: 'crm_redeemPoints',
  OPEN_EDIT_DRAWER: 'store_openEditCashDrawer',
  REFUND_ORDER: 'crm_refundOrder',
  RECORD_ADJUST_INVENTORY: 'admin_recordAdjustInventory',
  UPDATE_CUSTOMER_PROFILE: 'crm_updateCustomerProfiles',
  VOID_CHECKIN: 'calendar_voidCheckin',
  CREATE_AD_HOC_CHARGE: 'checkout_createAdHocCharge',
  CREATE_EVENT: 'calendar_createEvent',
};

export const SECTIONS = {
  ADMIN: 'admin',
  CALENDAR: 'calendar',
  CALENDAR_ADMIN: 'calendarAdmin',
  CHECKOUT: 'checkout',
  CRM: 'crm',
  FINANCES: 'finances',
  FORMS: 'forms',
  MERCHANT_TRACK: 'merchantTrack',
  POINTS: 'points',
  SETTINGS: 'settings',
  SUPPORT: 'support',
  STORE: 'store',
  STORE_ADMIN: 'storeAdmin',
  MEMBERSHIP_MANAGEMENT: 'membershipManagement',
};

export const SUB_SECTIONS = {
  STAFF: 'admin_staff',
  STAFF_ROLES: 'admin_staff_roles',
  CERTIFICATIONS: 'admin_certifications',
  LOCATIONS: 'admin_locations',
  DOCUMENT_TEMPLATES: 'admin_document_templates',
  PASS_TYPES: 'store_pass_types',
  MEMBERSHIP_TYPES: 'store_membership_types',
  GIFT_CARD_TYPES: 'store_gift_card_types',
  PRODUCTS: 'store_products',
  VARIANT_TYPES: 'store_variant_types',
  INVENTORY_LOGS: 'store_inventory_logs',
  PROMO_CODES: 'store_promo_codes',
  REVENUE_CATEOGORIES: 'store_revenue_categories',
  CUSTOMERS: 'crm_customers',
  CHECKINS: 'crm_checkins',
  GROUPS: 'crm_groups',
  MERGE_ACCOUNTS: 'crm_merge_accounts',
  CASH_DRAWER: 'store_cashDrawer',
  GIFTCARDS: 'store_giftcards',
};

export const APPLICATION_PERMISSIONS_DEFINITION = [
  {
    name: 'Calendar',
    description: 'This permission enables the calendar section.',
    UID: SECTIONS.CALENDAR,
    permissions: [
      {
        UID: PERMISSIONS.VOID_CHECKIN,
        name: 'Void Check-ins',
        description: 'When turned on, this permission will allow any staff member assigned this role to void any check-in',
      },
      {
        UID: PERMISSIONS.CREATE_EVENT,
        name: 'Create Events',
        description: 'When turned on, this permission will allow any staff member assigned this role to create events from the calendar view.',
      },
    ],
  },
  {
    //section
    name: 'CRM',
    description: 'This permission enables the CRM section.',
    UID: SECTIONS.CRM,
    permissions: [
      //sub sections
      //the APPLICATION_PERMISSIONS_DEFINITION can also have subsections to turn on and off
      /*
      {
          UID: SUB_SECTIONS.CUSTOMERS,
          name: 'Customers',
          description: 'This permission enables the Customers subsection.',
      },
      {
          UID: SUB_SECTIONS.CHECKINS,
          name: 'Checkins',
          description: 'This permission enables the Checkins subsection.',
      },
      {
          UID: SUB_SECTIONS.GROUPS,
          name: 'Groups',
          description: 'This permission enables the Groups subsection.',
      },
      {
          UID: SUB_SECTIONS.MERGE_ACCOUNTS,
          name: 'Merge Accounts',
          description: 'This permission enables the Merge Accounts subsection.',
      },*/
      //specific permissions
      {
        UID: PERMISSIONS.UPDATE_CUSTOMER_PROFILE,
        name: 'Update Customer Profiles',
        description: 'This permission allows staff members to edit and update customer profile information',
      },
      {
        UID: PERMISSIONS.CREATE_CUSTOMER_PROFILE,
        name: 'Create Customer Profiles',
        description: 'This permission allows staff members to create new customer profiles',
      },
      {
        UID: PERMISSIONS.EDIT_MEMBERSHIP,
        name: 'Editing Memberships',
        description: 'When turned on, this permission will allow staff members assigned this role to edit existing memberships. Please note if “Apply Discounts” (located under Checkout) is turned off staff will not be able to edit discounts on existing memberships',
      },
      {
        UID: PERMISSIONS.CANCEL_MEMBERSHIPS,
        name: 'Membership Cancellation',
        description: 'When turned on, any staff member assigned this role will be able to cancel a customer’s membership',
      },
      {
        UID: PERMISSIONS.MERGE_CUSTOMER_PROFILES,
        name: 'Merge Customer Profiles',
        description: 'Merging accounts will be allowed, when this permission is turned on, by any staff member assigned this role',
      },
      {
        UID: PERMISSIONS.REFUND_ORDER,
        name: 'Refund an Order',
        description: 'This setting, when turned on, will allow staff members to refund any order',
      },
      {
        UID: PERMISSIONS.CANCEL_ORDER,
        name: 'Cancel an Order',
        description: 'When this setting is turned on, it will enable the staff person to void any order.',
      },
      // {
      //   UID: SUB_SECTIONS.MERGE_ACCOUNTS,
      //   name: 'Merge Accounts',
      //   description: 'This permission enables the Merge Accounts subsection',
      // },
      {
        UID: PERMISSIONS.ADJUST_STORE_CREDIT,
        name: 'Adjust Store Credit',
        description: 'Turn this setting on to allow staff members to manually adjust store credit on customer profiles.',
      },
      {
        UID: PERMISSIONS.CONVERT_GIFT_CARDS,
        name: 'Convert Gift Cards to Store Credit',
        description: 'Turn this setting on to allow staff members to convert gift cards to store credit on customer profiles.',
      },
      {
        UID: PERMISSIONS.ADJUST_POINTS,
        name: 'Adjust Points',
        description: 'Turn this setting on to allow staff members to manually adjust points on customer profiles.',
      },
      {
        UID: PERMISSIONS.REDEEM_REWARDS,
        name: 'Redeem Rewards',
        description: 'Turn this setting on to allow staff members to redeem rewards for a customer.',
      },
    ],
  },
  {
    name: 'Store',
    description: 'This permission enables the Store section.',
    UID: SECTIONS.STORE,
    permissions: [
      // {
      //   UID: SUB_SECTIONS.GIFTCARDS,
      //   name: 'Giftcards',
      //   description: 'This permission enables the Giftcards subsection',
      // },
      // {
      //   UID: SUB_SECTIONS.CASH_DRAWER,
      //   name: 'Cash Drawer',
      //   description: 'This permission enables the Cash Drawer subsection',
      // },
      {
        UID: PERMISSIONS.OPEN_EDIT_DRAWER,
        name: 'Open and Edit Cash Drawer',
        description: 'This permission will allow any staff members assigned this role to create and edit daily cash drawers',
      },
    ],
  },
  {
    name: 'Membership Management',
    description: 'This permission enables the Membership Management section',
    UID: SECTIONS.MEMBERSHIP_MANAGEMENT,
    permissions: [],
  },
  {
    name: 'Finances',
    description: 'This permission enables the Finances section.',
    UID: SECTIONS.FINANCES,
    permissions: [],
  },
  {
    name: 'Loyalty Points',
    description: 'Turn this setting on to allow staff members to view the points tab and the two lists underneath it.',
    UID: SECTIONS.POINTS,
    permissions: [],
  },
  {
    name: 'Admin',
    description: 'This permission enables the Admin section.',
    UID: SECTIONS.ADMIN,
    permissions: [
      // {
      //   UID: SUB_SECTIONS.STAFF,
      //   name: 'Staff',
      //   description: 'This permission enables the Staff subsection',
      // },
      // {
      //   UID: SUB_SECTIONS.STAFF_ROLES,
      //   name: 'Staff Roles',
      //   description: 'This permission enables the Staff Roles subsection',
      // },
      // {
      //   UID: SUB_SECTIONS.CERTIFICATIONS,
      //   name: 'Certifications',
      //   description: 'This permission enables the Certifications subsection',
      // },
      // {
      //   UID: SUB_SECTIONS.LOCATIONS,
      //   name: 'Locations',
      //   description: 'This permission enables the Locations subsection',
      // },
      // {
      //   UID: SUB_SECTIONS.DOCUMENT_TEMPLATES,
      //   name: 'Document Templates',
      //   description: 'This permission enables the Document Templates subsection',
      // },
    ],
  },
  {
    name: 'Store Admin',
    description: 'This permission enables the Store Admin section.',
    UID: SECTIONS.STORE_ADMIN,
    permissions: [
      {
        UID: PERMISSIONS.ACTIVATE_DEACTIVATE_PRODUCT,
        name: 'Activate and Deactivate Products',
        description: 'This permission, when turned on, will allow any staff member assigned this role to activate or deactivate any product',
      },
      {
        UID: PERMISSIONS.ACTIVATE_DEACTIVATE_MEMBERSHIPS,
        name: 'Activate and Deactivate Membership Types',
        description: 'This permission, when turned on, will allow any staff member assigned this role to activate or deactivate any membership type',
      },
      {
        UID: PERMISSIONS.ACTIVATE_DEACTIVATE_PASSES,
        name: 'Activate and Deactivate Pass Types',
        description: 'This permission, when turned on, will allow any staff member assigned this role to activate or deactivate any pass type',
      },
      {
        UID: PERMISSIONS.RECORD_ADJUST_INVENTORY,
        name: 'Record and Adjust Inventory',
        description: 'Staff members assigned this role will be allowed to record new inventory and adjust current inventory when this permission is turned on.',
      },
      // {
      //   UID: SUB_SECTIONS.PASS_TYPES,
      //   name: 'Pass Types',
      //   description: 'This permission enables the Pass Types subsection',
      // },
      // {
      //   UID: SUB_SECTIONS.MEMBERSHIP_TYPES,
      //   name: 'Membership Types',
      //   description: 'This permission enables the Membership Types subsection',
      // },
      // {
      //   UID: SUB_SECTIONS.GIFT_CARD_TYPES,
      //   name: 'Gift Card Types',
      //   description: 'This permission enables the Gift Card Types subsection',
      // },
      // {
      //   UID: SUB_SECTIONS.PRODUCTS,
      //   name: 'Products',
      //   description: 'This permission enables the Products subsection',
      // },
      // {
      //   UID: SUB_SECTIONS.VARIANT_TYPES,
      //   name: 'Variant Types',
      //   description: 'This permission enables the Variant Types subsection',
      // },
      // {
      //   UID: SUB_SECTIONS.INVENTORY_LOGS,
      //   name: 'Inventory Logs',
      //   description: 'This permission enables the Inventory Logs subsection',
      // },
      // {
      //   UID: SUB_SECTIONS.PROMO_CODES,
      //   name: 'Promo Codes',
      //   description: 'This permission enables the Promo Codes subsection',
      // },
      // {
      //   UID: SUB_SECTIONS.REVENUE_CATEOGORIES,
      //   name: 'Revenue Categories',
      //   description: 'This permission enables the Revenue Categories subsection',
      // },
    ],
  },
  {
    name: 'Calendar Admin',
    description: 'This permission enables the Calendar Admin section.',
    UID: SECTIONS.CALENDAR_ADMIN,
    permissions: [],
  },
  {
    name: 'Settings',
    description: 'This permission enables the Settings section.',
    UID: SECTIONS.SETTINGS,
    permissions: [],
  },
  {
    name: 'Support',
    description: 'This permission enables the Support section.',
    UID: SECTIONS.SUPPORT,
    permissions: [],
  },
  {
    name: 'Checkout',
    description: 'This permission enables the Checkout section.',
    UID: SECTIONS.CHECKOUT,
    permissions: [
      {
        UID: PERMISSIONS.APPLY_DISCOUNTS,
        name: 'Apply Discounts',
        description: 'Turn this setting on to allow staff members with this role to be able to apply discounts everywhere discounts can be applied',
      },
      {
        UID: PERMISSIONS.CREATE_AD_HOC_CHARGE,
        name: 'Create Ad Hoc Charges',
        description: 'Turn this setting on to allow staff members with this role to be able to create ad hoc charges across the app.',
      },
    ],
  },
  {
    name: 'Forms',
    description: 'This permission enables the Forms section.',
    UID: SECTIONS.FORMS,
    permissions: [],
  },
];
