import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import {
  Table, TableBody, TableCell, TableRow, Typography, Divider, Button, IconButton,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import * as stateSelectors from 'app/redux/state/selectors';
import { Spinner } from 'common/statusIndicators';
import { locationUsesPriceBeforeTax } from 'service/i18n';
import { CartModel } from 'service/models';
import { roundTo2Decimals } from 'service/utility/numbers';
import { formatPriceWithoutTax } from 'service/utility/pricing';


const CartTotals = ({
  cart, cashAmount, checkout, currentLocation, giftCards, isCartLoading, paymentStarted, promoCode,
  removeCash, removeGiftCardAmount, removePromoCode, removeStoreCredit, storeCreditAmount,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const Cart = new CartModel(cart);
  const subtotal = Cart.getSubtotal();
  const total = Cart.getTotal();
  const totalPaid = Cart.getTotalPaid();
  const amountDue = Cart.getAmountDue();
  const totalDiscount = Cart.getTotalDiscount();
  const totalTax = Cart.getTotalTax();
  const totalCityTax = Cart.getTotalCityTax();
  const totalLocalTax = Cart.getTotalLocalTax();
  const totalStateTax = Cart.getTotalStateTax();
  const totalAdditionalTax = Cart.getTotalAdditionalTax();
  const cityTaxName = Cart.getCityTaxName();
  const localTaxName = Cart.getLocalTaxName();
  const stateTaxName = Cart.getStateTaxName();

  const giftCardsTotal = giftCards.reduce((a, b) => a + b.amount, 0);
  const cardAmountDue = roundTo2Decimals(amountDue - storeCreditAmount - cashAmount - giftCardsTotal);

  const hasCash = cashAmount > 0;
  const hasStoreCredit = storeCreditAmount > 0;
  const hasGiftCard = giftCards.length > 0;
  const hasPromoCode = Boolean(promoCode);

  return (
    <div className="cart-totals-wrapper">
      {isCartLoading ? (
        <Spinner />
      ) : (
        <Table className="cart-totals">
          <TableBody>
            {checkout && hasPromoCode && (
              <TableRow className="totals-row">
                <TableCell className="total-cell pb-3">
                  <div className="remove-cash-wrapper">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      classes={{ root: 'remove-cash-button' }}
                      onClick={removePromoCode}
                      disabled={paymentStarted}
                    >
                      {'Remove'}
                    </Button>
                    <Typography
                      variant="body1"
                      display="inline"
                    >
                      {'Promo Code'}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className="total-cell pb-3">
                  <Typography variant="body1">
                    {promoCode.code}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow className="totals-row">
              <TableCell className="total-cell font-weight-thick">
                {'Subtotal'}
              </TableCell>
              <TableCell className="total-cell">
                {formatPriceWithoutTax(subtotal, currentLocation)}
              </TableCell>
            </TableRow>
            <TableRow className="totals-row">
              <TableCell className="total-cell font-weight-thick">
                {'Total Discounts'}
              </TableCell>
              <TableCell className="total-cell">
                {`-${formatPriceWithoutTax(totalDiscount, currentLocation)}`}
              </TableCell>
            </TableRow>
            {locationUsesPriceBeforeTax(currentLocation) && (
              <>
                <TableRow className="totals-row">
                  <TableCell className="total-cell">
                    <IconButton
                      size="small"
                      onClick={() => setExpanded(!expanded)}
                      color="inherit"
                    >
                      {expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    </IconButton>
                    <span className="font-weight-thick">
                      {'Total Taxes'}
                    </span>
                  </TableCell>
                  <TableCell className="total-cell">
                    {formatPriceWithoutTax(totalTax, currentLocation)}
                  </TableCell>
                </TableRow>
                {expanded && (
                  <>
                    <TableRow className="totals-row">
                      <TableCell className="total-cell">
                        {stateTaxName || 'State Tax'}
                      </TableCell>
                      <TableCell className="total-cell">
                        {formatPriceWithoutTax(totalStateTax, currentLocation)}
                      </TableCell>
                    </TableRow>
                    <TableRow className="totals-row">
                      <TableCell className="total-cell">
                        {cityTaxName || 'City Tax'}
                      </TableCell>
                      <TableCell className="total-cell">
                        {formatPriceWithoutTax(totalCityTax, currentLocation)}
                      </TableCell>
                    </TableRow>
                    <TableRow className="totals-row">
                      <TableCell className="total-cell">
                        {localTaxName || 'Local Tax'}
                      </TableCell>
                      <TableCell className="total-cell">
                        {formatPriceWithoutTax(totalLocalTax, currentLocation)}
                      </TableCell>
                    </TableRow>
                    <TableRow className="totals-row">
                      <TableCell className="total-cell">
                        {'Additional Tax'}
                      </TableCell>
                      <TableCell className="total-cell">
                        {formatPriceWithoutTax(totalAdditionalTax, currentLocation)}
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </>
            )}
            <TableRow className="totals-row">
              <TableCell className="total-cell">
                <Typography
                  variant="body1"
                  className="font-weight-thick"
                >
                  {'Total'}
                </Typography>
              </TableCell>
              <TableCell className="total-cell">
                <Typography
                  variant="body1"
                  className="font-weight-thick"
                >
                  {formatPriceWithoutTax(total, currentLocation)}
                </Typography>
              </TableCell>
            </TableRow>
            {totalPaid > 0 && (
              <>
                <TableRow className="totals-row">
                  <TableCell className="total-cell">
                    {'Already Paid'}
                  </TableCell>
                  <TableCell className="total-cell">
                    {formatPriceWithoutTax(totalPaid, currentLocation)}
                  </TableCell>
                </TableRow>
                <TableRow className="totals-row">
                  <TableCell className="total-cell">
                    <Typography
                      variant="body1"
                      className="font-weight-thick"
                    >
                      {'Remaining to be Paid'}
                    </Typography>
                  </TableCell>
                  <TableCell className="total-cell">
                    <Typography
                      variant="body1"
                      className="font-weight-thick"
                    >
                      {formatPriceWithoutTax(amountDue, currentLocation)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
            {hasCash && (
              <TableRow className="totals-row">
                <TableCell
                  className="divider-cell"
                  colSpan={2}
                >
                  <Divider className="cash-divider" />
                  <LocalAtmIcon />
                </TableCell>
              </TableRow>
            )}
            {hasCash && (
              <TableRow className="totals-row">
                <TableCell className="total-cell">
                  <div className="remove-cash-wrapper">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      classes={{ root: 'remove-cash-button' }}
                      onClick={removeCash}
                      disabled={paymentStarted}
                    >
                      {'Remove'}
                    </Button>
                    <Typography
                      variant="body1"
                      display="inline"
                    >
                      {'Cash'}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className="total-cell">
                  <Typography variant="body1">
                    {formatPriceWithoutTax(cashAmount, currentLocation)}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {hasStoreCredit && (
              <TableRow className="totals-row">
                <TableCell
                  className="divider-cell"
                  colSpan={2}
                >
                  <Divider className="cash-divider" />
                  <MonetizationOnIcon />
                </TableCell>
              </TableRow>
            )}
            {hasStoreCredit && (
              <TableRow className="totals-row">
                <TableCell className="total-cell">
                  <div className="remove-cash-wrapper">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      classes={{ root: 'remove-cash-button' }}
                      onClick={removeStoreCredit}
                      disabled={paymentStarted}
                    >
                      {'Remove'}
                    </Button>
                    <Typography
                      variant="body1"
                      display="inline"
                    >
                      {'Store Credit'}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className="total-cell">
                  <Typography variant="body1">
                    {formatPriceWithoutTax(storeCreditAmount, currentLocation)}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {hasGiftCard && (
              <TableRow className="totals-row">
                <TableCell
                  className="divider-cell"
                  colSpan={2}
                >
                  <Divider />
                  <CardGiftcardIcon />
                </TableCell>
              </TableRow>
            )}
            {hasGiftCard && (
              giftCards.map((giftCard, idx) => (
                <TableRow
                  key={idx}
                  className="totals-row"
                >
                  <TableCell className="total-cell">
                    <div className="remove-cash-wrapper">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        classes={{ root: 'remove-cash-button' }}
                        onClick={() => removeGiftCardAmount(idx)}
                        disabled={paymentStarted}
                      >
                        {'Remove'}
                      </Button>
                      <Typography
                        variant="body1"
                        display="inline"
                      >
                        {'Gift Card'}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell className="total-cell">
                    <Typography variant="body1">
                      {formatPriceWithoutTax(giftCard.amount, currentLocation)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            )}
            {(hasCash || hasGiftCard || hasStoreCredit) && (
              <TableRow className="totals-row">
                <TableCell
                  className="divider-cell"
                  colSpan={2}
                >
                  <Divider />
                </TableCell>
              </TableRow>
            )}
            {(hasCash || hasGiftCard || hasStoreCredit) && (
              <TableRow className="totals-row">
                <TableCell className="total-cell">
                  <Typography variant="body1">
                    {'Amount Due'}
                  </Typography>
                </TableCell>
                <TableCell className="total-cell">
                  <Typography variant="body1">
                    {formatPriceWithoutTax(cardAmountDue, currentLocation)}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

CartTotals.propTypes = {
  cart: PropTypes.object.isRequired,
  cashAmount: PropTypes.number,
  checkout: PropTypes.bool,
  currentLocation: PropTypes.object.isRequired,
  giftCards: PropTypes.array,
  isCartLoading: PropTypes.bool.isRequired,
  paymentStarted: PropTypes.bool,
  promoCode: PropTypes.object,
  removeCash: PropTypes.func,
  removeGiftCardAmount: PropTypes.func,
  removePromoCode: PropTypes.func,
  removeStoreCredit: PropTypes.func,
  storeCreditAmount: PropTypes.number,
};

CartTotals.defaultProps = {
  cashAmount: 0,
  giftCards: [],
  storeCreditAmount: 0,
};


const mapStateToProps = (state) => ({
  currentLocation: stateSelectors.currentLocation(state),
});

export default connect(mapStateToProps)(CartTotals);
