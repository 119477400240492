import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { onSnapshot as fbFsOnSnapshot, setDoc as fbFsSetDoc } from 'firebase/firestore';

import * as authSelectors from 'app/redux/auth/selectors';
import * as cartActions from 'app/redux/cart/actions';
import * as cartSelectors from 'app/redux/cart/selectors';
import * as stateSelectors from 'app/redux/state/selectors';
import * as transactionSelectors from 'app/redux/transactions/selectors';
import { dialogActions } from 'app/redux/ui/actions';
import ErrorBoundary from 'common/ErrorBoundary';
import { ITEM_TYPES_REQUIRING_CUSTOMER } from 'config/itemTypes';
import firebaseService from 'service/firebase';
import { CartModel } from 'service/models';
import { getRoutePath } from 'service/navigation';
import { commaSpaceJoin } from 'service/utility';
import * as timeUtils from 'service/utility/timeUtils';

import SideCartView from './SideCartView';


class SideCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCustomerAlert: false,
    };
  }

  componentDidMount() {
    this.cartsLastUpdatedDT = null;

    this.subscribe();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentLocation.id !== prevProps.currentLocation.id) {
      this.subscribe();
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  getActiveCart = () => this.props.activeCart;

  getCurrentStaff = () => this.props.currentStaff;

  subscribe = () => {
    const { currentLocation, confirmAction, createNewCart } = this.props;

    this.unsubscribe();

    const docRef = firebaseService.getLocationFsDoc(currentLocation.id);
    this._unsubscribe = fbFsOnSnapshot(
      docRef,
      (doc) => {
        if (!doc.exists()) {
          fbFsSetDoc(docRef, { cartsLastUpdatedDT: timeUtils.iso8601NowInUTC() }, { merge: true });
          return;
        }

        const fsData = doc.data();
        const { cartsLastUpdatedDT, stolenCartId, stolenCartStaffId } = fsData;

        if (cartsLastUpdatedDT !== this.cartsLastUpdatedDT) {
          console.log(commaSpaceJoin([
            `cartsLastUpdatedDT=${cartsLastUpdatedDT}`,
            `stolenCartId=${stolenCartId}`,
            `stolenCartStaffId=${stolenCartStaffId}`,
          ]));
          const activeCart = this.getActiveCart();
          const currentStaff = this.getCurrentStaff();

          if (
            activeCart && activeCart.UUID &&
            activeCart.UUID === stolenCartId &&
            currentStaff.id !== stolenCartStaffId
          ) {
            // My cart was just stolen
            confirmAction(
              createNewCart,
              {
                hideCancel: true,
                title: 'A Staff Member took over your current cart.',
                message: 'A new empty one will be created.',
              },
            );
          }

          this.cartsLastUpdatedDT = cartsLastUpdatedDT;
        }
      }
    );
  };

  unsubscribe = () => {
    this.cartsLastUpdatedDT = null;

    if (this._unsubscribe) {
      this._unsubscribe();
      this._unsubscribe = null;
    }
  };

  handleCheckoutClick = () => {
    const { activeCart, history } = this.props;

    const ActiveCart = new CartModel(activeCart);
    if (!ActiveCart.hasCustomer() && ActiveCart.requiresCustomer(ITEM_TYPES_REQUIRING_CUSTOMER)) {
      this.setState({ showCustomerAlert: true });
    } else {
      history.push(getRoutePath('CHECKOUT'));
    }
  };

  closeCustomerAlert = () => {
    this.setState({ showCustomerAlert: false });
  };

  render() {
    const {
      show, activeCart, paymentStatus, isCartLoading, removePromoCodeFromCart,
    } = this.props;
    const { showCustomerAlert } = this.state;

    return show && (
      <ErrorBoundary>
        <SideCartView
          activeCart={activeCart}
          isCartLoading={isCartLoading}
          onCheckoutClick={this.handleCheckoutClick}
          onCloseCustomerAlert={this.closeCustomerAlert}
          paymentStarted={paymentStatus.started}
          removePromoCode={removePromoCodeFromCart}
          showCustomerAlert={showCustomerAlert}
        />
      </ErrorBoundary>
    );
  }
}

SideCart.propTypes = {
  activeCart: PropTypes.object,
  confirmAction: PropTypes.func.isRequired,
  createNewCart: PropTypes.func.isRequired,
  currentLocation: PropTypes.object.isRequired,
  currentStaff: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  paymentStatus: PropTypes.object,
  removePromoCodeFromCart: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

SideCart.defaultProps = {
  paymentStatus: {},
};


const mapStateToProps = (state) => ({
  activeCart: cartSelectors.activeCartSelector(state),
  currentLocation: stateSelectors.currentLocation(state),
  currentStaff: authSelectors.currentStaff(state),
  isCartLoading: cartSelectors.isCartLoading(state),
  paymentStatus: transactionSelectors.paymentStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  confirmAction: (callback, options) => dispatch(dialogActions.confirmAction(callback, options)),
  createNewCart: () => dispatch(cartActions.createNewCart()),
  removePromoCodeFromCart: () => dispatch(cartActions.removePromoCodeFromCart()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideCart));
