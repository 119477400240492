import { createReducer } from 'service/utility/redux';

import { SHOW_NEW_GIFT_CARD_DIALOG, HIDE_NEW_GIFT_CARD_DIALOG } from '../actions/dialog';


const initState = {
  isOpen: false,
  customer: null,
};

const newGiftCardDialog = createReducer(initState, {
  [SHOW_NEW_GIFT_CARD_DIALOG](state, { customer }) {
    return {
      ...state,
      isOpen: true,
      customer,
    };
  },
  [HIDE_NEW_GIFT_CARD_DIALOG](state) {
    return {
      ...state,
      isOpen: false,
      customer: null,
    };
  },
});


export default newGiftCardDialog;
