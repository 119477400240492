import React from 'react';
import { PropTypes } from 'prop-types';

import { Center, Header } from 'common/layoutComponents';

import {
  LocationSelector, LogoButton, QRCodeReader,
  ToggleCart, ToggleChat, ToggleNotifications, UserIcon,
} from '../components';


const MinimalLayout = ({ children }) => (
  <>
    <Header
      center={
        <LogoButton />
      }
      right={
        <>
          <QRCodeReader />
          <LocationSelector />
          <UserIcon />
          <ToggleChat disabled />
          <ToggleNotifications disabled />
          <ToggleCart disabled />
        </>
      }
    />
    <Center>
      {children}
    </Center>
  </>
);

MinimalLayout.propTypes = {
  children: PropTypes.any,
};


export default MinimalLayout;
