import { createReducer } from 'service/utility/redux';

import { TOGGLE_ALERT_ON_NOTES_DIALOG } from '../actions/dialog';


const initState = null;

const alertOnNotesDialog = createReducer(initState, {
  [TOGGLE_ALERT_ON_NOTES_DIALOG](state, { customerId }) {
    return customerId;
  },
});

export default alertOnNotesDialog;
