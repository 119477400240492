import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { components } from 'react-select';

import * as API from 'service/api';

import GenericSearch from '../GenericSearch';
import PricingTierSearchItem from './PricingTierSearchItem';


const getOptionValue = (option) => option.id;

const getOptionLabel = (option) => option.name;

/* eslint-disable */
const generateOption = (OptionComponent, currentLocation) => (props) => (
  <components.Option { ...props}>
    <OptionComponent
      {...props}
      currentLocation={currentLocation}
    />
  </components.Option>
);
/* eslint-enable */


class PricingTierSearch extends React.Component {
  getOptions = (input) => {
    const { filter } = this.props;

    return API.findPricingTier(`q=${input}&isActive=1`).then(
      ({ data: { data } }) => (filter ? filter(data) : data),
    );
  };


  render() {
    const { className, currentLocation, ...rest } = this.props;

    const suggestionItem = generateOption(PricingTierSearchItem, currentLocation);

    return (
      <GenericSearch
        suggestionItem={suggestionItem}
        getOptions={this.getOptions}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        className={classnames('pricing-tier-search', { [className]: Boolean(className) })}
        {...rest}
      />
    );
  }
}

PricingTierSearch.propTypes = {
  autoFocus: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  className: PropTypes.string,
  currentLocation: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  filter: PropTypes.func,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.object,
};

PricingTierSearch.defaultProps = {
  autoFocus: false,
  cacheOptions: true,
  className: '',
  disabled: false,
  error: '',
  fullWidth: true,
  placeholder: '',
  required: false,
};


export default PricingTierSearch;
