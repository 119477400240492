import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  MenuItem, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

import * as stateSelectors from 'app/redux/state/selectors';
import { formatName, formatInitials } from 'service/utility/stringFormatters';
import * as timeUtils from 'service/utility/timeUtils';


class CartMenuItem extends React.Component {
  handleCartSelect = () => {
    this.props.onCartSelect(this.props.cart);
  };

  render() {
    const { timeZone, cart, isSelected } = this.props;

    const isOld = !timeUtils.isInTheLast24h(cart.updatedAt, timeZone);

    return (
      <MenuItem
        button
        selected={isSelected}
        onClick={this.handleCartSelect}
        className="cart-selector-menu-item"
      >
        <ListItemAvatar className="cart-selector-listitem-avatar">
          <Avatar
            className="cart-selector-avatar"
            src={cart.customer?.imageURL || null}
          >
            {!cart.customer?.imageURL && (
              cart.customer ? (
                <span>
                  {formatInitials(cart.customer)}
                </span>
              ) : (
                <PersonIcon />
              )
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={cart.customerId ? formatName(cart.customer) : 'Guest Cart'}
          secondary={`${timeUtils.shortHumanDuration(cart.updatedAt, timeZone)} ago`}
          classes={{
            primary: 'overflow-hidden',
            secondary: classnames({ 'old-cart': isOld }),
          }}
        />
        {Boolean(cart.staffId) && (
          <ListItemSecondaryAction className="cart-selector-listitem-secondary">
            <Avatar
              className="cart-selector-avatar"
              src={cart.staff.imageURL || null}
              title={formatName(cart.staff)}
            >
              {!cart.staff.imageURL && (
                <span>
                  {formatInitials(cart.staff)}
                </span>
              )}
            </Avatar>
          </ListItemSecondaryAction>
        )}
      </MenuItem>
    );
  }
}

CartMenuItem.propTypes = {
  cart: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onCartSelect: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
};


const mapStateToProps = (state) => ({
  timeZone: stateSelectors.timeZone(state),
});

export default connect(mapStateToProps)(CartMenuItem);
