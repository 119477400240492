import { createReducer } from 'service/utility/redux';

import {
  AUTH_LOGGED_IN_SUCCESS, AUTH_LOGGED_OUT_SUCCESS, USER_IMAGE_HAS_CHANGED,
} from '../actions';


const initialState = {
  isLogged: false,
  currentUserUID: null,
  currentUserEmail: '',
  imageURL: null,
};

const currentUser = createReducer(initialState, {
  [AUTH_LOGGED_IN_SUCCESS](state, { user }) {
    return {
      ...state,
      isLogged: true,
      currentUserUID: user.uid,
      currentUserEmail: user.email,
    };
  },

  [AUTH_LOGGED_OUT_SUCCESS](state) {
    return {
      ...state,
      isLogged: false,
      currentUserUID: null,
      currentUserEmail: null,
    };
  },

  [USER_IMAGE_HAS_CHANGED](state, { imageURL }) {
    return {
      ...state,
      imageURL,
    };
  },
});

export default currentUser;
