import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
  getLocationLocale, getLocationCurrencyCode,
  getLocationCurrencySymbol, getLocationCurrencyDecimalPlaces,
} from 'service/i18n';

import NumberField from './NumberField';


const CurrencyField = ({ currentLocation, dispatch, className, InputProps, ...rest }) => {
  const currencyFieldClassName = classnames(
    'currency-field',
    { [className]: Boolean(className) },
  );
  const locale = getLocationLocale(currentLocation);
  const currencyCode = getLocationCurrencyCode(currentLocation);
  const currencySymbol = getLocationCurrencySymbol(currentLocation);
  const currencyDecimalPlaces = getLocationCurrencyDecimalPlaces(currentLocation);
  const inputProps = {
    decimalScale: currencyDecimalPlaces,
    thousandSeparator: true,
  };
  const zeroString = new Intl.NumberFormat(locale, {
    style: 'currency', currency: currencyCode, minimumFractionDigits: 0,
  }).format('0');

  const InputProps2 = { ...InputProps };
  if (zeroString.charAt(0) === '0') {
    InputProps2.endAdornment = InputProps2.endAdornment || (
      <InputAdornment position="end">{currencySymbol}</InputAdornment>
    );
  } else {
    InputProps2.startAdornment = InputProps2.startAdornment || (
      <InputAdornment position="start">{currencySymbol}</InputAdornment>
    );
  }

  return (
    <NumberField
      {...rest}
      InputProps={InputProps2}
      inputProps={inputProps}
      className={currencyFieldClassName}
    />
  );
};

CurrencyField.propTypes = {
  allowNegative: PropTypes.bool,
  className: PropTypes.string,
  currentLocation: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  InputProps: PropTypes.object,
};

CurrencyField.defaultProps = {
  allowNegative: false,
  InputProps: {},
};


export default CurrencyField;
