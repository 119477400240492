import { makeActionCreator } from 'service/utility/redux';


export const CANCEL_ORDER = 'CANCEL_ORDER';
export const cancelOrder = makeActionCreator(CANCEL_ORDER, 'payload', 'callback');

export const SET_PAYMENT_DEVICE = 'SET_PAYMENT_DEVICE';
export const setPaymentDevice = makeActionCreator(SET_PAYMENT_DEVICE, 'payload');

export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';
export const setPaymentStatus = makeActionCreator(SET_PAYMENT_STATUS, 'payload');

export const RESET_PAYMENT_STATUS = 'RESET_PAYMENT_STATUS';
export const resetPaymentStatus = makeActionCreator(RESET_PAYMENT_STATUS);

export const NEW_ORDER_PAYMENT = 'NEW_ORDER_PAYMENT';
export const newOrderPayment = makeActionCreator(NEW_ORDER_PAYMENT, 'payload', 'callback');

export const TERMINATE_ORDER = 'TERMINATE_ORDER';
export const terminateOrder = makeActionCreator(TERMINATE_ORDER, 'payload');

export const TERMINATE_ORDER_PAYMENT = 'TERMINATE_ORDER_PAYMENT';
export const terminateOrderPayment = makeActionCreator(TERMINATE_ORDER_PAYMENT);

export const NEW_REFUND = 'NEW_REFUND';
export const newRefund = makeActionCreator(NEW_REFUND, 'payload', 'callback');

export const TERMINATE_REFUND = 'TERMINATE_REFUND';
export const terminateRefund = makeActionCreator(TERMINATE_REFUND, 'payload');
