import { createReducer } from 'service/utility/redux';

import { TOGGLE_QR_SCANNER } from '../actions/scanning';


const initialState = true;

const qrScanner = createReducer(initialState, {
  [TOGGLE_QR_SCANNER]: (state) => !state,
});

export default qrScanner;
