import { createReducer } from 'service/utility/redux';

import { SET_CURRENT_LOCATION } from '../actions';


const initState = null;

const currentLocation = createReducer(initState, {
  [SET_CURRENT_LOCATION]: (state, { location }) => location,
});

export default currentLocation;
