import React from 'react';
import { PropTypes } from 'prop-types';

import { Spinner } from '../statusIndicators';
import PicklistField from './PicklistField';


class PickRoleField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      pickListValues: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.loadPickListValues();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadPickListValues = async () => {
    const { picklistValuesAPI } = this.props;

    const { data } = await picklistValuesAPI();
    const pickListValues_ = data ? (data.data || data) : [];
    const pickListValues = pickListValues_.map((value) => ({ value: value.id, label: value.name }));

    if (this._isMounted) {
      this.setState({
        loading: false,
        pickListValues,
      });
    }
  };

  render() {
    const { picklistValuesAPI, ...rest } = this.props;
    const { pickListValues, loading } = this.state;

    return loading ? (
      <Spinner />
    ) : (
      <PicklistField
        {...rest}
        picklistValues={pickListValues}
      />
    );
  }
}

PickRoleField.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  picklistValuesAPI: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};


export default PickRoleField;
