import { take, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getErrorMessage } from 'service/utility';

import { UNASSIGN_CART_CUSTOMER, setCartLoading } from '../actions';
import { activeCartIdSelector } from '../selectors';
import { loadCart, unassignCustomerFromCart } from './cartSagas';


function *unassignCartCustomerSaga() {
  while (true) {
    yield take(UNASSIGN_CART_CUSTOMER);

    const cartId = yield select(activeCartIdSelector);
    if (!cartId) return null;

    const toast1 = toast('Unassigning...');

    try {
      yield put(setCartLoading(true));
      yield call(unassignCustomerFromCart, cartId);
      yield call(loadCart, cartId);
      toast.dismiss(toast1);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('unassignCartCustomerSaga error: ');
      console.log(errorMessage);

      toast.dismiss(toast1);
      toast.error(errorMessage);
    } finally {
      yield put(setCartLoading(false));
    }
  }
}


export default unassignCartCustomerSaga;
