const MP = 'one or more memberships and/or passes';
// const FEE = 'an entrance fee';
const GROUP = 'one or more groups';

const ERROR_ENTRANCE_REQUIREMENTS_MP = `Select ${MP}`;
// const ERROR_ENTRANCE_REQUIREMENTS_FEE = `Select ${FEE}`;
// const ERROR_ENTRANCE_REQUIREMENTS_MP_AND_FEE = `Select ${MP} and ${FEE}`;
// const ERROR_ENTRANCE_REQUIREMENTS_MP_OR_FEE = `Select ${MP} or ${FEE}`;
const ERROR_ENTRANCE_REQUIREMENTS_GROUP = `Select ${GROUP}`;


export const BILLING_TYPES = [
  {
    name: 'Invoice',
    value: 'invoice',
    hasBookingType: true,
    hasInvoices: true,
    isFilled: (booking) => Boolean(booking.organizerId),
    isCancellable: (booking) => booking.status === 'active',
    rollingBookingInDaysOptions: [1, 5, 10, 20, 30, 45, 60, 90, 120, 150, 180, 210, 240, 270, 300],
    peopleOrTicketsLabel: 'Attendees Checked In',
  },
  {
    name: 'First Come, First Served',
    value: 'fcfs',
    hasEntranceRequirements: true,
    isFilled: (booking) => booking.customerCount > 0,
    isCancellable: (booking) => !booking.customerCount,
    rollingBookingInDaysOptions: [1, 5, 10, 20, 30, 45, 60, 90, 120, 150, 180, 210, 240, 270, 300],
    peopleOrTicketsLabel: 'Attendees',
  },
  {
    name: 'Ticketed',
    value: 'ticket',
    hasBookingType: true,
    hasTickets: true,
    isFilled: (booking) => booking.customerCount > 0,
    isCancellable: (booking) => !booking.customerCount,
    rollingBookingInDaysOptions: [1, 5, 10, 20, 30, 45, 60, 90, 120, 150, 180, 210, 240, 270, 300],
    peopleOrTicketsLabel: 'Tickets Sold',
  },
  {
    name: 'Multi-day Ticketed',
    value: 'multi-day',
    hasTickets: true,
    hasMultiDayTickets: true,
    isFilled: (booking) => booking.event.customerCount > 0,
    isCancellable: (booking) => !booking.event.customerCount,
    rollingBookingInDaysOptions: [1, 5, 10, 20, 30, 45, 60, 90, 120, 150, 180, 210, 240, 270, 300],
    peopleOrTicketsLabel: 'Tickets Sold',
  },
  // {
  //   name: '--none--',
  //   value: 'none',
  //   hasGuests: true,
  // },
];
export const BILLING_TYPE_MAP = BILLING_TYPES.reduce((a, b) => {
  a[b.value] = b;
  return a;
}, {});

export const BOOKING_TYPES = [
  // {
  //   name: 'Series',
  //   value: 'series',
  // },
  {
    name: 'Instance',
    value: 'instance',
  },
];

export const RECURRENCE_OPTIONS = [
  'DOES_NOT_REPEAT',
  'CUSTOM_RECURRENCE',
  'CUSTOM',
];

export const ENTRANCE_REQUIREMENTS = [
  {
    name: 'Membership or Pass',
    value: 'MP',
    hasPlan: true,
    hasPlanRequired: true,
    error: ERROR_ENTRANCE_REQUIREMENTS_MP,
  },
  // {
  //   name: 'Entrance Fee',
  //   value: 'Fee',
  //   hasFee: true,
  //   hasFeeRequired: true,
  //   error: ERROR_ENTRANCE_REQUIREMENTS_FEE,
  // },
  // {
  //   name: 'Membership and Entrance Fee',
  //   value: 'MP&&Fee',
  //   hasPlan: true,
  //   hasPlanRequired: true,
  //   hasFee: true,
  //   hasFeeRequired: true,
  //   error: ERROR_ENTRANCE_REQUIREMENTS_MP_AND_FEE,
  // },
  // {
  //   name: 'Membership or Entrance Fee',
  //   value: 'MP||Fee',
  //   hasPlan: true,
  //   hasFee: true,
  //   error: ERROR_ENTRANCE_REQUIREMENTS_MP_OR_FEE,
  // },
  {
    name: 'Group',
    value: 'group',
    hasGroup: true,
    hasGroupRequired: true,
    error: ERROR_ENTRANCE_REQUIREMENTS_GROUP,
  },
];
export const ENTRANCE_REQUIREMENT_MAP = ENTRANCE_REQUIREMENTS.reduce((a, b) => {
  a[b.value] = b;
  return a;
}, {});
export const ENTRANCE_REQUIREMENTS_ERRORS = ENTRANCE_REQUIREMENTS.map((er) => er.error);

export const FREQUENCIES = ['Yearly', 'Monthly', 'Weekly', 'Daily', 'Hourly', 'Minutely', 'Secondly'];
export const FREQUENCIES_DISPLAY_ORDER = [3, 2, 1];
export const FREQUENCIES_RRULE = FREQUENCIES.map((f) => f.toUpperCase());

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const WEEK_DAYS_3 = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const WEEK_DAYS_DISPLAY_ORDER = [6, 0, 1, 2, 3, 4, 5];
export const WEEK_DAYS_RRULE = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
export const WEEK_DAYS_SHORT = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

export const SET_POSS = [1, 2, 3, 4, -1];
export const ORDINALS = ['1st', '2nd', '3rd', '4th', 'last'];

export const ROLLING_BOOKING_IN_DAYS_DEFAULT = 30;

export const PARTICIPATION_STATUS = ['full', 'empty', 'partial'/*, 'overbooked'*/];
