import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, CardActions, CardContent, DialogContentText } from '@material-ui/core';

import { AlertDialog } from 'common/dialogs';
import { CartModel } from 'service/models';

import Cart from 'app/cart/Cart';
import CartTotals from 'app/cart/CartTotals';


const SideCartView = ({
  activeCart, isCartLoading,
  onCheckoutClick, onCloseCustomerAlert, paymentStarted,
  removePromoCode, showCustomerAlert,
}) => {
  const ActiveCart = new CartModel(activeCart);
  const isCartEmpty = ActiveCart.isEmpty();
  const cartPromoCode = ActiveCart.getPromoCode();

  return (
    <div className="side-cart">
      <div className="side-cart-content">
        <Cart />
        {!isCartEmpty && (
          <CardContent classes={{ root: 'bottom-wrapper' }}>
            <div className="bottom-right-wrapper">
              <CartTotals
                cart={activeCart}
                isCartLoading={isCartLoading}
                removePromoCode={removePromoCode}
                paymentStarted={paymentStarted}
                promoCode={cartPromoCode}
              />
            </div>
          </CardContent>
        )}
        <CardActions classes={{ root: 'bottom-actions' }}>
          <Button
            fullWidth
            variant="contained"
            onClick={onCheckoutClick}
            disabled={isCartEmpty}
            classes={{ root: 'checkout-button', disabled: 'disabled' }}
          >
            {'Checkout'}
          </Button>
        </CardActions>
        {showCustomerAlert && (
          <AlertDialog
            open
            onClose={onCloseCustomerAlert}
            title="Warning"
          >
            <DialogContentText>
              {'You need to assign a customer to this cart before checkout.'}
            </DialogContentText>
          </AlertDialog>
        )}
      </div>
    </div>
  );
};

SideCartView.propTypes = {
  activeCart: PropTypes.object,
  isCartLoading: PropTypes.bool.isRequired,
  onCheckoutClick: PropTypes.func.isRequired,
  onCloseCustomerAlert: PropTypes.func.isRequired,
  paymentStarted: PropTypes.bool,
  removePromoCode: PropTypes.func.isRequired,
  showCustomerAlert: PropTypes.bool.isRequired,
};


export default SideCartView;
