import React from 'react';


const SuspenseFallback = () => (
  <div>
    {'Loading...'}
  </div>
);


export default SuspenseFallback;
