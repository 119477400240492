import { take, select, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getErrorMessage } from 'service/utility';

import { PUT_CART_DISCOUNT, setCartLoading } from '../actions';
import { activeCartIdSelector } from '../selectors';
import { putCartDiscount, loadCart } from './cartSagas';


function *putCartDiscountSaga() {
  while (true) {
    const { discount } = yield take(PUT_CART_DISCOUNT);

    const cartId = yield select(activeCartIdSelector);
    if (!cartId) return null;

    const toast1 = toast('Setting discount...');

    try {
      yield put(setCartLoading(true));
      yield call(putCartDiscount, cartId, discount);
      yield call(loadCart, cartId);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('putCartDiscountSaga error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    } finally {
      toast.dismiss(toast1);
      yield put(setCartLoading(false));
    }
  }
}


export default putCartDiscountSaga;
