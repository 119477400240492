import React from 'react';
import { PropTypes } from 'prop-types';

import { REPORT_TYPES } from 'constants/revenueCategoryReports';
import { somePropsChanged } from 'service/utility';
import { capital } from 'service/utility/stringFormatters';

import { PicklistField } from '../../formFields';


const REPORT_TYPE_PICKLIST_VALUES = [
  {
    label: 'All',
    value: 0,
  },
  ...REPORT_TYPES.map((reportType) => ({
    label: capital(reportType),
    value: reportType,
  })),
];


class ReportTypeFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      reportType: (
        this.props.hasOwnProperty('type') && this.props.type !== 'null'
          ? this.props.type
          : 0
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['reportType'])) {
      this.handleValueChange();
    }
  }

  getOwnFilterMap = () => ({
    type: this.state.reportType === 0 ? null : this.state.reportType,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleReportTypeChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ reportType: update.value });
    }
  };


  render() {
    const { reportType } = this.state;

    return (
      <PicklistField
        fullWidth
        className="report-type-filter"
        label="Report Type"
        value={reportType}
        onChange={this.handleReportTypeChange}
        picklistValues={REPORT_TYPE_PICKLIST_VALUES}
      />
    );
  }
}

ReportTypeFilter.propTypes = {
  onValueChange: PropTypes.func,
  type: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

ReportTypeFilter.ownName = 'ReportTypeFilter';
ReportTypeFilter.ownFilters = ['type'];


export default ReportTypeFilter;
