import { toast } from 'react-toastify';

import * as API from '../api';
import firebaseService from '../firebase';
import { getErrorMessage } from '../utility';


const uploadImage = async (passTypeId, uploaded64) => {
  if (!uploaded64) return null;

  try {
    const imageURL = await firebaseService.uploadImage(`passType/${passTypeId}`, uploaded64);

    return imageURL;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('Error uploading pass type image; error = ');
    console.log(errorMessage);

    toast.error(errorMessage);
    return null;
  }
};

const uploadImageAndUpdate = async (passTypeId, uploaded64) => {
  if (!uploaded64) return;

  const imageURL = await uploadImage(passTypeId, uploaded64);

  if (!imageURL) return;

  try {
    await API.putPassType({
      id: passTypeId,
      imageURL,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('Error updating pass type; error = ');
    console.log(errorMessage);

    toast.error(errorMessage);
  }
};


const PassTypeModel = {
  uploadImage,
  uploadImageAndUpdate,
};

export default PassTypeModel;
