import { put, take, call, select } from 'redux-saga/effects';

import { getLocationLocale } from 'service/i18n';
import { setNewLocale } from 'service/utility';

import { CHANGE_LOCATION, setCurrentLocation } from '../actions';
import { currentLocation as currentLocationSelector } from '../selectors';
import { createNewEmptyCart } from '../../cart/sagas/cartSagas';


export function *changeLocation(location) {
  console.log('*changeLocation; new location:');
  console.log(location);

  const oldLocation = yield select(currentLocationSelector);

  yield put(setCurrentLocation(location));
  yield call(createNewEmptyCart);

  const currentLocale = getLocationLocale(oldLocation);
  const newLocale = getLocationLocale(location);

  if (newLocale !== currentLocale) {
    setNewLocale(newLocale);
  }
}

export function *changeLocationSaga() {
  while (true) {
    const { location } = yield take(CHANGE_LOCATION);

    yield call(changeLocation, location);
  }
}
