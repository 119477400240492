import React from 'react';
import { PropTypes } from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';


const InfoTooltip = (props) => (
  <Tooltip
    title={props.children}
    arrow
    classes={{
      tooltip: 'text-xsmall',
    }}
  >
    <InfoIcon />
  </Tooltip>
);

InfoTooltip.propTypes = {
  children: PropTypes.any.isRequired,
};


export default InfoTooltip;
