import { makeActionCreator } from 'service/utility/redux';


export const SET_LEFT_DRAWER_OPEN = 'SET_LEFT_DRAWER_OPEN';
export const setLeftDrawerOpen = makeActionCreator(SET_LEFT_DRAWER_OPEN, 'isOpen');

export const SET_LEFT_DRAWER_PERMANENT = 'SET_LEFT_DRAWER_PERMANENT';
export const setLeftDrawerPermanent = makeActionCreator(SET_LEFT_DRAWER_PERMANENT, 'isPermanent');

export const TOGGLE_RIGHT_DRAWER = 'TOGGLE_RIGHT_DRAWER';
export const toggleRightDrawer = makeActionCreator(TOGGLE_RIGHT_DRAWER);

export const SET_RIGHT_DRAWER_VIEW = 'SET_RIGHT_DRAWER_VIEW';
export const setRightDrawerView = makeActionCreator(SET_RIGHT_DRAWER_VIEW, 'view');
