import { race, take, call, put, delay } from 'redux-saga/effects';

import { TRANSACTION_TIMEOUT } from 'constants/transactions';
import * as API from 'service/api';
import { pollAsyncFunction } from 'service/utility';
import { roundTo2Decimals } from 'service/utility/numbers';

import {
  NEW_ORDER_PAYMENT, TERMINATE_ORDER, TERMINATE_ORDER_PAYMENT,
  setPaymentStatus, resetPaymentStatus, terminateOrder,
} from '../actions';


const newStoreCreditTransactionFromOrder = (order, storeCreditAmount) => ({
  method: 'store-credit',
  locationId: order.locationId,
  customerId: order.customerId,
  amount: storeCreditAmount,
});

const newCashTransactionFromOrder = (order, cashAmount) => ({
  method: 'cash',
  locationId: order.locationId,
  customerId: order.customerId,
  amount: cashAmount,
});

const newGiftCardTransactionFromOrder = (order, giftCard) => ({
  method: 'gift-card',
  locationId: order.locationId,
  customerId: order.customerId,
  amount: giftCard.amount,
  giftCardId: giftCard.id,
});

const newCardTransactionFromOrder = (payload, order, cardAmount) => {
  const transactionBody = {
    orderId: order.id,
    locationId: order.locationId,
    customerId: order.customerId,
    staffId: order.staffId,
    amount: cardAmount,
  };
  const { deviceId, paymentCardId, saveCardAfterPurchase } = payload;

  if (paymentCardId) {
    transactionBody.method = 'token';
    transactionBody.paymentCardId = paymentCardId;
  } else {
    transactionBody.method = 'credit';
    transactionBody.deviceId = deviceId;
    transactionBody.saveCard = saveCardAfterPurchase;
  }

  return transactionBody;
};

function *postOrderTransaction(order, transactionBody) {
  try {
    const result = yield call(API.postOrderSale, order.id, transactionBody);

    return result;
  } catch (error) {
    console.log('error in transaction sale', error);

    yield put(terminateOrder({
      error,
      orderResult: order,
      errorMessage: 'Sorry, there was an error processing this order.',
    }));
  }

  return null;
}

function *processStoreCreditTransaction(payload, order, storeCreditAmount) {
  if (storeCreditAmount) {
    yield put(setPaymentStatus({
      message: 'Processing Store Credit Transaction',
      started: true,
    }));

    // create Transaction
    const transactionBody = newStoreCreditTransactionFromOrder(order, storeCreditAmount);

    console.log('Posting Store Credit Transaction with the following Body: ', transactionBody);

    // post Transaction
    const { data: transaction } = yield call(postOrderTransaction, order, transactionBody);

    console.log('Post Store Credit Transaction Response: ', transaction);
  }
}

function *processCashTransaction(payload, order, cashAmount) {
  if (cashAmount) {
    yield put(setPaymentStatus({
      message: 'Processing Cash Transaction',
      started: true,
    }));

    // create Transaction
    const transactionBody = newCashTransactionFromOrder(order, cashAmount);

    console.log('Posting Cash Transaction with the following Body: ', transactionBody);

    // post Transaction
    const { data: transaction } = yield call(postOrderTransaction, order, transactionBody);

    console.log('Post Cash Transaction Response: ', transaction);
  }
}

function *processGiftCardTransaction(payload, order, giftCards) {
  if (giftCards.length) {
    yield put(setPaymentStatus({
      message: 'Processing Gift Card Transaction',
      started: true,
    }));

    for (let i = 0; i < giftCards.length; i++) {
      // create Transaction
      const transactionBody = newGiftCardTransactionFromOrder(order, giftCards[i]);

      console.log('Posting Gift Card Transaction with the following Body: ', transactionBody);

      // post Transaction
      const { data: transaction } = yield call(postOrderTransaction, order, transactionBody);

      console.log('Post Gift Card Transaction Response: ', transaction);
    }
  }
}

function *processCardTransaction(payload, order, creditCardAmount) {
  // signal that we are creating a Card Transaction
  yield put(setPaymentStatus({
    message: 'Initializing Card Transaction',
    started: true,
  }));

  // create Transaction
  const transactionBody = newCardTransactionFromOrder(payload, order, creditCardAmount);

  console.log('Posting Card Transaction with the following Body: ', transactionBody);

  // post Transaction
  const transactionResponse = yield call(postOrderTransaction, order, transactionBody);

  console.log('Post Card Transaction Response: ', transactionResponse);

  return transactionResponse;
}

function *successfulTransaction(order) {
  yield put(setPaymentStatus({
    message: 'Transaction Successful',
    started: true,
    finished: true,
    success: true,
  }));

  yield delay(2000);
  yield put(resetPaymentStatus());

  return {
    orderResult: order,
    transactionResult: {
      data: {
        isSuccessful: true,
      },
    },
  };
}

function *unfinishedTransaction(order, message, retryAction) {
  console.log('Unfinished transaction');

  yield put(setPaymentStatus({
    message,
    started: true,
    finished: null,
    actions: {
      retry: retryAction,
    },
  }));

  return {
    orderResult: order,
    transactionResult: {
      data: {
        isSuccessful: false,
      },
    },
  };
}

function *timedoutTransaction(order) {
  console.log('Timed out transaction');

  yield put(setPaymentStatus({
    message: 'Polling Timed Out.',
    started: true,
    finished: true,
    success: false,
  }));

  return {
    orderResult: order,
    transactionResult: {
      data: {
        isSuccessful: false,
      },
    },
    errorMessage: 'Transaction timed out. Try again.',
  };
}

function *finishedTransaction(order, transactionResponse) {
  const { data: { statusCode, statusDescription, isSuccessful } } = transactionResponse;

  console.log('Finished transaction; isSuccessful = ', isSuccessful);

  yield put(setPaymentStatus({
    message: isSuccessful ? statusDescription : `Status Code: ${statusCode}\nDescription: ${statusDescription}`,
    started: true,
    finished: true,
    success: Boolean(isSuccessful),
  }));

  if (isSuccessful) {
    yield delay(2000);
    yield put(resetPaymentStatus());
  }

  return {
    orderResult: order,
    transactionResult: transactionResponse,
  };
}

function *pollTransaction(order, transaction) {
  const transactionUUID = transaction.uuid;

  // signal that we are going to poll
  yield put(setPaymentStatus({
    message: 'Polling',
    started: true,
    polling: true,
  }));

  const { promise: pollingPromise, cancel: pollingCancel } = pollAsyncFunction(
    // async function to poll
    async () => {
      console.log('Attempting to poll Transaction with UUID = ', transactionUUID);
      const result = await API.getTransactionById(transactionUUID);

      console.log('Transaction Polling Result: ', result);

      return result;
    },

    // check function. Polling ends when true.
    ({ data: { isComplete } }) => (isComplete),

    // ms interval
    2000,
  );

  // wait for polling to finish. race between the following three :
  const raceResult = yield race({
    // if this finishes first that means polling is complete and transaction isComplete = 1
    pollingResult: pollingPromise,

    // if this finishes first that means the user manually cancelled the polling
    terminated: take(TERMINATE_ORDER_PAYMENT),

    // if this finishes first that means we reached the maximum limit of 2 minutes
    timedOut: delay(TRANSACTION_TIMEOUT),
  });

  console.log('Final result of polling race: ', raceResult);

  yield put(setPaymentStatus({
    polling: false,
  }));

  if (raceResult.terminated || raceResult.timedOut) {
    yield put(setPaymentStatus({
      message: 'Cancelling polling',
    }));

    pollingCancel();

    try {
      yield call(API.deleteTransaction, transactionUUID);
    } catch (error) {
      console.log('API.deleteTransaction error: ', error);

      yield put(terminateOrder({
        error: true,
        errorMessage: 'Sorry, there was an error cancelling the transaction.',
      }));

      return null;
    }
  }

  if (raceResult.terminated) {
    yield put(terminateOrder({
      error: true,
      errorMessage: 'Transaction Manually Cancelled',
    }));

    return null;
  }

  if (raceResult.timedOut) {
    // transaction polling took over 2 minutes
    console.log('Transaction Polling Timed Out');

    // signal that Transaction Polling Timed Out
    return yield call(timedoutTransaction, order);
  }

  if (raceResult.pollingResult) {
    // transaction polling finished and we have a response
    // eslint-disable-next-line no-use-before-define
    return yield call(processTransactionResponse, order, raceResult.pollingResult);
  }

  return null;
}

function *processTransactionResponse(order, transactionResponse) {
  console.log('Processing the following transaction response: ', transactionResponse);

  const { data: transaction } = transactionResponse;

  if (transaction.isComplete) {
    // transaction is complete; check for a status of 408
    if (transactionResponse.status === 408) {
      // transaction status is 408; display received status description
      return yield call(
        unfinishedTransaction,
        order,
        transaction.statusDescription,
        () => pollTransaction(order, transaction),
      );
    }

    // transaction finished
    return yield call(finishedTransaction, order, transactionResponse);
  }

  return yield call(pollTransaction, order, transaction);
}

function *newOrderPayment(payload) {
  try {
    console.log('newOrderPayment saga with the following Payload: ', payload);

    const { order, storeCreditAmount = 0, cashAmount = 0, giftCards = [] } = payload;
    let remainingAmount = order.amountDue;
    const storeCreditAmountTBU = (
      Boolean(remainingAmount) && Boolean(storeCreditAmount)
        ? Math.min(storeCreditAmount, remainingAmount)
        : 0
    );
    remainingAmount = roundTo2Decimals(remainingAmount - storeCreditAmountTBU);
    const cashAmountTBU = (
      Boolean(remainingAmount) && Boolean(cashAmount)
        ? Math.min(cashAmount, remainingAmount)
        : 0
    );
    remainingAmount = roundTo2Decimals(remainingAmount - cashAmountTBU);
    const giftCardAmount = giftCards.reduce((a, b) => a + b.amount, 0);
    const giftCardAmountTBU = (
      Boolean(remainingAmount) && Boolean(giftCardAmount)
        ? Math.min(giftCardAmount, remainingAmount)
        : 0
    );
    remainingAmount = roundTo2Decimals(remainingAmount - giftCardAmountTBU);
    const creditCardAmountTBU = remainingAmount;

    if (creditCardAmountTBU) {
      // post Card Transaction
      const transactionResponse = yield call(processCardTransaction, payload, order, creditCardAmountTBU);
      const cardTransactionResult = yield call(processTransactionResponse, order, transactionResponse);
      const { isSuccessful } = cardTransactionResult.transactionResult.data;

      if (!isSuccessful) {
        return cardTransactionResult;
      }
    }

    if (storeCreditAmountTBU) {
      // process Store Credit
      yield call(processStoreCreditTransaction, payload, order, storeCreditAmountTBU);
    }

    if (cashAmountTBU) {
      // process Cash
      yield call(processCashTransaction, payload, order, cashAmountTBU);
    }

    if (giftCardAmountTBU) {
      // process Gift Cards
      yield call(processGiftCardTransaction, payload, order, giftCards);
    }

    return yield call(successfulTransaction, order);
  } catch (error) {
    console.log('newOrderPayment error: ', error);
  }

  return null;
}

function *orderSaga() {
  while (true) {
    const { payload, callback } = yield take(NEW_ORDER_PAYMENT);

    const { result, terminated } = yield race({
      result: call(newOrderPayment, payload),
      terminated: take(TERMINATE_ORDER), // listens for action
    });

    if (result) {
      console.log('newOrderPayment result: ', result);

      yield call(callback, result);
    } else if (terminated) {
      // terminated for some reason.
      console.log('newOrderPayment was terminated');

      // set payment status UI.
      yield put(resetPaymentStatus());
      yield call(callback, terminated.payload);
    }
  }
}


export default orderSaga;
