import React from 'react';
import { PropTypes } from 'prop-types';
import BarcodeReader from 'react-barcode-reader';
import { FormControlLabel, Switch } from '@material-ui/core';


const QRScanner = (props) => (
  <>
    <FormControlLabel
      id="qr-scanner-switch"
      control={
        <Switch
          checked={props.isQrScannerOn}
          onChange={props.onToggleQrScanner}
          disableRipple
          focusVisibleClassName="focus-visible"
          classes={{
            root: 'switch-root',
            switchBase: 'switch-base',
            thumb: 'thumb',
            track: 'track',
            checked: 'is-checked',
          }}
        />
      }
      label="QR Scanner"
      labelPlacement="bottom"
      classes={{
        label: 'switch-label',
      }}
    />
    {props.isQrScannerOn && (
      <BarcodeReader
        onError={props.onError}
        onScan={props.onScan}
        minLength={props.minLength}
      />
    )}
  </>
);

QRScanner.propTypes = {
  isQrScannerOn: PropTypes.bool.isRequired,
  minLength: PropTypes.number,
  onError: PropTypes.func.isRequired,
  onScan: PropTypes.func.isRequired,
  onToggleQrScanner: PropTypes.func.isRequired,
};


export default QRScanner;
