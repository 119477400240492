import React from 'react';
import { PropTypes } from 'prop-types';
import {
  ListItem, ListItemText, ListItemSecondaryAction, IconButton,
} from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';


class UploadedFile extends React.Component {
  handleClick = () => {
    this.props.onRemove(this.props.idx);
  };

  render() {
    const { fileName } = this.props;

    return (
      <ListItem>
        <ListItemText primary={fileName} />
        <ListItemSecondaryAction>
          <IconButton onClick={this.handleClick}>
            <RemoveCircleIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

UploadedFile.propTypes = {
  fileName: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
};


export default UploadedFile;
