import React from 'react';
import { PropTypes } from 'prop-types';

import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


const PICKLIST_VALUES = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Current',
    value: 'current',
  },
  {
    label: 'Expired',
    value: 'expired',
  },
];


class CurrentExpiredFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filterStatus: (
        this.props.hasOwnProperty('filterStatus') && this.props.filterStatus !== 'null'
          ? this.props.filterStatus
          : 'current'
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['filterStatus'])) {
      this.handleValueChange();
    }
  }

  getOwnFilterMap = () => ({
    filterStatus: this.state.filterStatus,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ filterStatus: update.value });
    }
  };


  render() {
    const { filterStatus } = this.state;

    return (
      <PicklistField
        value={filterStatus}
        onChange={this.handleChange}
        picklistValues={PICKLIST_VALUES}
      />
    );
  }
}

CurrentExpiredFilter.propTypes = {
  filterStatus: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onValueChange: PropTypes.func,
};

CurrentExpiredFilter.ownName = 'CurrentExpiredFilter';
CurrentExpiredFilter.ownFilters = ['filterStatus'];


export default CurrentExpiredFilter;
