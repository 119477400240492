import { createReducer } from 'service/utility/redux';

import { SET_CURRENT_STAFF, AUTH_LOGGED_OUT_SUCCESS } from '../actions';


const initState = {};

const currentStaff = createReducer(initState, {
  [SET_CURRENT_STAFF](state, { staffData }) {
    return {
      ...staffData,
    };
  },
  [AUTH_LOGGED_OUT_SUCCESS](state) {
    return {
      ...state,
      firstName: null,
      lastName: null,
      UUID: null,
    };
  },
});

export default currentStaff;
