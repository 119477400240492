import React from 'react';
import { SvgIcon } from '@material-ui/core';


const MembershipIcon = () => (
  <SvgIcon
    viewBox="0 0 60 60"
    fontSize="inherit"
  >
    <path
      d="M55.783,8H4.217C1.892,8,0,9.892,0,12.217v35.566C0,50.108,1.892,52,4.217,52h51.566C58.108,52,60,50.108,60,47.783V12.217
      C60,9.892,58.108,8,55.783,8z M58,47.783C58,49.006,57.006,50,55.783,50H4.217C2.994,50,2,49.006,2,47.783V12.217
      C2,10.994,2.994,10,4.217,10h51.566C57.006,10,58,10.994,58,12.217V47.783z"
    />
    <path
      d="M22.638,36.246L19,34.388v-0.387c1.628-0.889,2.773-2.353,3.412-4.364C23.381,29.123,24,28.122,24,27v-1
      c0-0.926-0.431-1.785-1.151-2.349c-0.624-3.78-3.262-5.696-7.849-5.696c-0.217,0-0.429,0.008-0.636,0.024
      c-0.864,0.071-2.129-0.004-3.224-0.74c-0.409-0.276-0.718-0.544-0.915-0.793c-0.336-0.429-0.901-0.579-1.402-0.373
      c-0.502,0.206-0.797,0.708-0.734,1.247c0.042,0.374,0.105,0.809,0.2,1.286c0.193,0.975,0.193,0.975-0.078,1.558
      c-0.102,0.221-0.228,0.49-0.376,0.853c-0.331,0.811-0.566,1.699-0.701,2.647C6.424,24.229,6,25.083,6,26v1
      c0,1.122,0.619,2.123,1.588,2.637c0.639,2.012,1.784,3.476,3.412,4.364v0.376l-3.769,1.858C5.855,36.985,5,38.425,5,39.993v1.325
      C5,42.121,5,44,15,44s10-1.879,10-2.682v-1.245C25,38.442,24.095,36.977,22.638,36.246z M23,41.092C22.376,41.472,19.838,42,15,42
      s-7.376-0.528-8-0.908v-1.099c0-0.835,0.455-1.603,1.152-1.982l3.857-1.901c0.602-0.294,0.99-0.917,0.99-1.588v-1.803l-0.604-0.26
      c-1.517-0.654-2.503-1.901-3.015-3.814l-0.143-0.533l-0.526-0.164C8.293,27.817,8,27.428,8,27v-1c0-0.362,0.207-0.698,0.541-0.876
      l0.469-0.25l0.055-0.529c0.099-0.938,0.308-1.803,0.622-2.57c0.133-0.325,0.246-0.568,0.338-0.767
      c0.339-0.728,0.462-1.104,0.377-1.875c1.176,0.672,2.589,0.958,4.122,0.841c0.155-0.013,0.314-0.019,0.477-0.019
      c3.744,0,5.572,1.356,5.929,4.398l0.062,0.522l0.465,0.246C21.791,25.299,22,25.636,22,26v1c0,0.428-0.293,0.817-0.712,0.947
      l-0.526,0.164l-0.143,0.533c-0.512,1.913-1.498,3.16-3.015,3.814L17,32.719v1.811c0,0.669,0.37,1.272,0.964,1.575l3.775,1.929
      C22.517,38.422,23,39.204,23,40.073V41.092z"
    />
    <path d="M30,23h10c0.553,0,1-0.447,1-1s-0.447-1-1-1H30c-0.553,0-1,0.447-1,1S29.447,23,30,23z" />
    <path d="M44,23h1c0.553,0,1-0.447,1-1s-0.447-1-1-1h-1c-0.553,0-1,0.447-1,1S43.447,23,44,23z" />
    <path d="M31,38h-1c-0.553,0-1,0.447-1,1s0.447,1,1,1h1c0.553,0,1-0.447,1-1S31.553,38,31,38z" />
    <path d="M37,38h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2c0.553,0,1-0.447,1-1S37.553,38,37,38z" />
    <path d="M42,38h-1c-0.553,0-1,0.447-1,1s0.447,1,1,1h1c0.553,0,1-0.447,1-1S42.553,38,42,38z" />
    <path d="M48,38h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2c0.553,0,1-0.447,1-1S48.553,38,48,38z" />
    <path
      d="M51.29,38.29C51.109,38.479,51,38.74,51,39s0.109,0.52,0.29,0.71C51.479,39.89,51.74,40,52,40s0.52-0.11,0.71-0.29
      C52.89,39.52,53,39.26,53,39s-0.11-0.521-0.29-0.71C52.33,37.92,51.66,37.92,51.29,38.29z"
    />
    <path d="M52,27H30c-0.553,0-1,0.447-1,1s0.447,1,1,1h22c0.553,0,1-0.447,1-1S52.553,27,52,27z" />
    <path d="M52,33H30c-0.553,0-1,0.447-1,1s0.447,1,1,1h22c0.553,0,1-0.447,1-1S52.553,33,52,33z" />
  </SvgIcon>
);

export default MembershipIcon;
