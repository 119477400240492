// ADD CARD DIALOG
export const isAddCardDialogOpen = (state) => state.addCardDialog.isOpen;
export const addCardDialogCallback = (state) => state.addCardDialog.callback;
export const addCardDialogCustomerId = (state) => state.addCardDialog.customerId;

// ALERT ON NOTES DIALOG
export const alertOnNotesDialog = (state) => state.app.alertOnNotesDialog;

// CHECKIN DIALOG
export const isCheckinDialogOpen = (state) => state.checkinDialog.isOpen;
export const checkinDialogPhase = (state) => state.checkinDialog.checkinPhase;
export const checkinDialogMinPhase = (state) => state.checkinDialog.minCheckinPhase;
export const checkinDialogCustomerId = (state) => state.checkinDialog.customerId;
export const checkinDialogBookingId = (state) => state.checkinDialog.bookingId;
export const checkinDialogGroupId = (state) => state.checkinDialog.groupId;
export const checkinDialogEvent = (state) => state.checkinDialog.event;
export const checkinDialogCallback = (state) => state.checkinDialog.callback;

// CONFIRM ACTION DIALOG
export const confirmActionDialog = (state) => state.confirmActionDialog;

// COPY TO CLIPBOARD DIALOG
export const isCopyToClipboardDialogOpen = (state) => state.copyToClipboardDialog.isOpen;
export const copyToClipboardText = (state) => state.copyToClipboardDialog.text;

// CUSTOMER DIALOG
export const customerDialog = (state) => state.app.customerDialog;

// EVENT CHECKIN DIALOG
export const isEventCheckinDialogOpen = (state) => state.eventCheckinDialog.isOpen;
export const eventCheckinDialogTicketUUID = (state) => state.eventCheckinDialog.ticketUUID;
export const eventCheckinDialogCheckedInCustomerId = (state) => state.eventCheckinDialog.checkedInCustomerId;

// LAYOUT
export const isLeftDrawerOpen = (state) => state.layout.leftDrawer.isOpen;
export const isLeftDrawerPermanent = (state) => state.layout.leftDrawer.isPermanent;
export const rightDrawer = (state) => state.layout.rightDrawer;

// NEW GIFT CARD DIALOG
export const isNewGiftCardDialogOpen = (state) => state.newGiftCardDialog.isOpen;
export const newGiftCardDialogCustomer = (state) => state.newGiftCardDialog.customer;

// REFUND ORDER DIALOG
export const isRefundOrderDialogOpen = (state) => state.refundOrderDialog.isOpen;
export const refundOrderDialogPhase = (state) => state.refundOrderDialog.refundPhase;
export const refundOrderDialogMinPhase = (state) => state.refundOrderDialog.minRefundPhase;
export const refundOrderDialogOrder = (state) => state.refundOrderDialog.order;
export const refundOrderDialogPreselectedItem = (state) => state.refundOrderDialog.preselectedItem;
export const refundOrderDialogSelectedItems = (state) => state.refundOrderDialog.selectedItems;
export const refundOrderDialogSelectedMethod = (state) => state.refundOrderDialog.selectedMethod;
export const refundOrderDialogCallback = (state) => state.refundOrderDialog.callback;

// SCANNING
export const isQrScannerOn = (state) => state.app.qrScanner;

// ADD HOC CHARGE
export const isCreateAdHocChargeDialogOpen = (state) => state.createAdHocChargeDialog.isOpen;
export const createAdHocChargeDialogCustomer = (state) => state.createAdHocChargeDialog.customer;
