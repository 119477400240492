import React from 'react';
import { PropTypes } from 'prop-types';

import { formatPriceWithoutTax } from 'service/utility/pricing';


const GiftCardTypeDisplayItem = ({ currentLocation, data }) => (
  <div className="d-flex flex-row justify-content-between w-100">
    <span>
      {data.name}
    </span>
    <span>
      {`Value: ${formatPriceWithoutTax(data.value, currentLocation)}`}
    </span>
    <span>
      {`Price: ${formatPriceWithoutTax(data.price, currentLocation)}`}
    </span>
  </div>
);

GiftCardTypeDisplayItem.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};


export default GiftCardTypeDisplayItem;
