import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import { COUNTRY_PICKLIST_VALUES } from 'constants/countries';
import { getLocationCountry } from 'service/i18n';

import PicklistField from './PicklistField';


class CountryField extends React.PureComponent {
  componentDidMount() {
    const currentLocationCountry = getLocationCountry(this.props.currentLocation);

    if (currentLocationCountry && !this.props.value) {
      this.props.onChange({ value: currentLocationCountry });
    }
  }


  render() {
    const { currentLocation, dispatch, className, ...rest } = this.props;

    const countryFieldClassName = classnames('country-field', { [className]: Boolean(className) });

    return (
      <PicklistField
        {...rest}
        picklistValues={COUNTRY_PICKLIST_VALUES}
        className={countryFieldClassName}
      />
    );
  }
}

CountryField.propTypes = {
  className: PropTypes.string,
  currentLocation: PropTypes.object,
  dispatch: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

CountryField.defaultProps = {
  value: '',
};


export default CountryField;
