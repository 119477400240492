import assignCustomerToCartSaga from './assignCustomerToCartSaga';
import assignStaffToCartSaga from './assignStaffToCartSaga';
import clearCartSaga from './clearCartSaga';
import completeCartSaga from './completeCartSaga';
import createNewCartSaga from './createNewCartSaga';
import deleteCartSaga from './deleteCartSaga';
import focusOnCustomerCartSaga from './focusOnCustomerCartSaga';
import initialCartLoadSaga from './initialCartLoadSaga';
import putCartDiscountSaga from './putCartDiscountSaga';
import putCartPromoCodeSaga from './putCartPromoCodeSaga';
import putItemsInCartSaga from './putItemsInCartSaga';
import putItemsInCustomerCartSaga from './putItemsInCustomerCartSaga';
import removeItemFromCartSaga from './removeItemFromCartSaga';
import removePromoCodeFromCartSaga from './removePromoCodeFromCartSaga';
import unassignCartCustomerSaga from './unassignCartCustomerSaga';
import unassignCartStaffSaga from './unassignCartStaffSaga';
import updateCartItemQuantitySaga from './updateCartItemQuantitySaga';


const cartSagas = [
  assignCustomerToCartSaga,
  assignStaffToCartSaga,
  clearCartSaga,
  completeCartSaga,
  createNewCartSaga,
  deleteCartSaga,
  focusOnCustomerCartSaga,
  initialCartLoadSaga,
  putCartDiscountSaga,
  putCartPromoCodeSaga,
  putItemsInCartSaga,
  putItemsInCustomerCartSaga,
  removeItemFromCartSaga,
  removePromoCodeFromCartSaga,
  unassignCartCustomerSaga,
  unassignCartStaffSaga,
  updateCartItemQuantitySaga,
];

export default cartSagas;
