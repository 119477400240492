import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import * as cartActions from 'app/redux/cart/actions';
import { CustomerSearchDialog } from 'common/dialogs';


class AssignCustomerDialog extends React.Component {
  handleCustomerSelect = (customer) => {
    const { assignCustomerToCurrentCart, onClose } = this.props;

    assignCustomerToCurrentCart(customer);
    onClose();
  };

  render() {
    const { onClose, open } = this.props;

    return (
      <CustomerSearchDialog
        isOpen={open}
        title="Assign Customer To Cart"
        onCustomerSelect={this.handleCustomerSelect}
        onDialogClose={onClose}
      />
    );
  }
}

AssignCustomerDialog.propTypes = {
  assignCustomerToCurrentCart: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


const mapDispatchToProps = (dispatch) => ({
  assignCustomerToCurrentCart: (customer) => dispatch(cartActions.assignCustomerToCurrentCart(customer)),
});

export default connect(null, mapDispatchToProps)(AssignCustomerDialog);
