import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography, Paper } from '@material-ui/core';
import AttachMoney from '@material-ui/icons/AttachMoney';

import { RemoveFab } from 'common/buttons';
import { getSubtotalProp, formatPriceWithoutTax } from 'service/utility/pricing';


const CartFee = (props) => {
  const { item, currentLocation, disabled } = props;
  const { UUID, description } = item;

  const subtotal = item[getSubtotalProp(currentLocation)];

  const handleItemRemove = () => props.onItemRemove(UUID);


  return (
    <Paper
      className="cart-item"
      elevation={0}
    >
      <div className="cart-item-col image">
        <div className="mp-icon-wrapper">
          <AttachMoney fontSize="inherit" />
        </div>
      </div>
      <div className="cart-item-col details">
        <span className="item-name">
          {description}
        </span>
      </div>
      <div className="cart-item-col total">
        <Typography variant="body1">
          {formatPriceWithoutTax(subtotal, currentLocation)}
        </Typography>
      </div>
      {!disabled && (
        <RemoveFab onClick={() => handleItemRemove()} />
      )}
    </Paper>
  );
};

CartFee.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onItemRemove: PropTypes.func.isRequired,
};


export default CartFee;
