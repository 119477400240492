import { makeActionCreator } from 'service/utility/redux';


export const LOGIN_USER_EMAIL_PASS = 'LOGIN_USER_EMAIL_PASS';
export const loginUserEmailPass = makeActionCreator(LOGIN_USER_EMAIL_PASS, 'email', 'password');

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const setCurrentUser = makeActionCreator(SET_CURRENT_USER, 'user');

export const SET_CURRENT_STAFF = 'SET_CURRENT_STAFF';
export const setCurrentStaff = makeActionCreator(SET_CURRENT_STAFF, 'staffData');

export const SET_ACTIVE_USERS = 'SET_ACTIVE_USERS';
export const setActiveUsers = makeActionCreator(SET_ACTIVE_USERS, 'keys', 'data');

export const AUTH_LOGGED_IN_SUCCESS = 'AUTH_LOGGED_IN_SUCCESS';
export const authLoggedInSuccess = makeActionCreator(AUTH_LOGGED_IN_SUCCESS, 'user');

export const LOG_OUT_USER = 'LOG_OUT_USER';
export const logOutUser = makeActionCreator(LOG_OUT_USER);

export const LOG_OUT_AND_RESET_CACHE = 'LOG_OUT_AND_RESET_CACHE';
export const logOutAndResetCache = makeActionCreator(LOG_OUT_AND_RESET_CACHE);

export const AUTH_LOGGED_OUT_SUCCESS = 'AUTH_LOGGED_OUT_SUCCESS';
export const authLoggedOutSuccess = makeActionCreator(AUTH_LOGGED_OUT_SUCCESS);

export const LOCK_SCREEN = 'LOCK_SCREEN';
export const lockScreen = makeActionCreator(LOCK_SCREEN);

export const SET_LOCK_SCREEN = 'SET_LOCK_SCREEN';
export const setLockScreen = makeActionCreator(SET_LOCK_SCREEN, 'isLocked');

export const UNLOCK_SCREEN = 'UNLOCK_SCREEN';
export const unlockScreen = makeActionCreator(UNLOCK_SCREEN, 'pass');

export const SET_LOCK_SCREEN_MESSAGE = 'SET_LOCK_SCREEN_MESSAGE';
export const setLockScreenMessage = makeActionCreator(SET_LOCK_SCREEN_MESSAGE, 'errorMessage');

export const LOG_OUT_USER_FROM_LOCK = 'LOG_OUT_USER_FROM_LOCK';
export const logOutUserFromLock = makeActionCreator(LOG_OUT_USER_FROM_LOCK);

export const USER_IMAGE_HAS_CHANGED = 'USER_IMAGE_HAS_CHANGED';
export const userImageHasChanged = makeActionCreator(USER_IMAGE_HAS_CHANGED, 'imageURL');
