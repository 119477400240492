import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import { Button, TextField } from '@material-ui/core';

import { removeProps } from 'service/utility';
import * as timeUtils from 'service/utility/timeUtils';


const AirbnbDatepicker = (props) => {
  const datepickerRef = React.useRef();

  const [startDate, setStartDate] = React.useState(props.startDate);
  const [endDate, setEndDate] = React.useState(props.endDate);
  const [blurred, setBlurred] = React.useState(false);


  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleSave = () => {
    datepickerRef.current.setOpen(false);
    props.setDates(startDate, endDate || startDate);
  };


  // eslint-disable-next-line react/display-name
  const DatePickerButton = React.forwardRef(({ onClick, disabled }, ref) => (
    props.displayAsField ? (
      <TextField
        className={classnames('airbnb-datepicker-field', { empty: !startDate && !endDate })}
        variant="outlined"
        margin="dense"
        ref={ref}
        onClick={onClick}
        disabled={disabled}
        readOnly
        placeholder={timeUtils.getShortDateIntervalPlaceholder()}
        label={props.label}
        InputLabelProps={{
          shrink: true,
        }}
        value={timeUtils.shortDateInterval(startDate, endDate)}
        error={blurred && Boolean(props.error)}
        helperText={props.error}
      />
    ) : (
      <Button
        className="airbnb-datepicker-button text-nowrap"
        variant="outlined"
        ref={ref}
        onClick={onClick}
        disabled={disabled}
      >
        {!startDate ? props.label || 'Dates' : timeUtils.airbnbDateInterval(startDate, endDate)}
      </Button>
    )
  ));

  DatePickerButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  };


  const DatePickerContainer = ({ className, children }) => (
    <div className="airbnb-datepicker-container">
      <div className="datepicker-container-inner">
        <div className={className}>
          <div className="position-relative">
            {children}
          </div>
        </div>
      </div>
      <div className="buttons-container">
        <div className="buttons-container2">
          <Button
            color="primary"
            variant="text"
            onClick={handleClear}
          >
            {'Clear'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            {'Save'}
          </Button>
        </div>
      </div>
    </div>
  );

  DatePickerContainer.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.node,
      PropTypes.array,
    ]),
    className: PropTypes.string,
  };


  const handleDateSelect = (dates) => {
    const [start, end] = dates;

    setStartDate(start ? timeUtils.dateYearMonthDay(start) : null);
    setEndDate(end ? timeUtils.dateYearMonthDay(end) : null);
  };

  const handleCalendarClose = () => {
    setBlurred(true);
    handleSave();
  };


  const dStartDate = startDate ? timeUtils.localDateFromIso8601Date(startDate) : null;
  const dEndDate = endDate ? timeUtils.localDateFromIso8601Date(endDate) : null;

  const rest = removeProps(props, ['displayAsField', 'disabled', 'endDate', 'error', 'label', 'setDates', 'startDate']);

  return (
    <DatePicker
      ref={datepickerRef}
      selected={dStartDate}
      startDate={dStartDate}
      endDate={dEndDate}
      selectsRange
      onChange={handleDateSelect}
      onCalendarClose={handleCalendarClose}
      monthsShown={2}
      showPopperArrow={false}
      customInput={<DatePickerButton />}
      shouldCloseOnSelect={false}
      calendarContainer={DatePickerContainer}
      popperPlacement={props.displayAsField ? 'bottom-start' : 'bottom-end'}
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: document.getElementsByClassName('fixed-content')[0],
        },
      }}
      disabled={props.disabled}
      {...rest}
    />
  );
};

AirbnbDatepicker.propTypes = {
  disabled: PropTypes.bool,
  displayAsField: PropTypes.bool,
  endDate: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  setDates: PropTypes.func.isRequired,
  startDate: PropTypes.string,
};

AirbnbDatepicker.defaultProps = {
  disabled: false,
  displayAsField: false,
};


export default AirbnbDatepicker;
