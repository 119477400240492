import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import * as API from 'service/api';

import GenericSearch from '../GenericSearch';
import ActivitySearchItem from './ActivitySearchItem';


const getOptionValue = (option) => option.id;

const getOptionLabel = (option) => option.name;


class ActivitySearch extends React.Component {
  getOptions = (input) => {
    const { filter } = this.props;

    return API.findActivity(`q=${input}`).then(
      ({ data: { data } }) => (filter ? filter(data) : data),
    );
  };

  render() {
    const { className, ...rest } = this.props;

    return (
      <GenericSearch
        suggestionItem={ActivitySearchItem}
        getOptions={this.getOptions}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        className={classnames('activity-search', { [className]: Boolean(className) })}
        {...rest}
      />
    );
  }
}

ActivitySearch.propTypes = {
  autoFocus: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  filter: PropTypes.func,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.object,
};

ActivitySearch.defaultProps = {
  autoFocus: false,
  cacheOptions: true,
  className: '',
  disabled: false,
  error: '',
  fullWidth: true,
  placeholder: '',
  required: false,
};


export default ActivitySearch;
