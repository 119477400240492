import React from 'react';
import { PropTypes } from 'prop-types';
import {
  AppBar, Toolbar, Typography, Button, Dialog, DialogContent, DialogActions,
} from '@material-ui/core';

import { CustomerSearch } from '../../searches';


const CustomerSearchDialog = ({ isOpen, title, onCustomerSelect, onDialogClose }) => (
  <Dialog
    open={isOpen}
    maxWidth="sm"
    onClose={onDialogClose}
    classes={{ root: 'customer-search-dialog' }}
  >
    <AppBar
      position="static"
      classes={{ root: 'dialog-appbar' }}
    >
      <Toolbar classes={{ root: 'dialog-toolbar' }}>
        <Typography
          variant="h6"
          color="inherit"
          classes={{ root: 'dialog-title' }}
        >
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
    <DialogContent classes={{ root: 'dialog-content' }}>
      <CustomerSearch
        autoFocus
        onChange={(update) => update.value && onCustomerSelect(update.value)}
      />
    </DialogContent>
    <DialogActions classes={{ root: 'dialog-actions' }}>
      <Button onClick={onDialogClose}>
        {'Cancel'}
      </Button>
    </DialogActions>
  </Dialog>
);

CustomerSearchDialog.propTypes = {
  isOpen: PropTypes.bool,
  onCustomerSelect: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};


export default CustomerSearchDialog;
