import { take, put, select } from 'redux-saga/effects';

import { CLOSE_ADD_CARD_DIALOG, hideAddCardDialog } from '../actions/dialog';
import { addCardDialogCallback } from '../selectors';


function *closeAddCardDialogSaga() {
  while (true) {
    yield take(CLOSE_ADD_CARD_DIALOG);
    const callback = yield select(addCardDialogCallback);
    if (callback) {
      callback();
    }
    yield put(hideAddCardDialog());
  }
}


export default closeAddCardDialogSaga;
