import closeAddCardDialogSaga from './closeAddCardDialogSaga';
import closeCheckinDialogSaga from './closeCheckinDialogSaga';
import closeRefundDialogSaga from './closeRefundDialogSaga';
import confirmActionSaga from './confirmActionSaga';


const uiSagas = [
  closeAddCardDialogSaga,
  closeCheckinDialogSaga,
  closeRefundDialogSaga,
  confirmActionSaga,
];

export default uiSagas;
