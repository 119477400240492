export const ACCEPTABLE_CONTRAST_RATIO = 4.5;
export const MAX_IMAGE_SIZE = 256;

export const CALENDAR_COLORS = [
  { hex: '#AD1457', name: 'Radicchio' },
  { hex: '#F4511E', name: 'Tangerine' },
  { hex: '#E4C441', name: 'Citron' },
  { hex: '#0B8043', name: 'Basil' },
  { hex: '#3F51B5', name: 'Blueberry' },
  { hex: '#8E24AA', name: 'Grape' },
  { hex: '#D81B60', name: 'Cherry' },
  { hex: '#EF6C00', name: 'Pumpkin' },
  { hex: '#C0CA33', name: 'Avocado' },
  { hex: '#009688', name: 'Eucalyptus' },
  { hex: '#7986CB', name: 'Lavender' },
  { hex: '#795548', name: 'Cocoa' },
  { hex: '#D50000', name: 'Tomato' },
  { hex: '#F09300', name: 'Mango' },
  { hex: '#7CB342', name: 'Pistachio' },
  { hex: '#039BE5', name: 'Peacock' },
  { hex: '#B39DDB', name: 'Wisteria' },
  { hex: '#616161', name: 'Graphite' },
  { hex: '#E67C73', name: 'Flamingo' },
  { hex: '#F6BF26', name: 'Banana' },
  { hex: '#33B679', name: 'Sage' },
  { hex: '#4285F4', name: 'Cobalt' },
  { hex: '#9E69AF', name: 'Amethyst' },
  { hex: '#A79B8E', name: 'Birch' },
];
export const CALENDAR_DEFAULT_COLOR = '#4285F4';
export const COLOR_PICKER_WIDTH = 170;
export const COLORS_PER_ROW = 6;
export const CALENDAR_COLOR_MATRIX = (
  CALENDAR_COLORS.reduce((a, b) => {
    if (!a.length) a.push([]);
    if (a[a.length - 1].length === COLORS_PER_ROW) a.push([]);
    a[a.length - 1].push(b);

    return a;
  }, [])
);
