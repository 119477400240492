import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'styles/loader.scss';
import 'styles/reboot.scss';
import 'styles/utilities.scss';
import 'styles/ui.scss';
import 'styles/app.scss';
import 'styles/unlogged.scss';
import 'styles/smartForm.scss';
import 'styles/transactionList.scss';

import { injectConfig as injectConfigAxios } from 'service/axios';
import { injectConfig as injectConfigFirebase } from 'service/firebase';
import { initI18n, getLocationLocale } from 'service/i18n';
import { saveState } from 'service/storage';
import { setNewLocale } from 'service/utility';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-number-input/style.css';
import 'react-picky/dist/picky.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-dragula/dist/dragula.css';

import AppConfig from 'config/app';
import i18nConfig from 'config/i18n';

import { SW_INIT, SW_UPDATE } from './app/redux/serviceWorker/actions';
import store from './app/redux/store';
import * as serviceWorker from './serviceWorkerRegistration';

import App from './app';


initI18n(i18nConfig);
injectConfigAxios(AppConfig);
injectConfigFirebase(AppConfig);

const initialState = store.getState();

if (initialState.app && initialState.app.currentLocation) {
  setNewLocale(getLocationLocale(initialState.app.currentLocation));
}

store.subscribe(() => {
  saveState(store.getState());
});


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: (registration) => store.dispatch({ type: SW_UPDATE, payload: registration }),
});
