import React from 'react';
import { PropTypes } from 'prop-types';

import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


const ACTIVE_PICKLIST_VALUES = [
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'Inactive',
    value: 0,
  },
  {
    label: 'All',
    value: -1,
  },
];


class IsActiveFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isActive: (
        this.props.hasOwnProperty('isActive') && this.props.isActive !== 'null'
          ? Number(this.props.isActive)
          : -1
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['isActive'])) {
      this.handleValueChange();
    }
  }

  getOwnFilterMap = () => ({
    isActive: this.state.isActive === -1 ? null : this.state.isActive,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ isActive: update.value });
    }
  };


  render() {
    const { isActive } = this.state;

    return (
      <PicklistField
        value={isActive}
        onChange={this.handleChange}
        picklistValues={ACTIVE_PICKLIST_VALUES}
      />
    );
  }
}

IsActiveFilter.propTypes = {
  isActive: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onValueChange: PropTypes.func,
};

IsActiveFilter.ownName = 'IsActiveFilter';
IsActiveFilter.ownFilters = ['isActive'];


export default IsActiveFilter;
