import React from 'react';
import { PropTypes } from 'prop-types';

import { somePropsChanged } from 'service/utility';
import * as timeUtils from 'service/utility/timeUtils';

import AirbnbDatepicker from '../../AirbnbDatepicker';


class DateRangeFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      startDate: (
        this.props[this.props.startField] && this.props[this.props.startField] !== 'null'
          ? this.props.useDTs
            ? timeUtils.formatIso8601Date(this.props[this.props.startField], this.props.timeZone)
            : timeUtils.iso8601Date(this.props[this.props.startField])
          : null
      ),
      endDate: (
        this.props[this.props.endField] && this.props[this.props.endField] !== 'null'
          ? this.props.useDTs
            ? timeUtils.formatIso8601Date(this.props[this.props.endField], this.props.timeZone)
            : timeUtils.iso8601Date(this.props[this.props.endField])
          : null
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['startDate', 'endDate'])) {
      this.handleValueChange();
    }
  }

  getOwnFilterMap = () => {
    const { startField, endField, useDTs } = this.props;
    const { startDate, endDate } = this.state;

    return {
      [startField]: (
        startDate
          ? useDTs
            ? timeUtils.iso8601MidnightInUTC(startDate, this.props.timeZone)
            : startDate
          : null
      ),
      [endField]: (
        endDate
          ? useDTs
            ? timeUtils.iso8601EodInUTC(endDate, this.props.timeZone)
            : endDate
          : null
      ),
    };
  };

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
      this.props.onChangeCallback,
    );
  };

  handleSetDates = (startDate, endDate) => {
    this.setState({
      startDate,
      endDate,
    });
  };


  render() {
    const { displayAsField, label } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <AirbnbDatepicker
        startDate={startDate}
        endDate={endDate}
        setDates={this.handleSetDates}
        displayAsField={displayAsField}
        label={label}
      />
    );
  }
}

DateRangeFilter.propTypes = {
  displayAsField: PropTypes.bool,
  endField: PropTypes.string,
  label: PropTypes.string,
  onChangeCallback: PropTypes.func,
  onValueChange: PropTypes.func,
  startField: PropTypes.string,
  timeZone: PropTypes.string.isRequired,
  useDTs: PropTypes.bool,
};

DateRangeFilter.defaultProps = {
  endField: 'endDT',
  startField: 'startDT',
};

DateRangeFilter.ownName = 'DateRangeFilter';
DateRangeFilter.ownFilters = (props) => [props.startField, props.endField];


export default DateRangeFilter;
