import React from 'react';
import { PropTypes } from 'prop-types';
import { ListItem, ListItemText, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { formatName } from 'service/utility/stringFormatters';

import { PersonAvatar } from '../../avatars';


const CustomerSearchListItem = ({ data, onRemove }) => (
  <ListItem
    button
    className="customer-search-list-item"
  >
    <PersonAvatar
      src={data.imageURL}
      size="sm"
    />
    <ListItemText
      primary={formatName(data)}
      className="customer-name"
    />
    {onRemove && (
      <IconButton onClick={onRemove}>
        <ClearIcon />
      </IconButton>
    )}
  </ListItem>
);

CustomerSearchListItem.propTypes = {
  data: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};


export default CustomerSearchListItem;
