import React from 'react';
import { Route } from 'react-router-dom';

import { isNullish } from '../utility';


let routeConfig = {}, routeConfigList = [], fullScreenRoutes = [];

export const flattenRoutes = (routes, root = '') => (
  routes.map(({ subroutes = [], relPath = '', ...rest }) => [
    {
      relPath: `${root}${relPath}`,
      ...rest,
    },
    ...flattenRoutes(subroutes, `${root}${relPath}`),
  ]).flat()
);

export const filterFullScreenRoutes = (routes) => (
  routes.filter((e) => e.fullScreen).map((e) => e.relPath)
);

export const injectConfig = (_routeConfig) => {
  routeConfig = _routeConfig;
  routeConfigList = flattenRoutes(routeConfig);
  fullScreenRoutes = filterFullScreenRoutes(routeConfigList);
};

export const getSubRoutes = (routes, propsToPass, routeComponent) => {
  const Route2 = routeComponent || Route;

  return (
    routes.map(([route, component], idx) => {
      const RouteComp = component;

      return (
        <Route2
          key={idx}
          path={route}
          render={(routeProps) => (
            <RouteComp
              {...propsToPass}
              {...routeProps}
            />
          )}
        />
      );
    })
  );
};

export const getSubRoutes2 = ({
  rootPath = '', subroutes = [], propsForAll = {},
}) => subroutes.map(({
  component: Component, routeComponent: RouteComponent = Route, wrapper: Wrapper, ...route
}, idx) => {
  if (isNullish(route.relPath)) return null;

  const path = `${rootPath}${route.relPath}`;
  const propsToPass = route.propsToPass ?? {};
  const routePropsForAll = route.propsForAll ? { propsForAll: route.propsForAll } : {};

  return (
    <RouteComponent
      key={idx}
      path={path}
      exact={route.exact}
      permission={route.permission}
      render={(props) => {
        const content = (
          <Component
            rootPath={path}
            subroutes={route.subroutes}
            passthroughProps={route.passthroughProps}
            {...propsForAll}
            {...propsToPass}
            {...routePropsForAll}
            {...props}
          />
        );

        if (Wrapper) {
          return (
            <Wrapper>
              {content}
            </Wrapper>
          );
        }

        return content;
      }}
    />
  );
});

export const routeWithParams = (route, o) => (
  Object.keys(o).reduce((s, p) => s.replace(new RegExp(`:${p}(?=/|$)`), o[p]), route)
);

export const computeRoute = (_routes, routeNames) => {
  let i = 0, routes = _routes, route = '';
  while (i < routeNames.length) {
    const routeName = routeNames[i];
    const routeObj = routes.find((e) => e.name === routeName);
    if (!routeObj) throw new Error(`Route ${routeName} doesn't exist`);
    route += routeObj.relPath;
    routes = routeObj.subroutes;
    i += 1;
  }
  return route;
};

export const findRouteByName = (routes, routeName) => (
  // const route = routes.find((e) => e.name === routeName);
  // if (!route) throw new Error(`Route ${routeName} doesn't exist`);
  // return route;
  routes.find((e) => e.name === routeName) ?? null
);

export const findRouteByPath = (routes, path) => (
  // const route = routes.find((e) => e.relPath === path);
  // if (!route) throw new Error(`Route with path ${path} doesn't exist`);
  // return route;
  routes.find((e) => e.relPath === path) ?? null
);

export const getRouteByName = (routeName) => findRouteByName(routeConfigList, routeName);

export const getRouteByPath = (path) => findRouteByPath(routeConfigList, path);

export const getRoutePath = (routeName) => getRouteByName(routeName)?.relPath ?? '';

export const isFullScreenRoute = (routeName) => fullScreenRoutes.includes(routeName);

export const getRouteConfigList = () => routeConfigList;

export const getFullScreenRoutes = () => fullScreenRoutes;
