import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { Avatar as MuiAvatar } from '@material-ui/core';
import PhotoIcon from '@material-ui/icons/Photo';

import { compressFileOrBlobToBase64 } from 'service/utility';


class Avatar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blobSrc: null,
    };
  }

  componentDidMount() {
    this.checkImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.checkImage();
    }
  }

  checkImage = async () => {
    const { src, onImageUpdate } = this.props;

    if (onImageUpdate && Boolean(src)) {
      const response = await fetch(src);

      if (response.ok) {
        const size = response.headers.get('Content-Length');
        console.log('image size is ', size);
        const blob = await response.blob();
        this.setBlobSrc(URL.createObjectURL(blob));
        compressFileOrBlobToBase64(blob, onImageUpdate, true);
      }
    } else {
      this.setBlobSrc(this.props.src);
    }
  };

  setBlobSrc = (blobSrc) => {
    this.setState({ blobSrc });
  };

  render() {
    const { alt, className, size, onClick, variant } = this.props;
    const { blobSrc } = this.state;

    return (
      <MuiAvatar
        className={classnames(
          'avatar',
          {
            'img-loaded': Boolean(blobSrc),
            'avatar-xs': size === 'xs',
            'avatar-sm': size === 'sm',
            'avatar-lg': size === 'lg',
            'avatar-xl': size === 'xl',
            'avatar-header': size === 'header',
            'avatar-header-sm': size === 'header-sm',
            [className]: Boolean(className),
          }
        )}
        onClick={onClick && onClick}
        src={blobSrc}
        variant={variant}
        alt="avatar"
      >
        {!blobSrc && (
          <div className="alt-wrapper">
            {alt}
          </div>
        )}
      </MuiAvatar>
    );
  }
}

Avatar.propTypes = {
  alt: PropTypes.element,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onImageUpdate: PropTypes.func,
  size: PropTypes.string,
  src: PropTypes.string,
  variant: PropTypes.string,
};

Avatar.defaultProps = {
  alt: <PhotoIcon fontSize="inherit" />,
  className: '',
  size: 'md',
  src: '',
  variant: 'square',
};


export default Avatar;
