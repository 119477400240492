import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';

import * as API from 'service/api';
import { getErrorMessage, somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';
import { Spinner } from '../../statusIndicators';


class ActivityFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      activityPicklistValues: [],
      activityId: (
        this.props.hasOwnProperty('activityId') && this.props.activityId !== 'null'
          ? Number(this.props.activityId)
          : 0
      ),
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.handleValueChange();
    this.loadActivities();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['activityId'])) {
      this.handleValueChange();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadActivities = async () => {
    try {
      const { data: { data: activities } } = await API.findActivity('page=1&pagesize=999');
      const activityPicklistValues = [{
        label: 'All Categories',
        value: 0,
      }].concat(activities.map((activity) => ({
        label: activity.name,
        value: activity.id,
      })));

      if (this._isMounted) {
        this.setState({
          activityPicklistValues,
          loading: false,
        });
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.findActivity error: ');
      console.log(errorMessage);

      toast.error(errorMessage);

      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }
  };

  getOwnFilterMap = () => ({
    activityId: this.state.activityId === 0 ? null : this.state.activityId,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleActivityChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ activityId: update.value });
    }
  };


  render() {
    const { loading, activityId, activityPicklistValues } = this.state;

    return loading ? (
      <Spinner />
    ) : (
      <PicklistField
        fullWidth
        label="Activity"
        value={activityId}
        onChange={this.handleActivityChange}
        picklistValues={activityPicklistValues}
      />
    );
  }
}

ActivityFilter.propTypes = {
  activityId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onValueChange: PropTypes.func,
};

ActivityFilter.ownName = 'ActivityFilter';
ActivityFilter.ownFilters = ['activityId'];


export default ActivityFilter;
