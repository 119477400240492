import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { onValue as fbDbOnValue } from 'firebase/database';
import { IconButton, SvgIcon, Badge } from '@material-ui/core';

import * as stateSelectors from 'app/redux/state/selectors';
import { layoutActions } from 'app/redux/ui/actions';
import * as uiSelectors from 'app/redux/ui/selectors';
import firebaseService from 'service/firebase';


class ToggleNotifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingNotificationsCount: '…',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.createFirebaseRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentLocation.id !== prevProps.currentLocation.id) {
      this.cancelFirebaseRef();
      this.createFirebaseRef();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.cancelFirebaseRef();
  }

  createFirebaseRef() {
    const { currentLocation: { id: locationId } } = this.props;

    const firebaseRef = firebaseService.getPendingNotificationsRef(locationId);

    this._unsubscribe = fbDbOnValue(firebaseRef, (snapshot) => {
      const snapshotVal = snapshot.val();
      const pendingNotificationsCount = snapshotVal ? Object.keys(snapshotVal).length : 0;

      if (this._isMounted) {
        this.setState({ pendingNotificationsCount });
      }
    });
  }

  cancelFirebaseRef() {
    if (this._unsubscribe) {
      this._unsubscribe();
    }

    if (this._isMounted) {
      this.setState({
        pendingNotificationsCount: '…',
      });
    }
  }

  handleClick = () => {
    const { rightDrawer, setRightDrawerView, toggleRightDrawer } = this.props;

    if (rightDrawer.view !== 'notifications') {
      setRightDrawerView('notifications');
      if (!rightDrawer.isOpen) {
        toggleRightDrawer();
      }
    } else {
      toggleRightDrawer();
    }
  };

  render() {
    const { rightDrawer, disabled } = this.props;
    const { pendingNotificationsCount } = this.state;

    const activeTab = !disabled && rightDrawer.isOpen && rightDrawer.view === 'notifications';

    return (
      <div className={classnames('notifications-toggle', { active: activeTab, disabled })}>
        <IconButton
          onClick={this.handleClick}
          color="inherit"
          classes={{ root: 'toggle-icon-button' }}
        >
          <Badge
            color="secondary"
            badgeContent={pendingNotificationsCount}
            showZero
            classes={{ badge: classnames('notification-count-badge', { 'zero-notification-count': !pendingNotificationsCount }) }}
          >
            <SvgIcon
              viewBox="0 0 512 512"
              fontSize="inherit"
            >
              <path
                d="M480,0H32C14.368,0,0,14.368,0,32v352c0,17.664,14.368,32,32,32h136.512l75.2,90.24c3.04,3.68,7.552,5.76,12.288,5.76
        c4.736,0,9.248-2.08,12.288-5.76l75.2-90.24H480c17.664,0,32-14.336,32-32V32C512,14.368,497.664,0,480,0z M256,64
        c35.296,0,64,28.704,64,64c0,35.296-28.704,64-64,64s-64-28.704-64-64S220.704,64,256,64z M336,320H176c-8.832,0-16-7.168-16-16
        c0-44.096,35.904-80,80-80h32c44.128,0,80,35.904,80,80C352,312.832,344.832,320,336,320z"
              />
            </SvgIcon>
          </Badge>
        </IconButton>
      </div>
    );
  }
}

ToggleNotifications.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  rightDrawer: PropTypes.object.isRequired,
  setRightDrawerView: PropTypes.func.isRequired,
  toggleRightDrawer: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  currentLocation: stateSelectors.currentLocation(state),
  rightDrawer: uiSelectors.rightDrawer(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRightDrawerView: (view) => dispatch(layoutActions.setRightDrawerView(view)),
  toggleRightDrawer: () => dispatch(layoutActions.toggleRightDrawer()),
});

const ToggleNotifications2 = connect(mapStateToProps, mapDispatchToProps)(ToggleNotifications);


export default ToggleNotifications2;
