import { createReducer } from 'service/utility/redux';

import {
  SHOW_EVENT_CHECKIN_DIALOG, HIDE_EVENT_CHECKIN_DIALOG,
  EVENT_CHECKIN_DIALOG_SET_CHECKEDINCUSTOMERID,
} from '../actions/dialog';


const initState = {
  isOpen: false,
  ticketUUID: null,
  checkedInCustomerId: null,
};

const eventCheckinDialog = createReducer(initState, {
  [SHOW_EVENT_CHECKIN_DIALOG](state, { ticketUUID }) {
    return {
      ...state,
      isOpen: true,
      ticketUUID,
      checkedInCustomerId: null,
    };
  },
  [EVENT_CHECKIN_DIALOG_SET_CHECKEDINCUSTOMERID](state, { checkedInCustomerId }) {
    return {
      ...state,
      checkedInCustomerId,
    };
  },
  [HIDE_EVENT_CHECKIN_DIALOG](state) {
    return {
      ...state,
      isOpen: false,
      checkedInCustomerId: null,
    };
  },
});


export default eventCheckinDialog;
