// eslint-disable-next-line import/prefer-default-export
export const REPORT_HEADERS = [
  {
    label: 'Revenue Categories',
    key: 'revenueCategoryName',
  },
  {
    label: 'Cash Sale',
    key: 'totalCashSale',
  },
  {
    label: 'Cash Tax Total',
    key: 'totalCashSaleTax',
  },
  {
    label: 'Cash Total',
    key: 'totalCashSaleTaxInclusive',
  },
  {
    label: 'Credit Sale',
    key: 'totalCreditSale',
  },
  {
    label: 'Credit Tax Total',
    key: 'totalCreditSaleTax',
  },
  {
    label: 'Credit Total',
    key: 'totalCreditSaleTaxInclusive',
  },
  {
    label: 'Gift Card Redemption',
    key: 'totalGiftcardRedemption',
  },
  {
    label: 'Gift Card Redemption Tax',
    key: 'totalGiftcardRedemptionTax',
  },
  {
    label: 'Gift Card Redemption Total',
    key: 'totalGiftcardRedemptionTaxInclusive',
  },
  {
    label: 'Ach Sale',
    key: 'totalAchSale',
  },
  {
    label: 'Ach Tax Total',
    key: 'totalAchSaleTax',
  },
  {
    label: 'Ach Total',
    key: 'totalAchTaxInclusive',
  },
  {
    label: 'Store Credit Sale',
    key: 'totalStoreCreditSale',
  },
  {
    label: 'Store Credit Tax Total',
    key: 'totalStoreCreditSaleTax',
  },
  {
    label: 'Store Credit Total',
    key: 'totalStoreCreditSaleTaxInclusive',
  },
  {
    label: 'Cash Refund',
    key: 'totalCashRefund',
  },
  {
    label: 'Cash Refund Tax',
    key: 'totalCashRefundTax',
  },
  {
    label: 'Cash Refund Total',
    key: 'totalCashRefundTaxInclusive',
  },
  {
    label: 'Credit Refund',
    key: 'totalCreditRefund',
  },
  {
    label: 'Credit Refund Tax',
    key: 'totalCreditRefundTax',
  },
  {
    label: 'Credit Refund Total',
    key: 'totalCreditRefundTaxInclusive',
  },
  {
    label: 'Gift Card Refund',
    key: 'totalGiftcardRefund',
  },
  {
    label: 'Gift Card Refund Tax',
    key: 'totalGiftcardRefundTax',
  },
  {
    label: 'Gift Card Refund Total',
    key: 'totalGiftcardRefundTaxInclusive',
  },
  {
    label: 'Ach Refund',
    key: 'totalAchRefund',
  },
  {
    label: 'Ach Refund Tax',
    key: 'totalAchRefundTax',
  },
  {
    label: 'Ach Refund Total',
    key: 'totalAchRefundTaxInclusive',
  },
  {
    label: 'Store Credit Refund',
    key: 'totalStoreCreditRefund',
  },
  {
    label: 'Store Credit Refund Tax',
    key: 'totalStoreCreditRefundTax',
  },
  {
    label: 'Store Credit Refund Total',
    key: 'totalStoreCreditRefundTaxInclusive',
  },
  {
    label: 'Cash Net Sale',
    key: 'totalSumCashSale',
  },
  {
    label: 'Cash Net Tax ',
    key: 'totalSumCashTax',
  },
  {
    label: 'Cash Net Total',
    key: 'totalSumCashTaxInclusive',
  },
  {
    label: 'Credit Net Sale',
    key: 'totalSumCreditSale',
  },
  {
    label: 'Credit Net Tax',
    key: 'totalSumCreditTax',
  },
  {
    label: 'Credit Net Total',
    key: 'totalSumCreditTaxInclusive',
  },
  {
    label: 'Gift Card Net Sale',
    key: 'totalSumGiftcardSale',
  },
  {
    label: 'Gift Card Net Tax',
    key: 'totalSumGiftcardTax',
  },
  {
    label: 'Gift Card Net Total',
    key: 'totalSumGiftcardTaxInclusive',
  },
  {
    label: 'Ach Net Sale',
    key: 'totalSumAchSale',
  },
  {
    label: 'Ach Net Tax',
    key: 'totalSumAchTax',
  },
  {
    label: 'Ach Net Total',
    key: 'totalSumAchTaxInclusive',
  },
  {
    label: 'Store Credit Net Sale',
    key: 'totalSumStoreCreditSale',
  },
  {
    label: 'Store Credit Net Tax',
    key: 'totalSumStoreCreditTax',
  },
  {
    label: 'Store Credit Net Total',
    key: 'totalSumStoreCreditTaxInclusive',
  },
  {
    label: 'Taxes',
    key: 'tax',
  },
  {
    label: 'Tax Revenue',
    key: 'totalTaxableRevenue',
  },
  {
    label: 'Non-Taxable Revenue',
    key: 'totalNonTaxableRevenue',
  },
  {
    label: 'Gross Receipts',
    key: 'total',
  },
  {
    label: 'Total Revenue',
    key: 'totalAmount',
  },
  {
    label: 'Gross Receipts excluding Gift Card Redemption',
    key: 'totalWithoutGiftcardRedemption',
  },
  {
    label: 'Total Revenue excluding Gift Card Redemption',
    key: 'amountWithoutGiftcardRedemption',
  },
  {
    label: 'Taxes excluding Gift Card Redemption',
    key: 'taxWithoutGiftcardRedemption',
  },
];
