import { put, take, call, select } from 'redux-saga/effects';

import * as API from 'service/api';

import {
  authLoggedInSuccess, setCurrentStaff, setActiveUsers, SET_CURRENT_USER,
  userImageHasChanged,
} from '../actions';
import { activeUsersSelector } from '../selectors';


function *putInActiveUsers(user) {
  // keep most recent 5 users.
  const activeUsers = yield select(activeUsersSelector);

  const newData = {
    ...activeUsers.data,
    [user.uid]: {
      email: user.email,
      // jwt: user.m,
      // dtCreated: new Date(),
    },
  };

  // if user is in keys: return active users.
  if (activeUsers.keys.indexOf(user.uid) > -1) {
    // the token could be different so we need to update list.
    yield put(setActiveUsers(activeUsers.keys, newData));
  } else {
    // if not: add to beginning of arr.
    const newKeys = [
      user.uid,
      ...activeUsers.keys,
    ];

    yield put(setActiveUsers(newKeys, newData));
  }
}

function *setCurrentUserSaga() {
  while (true) {
    const { user } = yield take(SET_CURRENT_USER);

    yield call(putInActiveUsers, user);

    const { data: staff } = yield call(API.fetchStaffById, user.uid);

    yield put(setCurrentStaff(staff));
    yield put(authLoggedInSuccess(user));

    if (staff) {
      yield put(userImageHasChanged(staff.imageURL));
    }
  }
}


export default setCurrentUserSaga;
