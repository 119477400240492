import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';


const Main = ({ isLeftDrawerOpen, isRightDrawerOpen, children }) => {
  const className = classnames({
    'left-drawer-open': isLeftDrawerOpen,
    'right-drawer-open': isRightDrawerOpen,
  });

  return (
    <main id="content" className={className}>
      {children}
    </main>
  );
};

Main.propTypes = {
  children: PropTypes.any,
  isLeftDrawerOpen: PropTypes.bool,
  isRightDrawerOpen: PropTypes.bool,
};


export default Main;
