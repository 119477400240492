import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import TextField from './TextField';


const PasswordField = ({ className, ...rest }) => (
  <TextField
    {...rest}
    type="password"
    className={classnames('password-field', { [className]: Boolean(className) })}
  />
);

PasswordField.propTypes = {
  className: PropTypes.string,
};


export default PasswordField;
