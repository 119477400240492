import React from 'react';
import { PropTypes } from 'prop-types';

import PAYMENT_METHODS from 'constants/paymentMethods';
import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


const PAYMENT_METHODS_PICKLIST_VALUES = [
  {
    label: 'All',
    value: 0,
  },
  ...PAYMENT_METHODS.map((paymentMethod) => ({
    label: paymentMethod.name,
    value: paymentMethod.value,
  })),
];


class PaymentMethodFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: (
        this.props.hasOwnProperty('method') && this.props.method !== 'null'
          ? this.props.method
          : 0
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['paymentMethod'])) {
      this.handleValueChange();
    }
  }

  handlePaymentMethodChange = (update) => {
    if (!update.hasOwnProperty('value')) return;

    this.setState({
      paymentMethod: update.value,
    });
  };

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  getOwnFilterMap = () => ({
    method: this.state.paymentMethod === 0 ? null : this.state.paymentMethod,
  });

  render() {
    const { paymentMethod } = this.state;

    return (
      <PicklistField
        fullWidth
        label="Payment Method"
        value={paymentMethod}
        className="payment-method-filter"
        onChange={this.handlePaymentMethodChange}
        picklistValues={PAYMENT_METHODS_PICKLIST_VALUES}
      />
    );
  }
}

PaymentMethodFilter.propTypes = {
  method: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onValueChange: PropTypes.func,
};

PaymentMethodFilter.ownName = 'PaymentMethodFilter';
PaymentMethodFilter.ownFilters = ['method'];


export default PaymentMethodFilter;
