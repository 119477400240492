import { BILLING_CONFIG } from 'constants/memberships';

import { commaSpaceJoin, getAddressArray, getBillingAddressArray } from '.';


export const formatAddress = (o) => commaSpaceJoin(getAddressArray(o));

export const formatAddressWithCountry = (o) => commaSpaceJoin(getAddressArray(o, true));

export const formatBillingAddress = (o) => commaSpaceJoin(getBillingAddressArray(o));

export const formatName = (o) => {
  const a = [];

  if (typeof o === 'object' && Boolean(o)) {
    if (o.firstName) a.push(o.firstName);
    if (o.lastName) a.push(o.lastName);
  }

  return a.join(' ');
};

export const formatInitials = (o) => {
  const a = [];

  if (typeof o === 'object' && Boolean(o)) {
    if (o.firstName) a.push(o.firstName[0]);
    if (o.lastName) a.push(o.lastName[0]);
  }

  return a.join(' ');
};

export const testAgainst = (searchString, string) => RegExp(searchString.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i').test(string);

export const capital = (s) => {
  if (typeof s !== 'string') return '';

  return s.charAt(0).toUpperCase() + s.substr(1);
};

// export const proper = (s) => s.replace(/(^\w{1})|(\s+\w{1})/g, (c) => c.toUpperCase());
export const proper = (s) => {
  if (typeof s !== 'string') return '';

  return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
};

export const formatLocationArray = (locations) => (
  locations ? commaSpaceJoin(locations.map((e) => e.name)) : ''
);

export const formatLocations = (o) => (
  formatLocationArray(o.locations)
);

export const formatLocationCount = (o) => (
  Boolean(o.locations) && o.locations.length > 0
    ? `${o.locations[0].name}${o.locations.length > 1
      ? ` and ${o.locations.length - 1} other location${o.locations.length === 2 ? '' : 's'}`
      : ''}`
    : ''
);

export const formatBillingType = (o) => {
  if (!o.billingType) return '';

  const billingType = BILLING_CONFIG.filter(({ value }) => value === o.billingType);

  return billingType.length ? billingType[0].label : '';
};

export const isSimilar = (a, b) => (
  a && b
    ? a.trim().replace(/  +/g, ' ').toUpperCase() === b.trim().replace(/  +/g, ' ').toUpperCase()
    : false
);

export const leftPadZero = (num, width) => num.toString().padStart(width, 0);

export const isUUID = (str) => /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/.test(str);

export const shorten = (str) => {
  if (typeof str !== 'string') return '';
  if (str.length <= 255) return str;

  return `${str.substr(0, 255)}…`;
};

export const formatIsPublicRead = (o) => (
  o.isPublic
    ? o.isPublicRead
      ? 'For Sale & Use Online'
      : ''
    : o.isPublicRead
      ? 'For Use Online Only'
      : 'Not For Online Use or Sale'
);

export const formatExpiresAfterDays = (o) => (
  o.expirationInDays !== null
    ? `Expires at end of day, ${o.expirationInDays} day(s) after the purchase date`
    : 'Never Expires'
);

export const stripHtmlTags = (htmlString) => {
  if (!htmlString) return '';

  return new DOMParser().parseFromString(htmlString.replace(/<br>/g, '\n'), 'text/html').body.textContent;
};
