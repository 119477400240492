import { take, call, select } from 'redux-saga/effects';

import { PUT_ITEMS_IN_CUSTOMER_CART } from '../actions';
import { activeCartIdSelector } from '../selectors';
import { putItemsInCart, focusOnCustomerCart } from './cartSagas';


function *putItemsInCustomerCartSaga() {
  while (true) {
    const { items, customerId, callback } = yield take(PUT_ITEMS_IN_CUSTOMER_CART);

    yield call(focusOnCustomerCart, customerId);
    const cartId = yield select(activeCartIdSelector);
    yield call(putItemsInCart, items, cartId);

    if (callback) {
      callback();
    }
  }
}


export default putItemsInCustomerCartSaga;
