import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';

import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';

import { PicklistField } from '../../formFields';
import { Spinner } from '../../statusIndicators';


class VersionFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      versionsPickListValues: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.loadFormVersions();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadFormVersions = async () => {
    const { form, versionId } = this.props;

    try {
      const { data: formTemplate } = await API.getFormTemplateById(form.id);

      const formVersions = formTemplate?.FormVersions;
      const versionsPickListValues = formVersions.map((version) => ({
        label: `Version ${version.versionNumber}`,
        value: version.id,
      }));
      const versionToCompare = versionId || form.publishedVersionId;
      const currentVersion = formVersions.find(
        (v) => v.id === Number(versionToCompare)
      ) || formVersions[0];

      if (this._isMounted) {
        this.props.onValueChange(currentVersion.id);
        this.setState({
          versionsPickListValues,
          loading: false,
        });
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.getFormTemplateById error: ');
      console.log(errorMessage);

      toast.error(errorMessage);

      this.setState({ loading: false });
    }
  };

  handleVersionChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.props.onValueChange(update.value);
    }
  };

  render() {
    const { loading, versionsPickListValues } = this.state;
    const { versionId } = this.props;

    return loading ? (
      <Spinner />
    ) : (
      <PicklistField
        label="Versions"
        value={versionId}
        onChange={this.handleVersionChange}
        picklistValues={versionsPickListValues}
      />
    );
  }
}

VersionFilter.propTypes = {
  form: PropTypes.object.isRequired,
  onValueChange: PropTypes.func,
  versionId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default VersionFilter;
