import { take, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getErrorMessage } from 'service/utility';

import { UNASSIGN_CART_STAFF, setCartLoading } from '../actions';
import { activeCartIdSelector } from '../selectors';
import { unassignStaffFromCart, createNewEmptyCart } from './cartSagas';


function *unassignCartStaffSaga() {
  while (true) {
    yield take(UNASSIGN_CART_STAFF);

    const cartId = yield select(activeCartIdSelector);
    if (!cartId) return null;

    const toast1 = toast('Unassigning...');

    try {
      yield put(setCartLoading(true));
      yield call(unassignStaffFromCart, cartId);
      yield call(createNewEmptyCart);

      toast.dismiss(toast1);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('unassignCartStaffSaga error: ');
      console.log(errorMessage);

      toast.dismiss(toast1);
      toast.error(errorMessage);
    } finally {
      yield put(setCartLoading(false));
    }
  }
}


export default unassignCartStaffSaga;
