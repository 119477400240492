const ITEM_TYPE_MAP = {
  GIFT_CARD: 'Gift Card',
  PASS: 'Pass',
  MEMBERSHIP: 'Membership',
  MEMBERSHIP_RESUME: 'Membership',
  PASS_OR_MEMBERSHIP: 'Pass/Membership',
  FEE: 'Fee',
  EVENT_BOOKING_TICKET: 'Ticket',
  EVENT_SERIES_TICKET: 'Multi-Day Ticket',
  EVENT_BOOKING_INVOICE: 'Deposit',
  PRODUCT: 'Product',
  PRICING_TIER: 'Pricing Tier',
  TIP: 'Tip',
};

// eslint-disable-next-line import/prefer-default-export
export const getItemTypeName = (itemType) => ITEM_TYPE_MAP[itemType] ?? null;
