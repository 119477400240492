import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import { TextField } from '@material-ui/core';

import { isRequired } from 'service/utility/errorMessages';
import * as timeUtils from 'service/utility/timeUtils';

import PortalWrapper from '../../PortalWrapper';


class TimeField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount() {
    const { value: stringValue, onChange } = this.props;
    const value = stringValue || null;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.required !== this.props.required || prevProps.value !== this.props.value) {
      const { value: stringValue, onChange } = this.props;
      const value = stringValue || null;
      const error = this.validateSelf(value);

      if (onChange) {
        onChange({ error });
      }
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }

  validateSelf = (value) => {
    const { required, label } = this.props;

    if (!value && required) return isRequired(label);

    return null;
  };

  handleLeaveFocus = () => {
    const { value: stringValue, onChange } = this.props;
    const value = stringValue || null;
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ error });
    }
  };

  handleChangeSelf = (jsDateValue) => {
    const { onChange } = this.props;
    const value = jsDateValue ? timeUtils.dateHoursAndMinutes(jsDateValue) : null;

    if (onChange) {
      onChange({ value });
    }
  };

  render() {
    const {
      autoFocus, className, disabled, error, fullWidth, onChange, label, margin, narrow, required,
      value: stringValue, variant, DatePickerProps, ...rest
    } = this.props;
    const { blurred } = this.state;

    const timeFieldClassName = classnames('time-field', { narrow, [className]: Boolean(className) });
    const dateFormat = timeUtils.getSimpleTimeDatePickerFormat();
    const jsDateValue = !stringValue ? null : timeUtils.localDateFromIso8601Time(stringValue);
    const showError = blurred && Boolean(error);

    return (
      <div className={timeFieldClassName}>
        <DatePicker
          dateFormat={dateFormat}
          selected={jsDateValue}
          onChange={this.handleChangeSelf}
          onBlur={this.handleLeaveFocus}
          required={required}
          autoFocus={autoFocus}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          customInput={
            <TextField
              error={showError}
              helperText={error}
              required={required}
              fullWidth={fullWidth}
              label={label}
              InputLabelProps={{
                shrink: true,
              }}
              margin={margin}
              variant={variant}
              InputProps={{
                readOnly: true,
              }}
              {...rest}
            />
          }
          disabled={disabled}
          popperContainer={PortalWrapper}
          {...DatePickerProps}
        />
      </div>
    );
  }
}

TimeField.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  DatePickerProps: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  margin: PropTypes.string,
  narrow: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
};

TimeField.defaultProps = {
  autoFocus: false,
  DatePickerProps: {},
  error: '',
  fullWidth: false,
  margin: 'dense',
  narrow: false,
  required: false,
  value: '',
  variant: 'outlined',
};


export default TimeField;
